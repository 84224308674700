import { SharedService, userData } from 'src/app/shared/services/shared.service';
import { CryptUtilService } from './../../../shared/services/crypt-util.service';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { ProjectGlobalInfoModel } from './../../../shared/model/project-global-info.model';
import { PassGlobalInfoService } from './../../../shared/services/pass-project-global-info.service';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';
import { Subscription } from 'rxjs/Subscription';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { BUS_MESSAGE_KEY } from 'src/app/shared/constants/message-bus';
import { CommonDialogueBoxComponent } from 'src/app/shared/components/common-dialogue-box/common-dialogue-box.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { IIDRService } from '@projects/iidr/src/app/iidr/iidr.service';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { UsersDataSharedService } from '@projects/user-management/src/app/user/service/users-data-shared.service';
import { Location } from '@angular/common'
import { UNKNOWN } from 'src/app/shared/constants/external-user-constants';
import { User } from 'src/app/shared/constants/ascend-user-info';
import { environment } from 'src/environments/environment';
import { DELOITTE_WEB_PRIVACY, discoverCard } from '../../constants/home-constant'
import {TranslateService} from '@ngx-translate/core';
import { LoggerService } from '@projects/shared-lib/src/public-api';


@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit, OnDestroy, AfterViewInit {
  projectGlobalInfo: ProjectGlobalInfoModel;
  lsProjectGlobalData: any = this.cryptUtilService.getItem(
    "projectGlobalInfo",
    "LOCAL"
  );
  projectPackageData: any;
  projectFunctionsData: any[] = [];
  activePackagesData: any[] = [];
  activePackages: any[] = [];
  activeFunctions: any[] = [];
  userData: User;

  userPrefPackage: any;
  userPrefFunction: any[] = [];
  userPrefPackageId: any;
  userPrefActiveFunctions: any[] = [];
  datta: any;
  userDataForUserPreference: any;
  private subscriptions: Subscription[] = [];
  activeOffering: any;
  selectedOffering: any;
  isExternalUser: boolean;
  isUnknownUser;
  copyrightDate: number = new Date().getFullYear();
  ascendLogoSource: string;
  isDiscover: boolean = false;
  discoverCards: discoverCard[];
  languageId: number = 0;
  userPrefLanguageId: number = 1;

  constructor(
    private router: Router,
    private cryptUtilService: CryptUtilService,
    private userPreferences: UserPreferenceService,
    private messagingBus: MessagingService,
    private globalData: PassGlobalInfoService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private usersDataSharedService: UsersDataSharedService,
    private iidrService: IIDRService,
    private location: Location,
    private translate: TranslateService,
    private logger: LoggerService,
    private userPref: UserPreferenceService
  ) {

    this.userPref.getLanguageId().subscribe((res)=>{
      this.languageId = res;
    });

    this.discoverCards = [
      {
        iconImage: "assets/welcome/discover-icons/learn.svg",
        iconHoverImage: "assets/welcome/discover-icons/learn-hover.svg",
        content: "LandingPage.DiscoverCards.learn",
        buttonName: "learn"
      },
      {
        iconImage: "assets/welcome/discover-icons/ascendGO.svg",
        iconHoverImage: "assets/welcome/discover-icons/ascendGo-hover.svg",
        content: "LandingPage.DiscoverCards.ascendgo",
        buttonName: "ascendGO"
      },
      {
        iconImage: "assets/welcome/discover-icons/explore.svg",
        iconHoverImage: "assets/welcome/discover-icons/explore-hover.svg",
        content: "LandingPage.DiscoverCards.explore",
        buttonName: "explore"
      },
      {
        iconImage: "assets/welcome/discover-icons/ascendKX.svg",
        iconHoverImage: "assets/welcome/discover-icons/ascendKX-hover.svg",
        content: "LandingPage.DiscoverCards.ascendKX",
        buttonName: "ascendKX"
      },
      {
        iconImage: "assets/welcome/discover-icons/support.svg",
        iconHoverImage: "assets/welcome/discover-icons/support-hover.svg",
        content: "LandingPage.DiscoverCards.support",
        buttonName: "support"
      },
      {
        iconImage: "assets/welcome/discover-icons/training.svg",
        iconHoverImage: "assets/welcome/discover-icons/training-hover.svg",
        content: "LandingPage.DiscoverCards.training",
        buttonName: "training"
      }
    ]
  }

  ngOnInit() {
    this.ascendLogoSource = "assets/welcome/ascend-logo-animation.mov";
    this.isUnknownUser = null;
    this.subscriptions.push(
      this.messagingBus.subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
        this.userData = data;
        this.userData.userId = data?.ssoUser?.userPrincipalName ?? data.userId;
        // this.usersDataSharedService.updateUserRoleAssociation(this.projectGlobalInfo, this.userData.userId, this.globalData, true)
        if (this.userData?.userName?.split(UNKNOWN)?.length >= 2) {
          this.isUnknownUser = this.isExternalUser = true;
          this.location.replaceState("/external-user-signup");
          this.sharedService.setHideNavigationBar(this.isUnknownUser);
        } else {
          this.isUnknownUser = false;
          this.sharedService.setHideNavigationBar(this.isUnknownUser);
        }
      })
    );

    this.sharedService.aboutScreen.subscribe((val) => {
      if (val) {
        document.getElementById("about-banner").scrollIntoView();
      }
    });

    this.subscriptions.push(
      this.sharedService.getUserPermissionDataUpdated()?.subscribe((data) => {
        if (!this.userData?.userName?.includes(UNKNOWN)) {
          this.isExternalUser =
            this.sharedService?.getExternalUserData()?.isExternalUser;
        }
      })
    );

    this.subscriptions.push(
      this.sharedService.getIsExternalSignupCompleted().subscribe((data) => {
        if (data != null) {
          this.isExternalSignupCompleted(data);
        }
      })
    );
  }

  homepageActions(title) {
    switch (title) {
      case 'Discover':
        this.navigateToDiscover();
        break;
      case 'Explore':
        this.navigateToExploreMode();
        break;
      case 'Execute':
        this.start();
        break;
    }
  }

  start() {
    // localStorage.clear();
    this.cryptUtilService.sessionClear();
    this.userPreferences
      .getUserPreferencesData(this.userData?.userId)
      .subscribe(
        (response) => {
          if (response && response?.data) {
            response.data.forEach((ele) => {
              this.userPrefPackage = ele.package;
              this.userPrefFunction = ele.functions;
              this.userPrefPackageId = ele.package_id;
              this.userPrefLanguageId = ele.language_id;
              this.sendLanguageId(this.userPrefLanguageId);
              this.sendPackageData(this.userPrefPackage);
              this.sendFunctionData(this.userPrefFunction);
              this.sendPackageIdData(this.userPrefPackageId);
              this.sendParentPackageId(ele.parent_package_id);
              this.userPreferences.sendOfferingData(ele.parent_package_nm);
              this.cryptUtilService.setItem(
                "Previous-Session-Package-Selected-IIDR-Explore",
                this.userPrefPackage,
                "SESSION"
              );
              this.cryptUtilService.setItem(
                "Previous-Session-Function-Selected-IIDR-Explore",
                this.userPrefFunction,
                "SESSION"
              );
              this.cryptUtilService.setItem(
                "Previous-Session-Offering-Selected-IIDR-Explore",
                ele.parent_package_nm,
                "SESSION"
              );
            });
            //For Null Function and Package
            if (!this.userPrefFunction || !this.userPrefPackage) {
              this.userPreference();
            } else {
              this.sharedService
                .getPackagePref(this.userPrefPackageId, 0, 0, this.languageId)
                .subscribe((res) => {
                  if (res) this.globalData.sendToPackagePref(res);
                  this.router.navigate(["/workspace/my-projects"]);
                  this.iidrService.getTab(1);
                },
                (error) => {
                  this.userPreference();
                });
            }
          } else {
            if (this.router.url == "/welcome" && !response?.data) {
              this.saveNewUser();
              if (this.isExternalUser)
                this.router.navigate(["/workspace/my-projects"]);
              else this.userPreference();
              this.globalData.sendToWorkspaceUserPref("WorkspaceView");
            }
          }
        },
        (error) => {
          console.log(error);
          this.userPreference();
        }
      );
  }
  sendParentPackageId(parent_packgae_Id: any) {
    this.userPreferences.sendOfferingIdData(parent_packgae_Id);
  }

  saveNewUser() {
    this.sharedService.getUserData().subscribe((res: userData) => {
      if (res) {
        this.sharedService.saveUserData(res).subscribe(
          (resp: any) => {
            this.logger.log("New User Added successfully!");
          },
          (err) => {
            this.logger.log("Error in adding new user: ", err);
          }
        );
      }
    });
  }

  ngAfterViewInit() {
    this.globalData.scrollToView.subscribe((data) => {
      if (data == "Explore")
        document.getElementById("reimagineOracleCloud").scrollIntoView();
      else if (data == "About") {
        document.getElementById("about-banner").scrollIntoView();
      }
    });
  }

  navigateToExploreMode() {
    this.projectGlobalInfo = new ProjectGlobalInfoModel();
    this.projectGlobalInfo.viewMode = "EXPLORE";
    this.projectGlobalInfo.projectId = "0";
    this.projectGlobalInfo.uniqueId = "0";
    this.projectGlobalInfo.projectName = "";
    this.projectGlobalInfo.clientName = "";
    this.projectGlobalInfo.clientUrl = "";
    this.cryptUtilService.sessionClear();
    this.globalData.updateGlobalData(this.projectGlobalInfo);
    this.globalData.setProjectDetails({
      project: {},
      viewmode: this.projectGlobalInfo.viewMode
    });
    this.iidrService.setIsDataExplore.next(true);
    this.cryptUtilService.setItem('Previous-Session-Function-Selected-IIDR-Project', '', 'SESSION');
    this.userPreferences
      .getUserPreferencesData(this.userData?.userId)
      .subscribe(
        (response) => {
          if (response && response?.data && response?.data?.length) {
            const preferences = response.data[0];
            if (!preferences.package || !preferences.function_id) {
              this.userPreference();
              this.globalData.sendToExploreUserPref("IIDRView");
            } else {
              response.data.forEach((ele) => {
                this.userPrefPackage = ele.package;
                this.userPrefFunction = ele.functions;
                this.userPrefPackageId = ele.package_id;
                this.userPrefLanguageId = ele.language_id;
                this.sendLanguageId(this.userPrefLanguageId);
                this.sendPackageData(this.userPrefPackage);
                this.sendFunctionData(this.userPrefFunction);
                this.sendPackageIdData(this.userPrefPackageId);
                this.sendParentPackageId(ele.parent_package_id);
                this.userPreferences.sendOfferingData(ele.parent_package_nm);
                this.cryptUtilService.setItem(
                  "Previous-Session-Package-Selected-IIDR-Explore",
                  this.userPrefPackage,
                  "SESSION"
                );
                this.cryptUtilService.setItem(
                  "Previous-Session-Function-Selected-IIDR-Explore",
                  this.userPrefFunction,
                  "SESSION"
                );
                this.cryptUtilService.setItem(
                  "Previous-Session-Offering-Selected-IIDR-Explore",
                  ele.parent_package_nm,
                  "SESSION"
                );
              });

              this.sharedService
                .getPackagePref(this.userPrefPackageId, 0, 0, this.languageId)
                .subscribe((res) => {
                  let scrollBarLayout =
                    document.getElementById("container_layout");
                  scrollBarLayout.scrollTop = 0;
                  this.router.navigate(['/iidr']);
                  //Need to check routing
                  // this.router.navigate([]).then(res => { window.open('/iidr','_blank'); });
                  this.iidrService.getTab(0);
                },
                (error) => {
                  this.userPreference();
                });
            }
          } else {
            if (this.router.url == "/welcome" && !response?.data) {
              this.saveNewUser();
              this.userPreference();
              this.globalData.sendToExploreUserPref("IIDRView");
            }
          }
        },
        (error) => {
          console.log(error);
          this.userPreference();
        }
      );
    this.usersDataSharedService.updateUserRoleAssociation(
      this.projectGlobalInfo,
      this.userData.userId,
      this.globalData,
      true
    );
  }

  navigateToAscendDemo() {
    let URL =
      "https://web.microsoftstream.com/video/151dc484-7102-46ec-9da1-785ff3bee7f6";
    if (URL) {
      window.open(URL);
    }
  }

  navigateToTerms() {
    // let URLTerms='https://er45.deloitteonline.com/dol/dol_DisplayContent.aspx?Type=4';
    // if(URLTerms){
    //   window.open(URLTerms);
    // }
    this.openTermsOfUseDialog();
  }

  navigateToPolicy() {
    let URLPolicy = DELOITTE_WEB_PRIVACY;
    if (URLPolicy) {
      window.open(URLPolicy);
    }
  }

  openTermsOfUseDialog() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = "26rem";
    // dialogConfig.height = "14.5rem";
    dialogConfig.data = {
      title: this.translate.instant("LandingPage.TermsOfUse.Title"),
      primarybutton: this.translate.instant("LandingPage.TermsOfUse.Button.OK"),
    };

    const dialogRef = this.dialog.open(TermsOfUseComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      if (data === "true") {
        return;
      }
    });
  }

  userPreference() {
    this.dialog.open(CommonDialogueBoxComponent, {
      data: {
        from: "USER_PREFERENCES",
        userId: this.userData?.userId,
        title: this.translate.instant("Navigation.Menu.UserPreferences.Dialog.Title"),
        messageText: this.translate.instant("Navigation.Menu.UserPreferences.Dialog.MessageText"),
        buttonText: this.translate.instant("Navigation.Menu.UserPreferences.Dialog.Button.Apply"),
        buttonContinueClass: true,
        cancelBtn: this.translate.instant("Navigation.Menu.UserPreferences.Dialog.Button.Cancel"),
        buttonCancelClass: true,
        activePackages: this.activePackages,
        activeFunctions: this.activeFunctions,
        activeOffering: this.activeOffering,
        projectPackage: this.projectPackageData,
        selectedOffering: this.selectedOffering,
        selectedPackage: this.activePackagesData,
        selectedFunctions: this.projectFunctionsData,
        userPrefPackage: this.userPrefPackage,
        userPrefFunction: this.userPrefFunction,
      },
      maxWidth: "37vw",
      width: "37vw",
      panelClass: "custom-dialog-container",
    });
  }

  sendUserData() {
    this.userPreferences.sendUserId(this.userData?.userId);
  }

  sendLanguageId(i: any) {
    this.userPreferences.sendLanguageId(i);
  }
  sendPackageData(p: any) {
    this.userPreferences.sendPackageData(p);
  }

  sendFunctionData(f: any) {
    this.userPreferences.sendFunctionData(f);
  }

  sendPackageIdData(p: any) {
    this.userPreferences.sendPackageIdData(p);
  }

  isExternalSignupCompleted(event) {
    if (event) {
      this.location.replaceState("/welcome");
      this.isUnknownUser = this.isExternalUser = false;
      this.sharedService.setHideNavigationBar(this.isUnknownUser);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach((sub) => {
      sub.unsubscribe();
    });
    this.sharedService.triggerUserRelatedAPI(true);
  }

  navigateToDiscover() {
    if (this.isExternalUser) {
      this.router.navigate([]).then(res => { window.open('/about','_blank'); });

      // this.router.navigateByUrl('/about');
    } else {
      // window.open(environment.ASCEND_GO_URL);
      this.isDiscover = true;
    }
  }
  discoverAction(title) {
    switch (title) {
      case 'learn':
        this.router.navigate([]).then(res => { window.open('/about','_blank'); });
        break;
      case 'ascendGO':
        window.open(environment.ASCEND_GO_URL);
        break;
      case 'explore':
        this.navigateToExploreMode();
        break;
      case 'ascendKX':
        window.open('https://resources.deloitte.com/sites/consulting/sandd/delivery_excellence/Pages/Deloitte_Ascend.aspx');
        break;
      case 'support':
        window.open('https://deloitteus.service-now.com/sp?id=sc_cat_item&sys_id=0027aca34f3bd7c433c77bcd0210c72d&table=sc_cat_item&sysparm_value=AE');
        break;
      case 'training':
        window.open('https://deloitteus.service-now.com/sp?id=sc_cat_item&sys_id=0027aca34f3bd7c433c77bcd0210c72d&table=sc_cat_item&sysparm_value=AE');
        break;
    }
  }
  backToMain() {
    this.isDiscover = false;
  }
}
