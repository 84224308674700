<ng-container *ngIf="notify">
    <div class="notifictaion" [ngClass]="{'bg-red' : showSuccessNotification == false }">
        <!-- <igx-circular-bar [value]="100" [animate]="true" class="custom-size"></igx-circular-bar> -->
        <img *ngIf="!isInProgress && showSuccessNotification" src="assets/Complete.svg" id="complete-icon">
        <span *ngIf="showSuccessNotification && !depthlimitexists">Test scenarios generation for <strong>Prepare: Generate integration scenraios and iterations</strong> is completed. You can continue with this activity.</span>
        <span *ngIf="showSuccessNotification && depthlimitexists"> Test scenario generation for Prepare: Generate integration scenarios, and iterations are completed, but for 
            <span *ngFor="let name of l1Names;let i = index">{{name}} {{ i == l1Names.length - 1 ? '' : ', '}}</span> {{l1Names.length > 1 ? 'processes' : 'process'}} 
            scenarios could not be generated because of the high number of scenarios; please try generating scenarios manually. </span>
        
        <span *ngIf="!showSuccessNotification">Test scenarios generation for <strong>Prepare: Generate integration scenarios, and iterations</strong> is failed. Please try again with this activity.</span>
        <button *ngIf="isInProgress" class="notify-btn">Cancel generation</button>
        <button *ngIf="!isInProgress && showSuccessNotification"  class="notify-btn" (click)="openActivity()">Open activity</button>
        <mat-divider [vertical]="true" [inset]="true"></mat-divider>
        <img src="assets/closeIconDark.svg" class="close" (click)="setShowNotification()">
    </div>
</ng-container>