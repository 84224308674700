import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedLibComponent } from './components/shared-lib.component';
import { ReactiveFormsModule,FormsModule  } from '@angular/forms';
import { MaterialModule } from './utility';
import { SearchBoxComponent } from '../lib/components/search-box/search-box.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SprintBarchartComponent } from './components/sprint-barchart/sprint-barchart.component';
import { AscendPaginatoionDirective } from './components/ascend-pagination.directive';
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { CommonTableComponent } from './components/common-table/common-table.component';
import { DataPropertyGetterPipe } from './core/pipe/data-property-getter.pipe';
import { CustomTagPipe } from './core/pipe/tag.pipe';
import { EllipsisPipe } from './core/pipe/ellipsis.pipe';
import { CommonTreeFilterPopupComponent } from './components/common-tree-filter-popup/common-tree-filter-popup.component';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { SearchDocumentComponent } from './components/common-header/search-document/search-document.component';
import { CommonDragDropComponent } from './components/common-drag-drop/common-drag-drop.component';
import { CommonDragTileComponent } from './components/common-drag-tile/common-drag-tile.component';
import { UsefulResourcesComponent } from '@projects/methodology/src/app/methodology/components/useful-resources/useful-resources.component';
import { CommonNotificationBarComponent } from './components/common-notification-bar/common-notification-bar.component';
import { UploadErrorPopupComponent } from './components/upload-error-popup/upload-error-popup.component';
import { ErrorTableComponent } from './components/upload-error-popup/error-table/error-table.component';
import { AutoReportNotificationComponent } from './components/auto-report-notification/auto-report-notification/auto-report-notification.component';
import { QuickNavigatorComponent } from './components/quick-navigator/quick.navigator.component';
import {TranslateModule} from '@ngx-translate/core';
import { DisciplineFilterComponent } from './components/discipline-filter/discipline-filter.component';
import { ChipsApplicableComponent } from './components/chips-applicable/chips-applicable.component';
import { ToggleGridComponent } from './components/toggle-grid/toggle-grid.component';

@NgModule({
  declarations: [
    SharedLibComponent,
    SearchBoxComponent,
    SprintBarchartComponent,
    AscendPaginatoionDirective,
    CommonHeaderComponent,
    CommonTableComponent,
    DataPropertyGetterPipe,
    CustomTagPipe,
    EllipsisPipe,
    CommonTreeFilterPopupComponent,
    DocumentViewerComponent,
    SearchDocumentComponent,
    CommonDragDropComponent,
    CommonDragTileComponent,
    CommonNotificationBarComponent,
    UploadErrorPopupComponent,
    ErrorTableComponent,
    AutoReportNotificationComponent,
    QuickNavigatorComponent,
    DisciplineFilterComponent,
    ChipsApplicableComponent,
    ToggleGridComponent,
  ],
    imports: [
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        CommonModule,
        NgxDocViewerModule,
        TranslateModule
    ],
  exports: [
    SharedLibComponent,
    MaterialModule,
    NgxFileDropModule,
    SearchBoxComponent,
    SprintBarchartComponent,
    AscendPaginatoionDirective,
    CommonHeaderComponent,
    CommonTableComponent,
    CommonTreeFilterPopupComponent,
    DocumentViewerComponent,
    CommonDragDropComponent,
    CommonDragTileComponent,
    DataPropertyGetterPipe,
    CustomTagPipe,
    EllipsisPipe,
    CommonNotificationBarComponent,
    UploadErrorPopupComponent,
    ErrorTableComponent,
    AutoReportNotificationComponent,
    QuickNavigatorComponent,
    DisciplineFilterComponent,
    ChipsApplicableComponent,
    ToggleGridComponent,
  ]
})
export class SharedLibModule { }
