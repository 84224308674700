import { Inject, Injectable } from "@angular/core";
import { Location } from '@angular/common'
import { Subscription } from 'rxjs';
import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { MessagingService } from './messaging.service';
import { BUS_MESSAGE_KEY } from '../constants/message-bus';
import { environment } from '../../../environments/environment';
import { SharedService } from './shared.service';
import { CryptUtilService } from "./crypt-util.service";
import { Router } from '@angular/router';
import { EXTERNAL, UNKNOWN } from "../constants/external-user-constants";
import { User, SSOUSERI } from '../constants/ascend-user-info';
import { FeatureConfig } from "src/app/shared/model/feature-config.model";
import { SESSION_STORAGE_ROUTE_KEY } from '@projects/shared-lib/src/lib/utility/constants';
import { ExternalUserService } from '../../base/components/external-user-signup/service/external-user.service';
import { AuthenticationResult, EventMessage, EventType, RedirectRequest } from "@azure/msal-browser";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    userData: User;
    languageId: number;
    permissionData: any;
    userRoleData: any;
    private fetchInprogress: boolean = false;
    private authListeners: Subscription[] = [];


    constructor(
        private location: Location,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        @Inject(MsalBroadcastService) private broadcastService: MsalBroadcastService,
        private messagingService: MessagingService,
        private sharedService: SharedService,
        private http: HttpClient,
        private cryptUtilService: CryptUtilService,
        private router: Router,
        private externalUserService: ExternalUserService,
    ) {
    }

    /**
     *  trigger this method to initialize authentication flow
     *  when we trigger this from local, auth is not required, send a user to mimick the flow
     *  @param user
     */
    initializeAuth(user?: any): void {
        console.log('In initializeAuth');

        const initialRoute = sessionStorage.getItem(SESSION_STORAGE_ROUTE_KEY)
        if (initialRoute == null && initialRoute != '')
            sessionStorage.setItem(SESSION_STORAGE_ROUTE_KEY, this.location.path())

        if (environment.isLocal) {
            this.triggerLocalAuth(user);
        } else {
            if (!window.location.href.includes("logout") && !window.location.href.includes("client/survey"))
                this.triggerSSOAuth();
        }
    }

    /**
     * @returns Logged in User and details,
     * better use this.messagingService.subscribe(BUS_MESSAGE_KEY.USER_DETAILS, () => {});
     */
    getUser() {
        console.log('In getUser');
        return this.userData;
    }

    /**
     * triggers the login flow
     */
    login() {
        console.log('Trying to log in now');
        this.msalService.loginRedirect({ scopes: [environment.AUTH_RESOURCE_LINK.webAPI, "openid", "profile", "user.read"] } as RedirectRequest);
    }

    /**
     * logout user
     */
    logout() {
        this.msalService.logout();
    }

    private triggerLogoutAuth(currentUserData?: any): void {
        console.log('Trigerring Logout');
        const user = currentUserData;
        console.log(user)
        console.log('Printing ID_TOKEN NULL CHECK');
        if (user != null)
            console.log(user.idToken);
        if (!user) {
            this.login();
        }
    }

    /**
     * unsubscribe all listeners
     */
    cleanListerners() {
        for (let subscriber of this.authListeners) {
            if (subscriber) {
                subscriber.unsubscribe();
            }
        }

    }

    /**
     *
     * @param user - the user with which to update the user details
     */
    private triggerLocalAuth(user: User): void {
        console.log('Trigerring Local/Logout Login');
        if (!user || !user.userId) {
            this.userData = new User(environment.LOCAL_AUTH.userId, environment.LOCAL_AUTH.username);
        } else {
            this.userData = new User(user.userId, user.userName);
        }
        this.getUserProjectDetails();
    }


    /**
     * checks if user present, else triggers the login flow
     */
    triggerSSOAuth() {

        let activeAccount = this.msalService.instance.getActiveAccount();
        const accounts = this.msalService.instance.getAllAccounts();
        if (accounts.length === 0) {
            this.login();
        } else {
            if (!activeAccount && accounts?.length > 0) {
                let accounts = this.msalService.instance.getAllAccounts();
                this.msalService.instance.setActiveAccount(accounts[0]);
                activeAccount = this.msalService.instance.getActiveAccount();
            }

            if (activeAccount?.name?.includes(EXTERNAL)) {
                let userEmailId = JSON.parse(JSON.stringify(activeAccount?.username));
                let firstName = userEmailId.split('@');
                let splitEmail = firstName[0].split("");
                splitEmail[firstName[0].lastIndexOf('_')] = '@';
                let parsedEmail = splitEmail.join("");

                let existingUserData = this.fetchExternalUserData(userEmailId);
                let userName = existingUserData ? existingUserData : activeAccount.name.split(EXTERNAL)[0].trim().split(' ').join(', ');
                this.userData = new User(activeAccount?.username, userName);
                const userInfo = {
                    userId: parsedEmail,
                    userPrincipalName: activeAccount?.username
                };
                this.cryptUtilService.setItem('userInfo', userInfo, 'SESSION');
            } else {
                this.userData = new User(activeAccount.username, activeAccount.name);
                const userInfo = {
                    userId: this.userData?.userId,
                    userPrincipalName: activeAccount?.username
                };
                this.cryptUtilService.setItem('userInfo', userInfo, 'SESSION');
            }
            this.setupAuthListeners();
            this.getUserProjectDetails();
        }
    }

    handleLoginSuccess(result: EventMessage) {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        let activeAccount = this.msalService.instance.getActiveAccount();
        console.log("Active account details : ", activeAccount);
    }

    /**
     * setsup the auth listeners
     *  failure
     *  success
     *  acquireTokenFailure
     */
    private setupAuthListeners() {
        /* -- first clean the listeners if any -- */
        this.cleanListerners();
        this.listenLoginFailure();
        this.listenLoginSuccess();
        this.listenAcquireTokenFailure();
        this.redirect();
    }
    redirect() {
        // this.msalService.instance.handleRedirectCallback((authError, response) => {
        //     if (authError) {
        //       return;
        //     }

        //   });
    }

    /**
     * check if the login failed
     * todo: Create a 401 and redirect
     */
    private listenLoginFailure() {
        console.log('Registering listenLoginFailure');
        /* -- 401-- */
        this.authListeners
            .push(
                this.broadcastService.msalSubject$
                    .pipe(
                        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE)
                    )
                    .subscribe((result) => {
                        this.messagingService.publish(BUS_MESSAGE_KEY.USER_DETAILS, null);
                    })
            )
    }

    /**
     * login is success
     * get user
     */
    private listenLoginSuccess() {
        console.log('Registering listenLoginSuccess');
        this.authListeners
            .push(
                this.broadcastService.msalSubject$
                    .pipe(
                        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
                    )
                    .subscribe((payload) => {
                        console.log('Printing Payload obtained after successfull login')
                        console.log(payload);
                        this.getUserProfile();
                    })
            );
    }

    /**
     * if token failed, handle the failure
     */
    private listenAcquireTokenFailure() {
        console.log('Registering listenAcquireTokenFailure');
        //will work for acquireTokenSilent and acquireTokenPopup
        this.authListeners
            .push(
                this.broadcastService.msalSubject$
                    .pipe(
                        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE)
                    )
                    .subscribe((payload) => {
                        console.log('Printing Payload obtained after listenAcquireTokenFailure')
                        console.log(payload);
                        this.handleTokenFailure(payload)
                    })
            )
    }

    /**
     *
     * @param tokenFailureResponse - the token failure response
     */
    private handleTokenFailure(tokenFailureResponse) {
        console.log('Token failure custom message check')
        console.log(tokenFailureResponse)
    }

    loginInProgress() {
        return this.fetchInprogress;
    }

    /**
     * fetch the user details from SSO
     * this may not be needed and hence not invoked
     */
    private getUserProfile() {
        console.log('In getUserProfile')
        return this.http.get<SSOUSERI>(environment.AUTH_RESOURCE_LINK.self)
            .subscribe(
                data => {
                    this.userData.setssoUserDetails(data);
                    return data;
                },
                error => console.error(" Http get request to MS Graph failed" + JSON.stringify(error)
                )
            );
    }

    /**
     * get the project details for the logged in user, this decides many access levels
     */
    private getUserProjectDetails(): void {
        this.fetchInprogress = true;
        let userInfo = this.cryptUtilService.getItem('userInfo', 'SESSION');
        let userId = userInfo?.userPrincipalName ? userInfo?.userId : this.userData?.userId;
        this.fetchUserInfo(userId, userInfo?.userPrincipalName, undefined, true);
    }

    public fetchUserInfo(userId, userPrincipalName?, updatedUserDetails?, isInitial?: boolean) {
        if (updatedUserDetails?.userName) {
            this.userData.userName = updatedUserDetails?.userName;
            this.updateDeleteExternalUserData(userPrincipalName, updatedUserDetails?.userName);
        }
        let hasPrincipalName = userPrincipalName ? userPrincipalName : userId;
        // const url = `${environment.API_MICROSERVICE_URL?.USER}/user?email=${hasPrincipalName}`;
        const url = `${environment.API_MICROSERVICE_URL?.USER}/user/details?emailId=${hasPrincipalName}`;
        this.http.get<any>(url)
            .subscribe(
                (result: any) => {
                    var data;
                    if (!(result && result.length)) {
                        data = [];
                        data.push(result);
                        this.sharedService.userRoleData = data;
                        this.userRoleData = data;
                    } else {
                        data = result;
                    }
                    if (data && data[0]?.data && data.length) {
                        const userInfo = {
                            userId: data[0]?.data?.emailId,
                            userPrincipalName: userPrincipalName ? userPrincipalName : data[0]?.data?.emailId,
                            activeFlag: data[0]?.data?.activeFlag,
                        };
                        this.cryptUtilService.setItem('userInfo', userInfo, 'SESSION');
                        this.externalUserService.setUserDetails(true);
                        this.getExternalUserData(hasPrincipalName, this.languageId);
                        this.sharedService.setActiveFlag(data[0]?.data?.activeFlag);
                        //for refresh
                        this.sharedService.getUpdatedLangId().subscribe((data) => {
                            if (data) {
                                this.getExternalUserData(hasPrincipalName, data);
                            }
                        });
                        this.getFeatureConfigFlag();
                        if (!environment.isLocal) {
                            const emailId = data[0]?.data?.emailId ?? hasPrincipalName;
                            this.sharedService.getADUser(emailId).subscribe(
                                res => {
                                    if (res) {
                                        this.userData.setssoUserDetails(res.ssoUser);
                                    }
                                    this.setProjectDetails(data, hasPrincipalName);
                                },
                                error => {
                                    this.setProjectDetails(data, hasPrincipalName);
                                }
                            );
                        }
                        else {
                            this.userData.setssoUserDetails(environment.LOCAL_AUTH.ssoUser as SSOUSERI);
                            return this.setProjectDetails(data, hasPrincipalName);
                        }

                    } else {
                        if (this.userData?.userName?.split(UNKNOWN)?.length >= 2 || updatedUserDetails?.userName) {
                            this.getExternalUserData(hasPrincipalName, this.languageId);
                            //for refresh
                            this.sharedService.getUpdatedLangId().subscribe((data) => {
                                if (data) {
                                    this.getExternalUserData(hasPrincipalName, data);
                                }
                            });
                            this.setProjectDetails([], hasPrincipalName);
                        } else {
                            if (!environment.isLocal) {
                                this.sharedService.getADUser(hasPrincipalName).subscribe(
                                    res => {
                                        if (res) {
                                            this.userData.setssoUserDetails(res.ssoUser);
                                        }
                                        this.setProjectDetails([], hasPrincipalName);
                                    },
                                    error => {
                                        this.setProjectDetails([], hasPrincipalName);
                                    }
                                );
                            }
                            else {
                                this.userData.setssoUserDetails(environment.LOCAL_AUTH.ssoUser as SSOUSERI);
                                return this.setProjectDetails([], hasPrincipalName);
                            }

                        }
                        return {};
                    }
                },
                error => {
                    console.error("UNABLE to fetch user data:Error Message:" + error);
                    this.fetchInprogress = false
                }
            );
        if (isInitial) {
            const initialRoute = sessionStorage.getItem(SESSION_STORAGE_ROUTE_KEY)
            if (initialRoute != null && initialRoute != '/logout') {
                sessionStorage.removeItem(SESSION_STORAGE_ROUTE_KEY)
                this.router.navigateByUrl(initialRoute)
            } else if (initialRoute == '/logout') {
                sessionStorage.removeItem(SESSION_STORAGE_ROUTE_KEY)
                this.router.navigateByUrl('/welcome')
            }
        }
    }

    public getExternalUserData(email_id, languageId) {
        if (email_id) {
            //Here we are removing the language Id from the user permission URL as it is not required -  #2865340
            const externalUserURL = `${environment.API_MICROSERVICE_URL?.USER}/user/User-Permissions?email_id=${email_id}`;
            this.http.get(externalUserURL)
                .subscribe(data => {
                    if (data) {
                        this.cryptUtilService.removeItem("external-user-data", "SESSION");
                        let externalUserData;
                        externalUserData = data;
                        this.permissionData = data;     //Adding this variable in this service to use it in other components where already sharedService is used from projects folder instead of src folder
                        this.sharedService.UserPermissionData = data;
                        let tempObj = {};
                        for (let page of externalUserData?.pages) {
                            let temp = {};
                            for (let action of page.actions) {
                                temp[action.action_name?.trim()] = action.permission;
                            }
                            tempObj[page.page?.trim()] = temp;
                        }
                        this.cryptUtilService.setItem("external-user-data", { isExternalUser: externalUserData?.isExternalUser ? externalUserData?.isExternalUser : false, pages: tempObj }, "SESSION");
                        if (externalUserData?.isExternalUser || externalUserData?.isExternalUser === false) {
                            this.sharedService.setExternalUserDataUpdated(true);
                        }
                    }
                });
        }
    }

    setProjectDetails(data, userPrincipalName) {;
        this.fetchInprogress = false
        this.userData.setUserPrincipalName(userPrincipalName);
        this.userData.setProjectDetails(data && data[0] ? data[0] : []);
        this.messagingService.publish(BUS_MESSAGE_KEY.USER_DETAILS, this.userData);
        return data[0];
    }

    public updateDeleteExternalUserData(email_id, userName) {
        if (email_id && userName) {
            let exisitingUserList = this.cryptUtilService.getItem('external-user-list', 'SESSION');
            if (exisitingUserList?.length > 0) {
                let emailIdexists = false;
                for (let obj of exisitingUserList) {
                    if (obj[email_id]) {
                        emailIdexists = true;
                        break;
                    }
                }
                if (!emailIdexists) {
                    exisitingUserList.push({ email_id: userName })
                }
            }
            else {
                exisitingUserList = [{ email_id: userName }];
            }
            this.cryptUtilService.setItem('external-user-list', exisitingUserList, 'SESSION');
        }
    }

    public fetchExternalUserData(email_id) {
        if (email_id) {
            let exisitingUserList = this.cryptUtilService.getItem('external-user-list', 'SESSION');
            if (exisitingUserList?.length > 0) {
                for (let obj of exisitingUserList) {
                    if (obj[email_id]) {
                        return obj[email_id];
                    }
                }
            }
            return '';
        }
    }

    public getFeatureConfigFlag() {
        this.cryptUtilService.removeItem("commonFeatureFlag", "LOCAL");
        const url = `${environment.API_MICROSERVICE_URL?.ADMIN}/feature-configuration/configuration/0`;
        this.http.get(url)
            .subscribe((respObj: FeatureConfig) => {
                if (respObj) {
                    let data = {};
                    for (let obj of respObj?.data) {
                        if (obj?.configName) {
                            data[obj.configName] = obj?.configValue ? obj.configValue : null;
                        }
                    }
                    this.cryptUtilService.setItem('commonFeatureFlag', data, 'LOCAL');
                    setTimeout(() => {
                        this.sharedService.setIsFeatureFlagUpdated(true);
                    }, 500);
                }
            }, (error) => {
            });
    }

}
