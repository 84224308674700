import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { formFilterArray, FilterData, FILTER_CUSTOM_CONSTANTS, FilterArray } from '../model/filter-content.model';
import { environment } from 'src/environments/environment';
import { CryptUtilService } from './crypt-util.service';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class FilterCustomService {
  attributesFilterChanged : boolean;

  constructor(private http: HttpClient, private cryptUtilService: CryptUtilService, private router: Router, public sharedService: SharedService) { }

  getFilterData(filterURL, storageKey, projectId?: any, isSitemapPackageReset: boolean = false): Observable<any> {
    if (storageKey) {
      let data = this.cryptUtilService.getItem(storageKey, 'SESSION');
      if (data) return of(data);
    }
    let customComponentArray;
    let apiUrl;

    if(projectId == 0)
      apiUrl = `${environment.API_MICROSERVICE_URL?.METHOD}/explore/iidrfilter`;
    else
      apiUrl =  `${environment.API_MICROSERVICE_URL?.PROJECT}/project${filterURL}` ;
     
      return this.http.get<any>(apiUrl).map(data => {
        customComponentArray = formFilterArray(data);
        if(filterURL == "/iidrfilter/" + (projectId || 0) + "?ampdel=1&attributes=1&source=sitemap") {
          this.cryptUtilService.setItem('SITEMAP_OG_FILTERDATA', customComponentArray, 'SESSION');
          // if(!isSitemapPackageReset) {        
            this.cryptUtilService.setItem('SELECTED_PACKAGE',this.getSelectedPackage(customComponentArray), 'SESSION')
            this.sharedService.selectedPackage =  this.getSelectedPackage(customComponentArray) 
          // }
        }
        if(filterURL == "/iidrfilter/" + (projectId || 0)) {
          this.cryptUtilService.setItem('IIDR_OG_FILTERDATA', customComponentArray, 'SESSION');
        }
        if (storageKey) this.updateSessionStorage(customComponentArray, storageKey);
        return customComponentArray
      });
   
  }

  IsAdvancedFilterApplicable(filters: FilterData[]): boolean {
    return filters.findIndex(t => t.l1Filter.advFilterApplicable == 'Y') >= 0
  }

  updateSessionStorage(data, storageKey) {
    this.cryptUtilService.setItem(storageKey, data, 'SESSION');
  }

  formURL(filters: FilterData[], filterType?: string): string {
    let filterURL = "";

    for (let customComponent of filters) {
      filterURL += this.formCommaSeparatedString(customComponent, 1, filterType)
    }

    return filterURL;

  }

  appendToURL(filterType, filterObj) {
    return (
      filterType != FILTER_CUSTOM_CONSTANTS.ADVANCED_FILTER
      || (filterType == FILTER_CUSTOM_CONSTANTS.ADVANCED_FILTER && filterObj.l1Filter.advFilterApplicable == 'N')
    )
  }

  formCommaSeparatedString(filterObj: FilterData, level, filterType: string): string {
    let filterArray;
    let URL = "";

    switch (level) {
      case 1: filterArray = filterObj.l1Filter?.filterValues; break;
      case 2: filterArray = filterObj.l2Filter?.filterValues; break;
      case 3: filterArray = filterObj.l3Filter?.filterValues; break;
      case 4: filterArray = filterObj.l4Filter?.filterValues; break;
    }
    if (filterArray && this.appendToURL(filterType, filterObj)) {
      //check if filters were updated
      let changedFlag = (filterArray.findIndex(t => t.changed == 'Y') >= 0);
      if(filterObj.title=="Sprint" && level==1)
      { 
        changedFlag=true;
      }
      if (changedFlag)
        for (let filterEle of filterArray) {
          if (filterEle.childValues)
            URL += Array.prototype.map.call(
              filterEle.childValues.filter(t => t.selectedFlag == 'Y'),
              s => s.entityId
            ).toString();
          //append comma after last element
          URL = URL && URL.length > 0 && URL.charAt(URL.length - 1) === ',' ? URL : URL + ','
        }
      //Remove trailing comma if any
      URL && URL.length > 0 && URL.charAt(URL.length - 1) === ',' ? URL = URL.slice(0,-1) : null;
      URL = (URL ? "/" + URL : "/0") + this.formCommaSeparatedString(filterObj, level + 1, filterType);
    }
    return URL
  }

  updateFilters(originalData: FilterData[], filterComponent: FilterData, storageKey: string, filterType?: string) {

    let index = originalData.findIndex(t => t.l1Filter.filterId === filterComponent.l1Filter.filterId);
    if (index != -1) {
      // if (filterComponent.l1Filter.advFilterApplicable == 'Y' && filterType == FILTER_CUSTOM_CONSTANTS.ADVANCED_FILTER) { //Check if custom component is for advanced filter
      //   this.updateSelectedFlag(filterComponent.l1Filter.filterValues, originalData[index].l1Filter.filterValues);
      //   this.updateSelectedFlag(filterComponent.l2Filter.filterValues, originalData[index].l2Filter.filterValues);
      //   this.updateSelectedFlag(filterComponent.l3Filter.filterValues, originalData[index].l3Filter.filterValues);
      //   this.updateSelectedFlag(filterComponent.l4Filter.filterValues, originalData[index].l4Filter.filterValues);
      // }
      originalData[index] = filterComponent;
    }

    this.updateSessionStorage(originalData, storageKey);

    return originalData;
  }

  getSelectedFlag(filterValues: FilterArray[], childObj) {
    let selectedFlag = childObj ? childObj.selectedFlag : '';
    if (filterValues) {
      for (let ele of filterValues) {
        let index = ele.childValues.findIndex(t =>
          t.entityId == childObj.entityId &&
          t.entityType.toLowerCase() == childObj.entityType.toLowerCase());
        selectedFlag = index == -1 ? selectedFlag : ele.childValues[index].selectedFlag;
      }
    }

    return selectedFlag;
  }

  updateSelectedFlag(newFilterArray: FilterArray[], oldFilterArray: FilterArray[]) {
    if (newFilterArray && oldFilterArray) {
      for (let element of newFilterArray) {
        for (let l1Child of element.childValues) {
          l1Child.selectedFlag = this.getSelectedFlag(oldFilterArray, l1Child);
        }
      }
    }
    return newFilterArray;
  }

  checkFilterSelected(filters: FilterData[]): boolean {
    let checked = false;
    if (filters)
      for (let filterComponent of filters) {
        checked = false;
        if (filterComponent.l1Filter.filterValues)
          // check if atleast one filter value is selected for current filter object
          for (let obj of filterComponent.l1Filter.filterValues) {
            if(obj.childValues){
              let index = obj.childValues.findIndex(t => t.selectedFlag == 'Y');
              if (index != -1) {
                checked = true;
                break
              }
            }            
          }

        if ((!checked) && (filterComponent.l1Filter.filterValues))
          break;// filter found with no selected values return false
      }
    return checked
  }

  isPackageOrFunctionSelected(filters: FilterData[]): boolean {
    let checked = false;
    if (filters)
      for (let filterComponent of filters) {
        checked = false;
        if (filterComponent.l1Filter.filterValues && (filterComponent.title == 'Packages' || filterComponent.title == 'Functions'))
          // check if atleast one filter value is selected for current filter object
          for (let obj of filterComponent.l1Filter.filterValues) {
            if(obj.childValues){
              let index = obj.childValues.findIndex(t => t.selectedFlag == 'Y');
              if (index != -1) {
                checked = true;
                break
              }
              // if(obj.entityType == 'Package') {
              //   this.selectedPackageId = obj.childValues.find(ele => ele.selectedFlag == 'Y');
              // }
            }            
          }

        if ((checked) && (filterComponent.l1Filter.filterValues))
          break;// filter found with no selected values return false
      }
    return checked
  }

  setFunctionsFilterSelectState(changedFilterData: FilterData, storageKey?: string): void {
    // considering apply should be enabled and clickable only when there is atleast one function available.
    let originalFunctionsFilterData;
    if(storageKey) {
      originalFunctionsFilterData = this.cryptUtilService.getItem(storageKey, 'SESSION');
    }
    if(originalFunctionsFilterData) {
      if(changedFilterData) {
      changedFilterData.l1Filter.filterValues[0].childValues.forEach(item => {
        if(item.selectedFlag === 'Y') {
          originalFunctionsFilterData.find( x => x.title == 'Functions').l1Filter.filterValues[0].childValues.find(ele => ele.entityId == item.entityId).selectedFlag = 'Y' 
        }
        else {
          originalFunctionsFilterData.find( x => x.title == 'Functions').l1Filter.filterValues[0].childValues.find(ele => ele.entityId == item.entityId).selectedFlag = 'N' 
        }
      })

      } else {
        originalFunctionsFilterData.find( x => x.title == 'Functions').l1Filter.filterValues[0].childValues.map(ele => ele.selectedFlag = 'N' );
      }
      
      if(originalFunctionsFilterData) {
        // this.cryptUtilService.removeItem(storageKey, 'SESSION');
        this.cryptUtilService.setItem(storageKey, originalFunctionsFilterData, 'SESSION');        
      }
    }
  }

  getSelectedPackage(filters: FilterData[]): FilterArray {
    let selectedPackage;
    filters.forEach(item => {
      if(item.title == 'Packages') {
        selectedPackage = item.l1Filter.filterValues[0].childValues.find(x => x.selectedFlag == 'Y');
      } 
    });
    return selectedPackage;
  }

  filterFuntionsByPackageId(filterDataFromAPI?:any): FilterData {
    let originalSessionFilterData;
    let functionFilteredData: FilterData;
    let filter_data;
    if (this.router.url == "/site-map") {
      originalSessionFilterData = this.cryptUtilService.getItem(
        "SITEMAP_OG_FILTERDATA",
        "SESSION"
      );
    }
    if (this.router.url == "/home") {
      originalSessionFilterData = this.cryptUtilService.getItem(
        "IIDR_OG_FILTERDATA",
        "SESSION"
      );
    }
    if (
      (originalSessionFilterData || filterDataFromAPI) &&
      this.cryptUtilService.getItem("SELECTED_PACKAGE", "SESSION")
    ) {
      filter_data = (filterDataFromAPI) ? filterDataFromAPI : originalSessionFilterData 
      if(filter_data)
      filter_data.forEach((data) => {
        if (data.title === "Functions") {
          functionFilteredData = JSON.parse(JSON.stringify(data));
          functionFilteredData.l1Filter.filterValues[0].childValues = [];

          data.l1Filter.filterValues[0].childValues.forEach((element) => {            
            if (
              element.packageId &&
              element.packageId.find( (x) => x == this.cryptUtilService.getItem("SELECTED_PACKAGE", "SESSION").entityId)
            ) {
              functionFilteredData.l1Filter.filterValues[0].childValues.push(
                element
              );
            }
          });
        }
      });
      return JSON.parse(
        JSON.stringify(functionFilteredData)
      );
    }
  }
}
