import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs-compat';

@Injectable({
  providedIn: 'root'
})
export class DfteService {

  constructor() { }
  private dfteObj: BehaviorSubject<any> = new BehaviorSubject(null);

  getDfteObj(): Observable<any> {
      return this.dfteObj.asObservable();
  }

  setDfteObj(dfte: any) {
      this.dfteObj.next(dfte);
  }
}
