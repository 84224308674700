import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { UserSelectionComponent } from './components/user-selection/user-selection.component';

import { environment} from '../../environments/environment';
import { SiteMapComponent } from './components/site-map/site-map.component';
import { IsAuthenticatedGuard } from '../shared/services/gaurds/can-activate/isAuthenticated.guard';
import { LogoutComponent } from './components/logout/logout.component';
import { MaintenanceBreakComponent } from './components/maintenance-break/maintenance-break.component';
import { ExternalUserSignupComponent } from './components/external-user-signup/external-user-signup/external-user-signup.component';
import { AboutComponent } from './components/about/about.component';


let INITIAL_REDIRECT: string = '';
if (window.location.href.includes('/logout')) {
  INITIAL_REDIRECT = '/logout';
} else {
  INITIAL_REDIRECT = environment.isLocal ? '/userselection' : '/welcome';
}

export const routes: Routes = [     
    { path: '', pathMatch: 'full', redirectTo: INITIAL_REDIRECT},
    { path: 'welcome', canActivate: [IsAuthenticatedGuard], component: WelcomeComponent},
    { path: 'home', canActivate: [IsAuthenticatedGuard], component: HomeComponent},
    { path: 'site-map', canActivate: [IsAuthenticatedGuard], component: SiteMapComponent},
  { path: 'logout',  component: LogoutComponent},
  { path: 'maintenance',  component: MaintenanceBreakComponent},
  //todo-> remove the below line after testing
  // { path: 'external-user-signup', component: ExternalUserSignupComponent},
  { path: 'about', canActivate: [IsAuthenticatedGuard], component: AboutComponent}
];

if (environment.isLocal) {
    routes.push({ path: 'userselection', component: UserSelectionComponent });
}

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
  export class BaseRoutingModule { }
