import { Component, OnInit } from '@angular/core';
import {MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-spinner',
  template: `<div>
  <img src="assets/Loading.svg" alt="Loading...">
</div>`,
  styles: [`
  ::ng-deep .cdk-overlay-backdrop {
      top: 3rem;
      background: rgba(45, 50, 57,0.4);
  }`]
})
export class SpinnerComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

}
