import {createAction, props} from '@ngrx/store';
import {AppLinksModel} from '@app/core/models/app-links.model';

export const getAppLinksAction = createAction(
  '[Core] Get App Links'
);

export const getAppLinksSuccessAction = createAction(
  '[Core] Get App Links Success',
  props<{appConstants: AppLinksModel}>()
);

export const getAppLinksFailAction = createAction(
  '[Core] Get App Links Fail'
);
