<section class="banner-page">
    <section class="banner">
        <div class="breadcrumb">
            <div class="main-container">
                <div class="column-1">
                    <button id="btn-back-nav" (click)="goToPreviousPage()">
                        <span class="tooltipbox">{{ 'Global.Common.Label.BackToProjectSummary' | translate }}</span>
                    </button>
                    <span id="vert_line"><img src="assets/psg/Rectangle-3200.svg" alt="" /></span>
                    <span class="title" >
                        {{ title | translate }}
                    </span>
                </div>
                <div class="column-2">
                    <h3 class="project-title" *ngIf="projectId != 0">{{projectGlobalInfo?.projectName}}</h3>
                    <span class="pipeSearchIcon"></span>
                    <button (click)="showSearchInputBox()" class="searchIcon" *ngIf="!showDocInputSearch">
                        <span class="tooltiptext">{{ 'Global.Common.Label.Search' | translate }}</span>
                    </button>
                    <div *ngIf="showDocInputSearch" class="input-search">
                        <search-document
                        (selectDocSearchEvent)="selectSearchEvent($event)"
                        (closeSearchEvent)="closeSearchEvent($event)"
                        [showDocInputSearch]="showDocInputSearch"
                        [searchDocumentData]="documentData"
                        [selectedOption]="searchParams"
                        [placeholderText]="searchInputPlaceholder"
                        [noData]="noSearchResult"
                        [projectId] ="projectId"
                        >

                        </search-document>

                      </div>

                     <div *ngIf="showNavigator && projectId !== 0">
                        <app-quick-navigator [projectinfo]="currentProjectInstance" [projectId]="projectId" [currentScreenName]="currentScreenName"></app-quick-navigator>
                     </div> 

                </div>


            </div>
        </div>
    </section>
</section>
