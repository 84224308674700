import { Action } from '@ngrx/store';

export const UPDATE_MAP_FILTERS = '[Map Filters] Set Default Filters';
export const LOAD_LANDINGPAGE_FILTERS = '[Map Filters] Load Landing page Filters';
export const LOAD_LANDINGPAGE_FILTERS_SUCCESS = '[Map Filters] Load Landing page Filters Success';
export const LOAD_LANDINGPAGE_FILTERS_FAIL = '[Map Filters] Load Landing page Filters FAIL';

export class updateMapFiltersAction implements Action {
  readonly type = UPDATE_MAP_FILTERS;
  constructor(public payload: any) {

  }
}

export class loadLandingPageFilters implements Action {
    readonly type = LOAD_LANDINGPAGE_FILTERS;

}

export class loadLandingPageFiltersSuccess implements Action {
    readonly type = LOAD_LANDINGPAGE_FILTERS_SUCCESS;
    constructor(public payload: any) {
  
    }
}

export class loadLandingPageFiltersFail implements Action {
    readonly type = LOAD_LANDINGPAGE_FILTERS_FAIL;
}

export type Actions = updateMapFiltersAction | 
                     loadLandingPageFilters |  
                     loadLandingPageFiltersSuccess |
                     loadLandingPageFiltersFail;
