<div class="moscow-prioritization container">
    <div class="card-header">
      <h3 class="title"> {{'Activities.UserStoryLibrary.MoSCoWPriotization.Title' | translate}} </h3>
    </div>

    <div class="moscow-description">{{'GenerateReleasePlan.Popup.MoscowPrioritization.Placeholder' | translate}}</div>

    <div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

            <div class="col-10">
            <ng-container matColumnDef="proirity_name">
                <th class="header-cell" mat-header-cell *matHeaderCellDef> {{'Global.Common.Label.PriorityValues' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.proirity_name}} </td>
            </ng-container>
            </div>
            <div class="col-2">
            <ng-container matColumnDef="is_enabled">
                <th class="header-cell" mat-header-cell *matHeaderCellDef> {{'Activities.UserStoryLibrary.MoSCoWPriotization.IncludeReleasePlan' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <div class="data-cell">
                    <mat-slide-toggle class="tools-toggle"
                    [checked]="element.is_enabled" [disableRipple]="true"
                    [disabled]="isAleastL1Confirmed || viewMode=='EXPLORE'"
                    (change)="toggle(element, $event)">
                    </mat-slide-toggle>
                    <span *ngIf="element.is_enabled"> {{'Global.Common.Label.Yes' | translate}} </span>
                    <span *ngIf="!element.is_enabled"> {{'Global.Common.Label.No' | translate}} </span>

                </div>
                </td>
            </ng-container>
        </div>

            <tr mat-header-row *matHeaderRowDef="cols"></tr>
            <tr mat-row *matRowDef="let row; columns:cols;"></tr>
        </table>
    </div>
</div>

<div class="card-footer">
  <button  *ngIf="!isAllL1Finalized" class="reset-btn" (click)="resetData()" [disabled]="viewMode=== ASCEND_MODES?.EXPLORE_MODE || isAleastL1Confirmed"> {{'GenerateReleasePlan.Popup.Label.ResetChanges'| translate}}</button>
    <div class="buttons" style="float: right;">
      <button class="btn-secondary" type="button" (click)="onNextStep()"
      [matTooltip]="sprintCapacitySuccess.NEXT_STEP_TOOLTIP | translate" [matTooltipPosition]="'above'">
        <img class="arrow-up" src="assets/psg/arrow-up.svg" alt="" />
      </button>
      <button *ngIf="!isAllL1Finalized" mat-raised-buttom aria-label="Button that displays" matToolTipPosition="above"
      class="btn-primary-theme btn-confirm" (click)="onConfirm()" [disabled]="isAleastL1Confirmed || viewMode=='EXPLORE'">
        {{'GenerateReleasePlan.Popup.MoscowPrioritization.ConfirmButton' | translate}} <span class="tooltiptext">
        {{'Global.Common.Label.ConfirmSettings' | translate}}
      </span>
      </button>

    </div>
  </div>

  <ng-template #snackBarTemplate>
    <img src="assets/white_tick.svg"> {{'GenerateReleasePlan.Popup.MoscowPrioritization.ConfirmMessage' | translate}}
  </ng-template>
