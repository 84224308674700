import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  BASE_URL = '';
  MA_BASE_URL = '';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      ...(environment.isLocal) && {"Authorization": "Bearer " + localStorage.getItem('auth-token')}
    },
    )
  };


  httpOptionsForm = {
    headers: new HttpHeaders({
      ...(environment.isLocal) && {"Authorization": "Bearer " + localStorage.getItem('auth-token')}
    },
    )
  };

  constructor(private httpClient: HttpClient) {
    this.BASE_URL = environment.API_BASE_URL;
    this.MA_BASE_URL = environment.API_MICROSERVICE_URL.MATURITY_ASSESSMENT;
    // this.BASE_URL = 'http://localhost:3000';
  }

  httpUploadOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    responseType: 'text' as 'json'
  }

  get(endpoint: string, API_MICROSERVICE_URL=this.BASE_URL): Observable<any> {
    return this.httpClient
      .get<any>(`${API_MICROSERVICE_URL}${endpoint}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  getWithoutLoader(endpoint: string, API_MICROSERVICE_URL=this.BASE_URL): Observable<any> {
    const headers = new HttpHeaders().set('skip', 'true');
    return this.httpClient
      .get<any>(`${API_MICROSERVICE_URL}${endpoint}`,{headers})
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  getMA(endpoint: string): Observable<any> {
    return this.httpClient
      .get<any>(`${this.MA_BASE_URL}/${endpoint}`,this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }


  getMABlob(endpoint: string): Observable<any> {
    return this.httpClient
      .get<any>(`${this.MA_BASE_URL}/${endpoint}`,{
        headers:{
          'Content-Type': 'application/json',
      ...(environment.isLocal) && {"Authorization": "Bearer " + localStorage.getItem('auth-token'),
        },},
        observe: 'response',
        responseType: 'blob' as 'json',
      },)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }


  getById(endpoint: string, id: number): Observable<any> {
    return this.httpClient
      .get<any>(`${this.BASE_URL}/${endpoint}/${id}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  post(endpoint: string, item: any, API_MICROSERVICE_URL=this.BASE_URL): Observable<any> {
    return this.httpClient.post<any>(`${API_MICROSERVICE_URL}${endpoint}`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  postMA(endpoint: string, item: any): Observable<any> {
    return this.httpClient.post<any>(`${this.MA_BASE_URL}/${endpoint}`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }


  postMAForm(endpoint: string, item: any): Observable<any> {
    return this.httpClient.post<any>(`${this.MA_BASE_URL}/${endpoint}`, item, this.httpOptionsForm)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  patchMA(endpoint: string, item: any): Observable<any> {
    return this.httpClient.patch<any>(`${this.MA_BASE_URL}/${endpoint}`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  putMA(endpoint: string, item: any): Observable<any> {
    return this.httpClient.put<any>(`${this.MA_BASE_URL}/${endpoint}`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  deleteMA(endpoint: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.MA_BASE_URL}/${endpoint}`, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  postCMS(endpoint: string, item: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_MICROSERVICE_URL.CONTENT_MGMT}/${endpoint}`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  downloadCMS(endpoint: string, body) {
    return this.httpClient.post<any>(`${environment.API_MICROSERVICE_URL.CONTENT_MGMT}/${endpoint}`, body, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  uploadCMS(endpoint: string, item: any): Observable<any> {
    return this.httpClient.post<string>(`${environment.API_MICROSERVICE_URL.CONTENT_MGMT}/${endpoint}`, JSON.stringify(item), this.httpUploadOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  put(endpoint: string, data: any, API_MICROSERVICE_URL=this.BASE_URL): Observable<any> {
    return this.httpClient.put<any>(`${API_MICROSERVICE_URL}${endpoint}`, data, this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  update(endpoint: string, item: any): Observable<any> {
    return this.httpClient.put<any>(`${this.BASE_URL}/${endpoint}/${item.id}`, JSON.stringify(item), this.httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  delete(endpoint: string, API_MICROSERVICE_URL=this.BASE_URL, body: any = null) {
    return this.httpClient.delete<any>(`${API_MICROSERVICE_URL}${endpoint}`, {...this.httpOptions, body})
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  deleteByObject(url:any, options:any, API_MICROSERVICE_URL=this.BASE_URL){
    return this.httpClient.delete<any>(`${API_MICROSERVICE_URL}`+url, options)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  deleteByObjectHeaders(url:any, data:any, API_MICROSERVICE_URL=this.BASE_URL){
    let options = {
      ...this.httpOptions,
      body : data
    }
    return this.httpClient.delete<any>(`${API_MICROSERVICE_URL}`+url, options )
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  public getRequestWithParams(url: string, param: HttpParams) {
    return this.httpClient
      .get(url, { ...this.httpOptions, params: param })
      .map((response) => {
        return response;
      })
      .catch((error) => {
        return Observable.throw(error);
      });
  }

  public getBlob(endpoint: string, API_MICROSERVICE_URL=this.BASE_URL) {
    return this.httpClient.get(`${API_MICROSERVICE_URL}${endpoint}`, { responseType: 'blob' })
      .map((response) => {
        return response;
      })
      .catch((error) => {
        return Observable.throw(error);
      });
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      //error client
      errorMessage = error.error.message;
    } else {
      //error server
      errorMessage = `Código do erro: ${error.status}, ` + `mensagem: ${error.message}`;
    }

    return throwError(error.error);
  }

  getBlobAsJson(url, data: string, API_MICROSERVICE_URL=this.BASE_URL) {
    return this.httpClient.post(`${API_MICROSERVICE_URL}/${url}`, data, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  uploadFile(url, file, API_MICROSERVICE_URL=this.BASE_URL){
    const apiUrl = `${API_MICROSERVICE_URL}/${url}`;
    return this.httpClient.post(apiUrl, file);
  }

  patch(endpoint: string, data, API_MICROSERVICE_URL=this.BASE_URL ): Observable<any> {
    return this.httpClient.patch<any>(`${API_MICROSERVICE_URL}/${endpoint}`, data)
      .pipe(
        retry(2),
        catchError(this.handleError)
        );
  }

  patchCustom(endpoint: string, API_MICROSERVICE_URL = this.BASE_URL, data?: any): Observable<any> {
    return this.httpClient.patch<any>(`${API_MICROSERVICE_URL}${endpoint}`, data)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }
  
}
