<div class="tutorial-container">
  <div class="sub-container tutorial-left">
    <div class="globe">
      <!-- globe image -->
    </div>
  </div>
  <div class="sub-container tutorial-right">
    <div class="searchFieldContainer">
      <form autocomplete="off">
        <div class="search-div">
          <!-- <i [ngStyle]="{'background-color': colorSwitch=='true'?'grey':'#0097a9'}" class="search-btn"><img
              src="../../../assets/Icon_Search.svg" width="20px" />
          </i> -->
          <input type="text" name="search" placeholder="Search for tutorial" aria-label="Number"
            [formControl]="myControl" [matAutocomplete]="auto" (focusout)="handleFocusOutEvent($event)"
            (optionSelected)="handleStaticResultSelected($event)">
        </div>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </form>
    </div>
    <div class="text-block">
      Tutorials
    </div>
    <div class="desc-block">
      Learn more about Ascend with our tutorials. Download or watch a quick screen capture to get the most out of this
      robust platform.
      <br /><br /><br />
      <div class="scroller">
        <mat-grid-list gutterSize="26px" rowHeight="300px" cols="3">
          <mat-grid-tile *ngFor="let data of tutorialsData" colspan="1" rowspan="1">
            
            
            <div class="desc">
              <div class="title">
                {{data.heading}}
              </div>
              {{data.description}}
            </div>
            <mat-grid-tile-footer>
              <img class="doc-button" *ngIf="data.doclink" (click)="openDocument(data.doclink)"
                src='../../../../assets/document-1-neg@3x.png' />
              <img class="play-button" *ngIf="data.videolink" (click)="openVideoPlayer(data.videolink)"
                src='../../../../assets/play-neg@3x.png' />
            </mat-grid-tile-footer>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
  </div>
</div>
