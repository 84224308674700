import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { HOME_CONSTANT } from 'src/app/base/constants/home-constant';
import { UserInfo } from 'src/app/shared/constants/ascend-user-project-info';
import { BUS_MESSAGE_KEY } from 'src/app/shared/constants/message-bus';
import { FilterData, FILTER_CUSTOM_CONSTANTS } from 'src/app/shared/model/filter-content.model';
import { ProjectGlobalInfoModel } from 'src/app/shared/model/project-global-info.model';
import { CryptUtilService } from 'src/app/shared/services/crypt-util.service';
import { FilterCustomService } from 'src/app/shared/services/filter-custom.service';
import { PassGlobalInfoService } from 'src/app/shared/services/pass-project-global-info.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';


@Component({
  selector: 'app-home-description',
  templateUrl: './home-description.component.html',
  styleUrls: ['./home-description.component.scss']
})
export class HomeDescriptionComponent implements OnInit, OnDestroy {
  @Input() view: String;
  @Input() projectName: any;
  @Input() clientName: any;
  @Input() clientLogoURL: any;

  projectGlobalInfo: ProjectGlobalInfoModel;
  packageId:any;
  customOptions: OwlOptions = {
    loop: false,
    dots: false,
    // navSpeed: 700,
    items:3,
    //margin:25,
    //center: false,
    nav: true,
    navText: ["<img src='../../../../assets/left@3x.png' width='30px' />",
      "<img src='../../../../assets/Right@3x.png' width='30px' />"],
    stagePadding: 45,
    //autoWidth: true,
    responsive: {
      1394: {
        items: 4,
        margin: 20
      }
    }
  };
  router: any;
  constUserInfo: UserInfo;
  //cardData: any= LAYOUT_CONFIGURATION;
  cardData: any = HOME_CONSTANT;

  filterURL = "/iidrfilter/";
  filters: FilterData[] = [];
  selectedPackageData:any;
  selectedFunctionData:any;
  storageConstant: string;
  filterLoaded = false;
  tabName = "IIDR";
  subscription: Subscription;
  urlTrailer: string = '';
  userData :any[]=[];
  packageData:any[]=[];
  functionData:any[]=[];
  
  constructor(private sanitizer: DomSanitizer,
    private filterCustomService: FilterCustomService,
    private cryptUtilService: CryptUtilService,
    private sharedService: SharedService,
    private globalData: PassGlobalInfoService,
    private userPreferenceService : UserPreferenceService) {
      this.userPreferenceService.subjectData.subscribe(userId=>{
        this.userData.push(userId);
     });
     this.userPreferenceService.packageData.subscribe(packageVal=>{
      this.packageData.push(packageVal);
  });
  this.userPreferenceService.functionData.subscribe(functionVal=>{
    this.functionData.push(functionVal);
  });
    }

  getSafeURL(logoURL) {
    return logoURL;
      }

  ngOnInit() {
    this.subscription = this.globalData.share.subscribe(data => {
      this.view = data.viewMode;
      this.clientName = data.clientName;
      this.projectName = data.projectName;
      this.clientLogoURL = data.clientUrl;
      this.projectGlobalInfo = data;
      this.projectGlobalInfo.projectId = this.projectGlobalInfo.projectId ? this.projectGlobalInfo.projectId : '0';
      this.storageConstant = FILTER_CUSTOM_CONSTANTS.IIDR_FILTER + "_" + this.projectGlobalInfo.projectId;
      this.emitFilter(null);
    });
  }

  emitFilter(e) {
    if (e) {
      this.filterCustomService.updateFilters(this.filters, e, this.storageConstant);
      this.urlTrailer = this.filterCustomService.formURL(this.filters);
      this.cryptUtilService.setItem(BUS_MESSAGE_KEY.IIDR_FILTER + "_functionpackage_" + this.projectGlobalInfo.projectId, this.urlTrailer, 'SESSION');
      this.sharedService.filterSelected = this.filterCustomService.checkFilterSelected(this.filters);
      // this.messagingService.publish(BUS_MESSAGE_KEY.IIDR_FILTER, urlTrailer)
    }
    else {
      this.filterCustomService.getFilterData(this.filterURL + this.projectGlobalInfo.projectId, this.storageConstant, this.projectGlobalInfo.projectId).subscribe(data => {
        this.filters = data;

       

        if(this.projectGlobalInfo.projectId && this.projectGlobalInfo.projectId != '0' && this.filters) {
          this.cryptUtilService.setItem('SELECTED_PACKAGE', this.filterCustomService.getSelectedPackage(this.filters) || '', "SESSION");
          this.sharedService.selectedPackage =  this.filterCustomService.getSelectedPackage(this.filters);
        }
        //Baxter Project Package change
        if(this.filters && this.projectName.toLowerCase() == 'baxter jde next generation erp program'){
          this.filters[0].l1Filter.filterValues[0].childValues[0].entityName = 'JD Edwards'
        }
        
        this.filterLoaded = true;
        this.urlTrailer = this.filterCustomService.formURL(this.filters);
        this.cryptUtilService.setItem(BUS_MESSAGE_KEY.IIDR_FILTER + "_functionpackage_" + this.projectGlobalInfo.projectId, this.urlTrailer, 'SESSION');
        this.sharedService.filterSelected = this.filterCustomService.checkFilterSelected(this.filters);
        // this.messagingService.publish(BUS_MESSAGE_KEY.IIDR_FILTER, urlTrailer);

        if(this.filters)
        {
            this.filters.forEach(item=>{
              if(item.title=="Packages"){
                
               if(item?.l1Filter){
                item?.l1Filter?.filterValues.forEach(entity=>{
                  entity.childValues.forEach(element=>{
                    this.userPreferenceService.getPackageData().subscribe((response) => {
                      if(response && this.projectGlobalInfo.projectId && this.projectGlobalInfo.projectId == '0' ){
                        if(element.entityName.toLowerCase() == response.toLowerCase()){
                          element.selectedFlag="Y";
                          this.packageId=element.entityId;
                          
                        }
                        else{
                          element.selectedFlag="N";
                        }
                      }
                     
                    });
                      });
                    });
               }
              }
              
              if(item.title=="Functions"){
                
                if(item?.l1Filter){
                 item?.l1Filter?.filterValues.forEach(entity=>{
                   entity.childValues.forEach(element=>{
                    this.userPreferenceService.getFunctionData().subscribe((response) => {
                      if(response && this.projectGlobalInfo.projectId && this.projectGlobalInfo.projectId == '0' ){
                       
                            if(element && this.packageId ==element.packageId){
                              element.selectedFlag="Y";
                            }
                            else{
                              element.selectedFlag="N";
                            }
                        
                         
                        this.filterLoaded = true;
                        this.urlTrailer = this.filterCustomService.formURL(this.filters);
                        this.cryptUtilService.setItem(BUS_MESSAGE_KEY.IIDR_FILTER + "_functionpackage_" + this.projectGlobalInfo.projectId, this.urlTrailer, 'SESSION');
                        this.sharedService.filterSelected = this.filterCustomService.checkFilterSelected(this.filters);
                        
                        }
                      });
                   })
                 })
                }
               }

                
            })
        //  }
        }

        this.filterLoaded = true;
        this.urlTrailer = this.filterCustomService.formURL(this.filters);
        this.cryptUtilService.setItem(BUS_MESSAGE_KEY.IIDR_FILTER + "_functionpackage_" + this.projectGlobalInfo.projectId, this.urlTrailer, 'SESSION');
        this.sharedService.filterSelected = this.filterCustomService.checkFilterSelected(this.filters);
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
