import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { REFINE_USER_STORIES_CONFIRMALL } from '@projects/shared-lib/src/lib/core/polling-constants';
import { PollingService } from '@projects/shared-lib/src/lib/services/polling.service';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';

import { Subscription } from 'rxjs';
import { ProjectGlobalInfoModel } from 'src/app/shared/model/project-global-info.model';
import { PassGlobalInfoService } from 'src/app/shared/services/pass-project-global-info.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DefineRoadmapService, ISprintGenerationL1 } from '../../define-roadmap.service';
import { SuccessModalComponent } from '../../success-modal/success-modal.component';
import { SPRINT_COLORS, ASCEND_MODES } from '@projects/projectsummary/src/app/core/utility/constants';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-finalize-sprint',
  templateUrl: './finalize-sprint.component.html',
  styleUrls: ['./finalize-sprint.component.scss']
})
export class FinalizeSprintComponent implements OnInit, OnDestroy {
  l1_obj:any;
  selectedL1Subscription:Subscription;
  globalContextSubscription:Subscription;
  pollingSubscription:Subscription;
  acknowledgeFinalizeSprintData:Subscription;
  finalizeSubscription: Subscription;
  dataSource = [
  ];
  displayedColumns: string[] = ['index','sprint_name','target_sprint_name', 'start_date', 'end_date'];
  sideStepper;
  l1Obj: ISprintGenerationL1;
  finalizeSprintData = [];
  context;
  projectGlobalInfo: ProjectGlobalInfoModel;
  finalizeResponse;
  finalizedCount: any;
  viewMode: boolean = false;
  colors = SPRINT_COLORS;
  successMessage: string;
  disableFinalizeAll: boolean = false;
  isAllL1Finalized: boolean = false;
  isAtleastOneL1Generated: boolean = false;
  l1StatusData;
  l1StatusDataSubscription: Subscription;
  isAtleastOneL1GeneratedSubscription: Subscription;
  ASCEND_MODES=ASCEND_MODES;
  taxonomyId: number = 0;


  constructor(private defineRoadmapService: DefineRoadmapService, private http: HttpClient, private globalContext: ActivitiesGlobalContextService,
    private projectData: PassGlobalInfoService, private sharedService: SharedService,  private pollingService: PollingService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private userPreference: UserPreferenceService, private translateService: TranslateService,) {

    }



  ngOnInit(): void {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
    this.userPreference.getTaxonomyIdData().subscribe((resp) => {
      if(resp) {
        this.taxonomyId = resp;
      }
      else if (this.userPreference.getTaxonomyFromSessionStorage()
      ) {
        this.taxonomyId  = this.userPreference.getTaxonomyFromSessionStorage();
      }
    });
    this.acknowledgeFinalizeSprintData = this.defineRoadmapService.getacknowledgeFinalizeSprintData().subscribe(data => {
      if(data) {
        this.getFinalizeSprintData();
      }
    });
    this.getContextDetails();
    this.sidebarSubscription();
    this.getProjectDetails();
    this.defineRoadmapService.getFinalizedL1CountData().subscribe(data => {
      this.finalizedCount = data;
    });
    this.finalizeSubscription = this.defineRoadmapService.getIsAllL1Finalized().subscribe((data)=>{
      this.isAllL1Finalized = data
    });
    this.isAtleastOneL1GeneratedSubscription = this.defineRoadmapService.getconfirmedSprintCapacityL1Count().subscribe((data) => {
      this.isAtleastOneL1Generated = data?.confirmedSprintCapacityL1Count > 0 ? true : false;
    });
    this.l1StatusDataSubscription = this.defineRoadmapService.getL1StatuesData().subscribe(data => {
      this.l1StatusData = JSON.parse(JSON.stringify(data));
    });

  }
  getContextDetails() {
    this.globalContextSubscription = this.globalContext.getGlobalActivityContext().subscribe((data) => {

      this.context = data;
      this.viewMode = this.context.project.projectId == 0 ? true : false;
      this.getFinalizeSprintData();
    });
  }

  sidebarSubscription() {
    this.selectedL1Subscription=this.defineRoadmapService.getSelectedL1().subscribe((data: ISprintGenerationL1)=>{
      if(data) {
        this.l1Obj = data;
        if(this.defineRoadmapService.sprintStepper[data.parentIndex] && this.defineRoadmapService.sprintStepper[data.parentIndex].children && this.defineRoadmapService.sprintStepper[data.parentIndex].children?.length > 0){
          this.l1_obj= this.defineRoadmapService.sprintStepper[data.parentIndex]?.children[data.childIndex];
          if(this.l1_obj) {
            this.dataSource = this.l1_obj.sprints
            this.l1_obj['confirmFlag'] = true;
            this.l1_obj['isErrored'] = false;
            if(this.l1StatusData){          
              this.l1_obj['is_generated'] = this.l1StatusData.find(l1 => Number(l1?.l1_id) == this.l1_obj?.l1_id)?.is_generated;
              this.l1_obj['is_finalized'] = this.l1StatusData.find(l1 => Number(l1?.l1_id) == this.l1_obj?.l1_id)?.is_finalized;
            }
          }
        }
      }
    });
  }

  getProjectDetails() {
    this.projectData.share.subscribe(data => {
      this.projectGlobalInfo = data;
      this.projectGlobalInfo.projectId = this.projectGlobalInfo.projectId ? this.projectGlobalInfo.projectId : '0';
    });
  }

  onConfirm() {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)

    if(this.dataSource) {
      let temp = [];
      this.dataSource.forEach(el => {
        let tempObj = {
          l1: el.l1,
          sprint_name: el?.jira_sprint_name ? el.jira_sprint_name : el.sprint_name,
          sprint_id: el.sprint_id
        }
        temp.push(tempObj);
      });
      let alreadyFinalized=false;
      if(this.l1_obj?.sprints[0]?.isSprintsFinalizeForJira) {
        alreadyFinalized = true;
      }
      this.defineRoadmapService.updateFinalizeSprintdetails(this.projectGlobalInfo.projectId, temp)
        .subscribe((response:any) => {
          if(response.data){
            let showAllToasterMessage = false;
            if(this.finalizedCount?.finalizedL1Count === this.defineRoadmapService.sprintStepper[this.l1Obj.parentIndex]?.children?.length-1) {
              showAllToasterMessage = true;
              this.disableFinalizeAll = true;
            }
            this.successMessage = this.l1_obj.l1_tab + ': ' + (showAllToasterMessage ? this.translateService.instant('GenerateReleasePlan.Popup.FinalizeSprint.FinalizeSprintLastL1Success') :  this.translateService.instant('GenerateReleasePlan.Popup.FinalizeSprint.FinalizeSprintSuccess'));
            this.l1_obj.sprint_plan = response.data[this.l1Obj.childIndex]?.sprints;
            // this.sharedService.updateDefineRoadmapData(this.l1_obj);
            this.l1_obj.confirmFlag = false;
            this._snackBar.open(this.successMessage, '', { duration: 5000 });
            if(!alreadyFinalized) {
              this.defineRoadmapService.setFinalizedL1CountData({finalizedL1Count: this.finalizedCount?.finalizedL1Count + 1});
            }
            this.l1StatusData.forEach(l1 => {
              if(Number(l1?.l1_id) === this.l1_obj?.l1_id){
                l1['is_finalized'] = true;
              }
            });
            this.defineRoadmapService.setL1StatuesData(this.l1StatusData);
            this.onNextStep();
            this.getContextDetails();
          }
        }, err=> {})
    }


  }

  onFinalizeAll() {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)

    let notFinalizedL1s = this.defineRoadmapService.sprintStepper[this.l1Obj.parentIndex]?.children?.filter(l1 => !l1?.is_finalized && l1?.is_generated);
    let temp = [];
    notFinalizedL1s.forEach(el => {
        el?.sprints.forEach(sprint => {
          let tempObj = {
            l1: sprint.l1,
            sprint_name: sprint?.jira_sprint_name ? sprint.jira_sprint_name : sprint.sprint_name,
            sprint_id: sprint.sprint_id
          }
          temp.push(tempObj);
        });
    });
    this.defineRoadmapService.updateFinalizeSprintdetails(this.projectGlobalInfo.projectId, temp)
        .subscribe((response:any) => {
          if(response.data){
            this.disableFinalizeAll = true;
            this._snackBar.open(this.translateService.instant('GenerateReleasePlan.Popup.FinalizeSprint.FinalizeAllSprintSuccess'), '', { duration: 5000 });
            this.defineRoadmapService.setFinalizedL1CountData({finalizedL1Count: this.defineRoadmapService.sprintStepper[this.l1Obj.parentIndex]?.children?.length});
            this.l1Obj.childIndex = 0;
            this.defineRoadmapService.setSelectedL1(this.l1Obj);
            this.l1StatusData.forEach(l1 => l1['is_finalized'] = true);
            this.defineRoadmapService.setL1StatuesData(this.l1StatusData);
            this.getContextDetails();
          }
        }, err=> {})
  }


  onChangeInput(event, index, element) {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(true)
    if(event.target.value.trim() === '' ) {
      // TODO add error
      this.dataSource[index].errorText = true;
      this.l1_obj['isErrored'] = true;
    }else {
      this.dataSource[index].errorText = false;
      this.l1_obj['isErrored'] = false;
      this.l1_obj['confirmFlag'] = true;
      let indexValue = this.dataSource.findIndex(dt=> {
        return dt.sprint_id === element.sprint_id;
      });
      // this.dataSource[indexValue].sprint_name = event.target.value.trim();
      this.dataSource[indexValue].jira_sprint_name = event.target.value.trim();
    }

  }

  getFinalizeSprintData() {
        this.defineRoadmapService.getJiraDetails(this.context.package.packageId,this.context.package.functionId,this.context.selectedActivity.entityId,this.context.project.projectId,this.taxonomyId)
        .subscribe((response) => {
           let tempData=[]
              response?.active_processes?.forEach(l1 => {
                let tempL1={...l1,sprints:l1?.sprint_plan?.[0]?.sprint.map(el=>{
                  return {...el,l1:l1?.l1}
                }),...l1.l1_export_status}
    
                tempData.push(tempL1); 
              })
           this.finalizeResponse = tempData;
             this.defineRoadmapService.sprintStepper[3].children = tempData;
             this.defineRoadmapService.setFinalizeSprint(tempData);
             // setting for initial render only
             this.l1_obj = tempData[this.l1Obj?.childIndex];
             this.l1_obj['confirmFlag'] = true;
             this.l1_obj['isErrored'] = false;
             this.disableFinalizeAll = (this.finalizedCount?.finalizedL1Count === this.defineRoadmapService.sprintStepper[this.l1Obj.parentIndex]?.children?.length) ? true : false;
             this.dataSource = tempData[this.l1Obj?.childIndex].sprints;
             this.l1_obj['is_generated'] = this.l1StatusData.find(l1 => Number(l1?.l1_id) == this.l1_obj?.l1_id)?.is_generated;
             this.l1_obj['is_finalized'] = this.l1StatusData.find(l1 => Number(l1?.l1_id) == this.l1_obj?.l1_id)?.is_finalized;
      },(err) => {})
  }

  onNextStep() {
    this.l1Obj.childIndex += 1;
    this.defineRoadmapService.setSelectedL1(this.l1Obj);
  }

  onPreviousStep() {
    if(this.l1Obj.childIndex !== 0) {
      this.l1Obj.childIndex -= 1;
      this.defineRoadmapService.setSelectedL1(this.l1Obj);
    } else {
      this.l1Obj.parentIndex -= 1;
      let obj={
        parentIndex:this.l1Obj.parentIndex,
        childIndex:0,
        direction:'previous'
      }
      this.defineRoadmapService.setSwitchParent(obj);
    }
  }

  /* Generate sprint makes call to jira */
  generateSprint() {

    let payload = []
    this.dataSource.forEach(element => {
      let temp={
        sprint_id:element.sprint_id,
        sprint_name:element.jira_sprint_name ? element.jira_sprint_name: element.sprintName,
        l1:this.l1_obj.l1,
      };
      payload.push(temp);
    })
    this.sharedService.confirmpollingDefineRoadmapStep3(this.projectGlobalInfo.projectId, { finalizesprintPayload: payload })
    .subscribe((data) => {
      this.pollingService.startPolling(data.instanceId, REFINE_USER_STORIES_CONFIRMALL)
      this.pollingService.getPollingData().subscribe(res => {})
      this.pollingSubscription = this.pollingService.resultUSSprint
      .subscribe(res => {
        if(res && res.status == 'Completed') {
          this.dialog.open(SuccessModalComponent, {
            height: '125px',
            width: '568px',
            data: {text: 'Global.Common.Label.SprintGenerated'}
          })
          this.pollingService.setresultUSSprint();
          this.pollingSubscription.unsubscribe();
          // To disable the jiraSprint related fields
            this.defineRoadmapService.setGenerated(true);
            let resp = this.finalizeResponse.find(data => {
               return data.l1 == this.dataSource[0].l1;
            })
            if(resp) {
              resp.sprints.forEach(element => {
                element.isSprintsFinalizeForJira = true;
              });
            }
          // setting dynamic sidebar value when confirmed
          if(this.l1Obj.childIndex === (this.finalizeResponse.length - 1)) {
            return this.defineRoadmapService.setenableGenerate(true);
          }
          this.l1Obj.childIndex += 1;
          this.defineRoadmapService.setSelectedL1(this.l1Obj);
          }
      }, err => {})
  }, (err) => {});
  }

  ngOnDestroy() {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
    this.selectedL1Subscription.unsubscribe();
    this.globalContextSubscription.unsubscribe();
    this.acknowledgeFinalizeSprintData?.unsubscribe();
    this.finalizeSubscription?.unsubscribe();
    this.l1StatusDataSubscription?.unsubscribe();
    this.isAtleastOneL1GeneratedSubscription?.unsubscribe();

  }
}
