import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, retry, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TEST_SCENARIOS } from '../core/polling-constants';
import { CryptUtilService } from 'src/app/shared/services/crypt-util.service';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { BUS_MESSAGE_KEY } from 'src/app/shared/constants/message-bus';

@Injectable({
  providedIn: 'root',
})
export class ApiPollingService {
  private processStartURL = `${environment.API_BASE_URL}/process/start`;
  private processStopURL = `${environment.API_BASE_URL}/process/terminate`;
  private processStatusURL = `${environment.API_BASE_URL}/process/instance/status`;
  private stopPolling = new Subject();
  private allPollingData$: Observable<any[]>;
  private cryptUtilService : CryptUtilService;
  testScenarioSession: any = [];
  constructor(private http: HttpClient, private globalContext: ActivitiesGlobalContextService,) {
    this.cryptUtilService = new CryptUtilService();
  }

  startPolling(instanceId, entityName?, valueId?) {
    let url;
    if(entityName == "GENERATE") {
    } 
    else {
    url = `${environment.API_MICROSERVICE_URL.PROJECT}/process/status/` + instanceId;
    this.allPollingData$ = timer(1, 10000).pipe(
      switchMap(() =>
        this.http.get<any>(url, {headers: {skip: 'true'}}).catch((err) => this.handleError(err))
      ),
      catchError((err) => this.handleError(err)),
      retry(5),
      tap((data) => {
        data.instanceId = instanceId;
        this.globalContext.setTestScenarioPollingData(data);
        if (data.status === 'Completed' || data.status === 'Failed' || data.status === 'Terminated' || data.status === "DepthLimitReached") {
          if (entityName === TEST_SCENARIOS) {
            this.generateTestScenarioPollingResult(data, instanceId);
          }
          else {
            this.exitPolling(data);
          }
        }
      }),
      share(),
      takeUntil(this.stopPolling)
    );
    }
    return this.getPollingData();
  }


  getProcessStart(activityName): Observable<any> {
    return this.http.post<any>(this.processStartURL + '/' + activityName, {});
  }

  getPollingData(): Observable<any[]> {
    return this.allPollingData$.pipe(
      tap((data) => /* console.log('data sent to subscriber', new Date()) */ {})
    );
  }

  exitPolling(data) {
    this.stopPolling.next();
  }

  handleError(error: HttpErrorResponse) {
    console.log('An error occurred:', error);
    this.exitPolling(error);
    return throwError('Something bad happened; please try again later.');
  }

  generateTestScenarioPollingResult(data, instanceId) {
    this.stopPolling.next();
  }

  getProcessStatus(instanceIds) {
    let url = `${this.processStatusURL}?id=${instanceIds}`;
    return this.http.get<any>(url);
  }
}
