<div class="terms-of-use-container">
    <div class="title" mat-dialog-title>
        {{data.title}}
        <img class="close-icon" (click)="close()" src="assets/close-icon.png" />
    </div>
    <mat-divider></mat-divider>

    <div class="my-3">
        <mat-dialog-content>
            <span class="description">
                <h3 class='header'><strong>{{"LandingPage.TermsOfUse.Section1.Title" | translate}}</strong></h3>
                <p [innerHTML]="'LandingPage.TermsOfUse.Section1.Paragraph1' | translate"></p>
                <p [innerHTML]="'LandingPage.TermsOfUse.Section1.Paragraph1_1' | translate"></p>
                <p [innerHTML]="'LandingPage.TermsOfUse.Section1.Paragraph2'| translate"></p>
                <p [innerHTML]="'LandingPage.TermsOfUse.Section1.Paragraph3' | translate"></p>

                <h3 class='header'><strong>{{"LandingPage.TermsOfUse.Section2.Title" | translate}}</strong></h3>
                <p [innerHTML]="'LandingPage.TermsOfUse.Section2.Paragraph1' | translate"></p>

                <h3 class='header'><strong>{{"LandingPage.TermsOfUse.Section3.Title" | translate}}</strong></h3>
                <p [innerHTML]="'LandingPage.TermsOfUse.Section3.Paragraph1' | translate"></p>
                <ul style="list-style-type: disc;">
                    <li [innerHTML]="'LandingPage.TermsOfUse.Section3.ListItem1' | translate"></li>
                    <li><span>{{"LandingPage.TermsOfUse.Section3.ListItem2" | translate}}</span></li>
                    <li><span>{{"LandingPage.TermsOfUse.Section3.ListItem3" | translate}}</span></li>
                </ul>
                <p [innerHTML]="'LandingPage.TermsOfUse.Section3.Paragraph2' | translate"></p>

                <h3 class='header'><strong>{{"LandingPage.TermsOfUse.Section4.Title" | translate}} </strong></h3>
                <p [innerHTML]="'LandingPage.TermsOfUse.Section4.Paragraph1' | translate"></p>

                <h3 class='header'><strong>{{"LandingPage.TermsOfUse.Section5.Title" | translate}}</strong></h3>
                <p [innerHTML]="'LandingPage.TermsOfUse.Section5.Paragraph1' | translate"></p>
            </span>
        </mat-dialog-content>
    </div>

    <mat-divider></mat-divider>
    <div class="float-end">
        <mat-dialog-actions>
            <button class="mat-raised-button mat-primary ms-3" (click)="save()">{{data.primarybutton}}</button>
        </mat-dialog-actions>
    </div>
</div>
