import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { TermsOfUseComponent } from '../welcome/terms-of-use/terms-of-use.component';
import { Router } from '@angular/router';
import { DELOITTE_WEB_PRIVACY } from '../../constants/home-constant';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  copyrightDate: number = new Date().getFullYear();

  constructor(private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
  }

  navigateToTerms() {
    this.openTermsOfUseDialog();
  }

  openTermsOfUseDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: "TERMS OF USE",
      primarybutton: "Ok",
    };

    const dialogRef = this.dialog.open(TermsOfUseComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      if (data === "true") {
        return;
      }
    });
  }

  goToWelcomePage() {
    this.router.navigate(['/welcome']);
  }

  navigateToPolicy() {
    const URLPolicy = DELOITTE_WEB_PRIVACY;
    if (URLPolicy) {
      window.open(URLPolicy);
    }
  }
}
