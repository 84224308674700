<div class="explore" *ngIf="view == 'EXPLORE'">
  <!-- <div class="text-block">
    Welcome to Ascend!
  </div>
  <div class="desc-block">
    Elevate, Accelerate and Amplify your Digital Ambitions and Journey
  </div>
  <div class="iidr-filters">
    <app-filter-list (parentEmitter)="emitFilter($event)" [tabName]="tabName" filterBarHead="Filters"
      [filters]="filters" [filterLoaded]="filterLoaded" readOnly="false"></app-filter-list>
  </div> -->
  <div class="home-container" height="139px">
    <div class="sub-container">
      <div class="text-block">
        Welcome to Ascend!
      </div>
      <div class="desc-block">
        Elevate, Accelerate and Amplify your Digital Ambitions and Journey
      </div>
    </div>
    <div class="sub-container" style="margin-top: 1.5%;">
      <app-filter-list (parentEmitter)="emitFilter($event)" [tabName]="tabName" filterBarHead="Filters"
        [filters]="filters" [filterLoaded]="filterLoaded" readOnly="false"></app-filter-list>
    </div>
  </div>
</div>
<!-- <mat-card class="project-info-card" *ngIf="view == 'PROJECT'">
  <div class="container">
    <!-- <img [src]="clientLogoURL | secureImage" width="200px" height="100px" /> -->
<!--   <img [src]="getSafeURL(clientLogoURL)" width="200px" height="100px" />
    <div class="sub-container">
      <div class="header-font">
        Project name
      </div>
      <div class="description-font">
        {{ projectName }}
      </div>
    </div>
    <div class="sub-container">
      <div class="header-font">
        Client name
      </div>
      <div class="description-font">
        {{clientName}}
      </div>
    </div>
  </div>

</mat-card> -->
<div class="project" *ngIf="view == 'PROJECT'">
  <div class="project-container" height="139px">
    <img [src]="getSafeURL(clientLogoURL)" width="150px" height="75px" style="padding:10px; padding-left: 3%;" />
    <div class="sub-container">
      <div class="header-font">
        Project name
      </div>
      <div class="description-font">
        {{ projectName }}
      </div>
    </div>
    <div class="sub-container">
      <div class="header-font">
        Client name
      </div>
      <div class="description-font">
        {{clientName}}
      </div>
    </div>
    <div class="sub-container" style="margin-top: -0.5%;">
      <app-filter-list (parentEmitter)="emitFilter($event)" [tabName]="tabName" filterBarHead="Filters"
        [filters]="filters" [filterLoaded]="filterLoaded" readOnly="false"></app-filter-list>
    </div>
  </div>
</div>
<div style="padding: 1% 0% 0% 3%; font-size: 14px; font-weight: 400;">
  To get started select filter values on top right of page. Then simply select a train stop within the 4 phases to begin
  your journey.
</div>