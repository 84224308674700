import { environment } from "src/environments/environment";

export const LANGUAGE_DROPDOWN = {
    JAPANESE:'Japanese - 日本語',
    SPANISH:'Spanish - Español',
    FRENCH:'French - Français '
}

export const LANGUAGE = {
    LANGUAGE_SETTINGS_TITLE: 'Language Settings',
    DOWNLOAD_CURRENT_TRANSLATION: 'Download Current Translation',
    UPLOAD_TRANSLATION: 'Upload Translation',
    PUBLISH: 'Publish',
    DOWNLOAD_AND_UPLOAD: 'Download & Upload',
    TOOLTIP_PUBLISH: 'You can not publish until every translation is validated for this language',
    BLANK_COUNT: 'Blank',
    UNPUBLISH_COUNT: 'Unpublished',
    TOOLTIP_NO_DATA_TO_PUBLISH: 'There is no data to be published.',
    TOOLTIP_FILL_ALL_BLANK_TO_PUBLISH: 'Please fill in all blank translations to publish.'
}

export const STATUS_INDICATOR = {
    VALIDATED: 'Published',
    NOT_VALIDATED: 'Unpublished',
    UNFILLED: 'Blank'
}

export const ENGLISH_LANGUAGE = "English";
export const JAPANESE_LANGUAGE = "Japanese";

export const LANGUAGE_SUB_HEADER = {
    ASC: 'asc',
    DESC: 'desc',
    ENGLISH: 'English',
    RESOURCE_KEY: 'Resource Key',
    DATE: 'Date',
    SEARCH: 'Search',
    SEARCH_PLACEHOLDER: 'Search by Resource Key or English columns',
    SEARCH_IN_METADATA_PLACEHOLDER: 'Search by English or Translation',
    SELECTED: 'selected',
    UNSELECTED: 'unselected',
    SEARCH_LIMIT: 'The search limit exceeds 500 characters',
    DOWNLOAD: 'Download',
    UPLOAD: 'Upload',
    DOWNLOAD_TOOLTIP: 'Download Current Translation',
    UPLOAD_TOOLTIP: 'Upload Translation',
}

export const DOWNLOAD_SUCCESS_MSG = 'Translation sheet is successfully downloaded';
export const DOWNLOAD_FAIL_MSG = 'An unexpected error occurred. Please try again';

export const NO_DATA_FOUND = {
    NO_RESULT_FOUND : 'No results found',
    NO_SEARCH_FOUND: 'No search results matching your criteria were found.',
    REFINE_SEARCH: 'Please refine your search criteria'
}

export const UPLOAD_DESCRIPTION_INFO = 'The translations in the excel file will override the existing translation for the selected language.';
export const UPLOAD_SUCCESS_TITLE ='Translation successfully uploaded';
export const UPLOAD_SUCCESS_MSG = 'Translation validation file is successfully uploaded and published for all the keys.';
export const UPLOAD_FAIL_TITLE = 'Translation upload failed';
export const UPLOAD_FAIL_MSG ='Something went wrong';
export const SUPPORTED_FORMAT_ERROR = 'The supported file formats are .xls and .xlsx only.';
export const UPLOAD_AND_PUBLISH = 'Upload & Publish';
export const PUBLISH_IN_PROGRESS = 'Publishing of the updated translations is in progress. You will be notified once the records are successfully published.';
export const PUBLISH_SUCCESS = 'All the unpublished records are successfully published';
export const PUBLISH_ERROR = 'An unexpected error occurred. Please try again';

export const UNSAVED_POPUP = {
    TITLE : 'Publish translation?',
    ALERT_MESSAGE: 'There are unpublished records in this page. Please publish the records to proceed.',
    PUBLISH_MESSAGE: 'Publishing a translation will make #languagename translation available to all users of the system.',
    PRIMARY_BTN_TEXT: 'Publish and Go',
    SECONDARY_BTN_TEXT: 'Discard and Go',
    SAVE: 'save',
    CANCEL: 'Cancel',
    DISCARD: 'discard',
    PUBLISH_PRIMARY_BTN: 'Publish',
    PUBLISH_SECONDARY_BTN: 'Discard'
}

export const BLANK_DATA_POPUP = {
    TITLE : 'Blank records found?',
    MESSAGE : 'Blank records are found in this screen. Please close this popup to add a translation or discard and leave to proceed.',
    CANCEL: 'Cancel',
    SECONDARY_BTN_TEXT: 'Discard and Go',
}

export const META_UPLOAD_PROGRESS = 'Uploading of the translations is in progress. You will be notified once the records are successfully uploaded and published.';

export const SUB_HEADER_ACTIONS = {
    DOWNLOAD: 'DOWNLOAD',
    UPLOAD: 'UPLOAD',
    LANGUAGE_CHANGE:'LANGUAGE_CHANGE',
    MODULE_CHANGE: 'MODULE_CHANGE',
    SEARCH: 'SEARCH',
    SORT: 'SORT',
    TAB_CHANGE: 'TAB_CHANGE',
    NAVIGATE: 'NAVIGATE'
}
