<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 311 282" style="enable-background:new 0 0 311 282;" xml:space="preserve">
	<style type="text/css">
		.stim0 {
			fill: none;
			stroke: #0097A9;
			stroke-width: 3;
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.stim1 {
			fill: #FFFFFF;
		}

		.stim2 {
			fill: none;
			stroke: #0097A9;
			stroke-width: 2;
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.stim3 {
			fill: #0097A9;
		}

		.stim4 {
			fill: none;
			stroke: #0097A9;
			stroke-width: 3;
			stroke-miterlimit: 10;
		}

		.stim5 {
			fill: #0097A9;
			fill-opacity: 0.2;
		}

		.stim6 {
			fill: none;
		}

		.stim7 {
			font-family: 'Open Sans';
		}

		.stim8 {
			font-size: 16px;
		}

		.stim9 {
			font-family: 'Open Sans Semibold';
		}

		.stim10 {
			fill: #FFFFFF;
			stroke: #0097A9;
			stroke-width: 2;
			stroke-miterlimit: 10;
		}

		.stim11 {
			display: none;
		}

		.stim12 {
			display: inline;
			fill: #0097A9;
			stroke: #0097A9;
			stroke-width: 2;
			stroke-miterlimit: 10;
		}

		.stim13 {
			display: inline;
			fill: #FFFFFF;
		}

		.stim14 {
			fill: none;
			stroke: #0097A9;
			stroke-miterlimit: 10;
		}
	</style>
	<g id="Layer_1_1_">
		<g id="Layer_1-2">
			<g id="paths_circles">
				<path class="stim0" d="M250.4,167c0,57.5-46.6,104.1-104.1,104.1S42.2,224.5,42.2,167c-0.2-7.7,0.7-15.5,2.5-23
				c9.7-38.5,48.6-81.2,101.5-81.1C200.8,63,250.4,108.3,250.4,167z" />

				<rect x="196.2" y="74" transform="matrix(0.7792 -0.6267 0.6267 0.7792 -4.6829 144.1651)" class="stim1"
					width="12.3" height="9.5" />
				<path class="stim1" d="M200.6,78.1c-1.5-0.8-3-1.6-4.6-2.4" />
				<path class="stim2" d="M198.4,77.1c-0.9-0.4-1.6-0.8-2.4-1.3" />
				<path class="stim3" d="M190.8,62.2c-0.5,0.3-0.6,0.9-0.4,1.3l7.7,13.2l-15.2,1.3c-0.6,0.1-1,0.6-0.9,1.1s0.6,1,1.1,0.9l16.8-1.5
				c0.3,0,0.6-0.2,0.8-0.5c0.2-0.3,0.2-0.7-0.1-0.9L192,62.6c-0.1-0.2-0.2-0.3-0.4-0.4C191.3,62,191,62,190.8,62.2z" />
				<rect x="49.4" y="106.7" class="stim1" width="11.2" height="17.9" />
				<line class="stim4" x1="257.3" y1="42.3" x2="304.4" y2="42.3" />
				<path class="stim3"
					d="M289.8,52.5c-0.3-0.5-0.2-1.2,0.3-1.5l13.7-8.8l-13.7-8.8c-0.5-0.3-0.6-1-0.3-1.5c0.3-0.5,1-0.6,1.5-0.3
				l15.2,9.7c0.5,0.3,0.6,1,0.3,1.5c-0.1,0.1-0.2,0.3-0.3,0.3l-15.2,9.7c-0.2,0.1-0.4,0.2-0.6,0.2C290.3,53,290,52.8,289.8,52.5z" />
				<path class="stim1" d="M52.9,121.9c-0.6,1.6-1.2,3.2-1.9,4.8" />
				<path class="stim2" d="M52,124.1c-0.3,0.9-0.7,1.7-1,2.6" />
				<path class="stim3"
					d="M38.2,133.4c0.4,0.4,0.9,0.5,1.4,0.2l12.3-9.1l3,15c0.1,0.6,0.6,0.9,1.2,0.8s0.9-0.6,0.8-1.2l-3.3-16.5
				c-0.1-0.3-0.3-0.6-0.6-0.7c-0.3-0.1-0.7-0.1-1,0.1l-13.6,10c-0.2,0.1-0.3,0.2-0.3,0.4C37.9,132.7,37.9,133.1,38.2,133.4z" />

				<rect x="73.9" y="243.4" transform="matrix(0.4286 -0.9035 0.9035 0.4286 -177.6527 217.0949)"
					class="stim1" width="17.9" height="11.2" />
				<path class="stim1" d="M84.8,251.5c1.5,0.9,2.9,1.8,4.4,2.6" />
				<path class="stim2" d="M86.9,252.7c0.8,0.5,1.6,0.9,2.4,1.4" />
				<path class="stim3"
					d="M93.7,267.9c0.4-0.2,0.7-0.7,0.5-1.1c0-0.1,0-0.1-0.1-0.2l-6.9-13.7l15.3-0.4c0.6,0,1-0.4,1-1s-0.4-1-1-1
				L85.7,251c-0.3,0-0.7,0.2-0.8,0.5c-0.2,0.3-0.2,0.7,0,1l7.6,15.1c0.1,0.2,0.2,0.3,0.4,0.4C93.2,268.1,93.5,268.1,93.7,267.9z" />
				<polygon class="stim1" points="157.4,66.5 162.2,54.2 178.1,60.7 173.3,73 			" />

				<rect x="238.7" y="191.9" transform="matrix(0.4933 -0.8698 0.8698 0.4933 -50.9088 314.2813)"
					class="stim1" width="11.2" height="17.9" />
				<path class="stim1" d="M244.9,199.1c0.6-1.6,1.1-3.2,1.7-4.9" />
				<path class="stim2" d="M245.7,196.8c0.3-0.9,0.6-1.7,0.9-2.6" />
				<path class="stim3" d="M259.3,187.1c-0.4-0.4-0.9-0.5-1.4-0.2l-12,9.5l-3.5-14.9c0-0.6-0.5-1-1.1-0.9c-0.6,0-1,0.5-0.9,1.1
				c0,0.1,0,0.2,0.1,0.4l3.9,16.4c0.1,0.3,0.3,0.6,0.6,0.7c0.3,0.1,0.7,0.1,0.9-0.2l13.2-10.5c0.2-0.1,0.3-0.3,0.3-0.5
				C259.5,187.6,259.5,187.3,259.3,187.1z" />
				<path class="stim0" d="M95.8,76.9c25.4-15,58.8-28.9,98.8-31.7c4.1-0.3,8-0.4,11.9-0.5" />
				<path class="stim3" d="M194.3,55.5c-0.3-0.5-0.2-1.2,0.3-1.6l14.1-9.4l-14.4-8.8c-0.5-0.3-0.7-1-0.3-1.5c0,0,0,0,0-0.1
				c0.3-0.5,0.9-0.7,1.4-0.4c0,0,0.1,0.1,0.1,0.1l15.9,9.8c0.5,0.3,0.6,1,0.3,1.5c-0.1,0.1-0.2,0.3-0.3,0.3L196,55.8
				C195.4,56.2,194.7,56,194.3,55.5z" />
				<circle id="Oval-Copy-8" class="stim5" cx="147.5" cy="169" r="55" />
				<circle id="Oval-Copy-9" class="stim3" cx="147.5" cy="169" r="40" />
				<ellipse class="stim1" cx="155.5" cy="63.8" rx="10.2" ry="4.3" />
				<path class="stim1" d="M96.6,78.2l5.5-3.2l8.3-4.3l6.2-3.1l5.5-2.4l4.5-2.1l5.2-2c0,0,9.3-0.5,9.5-0.5l6,0.1l1.3,1.9l-0.5,2.5
				l-23,8.3L109,75.8L96.6,78.2z" />
			</g>
			<g id="copy">
				<rect x="210.1" class="stim6" width="92.3" height="21.2" />
				<rect x="26.4" y="55.1" class="stim6" width="92.3" height="21.2" />
				<text transform="matrix(1 0 0 1 22.41 30.32)" class="stim7 stim8">Adapt</text>
				<text transform="matrix(1 0 0 1 18.01 48.32)" class="stim7 stim8">leading</text>
				<text transform="matrix(1 0 0 1 15.11 65.32)" class="stim7 stim8">practices</text>
				<rect x="234.7" y="95.8" class="stim6" width="92.3" height="21.2" />
				<text transform="matrix(1 0 0 1 247.62 100.75)" class="stim7 stim8">Launch</text>
				<text transform="matrix(1 0 0 1 248.62 116.75)" class="stim7 stim8">journey</text>
				<rect x="243" y="221" class="stim6" width="92.3" height="21.2" />
				<text transform="matrix(1 0 0 1 260.3027 242.7002)" class="stim7 stim8">Define</text>
				<text transform="matrix(1 0 0 1 248.1902 259.4893)" class="stim7 stim8">operate</text>
				<text transform="matrix(1 0 0 1 303.0742 231.8)" class="stim9 stim8"> </text>
				<text transform="matrix(1 0 0 1 216.0492 277.0002)" class="stim7 stim8">organization</text>
				<text transform="matrix(1 0 0 1 313.2939 243.8)" class="stim9 stim8"> </text>
				<rect y="256.8" class="stim6" width="92.3" height="21.2" />
				<text transform="matrix(1 0 0 1 1.48 240.62)" class="stim7 stim8">Apply</text>
				<text transform="matrix(1 0 0 1 1.28 258.62)" class="stim7 stim8">design</text>
				<text transform="matrix(1 0 0 1 2.18 276.62)" class="stim7 stim8">thinking</text>
				<g>
					<text transform="matrix(1 0 0 1 154.05 13.66)" class="stim7 stim8">Refine</text>
					<text transform="matrix(1 0 0 1 132.13 30.66)" class="stim7 stim8">user stories</text>
				</g>
			</g>
		</g>
		<g id="refine_off">
			<circle class="stim10" cx="245.9" cy="37.4" r="22.6" />
			<path class="stim3" d="M259.4,26.6h-25.2c-0.5,0-0.9,0.3-0.9,0.8c0,0,0,0.1,0,0.1v16.2c0,0.5,0.4,0.9,0.9,0.9h3.6v4.5
			c0,0.4,0.3,0.7,0.6,0.8c0.1,0,0.1,0.1,0.3,0.1c0.3,0,0.6-0.1,0.7-0.4l4.2-5h15.8c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1V27.5
			C260.3,27,259.9,26.6,259.4,26.6z M258.5,42.8h-15.3c-0.3,0-0.6,0.1-0.7,0.4l-2.9,3.5v-2.9c0-0.5-0.4-0.9-0.9-0.9h-3.6V28.5h23.4
			V42.8z" />
			<path class="stim3" d="M237.8,32.9h18c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1c0-0.5-0.4-0.9-0.9-0.9h-18c-0.5,0-0.9,0.3-0.9,0.8
			c0,0,0,0.1,0,0.1C236.9,32.5,237.3,32.9,237.8,32.9z" />
			<path class="stim3" d="M237.8,36.5h18c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1c0-0.5-0.4-0.9-0.9-0.9h-18c-0.5,0-0.9,0.3-0.9,0.8
			c0,0,0,0.1,0,0.1C237,36.2,237.3,36.5,237.8,36.5z" />
			<path class="stim3" d="M237.8,40.1h18c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1c0-0.5-0.4-0.9-0.9-0.9h-18c-0.5,0-0.9,0.3-0.9,0.8
			c0,0,0,0.1,0,0.1C236.9,39.7,237.3,40.1,237.8,40.1z" />
		</g>
		<g id="refine_on" class="stim11" (click)="goto('Refine user stories')">
			<circle class="stim12" cx="245.9" cy="37.4" r="22.6" />
			<path class="stim13" d="M259.4,26.6h-25.2c-0.5,0-0.9,0.3-0.9,0.8c0,0,0,0.1,0,0.1v16.2c0,0.5,0.4,0.9,0.9,0.9h3.6v4.5
			c0,0.4,0.3,0.7,0.6,0.8c0.1,0,0.1,0.1,0.3,0.1c0.3,0,0.6-0.1,0.7-0.4l4.2-5h15.8c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1V27.5
			C260.3,27,259.9,26.6,259.4,26.6z M258.5,42.8h-15.3c-0.3,0-0.6,0.1-0.7,0.4l-2.9,3.5v-2.9c0-0.5-0.4-0.9-0.9-0.9h-3.6V28.5h23.4
			V42.8z" />
			<path class="stim13" d="M237.8,32.9h18c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1c0-0.5-0.4-0.9-0.9-0.9h-18c-0.5,0-0.9,0.3-0.9,0.8
			c0,0,0,0.1,0,0.1C236.9,32.5,237.3,32.9,237.8,32.9z" />
			<path class="stim13" d="M237.8,36.5h18c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1c0-0.5-0.4-0.9-0.9-0.9h-18c-0.5,0-0.9,0.3-0.9,0.8
			c0,0,0,0.1,0,0.1C237,36.2,237.3,36.5,237.8,36.5z" />
			<path class="stim13" d="M237.8,40.1h18c0.5,0,0.9-0.3,0.9-0.8c0,0,0-0.1,0-0.1c0-0.5-0.4-0.9-0.9-0.9h-18c-0.5,0-0.9,0.3-0.9,0.8
			c0,0,0,0.1,0,0.1C236.9,39.7,237.3,40.1,237.8,40.1z" />
		</g>
		<g id="adapt_off">
			<circle class="stim10" cx="72" cy="99" r="22.6" />
			<path class="stim3" d="M79.1,95.7l2.1-2.1h-2.3c-2.6,0-4.1,2-5.4,3.7c-0.4,0.4-0.7,0.8-1,1.3L72.1,99l0.4,0.4c0.4,0.4,0.7,0.8,1,1.3
			c1.4,1.7,2.9,3.7,5.4,3.7h2.3l-2.1-2.1c-0.3-0.4-0.3-1,0.1-1.3c0.4-0.3,0.9-0.3,1.2,0l3.6,3.6c0.1,0.1,0.1,0.2,0.1,0.3
			c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.1-0.1,0.1-0.1,0.3l-3.6,3.6c-0.1,0.1-0.3,0.1-0.6,0.1c-0.2,0-0.5-0.1-0.6-0.3
			c-0.4-0.3-0.4-0.8-0.1-1.2c0,0,0.1-0.1,0.1-0.1l2.1-2.1H79c-3.4,0-5.4-2.5-6.8-4.3c-0.4-0.4-0.6-0.8-0.9-1.1l-0.3-0.3l-0.1,0.3
			c-0.3,0.4-0.6,0.7-0.9,1.1c-1.5,1.9-3.6,4.3-7,4.3h-4.4c-0.5,0-0.9-0.3-0.9-0.8c0,0,0-0.1,0-0.1c0-0.5,0.4-0.9,0.9-0.9h0H63
			c2.6,0,4.1-1.9,5.4-3.7c0.4-0.4,0.7-0.8,1-1.3l0.4-0.4l-0.4-0.4c-0.4-0.4-0.7-0.8-1-1.3c-1.4-1.6-2.8-3.6-5.4-3.6h-4.4
			c-0.5,0-0.9-0.3-0.9-0.8c0,0,0-0.1,0-0.1c0-0.5,0.4-0.9,0.9-0.9h0H63c3.4,0,5.4,2.5,6.8,4.3c0.4,0.4,0.6,0.8,0.9,1.1l0.3,0.3
			l0.1-0.3c0.3-0.4,0.6-0.7,0.9-1.1c1.4-1.9,3.5-4.3,6.8-4.3h2.3L79,89.5c-0.4-0.3-0.4-0.9-0.1-1.3c0.3-0.4,0.9-0.4,1.3-0.1
			c0,0,0,0,0.1,0.1l3.6,3.6c0.1,0.1,0.1,0.1,0.1,0.3c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.1-0.1,0.1-0.1,0.3l-3.6,3.6
			c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.5-0.1-0.6-0.3C78.8,96.6,78.8,96,79.1,95.7z" />
		</g>
		<g id="adapt_on" class="stim11" (click)="goto('Adapt leading practices')">
			<circle class="stim12" cx="72" cy="99" r="22.6" />
			<path class="stim13" d="M79.1,95.7l2.1-2.1h-2.3c-2.6,0-4.1,2-5.4,3.7c-0.4,0.4-0.7,0.8-1,1.3L72.1,99l0.4,0.4
			c0.4,0.4,0.7,0.8,1,1.3c1.4,1.7,2.9,3.7,5.4,3.7h2.3l-2.1-2.1c-0.3-0.4-0.3-1,0.1-1.3c0.4-0.3,0.9-0.3,1.2,0l3.6,3.6
			c0.1,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.5,0,0.7c-0.1,0.1-0.1,0.1-0.1,0.3l-3.6,3.6c-0.1,0.1-0.3,0.1-0.6,0.1
			c-0.2,0-0.5-0.1-0.6-0.3c-0.4-0.3-0.4-0.8-0.1-1.2c0,0,0.1-0.1,0.1-0.1l2.1-2.1H79c-3.4,0-5.4-2.5-6.8-4.3
			c-0.4-0.4-0.6-0.8-0.9-1.1l-0.3-0.3l-0.1,0.3c-0.3,0.4-0.6,0.7-0.9,1.1c-1.5,1.9-3.6,4.3-7,4.3h-4.4c-0.5,0-0.9-0.3-0.9-0.8
			c0,0,0-0.1,0-0.1c0-0.5,0.4-0.9,0.9-0.9h0H63c2.6,0,4.1-1.9,5.4-3.7c0.4-0.4,0.7-0.8,1-1.3l0.4-0.4l-0.4-0.4
			c-0.4-0.4-0.7-0.8-1-1.3c-1.4-1.6-2.8-3.6-5.4-3.6h-4.4c-0.5,0-0.9-0.3-0.9-0.8c0,0,0-0.1,0-0.1c0-0.5,0.4-0.9,0.9-0.9h0H63
			c3.4,0,5.4,2.5,6.8,4.3c0.4,0.4,0.6,0.8,0.9,1.1l0.3,0.3l0.1-0.3c0.3-0.4,0.6-0.7,0.9-1.1c1.4-1.9,3.5-4.3,6.8-4.3h2.3L79,89.5
			c-0.4-0.3-0.4-0.9-0.1-1.3c0.3-0.4,0.9-0.4,1.3-0.1c0,0,0,0,0.1,0.1l3.6,3.6c0.1,0.1,0.1,0.1,0.1,0.3c0.1,0.2,0.1,0.5,0,0.7
			c-0.1,0.1-0.1,0.1-0.1,0.3l-3.6,3.6c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.5-0.1-0.6-0.3C78.8,96.6,78.8,96,79.1,95.7z" />
		</g>
		<g id="apply_off">
			<circle class="stim10" cx="71" cy="225.1" r="22.6" />
			<path class="stim3" d="M84.9,226.4c0-1.2-0.5-2.4-1.5-3.2c0.2-0.6,0.4-1.2,0.4-1.8c0-2.5-1.9-4.5-4.4-4.5c-0.6-1.8-2.3-3-4.2-3.1
			c-0.6,0-1.2,0.1-1.8,0.4c-0.8-0.9-2-1.5-3.2-1.5c-1.1,0-2.2,0.4-3.1,1.1c-1.4-0.3-3,0-4.1,1c-0.3-0.1-0.7-0.1-1-0.1
			c-2.5,0-4.5,2-4.5,4.5c0,0,0,0,0,0v0.3c-2.9,1.6-4,5.4-2.3,8.3c1.1,1.9,3.1,3.1,5.3,3.1c0.8,0,1.6-0.1,2.3-0.4
			c0.8,0.9,2,1.5,3.3,1.5c0.9,0,1.7-0.3,2.5-0.7c0.5,0.7,1.2,1.2,2,1.5v5.3c0,0.5,0.4,1,0.9,1c0,0,0.1,0,0.1,0h6.1c0.6,0,1-0.4,1-1
			c0-0.9,0.5-1.8,1.2-2.4c1.3-0.8,2.8-2.1,2.8-4.1c0-0.4-0.1-0.7-0.2-1.1C84,229.7,84.9,228.1,84.9,226.4z M81.2,228.9
			c-0.3,0.1-0.5,0.3-0.6,0.6c-0.2,0.3-0.1,0.7,0.1,0.9c0.2,0.4,0.3,0.8,0.3,1.2c0,0.7-0.3,1.5-1.8,2.4c-1.1,0.8-1.8,1.9-2,3.2h-4.5
			v-5.1c0-0.5-0.4-0.9-0.9-1c-0.9-0.1-1.7-0.7-2-1.6c-0.1-0.3-0.4-0.6-0.8-0.6c-0.4-0.1-0.7,0.1-0.9,0.4c-0.5,0.5-1.2,0.7-1.9,0.7
			c-0.9,0-1.7-0.5-2.1-1.2c-0.3-0.5-1-0.6-1.5-0.3c0,0,0,0,0,0c-0.6,0.4-1.3,0.6-2,0.6c-2.2,0-4.1-1.8-4.1-4c0,0,0-0.1,0-0.1
			c0-1.6,1-3.1,2.5-3.7c0.5-0.2,0.7-0.7,0.6-1.2c-0.1-0.2-0.1-0.5-0.1-0.7c0-1.3,1-2.5,2.3-2.5c0.1,0,0.1,0,0.2,0
			c0.3,0,0.6,0.1,0.9,0.2c0.4,0.2,0.9,0.1,1.1-0.3c0,0,0,0,0,0c0.7-0.8,1.9-1.1,2.9-0.7c0.4,0.2,0.9,0.1,1.1-0.3c0,0,0,0,0,0
			c0.5-0.6,1.2-0.9,1.9-0.9c0.9,0,1.7,0.4,2.1,1.2c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0,0.8-0.2c0.4-0.3,1-0.5,1.5-0.5
			c1.3,0,2.4,1,2.5,2.3c0,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.5,0.2,0.8,0.2h0.4c1.3,0,2.5,1,2.5,2.3c0,0.1,0,0.1,0,0.2
			c0,0.5-0.2,1.1-0.5,1.5c-0.2,0.2-0.2,0.5-0.2,0.8c0,0.3,0.2,0.5,0.5,0.7c0.7,0.5,1.2,1.3,1.2,2.1
			C82.8,227.7,82.2,228.6,81.2,228.9z M63.6,219.4c0.6,1.4,1,2.9,1.1,4.5c0,0.4-0.2,0.8-0.5,1c-0.4,0.2-0.8,0.1-1.1-0.1
			c-0.3-0.2-1.6,0.2-2.8,1.1c-0.3,0-0.5,0.1-0.7,0.1c-0.3,0-0.6-0.1-0.8-0.4c-0.3-0.5-0.2-1.1,0.2-1.5c1-0.8,2.2-1.3,3.4-1.5
			c-0.1-0.8-0.3-1.6-0.7-2.3c-0.3-0.5-0.1-1.2,0.4-1.5C62.7,218.7,63.3,218.9,63.6,219.4z M67,221.6c-0.3-0.3-0.5-0.8-0.3-1.2
			c0-0.2,0.2-0.6,1-1.1c-0.1-0.3,0-0.6,0.1-0.9c0.3-0.5,1-0.7,1.5-0.4c0.2,0.1,0.4,0.3,0.4,0.5h0.2c0.5,0,0.9,0.4,0.9,0.9
			c0,0.5-0.2,0.9-0.7,1.1c-0.4,0.1-0.9,0.3-1.3,0.5c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.3-0.7,0.3C67.5,221.9,67.2,221.8,67,221.6
			z M72.6,223.5c-0.8,1-0.2,1.6-0.1,1.7c0.4,0.3,0.4,0.9,0.2,1.3c-0.2,0.2-0.6,0.4-0.9,0.4c-0.1,0-0.3,0-0.4-0.1
			c-1-0.4-2.2-0.4-3.2,0c-0.5,0.3-1.1,0.2-1.4-0.3c-0.3-0.5-0.2-1.1,0.3-1.4c0.1-0.1,0.2-0.1,0.3-0.1c0.9-0.4,1.8-0.5,2.8-0.5
			c0-0.8,0.3-1.6,0.8-2.3c0.4-0.4,1-0.5,1.5-0.2C72.9,222.4,72.9,223,72.6,223.5L72.6,223.5z M74.8,220.8c0-0.5,0.5-1,1-1l0,0
			c0.5,0,1.5,0.3,2.8,2.5c0.3,0.5,0.2,1.2-0.3,1.5c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.7-0.2-0.8-0.5c-0.4-0.6-0.8-1.1-1.3-1.6
			C75.2,221.8,74.8,221.4,74.8,220.8C74.8,220.8,74.8,220.8,74.8,220.8z M80.9,225.9c0,0.6-0.4,1-1,1c-0.9-0.1-1.7,0.5-2,1.3l0,0
			c-0.3,0.7-0.7,1.4-1.1,2.1c0,0.2,0.3,0.5,0.6,0.6c0.5,0.2,0.7,0.8,0.5,1.3c-0.3,0.5-0.6,0.7-1,0.7c-0.1,0-0.3,0-0.4-0.1
			c-0.8-0.4-1.4-1-1.7-1.8c-0.2-0.6-0.1-1.2,0.2-1.7c0.4-0.6,0.7-1.2,0.9-1.8c-0.1-0.3-0.3-0.7-0.6-0.9c-0.5-0.4-0.6-1-0.2-1.5
			s1-0.6,1.5-0.2l0,0c0.3,0.2,0.5,0.4,0.7,0.7c0.8-0.5,1.8-0.8,2.8-0.8C80.5,225,80.9,225.4,80.9,225.9z" />
		</g>
		<g id="apply_on" class="stim11" (click)="goto('Apply design thinking')">
			<circle class="stim12" cx="71" cy="225.1" r="22.6" />
			<path class="stim13" d="M84.9,226.4c0-1.2-0.5-2.4-1.5-3.2c0.2-0.6,0.4-1.2,0.4-1.8c0-2.5-1.9-4.5-4.4-4.5c-0.6-1.8-2.3-3-4.2-3.1
			c-0.6,0-1.2,0.1-1.8,0.4c-0.8-0.9-2-1.5-3.2-1.5c-1.1,0-2.2,0.4-3.1,1.1c-1.4-0.3-3,0-4.1,1c-0.3-0.1-0.7-0.1-1-0.1
			c-2.5,0-4.5,2-4.5,4.5c0,0,0,0,0,0v0.3c-2.9,1.6-4,5.4-2.3,8.3c1.1,1.9,3.1,3.1,5.3,3.1c0.8,0,1.6-0.1,2.3-0.4
			c0.8,0.9,2,1.5,3.3,1.5c0.9,0,1.7-0.3,2.5-0.7c0.5,0.7,1.2,1.2,2,1.5v5.3c0,0.5,0.4,1,0.9,1c0,0,0.1,0,0.1,0h6.1c0.6,0,1-0.4,1-1
			c0-0.9,0.5-1.8,1.2-2.4c1.3-0.8,2.8-2.1,2.8-4.1c0-0.4-0.1-0.7-0.2-1.1C84,229.7,84.9,228.1,84.9,226.4z M81.2,228.9
			c-0.3,0.1-0.5,0.3-0.6,0.6c-0.2,0.3-0.1,0.7,0.1,0.9c0.2,0.4,0.3,0.8,0.3,1.2c0,0.7-0.3,1.5-1.8,2.4c-1.1,0.8-1.8,1.9-2,3.2h-4.5
			v-5.1c0-0.5-0.4-0.9-0.9-1c-0.9-0.1-1.7-0.7-2-1.6c-0.1-0.3-0.4-0.6-0.8-0.6c-0.4-0.1-0.7,0.1-0.9,0.4c-0.5,0.5-1.2,0.7-1.9,0.7
			c-0.9,0-1.7-0.5-2.1-1.2c-0.3-0.5-1-0.6-1.5-0.3c0,0,0,0,0,0c-0.6,0.4-1.3,0.6-2,0.6c-2.2,0-4.1-1.8-4.1-4c0,0,0-0.1,0-0.1
			c0-1.6,1-3.1,2.5-3.7c0.5-0.2,0.7-0.7,0.6-1.2c-0.1-0.2-0.1-0.5-0.1-0.7c0-1.3,1-2.5,2.3-2.5c0.1,0,0.1,0,0.2,0
			c0.3,0,0.6,0.1,0.9,0.2c0.4,0.2,0.9,0.1,1.1-0.3c0,0,0,0,0,0c0.7-0.8,1.9-1.1,2.9-0.7c0.4,0.2,0.9,0.1,1.1-0.3c0,0,0,0,0,0
			c0.5-0.6,1.2-0.9,1.9-0.9c0.9,0,1.7,0.4,2.1,1.2c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0,0.8-0.2c0.4-0.3,1-0.5,1.5-0.5
			c1.3,0,2.4,1,2.5,2.3c0,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.5,0.2,0.8,0.2h0.4c1.3,0,2.5,1,2.5,2.3c0,0.1,0,0.1,0,0.2
			c0,0.5-0.2,1.1-0.5,1.5c-0.2,0.2-0.2,0.5-0.2,0.8c0,0.3,0.2,0.5,0.5,0.7c0.7,0.5,1.2,1.3,1.2,2.1
			C82.8,227.7,82.2,228.6,81.2,228.9z M63.6,219.4c0.6,1.4,1,2.9,1.1,4.5c0,0.4-0.2,0.8-0.5,1c-0.4,0.2-0.8,0.1-1.1-0.1
			c-0.3-0.2-1.6,0.2-2.8,1.1c-0.3,0-0.5,0.1-0.7,0.1c-0.3,0-0.6-0.1-0.8-0.4c-0.3-0.5-0.2-1.1,0.2-1.5c1-0.8,2.2-1.3,3.4-1.5
			c-0.1-0.8-0.3-1.6-0.7-2.3c-0.3-0.5-0.1-1.2,0.4-1.5C62.7,218.7,63.3,218.9,63.6,219.4z M67,221.6c-0.3-0.3-0.5-0.8-0.3-1.2
			c0-0.2,0.2-0.6,1-1.1c-0.1-0.3,0-0.6,0.1-0.9c0.3-0.5,1-0.7,1.5-0.4c0.2,0.1,0.4,0.3,0.4,0.5h0.2c0.5,0,0.9,0.4,0.9,0.9
			c0,0.5-0.2,0.9-0.7,1.1c-0.4,0.1-0.9,0.3-1.3,0.5c0,0.2-0.1,0.4-0.3,0.6c-0.2,0.2-0.4,0.3-0.7,0.3C67.5,221.9,67.2,221.8,67,221.6
			z M72.6,223.5c-0.8,1-0.2,1.6-0.1,1.7c0.4,0.3,0.4,0.9,0.2,1.3c-0.2,0.2-0.6,0.4-0.9,0.4c-0.1,0-0.3,0-0.4-0.1
			c-1-0.4-2.2-0.4-3.2,0c-0.5,0.3-1.1,0.2-1.4-0.3c-0.3-0.5-0.2-1.1,0.3-1.4c0.1-0.1,0.2-0.1,0.3-0.1c0.9-0.4,1.8-0.5,2.8-0.5
			c0-0.8,0.3-1.6,0.8-2.3c0.4-0.4,1-0.5,1.5-0.2C72.9,222.4,72.9,223,72.6,223.5L72.6,223.5z M74.8,220.8c0-0.5,0.5-1,1-1l0,0
			c0.5,0,1.5,0.3,2.8,2.5c0.3,0.5,0.2,1.2-0.3,1.5c-0.1,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.7-0.2-0.8-0.5c-0.4-0.6-0.8-1.1-1.3-1.6
			C75.2,221.8,74.8,221.4,74.8,220.8C74.8,220.8,74.8,220.8,74.8,220.8z M80.9,225.9c0,0.6-0.4,1-1,1c-0.9-0.1-1.7,0.5-2,1.3l0,0
			c-0.3,0.7-0.7,1.4-1.1,2.1c0,0.2,0.3,0.5,0.6,0.6c0.5,0.2,0.7,0.8,0.5,1.3c-0.3,0.5-0.6,0.7-1,0.7c-0.1,0-0.3,0-0.4-0.1
			c-0.8-0.4-1.4-1-1.7-1.8c-0.2-0.6-0.1-1.2,0.2-1.7c0.4-0.6,0.7-1.2,0.9-1.8c-0.1-0.3-0.3-0.7-0.6-0.9c-0.5-0.4-0.6-1-0.2-1.5
			s1-0.6,1.5-0.2l0,0c0.3,0.2,0.5,0.4,0.7,0.7c0.8-0.5,1.8-0.8,2.8-0.8C80.5,225,80.9,225.4,80.9,225.9z" />
		</g>
		<g id="define_off">

			<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -91.8307 228.5062)" class="stim10" cx="229.9"
				cy="225.1" rx="22.6" ry="22.6" />
			<path class="stim3" d="M234,232.7c-1.1-0.5-2.3-0.7-3.5-0.7c-0.5,0-1-0.1-1.5-0.2c-0.7-0.7-1.1-1.8-1-2.8c0.9-1.5,1.6-3.1,2-4.8
			c0.7-3,0.4-5.3-0.9-6.9c-2.2-2.5-6.1-2.8-8.6-0.6c-0.2,0.2-0.4,0.4-0.6,0.6c-1.3,1.6-1.6,3.9-0.9,6.9c0.4,1.7,1.1,3.3,2,4.8
			c0.1,0.6-0.4,2.5-1,2.8c-0.5,0.2-1,0.3-1.5,0.2c-1.2,0-2.4,0.2-3.5,0.7c-0.5,0.2-0.7,0.8-0.5,1.3c0.2,0.4,0.5,0.6,0.9,0.6
			c0.1,0,0.3,0,0.4-0.1c0.9-0.4,1.8-0.6,2.7-0.6c0.8,0,1.6-0.1,2.3-0.5c1.3-0.7,1.8-2.6,1.9-3.1c0.3-0.8,0.2-1.7-0.2-2.5
			c-0.8-1.3-1.4-2.6-1.8-4.1c-0.6-2.3-0.4-4,0.5-5.1c0.8-0.8,1.9-1.3,3-1.3l0,0c1.1,0,2.2,0.5,3,1.3c0.9,1.1,1,2.8,0.5,5.1
			c-0.4,1.5-1,2.8-1.8,4.1c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,1.2,0.9,2.3,1.9,3.1c0.7,0.3,1.5,0.5,2.3,0.5c0.9,0,1.9,0.2,2.7,0.6
			c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7-0.2,0.9-0.6C234.7,233.5,234.5,233,234,232.7z" />
			<path class="stim3" d="M244,230.8c-0.8-0.4-1.6-0.7-2.5-0.7c-0.5-0.1-1.1-0.2-1.4-0.3c-0.4-0.4-0.6-1-0.5-1.6
			c0.8-1.2,1.3-2.4,1.6-3.8c0.6-1.8,0.4-3.9-0.7-5.5c-2-2.2-5.3-2.4-7.5-0.5c-0.2,0.1-0.3,0.3-0.5,0.5c-1,1.3-1.3,3.1-0.7,5.5
			c0.3,1.3,0.9,2.6,1.6,3.8c0.1,0.6-0.1,1.2-0.5,1.7c-0.4,0.4-0.5,0.9-0.2,1.4c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2
			c1.3-1,1.8-2.9,1-4.4c-0.6-0.9-1.1-2-1.3-3.1c-0.4-1.7-0.3-2.9,0.3-3.7c1.3-1.3,3.5-1.3,4.8,0c0,0,0,0,0,0c0.6,0.8,0.7,2,0.3,3.7
			c-0.3,1.1-0.7,2.1-1.3,3.1c-0.7,1.5-0.3,3.3,1,4.4l0.1,0.1c0.7,0.4,1.4,0.6,2.2,0.6c0.6,0,1.2,0.2,1.8,0.5
			c0.2,0.1,0.3,0.2,0.5,0.2c0.4,0,0.7-0.2,0.9-0.5C244.7,231.8,244.5,231.2,244,230.8z" />
		</g>
		<g id="define_on" class="stim11" (click)="goto('Define operate organization')"> 

			<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -91.8307 228.5062)" class="stim12" cx="229.9"
				cy="225.1" rx="22.6" ry="22.6" />
			<path class="stim13" d="M234,232.7c-1.1-0.5-2.3-0.7-3.5-0.7c-0.5,0-1-0.1-1.5-0.2c-0.7-0.7-1.1-1.8-1-2.8c0.9-1.5,1.6-3.1,2-4.8
			c0.7-3,0.4-5.3-0.9-6.9c-2.2-2.5-6.1-2.8-8.6-0.6c-0.2,0.2-0.4,0.4-0.6,0.6c-1.3,1.6-1.6,3.9-0.9,6.9c0.4,1.7,1.1,3.3,2,4.8
			c0.1,0.6-0.4,2.5-1,2.8c-0.5,0.2-1,0.3-1.5,0.2c-1.2,0-2.4,0.2-3.5,0.7c-0.5,0.2-0.7,0.8-0.5,1.3c0.2,0.4,0.5,0.6,0.9,0.6
			c0.1,0,0.3,0,0.4-0.1c0.9-0.4,1.8-0.6,2.7-0.6c0.8,0,1.6-0.1,2.3-0.5c1.3-0.7,1.8-2.6,1.9-3.1c0.3-0.8,0.2-1.7-0.2-2.5
			c-0.8-1.3-1.4-2.6-1.8-4.1c-0.6-2.3-0.4-4,0.5-5.1c0.8-0.8,1.9-1.3,3-1.3l0,0c1.1,0,2.2,0.5,3,1.3c0.9,1.1,1,2.8,0.5,5.1
			c-0.4,1.5-1,2.8-1.8,4.1c-0.4,0.8-0.5,1.7-0.2,2.5c0.2,1.2,0.9,2.3,1.9,3.1c0.7,0.3,1.5,0.5,2.3,0.5c0.9,0,1.9,0.2,2.7,0.6
			c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7-0.2,0.9-0.6C234.7,233.5,234.5,233,234,232.7z" />
			<path class="stim13" d="M244,230.8c-0.8-0.4-1.6-0.7-2.5-0.7c-0.5-0.1-1.1-0.2-1.4-0.3c-0.4-0.4-0.6-1-0.5-1.6
			c0.8-1.2,1.3-2.4,1.6-3.8c0.6-1.8,0.4-3.9-0.7-5.5c-2-2.2-5.3-2.4-7.5-0.5c-0.2,0.1-0.3,0.3-0.5,0.5c-1,1.3-1.3,3.1-0.7,5.5
			c0.3,1.3,0.9,2.6,1.6,3.8c0.1,0.6-0.1,1.2-0.5,1.7c-0.4,0.4-0.5,0.9-0.2,1.4c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2
			c1.3-1,1.8-2.9,1-4.4c-0.6-0.9-1.1-2-1.3-3.1c-0.4-1.7-0.3-2.9,0.3-3.7c1.3-1.3,3.5-1.3,4.8,0c0,0,0,0,0,0c0.6,0.8,0.7,2,0.3,3.7
			c-0.3,1.1-0.7,2.1-1.3,3.1c-0.7,1.5-0.3,3.3,1,4.4l0.1,0.1c0.7,0.4,1.4,0.6,2.2,0.6c0.6,0,1.2,0.2,1.8,0.5
			c0.2,0.1,0.3,0.2,0.5,0.2c0.4,0,0.7-0.2,0.9-0.5C244.7,231.8,244.5,231.2,244,230.8z" />
		</g>
		<g id="launch_off">
			<circle class="stim10" cx="217.6" cy="98.4" r="22.6" />
			<path class="stim3" d="M218.6,113.3c-0.3,0-0.5-0.1-0.7-0.3l-4.1-4.1c-0.6,0.3-1.3,0.4-2,0.5c-1.3,0.2-2.6-0.2-3.5-1.1
			c-1.5-1.5-1.3-4-0.6-5.5l-4.1-4.1c-0.3-0.3-0.4-0.8-0.1-1.2c3-4.9,7.2-4.7,8.8-4.3c9.1-8.8,16.7-6.5,17.1-6.4
			c0.4,0.1,0.6,0.4,0.7,0.8c0.1,0.4,1.4,8.8-6.5,17c0.3,1.5,0.4,5.4-4.1,8.7H218.6z M213.8,106.7c0.3,0,0.5,0.1,0.7,0.3l4.1,4.1
			c3.7-3.2,2.5-6.4,2.5-6.5c-0.1-0.3,0-0.7,0.2-1c6.4-6.4,6.7-12.9,6.4-15c-1.9-0.3-7.9-0.5-15,6.5c-0.1,0.3-0.5,0.3-0.9,0.2
			c-0.2-0.1-3.8-1.4-6.5,2.5l4.1,4.1c0.4,0.4,0.4,1,0,1.4c-0.4,0.5-0.9,2.5,0,3.5s3.1,0.4,3.5,0
			C213.2,106.7,213.5,106.7,213.8,106.7L213.8,106.7z M216.5,103.9c-1.1,0-2.2-0.5-3-1.3c-1.7-1.6-1.7-4.2-0.1-5.9
			c0,0,0.1-0.1,0.1-0.1c0.8-0.8,1.9-1.3,3-1.3c1.1,0,2.2,0.5,3,1.3c1.7,1.6,1.7,4.2,0.1,5.9c0,0-0.1,0.1-0.1,0.1l0,0
			C218.7,103.4,217.6,103.9,216.5,103.9L216.5,103.9z M216.5,97.4c-0.6,0-1.2,0.2-1.6,0.7c-0.9,0.9-0.9,2.4,0,3.3s2.4,0.9,3.3,0l0,0
			c0.9-0.9,0.9-2.4,0-3.3C217.8,97.6,217.1,97.3,216.5,97.4L216.5,97.4z" />
		</g>
		<g id="launch_on" class="stim11" (click)="goto('Launch journey')">
			<circle class="stim12" cx="217.6" cy="98.4" r="22.6" />
			<path class="stim13" d="M218.6,113.3c-0.3,0-0.5-0.1-0.7-0.3l-4.1-4.1c-0.6,0.3-1.3,0.4-2,0.5c-1.3,0.2-2.6-0.2-3.5-1.1
			c-1.5-1.5-1.3-4-0.6-5.5l-4.1-4.1c-0.3-0.3-0.4-0.8-0.1-1.2c3-4.9,7.2-4.7,8.8-4.3c9.1-8.8,16.7-6.5,17.1-6.4
			c0.4,0.1,0.6,0.4,0.7,0.8c0.1,0.4,1.4,8.8-6.5,17c0.3,1.5,0.4,5.4-4.1,8.7H218.6z M213.8,106.7c0.3,0,0.5,0.1,0.7,0.3l4.1,4.1
			c3.7-3.2,2.5-6.4,2.5-6.5c-0.1-0.3,0-0.7,0.2-1c6.4-6.4,6.7-12.9,6.4-15c-1.9-0.3-7.9-0.5-15,6.5c-0.1,0.3-0.5,0.3-0.9,0.2
			c-0.2-0.1-3.8-1.4-6.5,2.5l4.1,4.1c0.4,0.4,0.4,1,0,1.4c-0.4,0.5-0.9,2.5,0,3.5s3.1,0.4,3.5,0
			C213.2,106.7,213.5,106.7,213.8,106.7L213.8,106.7z M216.5,103.9c-1.1,0-2.2-0.5-3-1.3c-1.7-1.6-1.7-4.2-0.1-5.9
			c0,0,0.1-0.1,0.1-0.1c0.8-0.8,1.9-1.3,3-1.3c1.1,0,2.2,0.5,3,1.3c1.7,1.6,1.7,4.2,0.1,5.9c0,0-0.1,0.1-0.1,0.1l0,0
			C218.7,103.4,217.6,103.9,216.5,103.9L216.5,103.9z M216.5,97.4c-0.6,0-1.2,0.2-1.6,0.7c-0.9,0.9-0.9,2.4,0,3.3s2.4,0.9,3.3,0l0,0
			c0.9-0.9,0.9-2.4,0-3.3C217.8,97.6,217.1,97.3,216.5,97.4L216.5,97.4z" />
		</g>
	</g>
	<g id="Isolation_Mode">
		<circle class="stim3" cx="176.3" cy="67.6" r="3.7" />
		<path class="stim14" d="M179.3,67.6" />
	</g>
</svg>