interface CarouselCardI {
  header: string;
  subheader: string;
  description: string;
  themeKey: string;
  imageName: string;
}
interface homepageCard {
  title: string;
  content: string;
  buttonName: string;
}

export interface discoverCard {
  iconImage: string;
  iconHoverImage: string;
  content: string;
  buttonName: string;
}

/* -- Data for rendering Insight card on home page -- */

const HOME_INSIGHTS_CONSTANT: CarouselCardI = {
  header: "Prepare",
  subheader: "Ambitions | Value | Exploring digital",
  description: "Explore digital platform, ambitions and value it provides",
  themeKey: "insights",
  imageName: "Insights - wheel.svg",
};

/* -- Data for rendering Imagine card on home page -- */

const HOME_IMAGINE_CONSTANT: CarouselCardI = {
  header: "Imagine",
  subheader: "Personalized | Insightful | Meaningful", //"Digital native | Personalized | Becoming digital",
  description: "Decide your personalized digital strategy",
  themeKey: "imagine",
  imageName: "Imagine - wheel.svg",
};

/* --Data for rendering Deliver card on home page -- */
const HOME_DELIVER_CONSTANT: CarouselCardI = {
  header: "Deliver",
  subheader: "Agile | Iterative | Responsive",
  description: "Build your capabilities and start your implementation",
  themeKey: "deliver",
  imageName: "Deliver_all_OFF.svg",
};
/* -- Data for rendering Deliver card on home page -- */

const HOME_RUN_CONSTANT: CarouselCardI = {
  header: "Run",
  subheader: "Innovative | Efficient | Productive",
  description:
    "Become fully digital by implementing efficient and sustainable solutions",
  themeKey: "run",
  imageName: "Run - wheel.svg",
};

export const HOME_CONSTANT: CarouselCardI[] = [
  HOME_INSIGHTS_CONSTANT,
  HOME_IMAGINE_CONSTANT,
  HOME_DELIVER_CONSTANT,
  HOME_RUN_CONSTANT,
];

export const DELOITTE_WEB_PRIVACY =
  "https://www2.deloitte.com/us/en/footerlinks1/privacy-notice-limited-controller.html";

export const homepageCards: homepageCard[] = [
  // {
  //   title: "Discover",
  //   content: "Discover how Ascend can help meet your engagement goals",
  //   buttonName: "Discover Ascend",
  // },  //Commented for prod request. Need to uncomment once Actual links are there.
  {
    title: "Explore",
    content:
      "Explore how Ascend can orchestrate your end-to-end digital transformation journey",
    buttonName: "Explore Ascend",
  },
  {
    title: "Execute",
    content: "Deliver your project using Ascend",
    buttonName: "Go to My Projects",
  },
];
