import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-capacity-warning-popup',
  templateUrl: './edit-capacity-warning-popup.component.html',
  styleUrls: ['./edit-capacity-warning-popup.component.scss']
})
export class EditCapacityWarningPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditCapacityWarningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService
  ){

  }


  ngOnInit(){
  }

  close(){
    this.dialogRef.close();
  }

  regenerate(){
    this.dialogRef.close('confirm');
  }



}
