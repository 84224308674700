import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UNSAVED_POPUP } from '../../../core/utility/constants';

@Component({
  selector: 'app-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss']
})
export class CommonPopupComponent implements OnInit {


  constructor(
    private dialogRef: MatDialogRef<CommonPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {}

  discardChanges() {
    this.dialogRef.close(UNSAVED_POPUP.DISCARD);
  }
  saveChanges() {
    this.dialogRef.close(UNSAVED_POPUP.SAVE);
  }

  cancelChanges() {
    this.dialogRef.close();
  }

}
