<div class="external-user-container">
    <div class="logo">
        <img class="logo-img" src="../../../assets/images/logo.svg" />
    </div>
    <div class="container">
        <div class="login-section signup-field">
            <form *ngIf="externalUserForm" [formGroup]="externalUserForm">
                <img src="../../../../../assets/deloitte_logo.svg" width="228px" height="20px" loading="lazy"/>
                <div class="user-name">
                    <mat-form-field appearance="outline" floatLabel="never">
                        <input class="field-input" matInput [placeholder]="'LandingPage.ExternalSignUp.FirstName' | translate" formControlName="firstName">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" floatLabel="never">
                        <input class="field-input" matInput [placeholder]="'LandingPage.ExternalSignUp.LastName' | translate" formControlName="lastName">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="outline" floatLabel="never">
                        <input class="field-input" matInput [placeholder]="'LandingPage.ExternalSignUp.Email' | translate" formControlName="email">
                    </mat-form-field>
                </div>
                <div>
                    <button mat-button class="login-btn" (click)="externalUserLogin()">
                      {{"LandingPage.ExternalSignUp.StartJourney" | translate}}
                    </button>
                </div>
            </form>
        </div>
        <div class="bottom-section">
            <div class="unable-to-login">
                <span>{{"LandingPage.ExternalSignUp.UnableToLogin" | translate}}</span>
            </div>
            <div class="external-user-text">
                <span>
                    {{"LandingPage.ExternalSignUp.AccessMessage" | translate}}
                </span>
            </div>
        </div>
    </div>
</div>