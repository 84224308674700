import { Injectable } from '@angular/core';
import { CryptUtilService } from 'src/app/shared/services/crypt-util.service';
import { environment } from 'src/environments/environment';
import { BaseService } from './base-http.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalInstancesService {
  languageId: number = 1;
  public currentGlobalInstance: BehaviorSubject<any>;
  constructor(
    private cryptService: CryptUtilService,
    private baseService: BaseService
  ) {
    this.currentGlobalInstance = new BehaviorSubject(null);
  }

  async globalInstances(): Promise<any> {
    const currentInstance = this.cryptService.getItem('currentInstance', 'SESSION');
    if (currentInstance) {
      return Promise.resolve(currentInstance);
    } else {
      const data = await this.getGlobalInstances().toPromise()
      if (data.status == 200 && data?.data) {
        this.cryptService.setItem('currentInstance', data, 'SESSION');
        return Promise.resolve(data);
      }
    }
  }

  getGlobalInstances(): any {
    return this.baseService.get(`/admin/global-instances`, environment.API_MICROSERVICE_URL?.ADMIN)
  }

  async getIsReadOnlyRegionValue(): Promise<boolean> {
    const isReadOnlyRegion = this.cryptService.getItem('isReadOnlyRegion', 'SESSION');
    if (typeof isReadOnlyRegion == 'boolean') {
      return Promise.resolve(isReadOnlyRegion);
    } else {
      // calling admin API to get global-instances
      const data = await this.getGlobalInstances().toPromise()
      if (data.status == 200 && data?.data) {
        if (data.data.hasOwnProperty('instances')) {
          this.cryptService.setItem('instances', data?.data?.instances, 'SESSION');
        }
        if (data.data.hasOwnProperty('isPublisher')) {
          this.cryptService.setItem('isPublisher', data?.data?.isPublisher, 'SESSION');
        }
        let isReadOnlyRegion = false;
        if (data.data.hasOwnProperty('isReadOnlyRegion')) {
          isReadOnlyRegion = data.data?.isReadOnlyRegion;
          this.cryptService.setItem('isReadOnlyRegion', isReadOnlyRegion, 'SESSION');
        }
        return isReadOnlyRegion;
      }
    }
  }

  setCurrentGlobalInstance(data: any) {
    this.currentGlobalInstance.next(data);
  }

  getCurrentGlobalInstance() {
    return this.currentGlobalInstance.asObservable();
  }
}
