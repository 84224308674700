export const CONTINUE_LAYTOUT = {
  right: {
      title: 'Innovate',
      colorScheme: 'rgb(134, 188, 37, 1)',
      textColorScheme: '#86bc25',
      stopIcon: 'images/activity-icons/stops/Run/Innovate.svg',
  },
  left: {
      title: 'Run',
      subTitle: 'Become fully digital by implementing efficient and sustainable solutions',
      image: {
          normal: 'Run_wheel_small.svg',
          normalDimension: {
              left: '0%',
              height: '100',
              width: '100'
          },
          expandedDimension: {
              left: '3%',
              top: '45%',
              height: '100%',
              width: '100%'
          }
      }
  }
}
