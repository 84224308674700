<div *ngIf="showNotifications" class="main-container" [ngClass]="{'new-notification-bg': !notification?.isSeen && !notification?.isRead}">
    <div class="notification-block" (click)="$event.stopPropagation();">
        <div class="icons">
        </div>
        <div id="icon-and-msg" (click)="$event.stopPropagation();">
            <div class="notification-msg" [innerHTML]="notification?.notification"></div>
            <div class="del-btn">
                <button mat-button (click)="deleteNotification(notification?.id)" class="delete-button">
                    <img src="assets/system_notification/delete_icon.svg"/>
                </button>
            </div>
            <div class="additional-info" *ngFor="let lables of notification?.metaDataLabels" [innerHTML]="lables"> <!-- add ng for in case multiple row data-->
            </div>
        </div>
    </div>
    <div id="trigger-time" (click)="$event.stopPropagation();">
        {{timeDiffInNotification}}
    </div>
    <div id="go-to-link" (click)="notificationRedirect(notification, notification?.redirectionUrlData?.label)" *ngIf ="!projectCreatedNotification">
        <a id="link">{{notification?.redirectionUrlData?.label | translate}}</a>
    </div>
    <!-- <div *ngIf="notification?.isRead" id="read-text" (click)="$event.stopPropagation();">
      {{ Navigation.Menu.Notification?.Read | translate}}
    </div> -->
    <div class="divider-container" (click)="$event.stopPropagation();">
        <mat-divider></mat-divider>
    </div>
</div>
<div *ngIf="!showNotifications" class="no-notification-container" (click)="$event.stopPropagation();">
    <img src="assets/system_notification/No_Results.svg"  />
</div>
