<div class="confirm_dialog_cont">
    <!-- DIALOG HEADER -->
    <div class="title">
      <span cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle><img class="drag-icon"  src="assets/userstories/drag-icon.svg"/></span>
      <span>
        {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate}}
      </span>
      <mat-icon class="close-button" (click)="closePopup()">close</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <!-- DIALOG CONTENT -->
    <div class="sprint-scope">
      <div class="overrun-info" *ngIf="showOverrunNote || showShortrunNote || minimumVelocityNote">
        <img src="assets/trainstop/Description.svg">
        <span *ngIf="showOverrunNote">{{'GenerateReleasePlan.Popup.SprintCapacity.OverrunByNote' | translate}} {{overrunPercentage}}%</span>
        <span *ngIf="showShortrunNote">{{'GenerateReleasePlan.Popup.SprintCapacity.ShortByNote' | translate}} {{overrunPercentage}}%</span>
        <span *ngIf="minimumVelocityNote">{{'GenerateReleasePlan.Popup.SprintCapacity.MinimumVelocityNotePart1'| translate}}{{currentSprintMinVelocity}} as {{currentSprintMinVelocity}}% {{'GenerateReleasePlan.Popup.SprintCapacity.MinimumVelocityNotePart2' | translate}}</span>
      </div>
      <div class="sprint-row header-height">
        <span class="title-padding">
          <span class="sprints">#</span>
          {{'Global.Common.Label.Sprints' | translate}}</span>
        <span class="title-padding">{{'Global.Common.Label.ValuePercent' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <section *ngIf="l1_obj">
        <div class="scope">
          <div *ngFor="let sprint of sprints; let i = index" class="sprint-row sprint-border ">
            <span class="sprint-padding">
              <span class="color-box" [ngStyle]="{'background-color':colors[sprint.sprintOrderNo]}">{{sprint.sprintOrderNo}}</span>
              {{ sprint.name }}
            </span>
            <span *ngIf="!sprint.isSprintCompleted">
              <mat-form-field class="custom-height" 
              [ngClass]="showOverrunNote || showShortrunNote || minimumVelocityNote ? 'input-error' : ''" 
              appearance="outline" floatLabel="never" 
              [ngClass]="(showOverrunNote || showShortrunNote || minimumVelocityNote) ? 'error-border'  : ''">
                <input matInput  [(ngModel)]="sprint.idealVelocity" 
                (keypress)="keyPress($event)" (keydown.Tab)="keyDown($event,i)" [disabled]="sprint.isSprintCompleted" (input)="percentChange(sprint)">
              </mat-form-field>
            </span>
            <span *ngIf="sprint.isSprintCompleted">
              <button  mat-button class="past-sprint" 
                [disabled]=true>{{sprint?.idealVelocity}}<span class="tooltiptext">{{sprint?.name + ' '}} {{'GenerateReleasePlan.Popup.SprintCapacity.EditVelocityPastSprintTooltip' | translate}}</span></button>
            </span>
          </div>
        </div>
      </section>
    </div>
     <mat-divider></mat-divider>
    <div class="button-row">
      <button mat-raised-button class="btn-secondary-cancel" (click)="closePopup()" > {{'Global.Common.Button.Cancel' | translate}}</button>
      <button mat-raised-button class="btn-primary-apply"  [disabled]="isInvalidInput" (click)="apply()"> {{'Global.Common.Button.Apply' | translate}} </button>
  </div>
  </div>
