export const cookieConsent = ((primaryInstance, secondaryInstances) => {
   
          // cookie share start
          function getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(";");
            for (var i = 0; i < ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0) == " ") {
                c = c.substring(1);
              }
              if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
              }
            }
            return null;
          }

          function setCookie(cname, cvalue, exdays, domain, path) {
            // Default to 180 if no expiry provided
            if (exdays === null) {
              exdays = 180;
            }
            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = ";expires=" + d.toUTCString();
            // Default to location host if no domain value provided
            if (domain === null && getDeloitteSite() == "DCOM") {
              domain = ";domain=." + window.location.host;
            } else if (domain === null && getDeloitteSite() == "MYDELOITTE") {
              domain = ";domain=;";
              // domain = ";domain=" + window.location.host;
            } else {
              domain = ";domain=" + domain;
            }
            // Default to / path if no value provided
            if (path === null) {
              path = ";path=/;";
            } else {
              path = ";path=" + path;
            }

            document.cookie = cname + "=" + cvalue + expires + path + domain;
          }

          function deleteCookie(cname, domain, path) {
            setCookie(cname, null, 0, domain, path);
            //setCookie(cname, null, '01 Jan 1970 00:00:00 UTC', domain, path);
          }


          function getDeloitteSite() {
            var deloitteSite = null;
            if (
              window.location.host.split(".")[0] == primaryInstance
            ) {
              deloitteSite = "DCOM";
            } else if (
              secondaryInstances.includes(window.location.host.split(".")[0])
            ) {
              deloitteSite = "MYDELOITTE";
            }
            return deloitteSite;
          }


          function getCountryLang() {
            return ['us', 'en'];
          }

          (<any> window).setConsentShareCookie = function () {
            if (getCountryLang()[0] !== "") {
              var optboxclosed = (<any> window).Optanon.IsAlertBoxClosed();
              var optboxexpiry = "null";
              var consentShareExpiry = 1;

              var country = getCountryLang()[0].toString().toUpperCase();

              if (optboxclosed) {
                optboxexpiry = getCookie("OptanonAlertBoxClosed");
                //If the OptanonAlertBoxClosed cookie contains a landing path or gigya cookie content, the cookie was parsed incorrectly by getcookie(), reset optboxexpiry date/time.
                if (
                  optboxexpiry !== null &&
                  (optboxexpiry.includes("landingPath=h") === true ||
                    optboxexpiry.includes("gigya") === true)
                ) {
                  //reset optboxexpiry to current date as "YYYY-MM-DDTHH:MM:SS.mmmZ";
                  var current = new Date();
                  optboxexpiry =
                    current.getFullYear() +
                    "-" +
                    ("00" + (current.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("00" + current.getDate()).slice(-2) +
                    "T" +
                    ("00" + current.getHours()).slice(-2) +
                    ":" +
                    ("00" + current.getMinutes()).slice(-2) +
                    ":" +
                    ("00" + current.getSeconds()).slice(-2) +
                    ".000z";
                  //return;
                }

                var optBoxDate: any = new Date(optboxexpiry);
                // optBoxDate.setFullYear(optBoxDate.getFullYear() + 1); //Add 1 year
                // optBoxDate.setMonth(optBoxDate.getMonth() + 6); // add 6 months
                optBoxDate.setMonth(optBoxDate.getMonth() + 1); // add 1 month
                var currentTime:any = new Date();
                var consentShareExpiry =
                  (optBoxDate - currentTime) / (1000 * 60 * 60 * 24);
                consentShareExpiry = Math.round(consentShareExpiry);
              }
              var country = getCountryLang()[0].toString().toUpperCase();
              var consentSharePayload =
                "optboxclosed=" +
                optboxclosed +
                "&" +
                "optboxexpiry=" +
                optboxexpiry +
                "&" +
                getCookie("OptanonConsent");

              setCookie(
                "OneTrustConsentShare_" + country,
                consentSharePayload,
                consentShareExpiry,
                ".deloitte.com",
                "/"
              );
            }
          };

          // Run this on load before the OT tag
          (<any> window).checkConsentShareCookie = function () {
            if (getCountryLang()[0] !== "") {
              var country = getCountryLang()[0].toString().toUpperCase();
              var optanonCookie = getCookie("OptanonConsent");
              var consentShareCookieName = "OneTrustConsentShare_"+ country;
              var defaultExpiryDays = 180;
              var currentSite = getDeloitteSite();
              var otCookiePath = "/";
            //   if (currentSite == "DCOM") {
            //     otCookiePath = "/" + getCountryLang()[0] + String(window.location.host.split(".")[0].includes("www2")?"/":"");
            //   }
              
              if(optanonCookie !== null && optanonCookie.includes("AwaitingReconsent=true") === true) {
                deleteCookiesIfPathMatches("OptanonAlertBoxClosed");
                deleteCookiesIfPathMatches(consentShareCookieName);
                optanonCookie = optanonCookie.replace(/AwaitingReconsent=true/g, "AwaitingReconsent=false");
                setCookie("OptanonConsent", optanonCookie, defaultExpiryDays, null, otCookiePath);
              }
              
              var consentShareCookie = getCookie(consentShareCookieName);
              var optBoxCookie = getCookie("OptanonAlertBoxClosed");
              
              // if consentsharecookie is null, but alertboxclosed does, delete optanon cookies
              if (
                consentShareCookie === null &&
                optBoxCookie !== null &&
                currentSite == "MYDELOITTE"
              ) {
                //function deleteCookie(cname, domain, path) {
                deleteCookie("OptanonConsent", null, null);
                deleteCookie("OptanonAlertBoxClosed", null, null);
              }

              if (
                consentShareCookie !== null &&
                consentShareCookie.includes("optboxclosed=true") === true
              ) {
                // if ((consentShareCookie !== null) && (consentShareCookie.includes("optboxclosed=true") === true) && (optBoxCookie === null)) {
                // Consent share cookie exists, includes optboxclosed being true, and OptanonAlertBoxClosed doesn't exist on current page
                // setCookie("OptanonConsent", consentShareCookie.substring(consentShareCookie.indexOf('&')+2), 365, null, otCookiePath);
                var optconsentpayload = consentShareCookie.split("&");
                optconsentpayload.shift();
                optconsentpayload.shift();
                let optconsentpayloadFinal = optconsentpayload.join("&");
                var optboxexpiryValue:any = consentShareCookie.split("&")[1].split("=")[1];
                var currentDate:any = new Date();
                let optboxexpiryValueFinal:any = new Date(optboxexpiryValue);
                var diffDaysValalue = Math.abs(optboxexpiryValueFinal -  currentDate);
                var optboxexpiryDiffDays = Math.ceil(diffDaysValalue / (1000 * 60 * 60 * 24)); 
                const remainingExpiryDays = defaultExpiryDays - optboxexpiryDiffDays;
                setCookie("OptanonConsent", optconsentpayloadFinal, remainingExpiryDays, null, otCookiePath);
                if (optBoxCookie === null && optboxexpiryDiffDays < defaultExpiryDays) {
                  setCookie("OptanonAlertBoxClosed", optboxexpiryValue, remainingExpiryDays, null, otCookiePath);
                }
              }
            }
          };

          function deleteCookiesIfPathMatches(cookieName, pathRegex = undefined ) {
            pathRegex = pathRegex ?  pathRegex :  /^\/.*?\/?$/;
            (<any> window).cookieStore.getAll(cookieName).then(function(cookies) {
                cookies.forEach(function(cookie) {
                    if (cookie.name === cookieName && cookie.path && pathRegex.test(cookie.path)) {
                      document.cookie = `${cookieName}=; path=${cookie.path}; domain=${cookie.domain}; expires=` + new Date(0).toUTCString();
                    }
                });
            }).catch(function(error) {
                console.error("Error retrieving cookies:", error);
            });
          }


          // calling before OT tag
          (<any> window).checkConsentShareCookie();
          //cookie share stop

});
