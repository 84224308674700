import { dataLoader } from '@amcharts/amcharts4/core';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { SharedService } from '@projects/shared-lib/src/lib/services/shared.service';
import { DEFINEROADMAP_STEPS } from '@projects/trainstops/src/app/core/utility/constants';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';
import { DialogueBoxComponent } from '@projects/trainstops/src/app/shared/dialogue-box/dialogue-box.component';
import { Subscription } from 'rxjs';
import { DefineRoadmapService } from '../../define-roadmap.service';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { SPRINT_CAPACITY, SPRINT_COLORS, SPRINT_PLAN } from '@projects/projectsummary/src/app/core/utility/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SprintGenerationPopupComponent } from '../../sprint-generation-popup/sprint-generation-popup.component';

const DAY_MSECS = 86400000;
@Component({
  selector: 'app-sprint-plan',
  templateUrl: './sprint-plan.component.html',
  styleUrls: ['./sprint-plan.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class SprintPlanComponent implements OnInit,OnDestroy {
  l1_obj:any;
  selectedL1Subscription:Subscription;
  displayedColumns : string[] = ['index','sprint_name','start_date','end_date'];
  sprintPlanForm: UntypedFormGroup;
  context:any;
  globalContextSubscription:Subscription;
  totalL1:number;
  selectedParentStepIndex:number;
  selectedL1Index:number;
  isSyncAllL1AppliedSubscription: Subscription;
  isSyncAllL1Enabled: boolean = false;
  isSaveEnabled = false;
  enableSprintTimeline:boolean=false;
  totalWeeks:number[]=[];
  maxNumberOfWeek=10;
  projectId: any;
  areSprintsUpdated = false;
  startDate: Date;
  disableDefineRoadmapSubscription: Subscription;
  isNextBtnDisable: boolean;
  restrictValuechanges:boolean=false;
  todayDate:Date=new Date();
  isMoscowProject;
  colors = SPRINT_COLORS;
  sprintCapacitySuccess= SPRINT_CAPACITY;

  disableConfirmAllSprintsButton: boolean = false;
  sprintPlanSuccess = SPRINT_PLAN;
  successMessage: string = '';
  @ViewChild('snackBarTemplate') snackBarTemplate: TemplateRef<any>;
  confirmCount;
  allL1s;
  stepTitle: string = '';
  isAllL1Finalized: boolean = false;
  l1StatusData;
  l1StatusDataSubscription: Subscription;

  constructor(
    private defineRoadmapService: DefineRoadmapService,
    private formBuilder: UntypedFormBuilder,
    private globalContext: ActivitiesGlobalContextService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService
    ) { }

  ngOnInit(): void {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
    this.defineRoadmapService.getIsAllL1Finalized().subscribe((data)=>{
      this.isAllL1Finalized=data
    })
    this.createWeek();
    this.isMoscowProject = this.defineRoadmapService.isMoscowApplied;
    this.globalContextSubscription = this.globalContext.getGlobalActivityContext().subscribe((data) => {
      this.context = data;
      this.projectId = this.context.project.projectId;
    });
    this.createForm();
    this.disableDefineRoadmapSubscription = this.sharedService.disableDefineRoadmapBtn.subscribe((value: boolean) => {
      this.isNextBtnDisable = value;
    });
    this.selectedL1Subscription=this.defineRoadmapService.getSelectedL1().subscribe((data)=>{
      this.stepTitle = this.defineRoadmapService.sprintStepper[data.parentIndex].title
      if(data && this.stepTitle == DEFINEROADMAP_STEPS.SPRINT_PLAN)
        {
           this.allL1s= this.defineRoadmapService.sprintStepper[data?.parentIndex]?.children;
           this.l1_obj= this.allL1s[data.childIndex];
           this.disableConfirmAllSprintsButton = (this.isMoscowProject && !this.l1_obj.is_finalized) ? false : true;
           let value_update;
           if(this.l1_obj?.sprint_plan?.length){
            value_update={
              numberOfSprint:this.l1_obj?.sprint_plan[0]?.number_of_sprint,
              numberOfWeek:this.l1_obj?.sprint_plan[0]?.duration_of_sprint,
              startDate:this.l1_obj?.sprint_plan[0]?.start_date_of_sprint
              };
           }

            this.sprintPlanForm.patchValue(value_update,{emitEvent: false, onlySelf: true});
            if(this.l1_obj?.sprint_plan && this.l1_obj?.sprint_plan?.length && this.l1_obj?.sprint_plan[0]?.sprint==null)
            {
              this.createSprints(value_update.numberOfSprint,value_update.numberOfWeek,value_update.startDate);
            }
            this.totalL1=this.defineRoadmapService.sprintStepper[data.parentIndex].children.length;
            this.selectedL1Index=data.childIndex;
            this.selectedParentStepIndex=data.parentIndex;
        }
    });

    this.isSyncAllL1AppliedSubscription = this.defineRoadmapService.getIsSyncAllL1Applied().subscribe((data: boolean) => {
      if(data!=null)
      {
        this.isSyncAllL1Enabled = data;
        if(this.isSyncAllL1Enabled || this.projectId == '0')
          this.sprintPlanForm.controls['numberOfSprint'].disable();
        else if(this.projectId !== '0') {
          this.restrictValuechanges=true;
          this.sprintPlanForm.controls['numberOfSprint'].enable();
        }
        let value_update;
        if(this.l1_obj?.sprint_plan?.length){
          value_update={
            numberOfSprint:this.l1_obj?.sprint_plan[0]?.number_of_sprint,
            numberOfWeek:this.l1_obj?.sprint_plan[0]?.duration_of_sprint,
            startDate:this.l1_obj?.sprint_plan[0]?.start_date_of_sprint
            };
        }

        this.sprintPlanForm.patchValue(value_update,{emitEvent: false, onlySelf: true});
      }
    });

    this.sprintPlanForm.valueChanges.subscribe((data) => {
      this.defineRoadmapService.setIsAnyChangesInGSPFlyout(true)
      if (data.numberOfSprint && data.numberOfWeek && data.startDate && !this.restrictValuechanges) {
        let startDate;
        if(typeof(data.startDate)=='string')
        {
          startDate=data.startDate;
        }
        else{
          let dd = (data.startDate?.date()+'').padStart(2, '0');
          let mm = (data.startDate?.month()+1+'').padStart(2, '0');
          startDate=`${data.startDate?.year()}-${mm}-${dd}`;
        }

        this.createSprints(+data.numberOfSprint,+data.numberOfWeek,startDate);
        if(this.l1_obj?.sprint_plan?.length){
        this.l1_obj.sprint_plan[0].number_of_sprint=+data.numberOfSprint;
        this.l1_obj.sprint_plan[0].duration_of_sprint=+data.numberOfWeek;
        this.l1_obj.sprint_plan[0].start_date_of_sprint=data.startDate;
      }
      }
      this.restrictValuechanges=false;
    });

    this.defineRoadmapService.getConfirmedCountData().subscribe(confirmCount => { this.confirmCount = confirmCount?.confirmedSprintPlanCount});


    this.l1StatusDataSubscription = this.defineRoadmapService.getL1StatuesData().subscribe(data => {
      this.l1StatusData = JSON.parse(JSON.stringify(data));
    });
  }

  createForm(){
    this.sprintPlanForm = this.formBuilder.group({
      numberOfSprint: [{ value: '', disabled: (this.isAllL1Finalized || this.projectId == '0' || !this.isMoscowProject ? true : false) }, [Validators.required]],
      numberOfWeek: [{ value: '', disabled: (this.isAllL1Finalized || this.projectId == '0' || !this.isMoscowProject? true : false) }, [Validators.required]],
      startDate :[{ value: this.startDate, disabled: (this.isAllL1Finalized || this.projectId == '0' || !this.isMoscowProject ? true : false) }, [Validators.required]]
    });
  }

  createWeek(){
    this.totalWeeks=Array(10).fill(1).map((x,i)=>i+1);
  }

  createSprints(sprintNumber,sprintDuration,startDate){
      this.l1_obj.confirmFlag = true;
      let sprints=[];
      for (let i = 0; i < sprintNumber; i++) {
        let sprint = {
          sprint_name: '',
          end_date: null,
          start_date: null,
        };
        if (i==0) {
          sprint['start_date'] = startDate;
        } else {
          let last_sprint_end_date=new Date(sprints[i-1]['end_date']);
          let nextDay=new Date(last_sprint_end_date);
          nextDay.setDate(last_sprint_end_date.getDate()+1);
          let dd = String(nextDay.getUTCDate()).padStart(2, '0');
          let mm = String(nextDay.getUTCMonth() + 1).padStart(2, '0');
          let yyyy = nextDay.getUTCFullYear();
          sprint['start_date'] = `${yyyy}-${mm}-${dd}`;
        }

        let current_sprint_start_date=new Date(sprint['start_date']);
        let nextWeek=new Date(current_sprint_start_date);
        nextWeek.setDate(current_sprint_start_date.getDate()+((sprintDuration * 7)-1));
        let dd = String(nextWeek.getUTCDate()).padStart(2, '0');
        let mm = String(nextWeek.getUTCMonth() + 1).padStart(2, '0');
        let yyyy = nextWeek.getUTCFullYear();
        sprint['end_date'] = `${yyyy}-${mm}-${dd}`;

        sprint['sprint_name'] = `Sprint ${i + 1}`;
        sprints.push({
          sprint_name: sprint.sprint_name,
          start_date: sprint.start_date,
          end_date: sprint.end_date,
          sprint_order_no: i + 1,
          sprint_id: null,
        });

      }
      this.l1_obj.confirmFlag = true;
      if(this.l1_obj?.sprint_plan?.length){
      this.l1_obj.sprint_plan[0].sprint=sprints;
      }
  }

  reset() {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
    this.defineRoadmapService.resetSprintPlan(
      this.context.package.packageId,
      this.context.package.functionId,
      this.context.selectedActivity.entityId,
      this.context.project.projectId,
      { "l1": this.l1_obj.l1 }
      ).subscribe((data)=>{
        if(data.length>0 && this.l1_obj?.sprint_plan?.length)
        {
          let successMessage = 'Activities.UserStoryLibrary.SprintPlan.ResetSprintSuccess';
          this.messageDialoguePopup(successMessage);
          this.defineRoadmapService.setSprintPlanReset({reset:true,childIndex:this.selectedL1Index})
        }
    });
  }

  showWarningPopup() {
    let data = {
      title: 'GenerateReleasePlan.Popup.UpdateDataWarning.Popup.Title',
      for: 'Activities.UserStoryLibrary.SprintPlan.Label.DefineSprints',
      level: this.l1_obj.l1_tab,
      description: 'GenerateReleasePlan.Popup.UpdateDataWarning.Popup.Description',
    };

    const dialogRef = this.dialog.open(SprintGenerationPopupComponent, {
      width: "700px",
      height: 'auto',
      data: data,
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response == 'confirm') {
        this.confirmAndSave(false);
      }
    })

  }

  confirm(confirmAll: boolean) {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
    if (this.l1_obj?.l1_export_status?.is_generated && !confirmAll) {
      this.showWarningPopup();

    } else {
      this.confirmAndSave(confirmAll);
    }
  }

  confirmAndSave(confirmAll: boolean)
  {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
    let sprintData=this.sprintPlanForm.getRawValue();
    // let successMessage: string = this.getSuccessMessage();
    let showAllToasterMessage = false;
    if(this.defineRoadmapService.sprintStepper[1]?.children?.filter(l1 => l1.isStepCompleted ).length === this.defineRoadmapService.sprintStepper[1]?.children?.length-1) {
      showAllToasterMessage = true;
    }
    let postData;
    if (confirmAll) {
      postData = this.allL1s.map((e) => {
        if( !e.l1_export_status.is_confirmed) {
          return {
            sprintPlan: {
              sprint_plan_id: e.sprint_plan[0]?.sprint_plan_id,
              project_id: e.sprint_plan[0]?.project_id,
              l1: e.l1,
              number_of_sprint: e.sprint_plan[0]?.sprint?.length ?? 0,
              duration_of_sprint: e.sprint_plan[0]?.duration_of_sprint,
              start_date_of_sprint: e.sprint_plan[0]?.start_date_of_sprint,
              sprint: e.sprint_plan[0]?.sprint
            }
          }
        }
      });
      postData=postData.filter(obj=>obj!==undefined)
      this.successMessage = this.translateService.instant(this.sprintPlanSuccess.SPRINT_PLAN_CONFIRMALL_SUCCESS);
    } else {
      if (this.l1_obj?.sprint_plan?.length > 0) {
        postData = [{
          sprintPlan: {
            sprint_plan_id: this.l1_obj.sprint_plan[0]?.sprint_plan_id,
            project_id: this.l1_obj.project_id,
            l1: this.l1_obj.l1,
            number_of_sprint: sprintData.numberOfSprint,
            duration_of_sprint: sprintData.numberOfWeek,
            start_date_of_sprint: sprintData.startDate,
            sprint: this.l1_obj.sprint_plan[0]?.sprint,
          },
        }]
      }
      this.successMessage = this.l1_obj.l1_tab + ': ' + this.translateService.instant(this.sprintPlanSuccess.SPRINT_PLAN_SUCCESS) + (showAllToasterMessage ? this.translateService.instant(this.sprintPlanSuccess.SPRINT_PLAN_SUCCESS_ALL) : '');
    }

    this.defineRoadmapService.saveSprintPlan(
      this.context.package.packageId,
      this.context.package.functionId,
      this.context.selectedActivity.entityId,
      this.context.project.projectId,
      postData
      ).subscribe((data)=>{
        if(data.length>0 && this.l1_obj?.sprint_plan?.length>0)
        {
          this.l1_obj.sprint_plan = data[0].sprint_plan;
          this.areSprintsUpdated = false;
          this.sharedService.updateDefineRoadmapData(this.l1_obj);
          this.isSaveEnabled = false;
          this.l1_obj.confirmFlag = false;
          if(showAllToasterMessage) {
            this.disableConfirmAllSprintsButton = true;
          }
          this._snackBar.open(this.successMessage, '', { duration: 5000 });
          if(!this.defineRoadmapService.sprintStepper[this.selectedParentStepIndex]?.children[this.selectedL1Index]?.isStepCompleted) {
            this.defineRoadmapService.sprintStepper[this.selectedParentStepIndex].children[this.selectedL1Index]['isStepCompleted'] = true;
            this.defineRoadmapService.setConfirmedCountData({confirmedSprintPlanCount: this.confirmCount + 1});
          }
          if(confirmAll) {
            this.l1StatusData.forEach(l1 => l1['is_confirmed'] = true);
            this.defineRoadmapService.setL1StatuesData(this.l1StatusData);
            this.nextParent();
            this.defineRoadmapService.setUpdateStepper(this.stepTitle);
          }
          else {
            this.l1StatusData.forEach(l1 => {
              if(l1?.l1_id === this.l1_obj?.l1_id){
                l1['is_confirmed'] = true;
              }
            });
            this.defineRoadmapService.setL1StatuesData(this.l1StatusData);
            this.nextStep();
            this.defineRoadmapService.setUpdateStepper(this.stepTitle);
          }
        }
    }, (err) => {
      this.isSaveEnabled = true;
      this.successMessage = 'Activities.UserStoryLibrary.SprintPlan.SprintSaveError';
      this.messageDialoguePopup(this.successMessage, this.translateService.instant('Global.Common.Label.Error'));
    });
    return this.successMessage;
  }

  nextStep(){
    if(this.selectedL1Index < this.totalL1-1)
    {
      let obj={
        parentIndex:this.selectedParentStepIndex,
        childIndex:this.selectedL1Index+1,
      }
      this.defineRoadmapService.setSelectedL1(obj);
    }
    else{
      this.nextParent();
    }
  }

  previousSTep(){
    if(this.selectedL1Index!=0)
    {
      let obj={
        parentIndex:this.selectedParentStepIndex,
        childIndex:this.selectedL1Index-1,
      }
      this.defineRoadmapService.setSelectedL1(obj);
    }
    else{
      let obj={
        parentIndex:this.selectedParentStepIndex-1,
        childIndex:0,
        direction:'previous'
      }
      this.defineRoadmapService.setSwitchParent(obj);
    }
  }

  nextParent(){

    let obj={
      parentIndex: this.selectedParentStepIndex + 1,
      childIndex:0
    }
    this.defineRoadmapService.setSwitchParent(obj);
  }

  toggle(value)
  {

    this.enableSprintTimeline=value;
    //this.ganttChartComponent.expandToggle(value.checked);

  }

  get f() { return this.sprintPlanForm.controls; }

  sprintStartDateChange(type: string, event, index: any) {
    if (type === 'change' && this.l1_obj?.sprint_plan?.length>0) {
      this.l1_obj.confirmFlag = true;
      if (index === 0) {
        this.startDate = event.value;
        let dd = (event.value?.date()+'').padStart(2, '0');
        let mm = (event.value?.month()+1+'').padStart(2, '0');
        let startDate=`${event.value?.year()}-${mm}-${dd}`;
        this.l1_obj.sprint_plan[0].start_date_of_sprint=startDate;
        this.sprintPlanForm.patchValue({  startDate: startDate  },{emitEvent: false, onlySelf: true});
      }
      this.areSprintsUpdated = true;
      const originalDate = this.l1_obj.sprint_plan[0].sprint[index].start_date;
      const diff = new Date(event.value).getTime() - new Date(originalDate).getTime();
      for (let i = index; i < this.l1_obj.sprint_plan[0].sprint.length; i++) {
        this.l1_obj.sprint_plan[0].sprint[i].start_date = new Date(new Date(this.l1_obj.sprint_plan[0].sprint[i].start_date).getTime() + diff);
        this.l1_obj.sprint_plan[0].sprint[i].end_date = new Date(new Date(this.l1_obj.sprint_plan[0].sprint[i].end_date).getTime() + diff);
        this.l1_obj.sprint_plan[0].sprint[i].min = i > 0 ? new Date(new Date(this.l1_obj.sprint_plan[0].sprint[i - 1].end_date).getTime() + DAY_MSECS) : new Date(this.startDate);
        this.l1_obj.sprint_plan[0].sprint[i].minEndDate = new Date(this.l1_obj.sprint_plan[0].sprint[i].min.getTime() + DAY_MSECS);

        if (this.l1_obj.sprint_plan[0].sprint[i].start_date.getTime() < this.l1_obj.sprint_plan[0].sprint[i].min.getTime()) {
          this.l1_obj.sprint_plan[0].sprint[i].isInvalidStartDate = true;
        } else {
          this.l1_obj.sprint_plan[0].sprint[i].isInvalidStartDate = false;
        }
      }
    }
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(true)
  }

  sprintEndDateChange(type: string, event: MatDatepickerInputEvent<Date>, index: any) {
    if (type === 'change' && this.l1_obj?.sprint_plan?.length>0) {
      this.l1_obj.confirmFlag = true;
      this.areSprintsUpdated = true;
      const originalDate = this.l1_obj.sprint_plan[0].sprint[index].end_date;
      const diff = new Date(event.value).getTime() - new Date(originalDate).getTime();
      this.l1_obj.sprint_plan[0].sprint[index].end_date = new Date(new Date(this.l1_obj.sprint_plan[0].sprint[index].end_date).getTime() + diff);
      const convertStartDate = typeof this.l1_obj.sprint_plan[0].sprint[index].start_date === 'string' ?
        (new Date(this.l1_obj.sprint_plan[0].sprint[index].start_date).getTime() + DAY_MSECS) : (this.l1_obj.sprint_plan[0].sprint[index].start_date.getTime() + DAY_MSECS);
      if (this.l1_obj.sprint_plan[0].sprint[index].end_date.getTime() < convertStartDate ) {
        this.l1_obj.sprint_plan[0].sprint[index].isInvalidEndDate = true;
        return;
      } else {
        this.l1_obj.sprint_plan[0].sprint[index].isInvalidEndDate = false;
      }
      for (let i = index + 1; i < this.l1_obj.sprint_plan[0].sprint.length; i++) {
        this.l1_obj.sprint_plan[0].sprint[i].start_date = new Date(new Date(this.l1_obj.sprint_plan[0].sprint[i].start_date).getTime() + diff);
        this.l1_obj.sprint_plan[0].sprint[i].end_date = new Date(new Date(this.l1_obj.sprint_plan[0].sprint[i].end_date).getTime() + diff);
        this.l1_obj.sprint_plan[0].sprint[i].min = i > 0 ? new Date(new Date(this.l1_obj.sprint_plan[0].sprint[i - 1].end_date).getTime() + DAY_MSECS) : new Date(this.startDate);
        this.l1_obj.sprint_plan[0].sprint[i].minEndDate = new Date(this.l1_obj.sprint_plan[0].sprint[i].min.getTime() + DAY_MSECS);
        if (this.l1_obj.sprint_plan[0].sprint[i].end_date.getTime() < this.l1_obj.sprint_plan[0].sprint[i].start_date.getTime()) {
          this.l1_obj.sprint_plan[0].sprint[i].isInvalidEndDate = true;
          return;
        } else {
          this.l1_obj.sprint_plan[0].sprint[i].isInvalidEndDate = false;
        }
      }
    }
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(true)
  }

  weekdays = (d): boolean => {
    if (d) {
      const day = d.day();
      // Prevent Saturday and Sunday from being selected.
      return day !== 0 && day !== 6;
    }
  }

  private messageDialoguePopup(popupMessage, title?) {
    return this.dialog.open(DialogueBoxComponent, {
      data: {
        from: '',
        message: popupMessage,
        title: title ? title : 'Global.Common.Label.Success',
        buttonText: 'Global.Common.Button.Continue'
      },
      panelClass: 'project-confirm-dialog-wrapper',
      maxWidth: '50vw',
    });
  }

  private getSuccessMessage() {
    let successMsg = 'Activities.UserStoryLibrary.SprintPlan.SprintPlanSuccess';
    let changedAttrCount = 0;

    if (this.l1_obj?.sprint_plan?.length>0 && (this.l1_obj.sprint_plan[0].duration_of_sprint !== +this.sprintPlanForm.value.numberOfWeek)) {
      changedAttrCount += 1;
      successMsg = 'Activities.UserStoryLibrary.SprintPlan.SprintDurationSuccess';
    }

    if (this.l1_obj?.sprint_plan?.length>0 && (this.l1_obj.sprint_plan[0].number_of_sprint !== +this.sprintPlanForm.value.numberOfSprint)) {
      changedAttrCount += 1;
      successMsg = 'Activities.UserStoryLibrary.SprintPlan.SprintNumberSuccess';
    }

    if (this.l1_obj?.sprint_plan?.length>0 && this.l1_obj.sprint_plan[0].start_date_of_sprint &&
      new Date(Date.parse(this.l1_obj.sprint_plan[0].start_date_of_sprint)).getTime() !== new Date(Date.parse(this.sprintPlanForm.value.startDate)).getTime() &&
      !this.areSprintsUpdated ) {
      changedAttrCount += 1;
      successMsg = 'Activities.UserStoryLibrary.SprintPlan.FirstSprintStartDateSuccess';
    }

    if (this.areSprintsUpdated) {
      changedAttrCount += 1;
      successMsg = 'Activities.UserStoryLibrary.SprintPlan.SprintPlanDatesSuccess';
    }

    if (changedAttrCount > 1) {
      successMsg = 'Activities.UserStoryLibrary.SprintPlan.SprintPlanSuccess';
    }

    return successMsg;
  }

  checkAnyL1Save(){
    let isL1Saved=false;
    this.defineRoadmapService.sprintStepper[this.selectedParentStepIndex].children.forEach(l1=>{
        if(l1?.sprint_plan?.length>0 && l1?.sprint_plan[0]?.sprint_plan_id){
          isL1Saved=true;
        }
    });
    return isL1Saved;
  }

  setminDateForStartDatePicker(index){
    let todayDate:Date=new Date();
    let minDate;
    if(index==0)
    {
        minDate=todayDate;
    }
    else{
        let current_sprint_start_date;
        if(this.l1_obj?.sprint_plan?.length > 0){
          current_sprint_start_date=new Date(this.l1_obj?.sprint_plan[0]?.sprint[index-1].end_date);
        }
        let nextDay=new Date(current_sprint_start_date);
        nextDay.setDate(current_sprint_start_date.getDate()+1);
        let dd = String(nextDay.getUTCDate()).padStart(2, '0');
        let mm = String(nextDay.getUTCMonth() + 1).padStart(2, '0');
        let yyyy = nextDay.getUTCFullYear();
        minDate = `${yyyy}-${mm}-${dd}`;
    }

    return minDate;
  }

  setminDateForEndDatePicker(index){
    let minDate;
    if(this.l1_obj?.sprint_plan?.length > 0){
      minDate=this.l1_obj?.sprint_plan[0]?.sprint[index].start_date;
    }
    return minDate;
  }

  ngOnDestroy()
  {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
    this.selectedL1Subscription?.unsubscribe();
    this.globalContextSubscription?.unsubscribe();
    this.isSyncAllL1AppliedSubscription?.unsubscribe();
    this.l1StatusDataSubscription?.unsubscribe();
  }

}
