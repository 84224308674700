import * as mapFilterActions from '../actions/map-filters.actions';
import { Actions } from '../actions/map-filters.actions';
import { MapFilter } from '@app/models';

export interface State {
    mapFilters: MapFilter;
    landingPageFilters: any;
}

const initialState: State = {
    mapFilters: new MapFilter(),
    landingPageFilters: null
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case mapFilterActions.UPDATE_MAP_FILTERS:
            return {
                ...state,
                mapFilters: { ...action.payload }
            }
        case mapFilterActions.LOAD_LANDINGPAGE_FILTERS_SUCCESS:
            return {
                ...state,
                landingPageFilters: { ...action.payload }
            }
        default:
            return state;
    }
}

export const appliedFilters = (state: State) => state.mapFilters;
export const landingPageFilters = (state: State) => state.landingPageFilters;
