import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges, computed, signal  } from '@angular/core';
import { GlobalInstancesService } from '@projects/shared-lib/src/lib/services/global-instances.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { USERROLES } from '@projects/shared-lib/src/public-api';
import { UserRoleAssociationDetails } from '../../model/project-global-info.model';
import { LANGUAGE_DATA } from '@projects/psg/src/app/core/utility/constants';
import { MyProjectsService } from '@projects/workspace/src/app/my-projects/my-projects.service';
import { User } from '../../constants/ascend-user-info';
import { US_REGION } from '@projects/workspace/src/app/my-projects/utility/constants';
import { MAX_COUNT_OF_PINNING_CONTACTS_TOOLTIPTEXT, MAX_LIMIT_PROJECT_PINNING } from '@projects/shared-lib/src/lib/utility/constants';
import { AdminManagementService } from '@projects/user-management/src/app/user/service/admin-management.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrl: './contact-list.component.scss'
})
export class ContactListComponent implements OnInit, OnChanges {
  @Input() contactDetails: any;
  @Input() globalInstances;
  @Input() pinnedUserCount;
  @Output() regionChange = new EventEmitter();
  selectedRegion: String = US_REGION;
  subscriptions: Subscription[] = [];
  languageId: number = 1;
  selectedParentPackage = signal<string>('');
  packages = []
  roles = []
  userInfo: UserRoleAssociationDetails;
  elevatedUserInfo;
  selectedRoles = signal<string[]>([]);
  userData: User;
  contactList = signal([]);
  allowedRoles = [USERROLES.PACKAGEADMIN, USERROLES.PACKAGELEAD];
  disablePackageSelect = false;
  @Output() contactsEmitter = new EventEmitter();
  pinnedContactList= [];
  MAX_LIMIT_PROJECT_PINNING = MAX_LIMIT_PROJECT_PINNING;
  searchTerm = signal<string>('');
  usersSearchStatus = computed(() => {
    return this.filteredContactList().every((contact) => contact.users.length === 0)
  });
  MAX_COUNT_OF_PINNING_CONTACTS_TOOLTIPTEXT = MAX_COUNT_OF_PINNING_CONTACTS_TOOLTIPTEXT;
  currentRegionInstance;

  setRoles(){
    const roles = this.selectedRoles();
    const found = roles.some(r => this.allowedRoles.includes(r.toLowerCase()));
    this.disablePackageSelect = !found && roles.length > 0;
    if (roles.length === 0) {
      this.disablePackageSelect = false;
    }
    if(this.disablePackageSelect){
      this.selectedParentPackage.set(null);
    }
  }
  filteredContactList = computed(() => {
    const roles = this.selectedRoles();
    const parentPackage = this.selectedParentPackage();
    const searchTerm = this.searchTerm().toLocaleLowerCase();
  
    // Temporary array to collect pinned contacts
    this.pinnedContactList = [];
  
    const filteredContacts = this.contactList().map(contact => {
      // Check if the contact's role matches the selected roles
      if (roles.length > 0 && !roles.includes(contact.roleTitle)) {
        return null;
      }
  
      // Filter users based on the search criteria
      const filteredUsers = contact.users.filter(user => {
        const matchesPackage = !parentPackage || user.package_id === parentPackage;
        const matchesSearchTerm = !searchTerm || 
          user.getUserName?.toLowerCase().includes(searchTerm) || 
          user.email_id?.toLowerCase().includes(searchTerm);
  
        this.getUserInitials(user);
  
        if (user.pinnedStatus === 1 && matchesPackage && matchesSearchTerm) {
          this.pinnedContactList.push(user);
        }
  
        return matchesPackage && matchesSearchTerm;
      });
  
      // Return the contact with filtered users if any users match the criteria
      if (filteredUsers.length > 0) {
        return { ...contact, users: filteredUsers };
      }
      return null;
    }).filter(contact => contact !== null);
    return filteredContacts;
  });

  constructor(private globalInstanceService: GlobalInstancesService,
    private sharedService: SharedService,
    private myProjectsService : MyProjectsService,
    private adminManagementService: AdminManagementService
  ) {
    this.getRoleData();

    this.subscriptions.push(this.sharedService.getCurrentLanguage().subscribe((res) => {
      this.languageId = res;
    }))
  }


  resetFilters(event?) {
    if(event)
     event.stopPropagation();
    this.selectedParentPackage.set(null);
    this.selectedRoles.set([]);
    this.contactList.set(this.contactDetails);
    this.disablePackageSelect = false;
  }

  getPackageList() {
    this.packages = [];
    this.contactDetails.forEach(element => {
      if(this.allowedRoles.includes(element.roleTitle.toLowerCase())){
        element.users.forEach(user => {
          if(!this.packages.find(packageItem => packageItem.id === user.package_id) && user.package_id){
            this.packages.push({id : user.package_id , name:user.package});
          }
        });
      }
    });
  }

  getRoleData() {
      this.myProjectsService.getMasterUserRole(LANGUAGE_DATA.ENGLISH).subscribe((res) =>{
        this.roles = res?.data?.filter(role => role.name?.toLowerCase() !== USERROLES.PROJECTADMIN && role.name.toLowerCase() !== USERROLES.PROJECTUSER  && role.name.toLowerCase() !== USERROLES.EXTERNALUSER);
          if (this.selectedRegion !== US_REGION) {
          this.roles = this.roles?.filter(role => role.name?.toLowerCase() !== USERROLES.METHODADMIN && role.name.toLowerCase() !== USERROLES.METHODLEAD  && role.name.toLowerCase() !== USERROLES.LANGUAGEADMIN);
        }
      })
  }

  ngOnInit() {
    this.globalInstanceService.getCurrentGlobalInstance().subscribe((data) => {
      if (data) {
        this.selectedRegion = data?.current_instance;
        this.currentRegionInstance = this.globalInstances?.find(instance => instance?.instance_name === this.selectedRegion);
      }
    });
  }

  ngOnChanges(): void {
    this.contactList.set([...this.contactDetails]);
    this.getPackageList();
    if(this.selectedRegion && this.globalInstances){
      this.currentRegionInstance = this.globalInstances?.find(instance => instance?.instance_name === this.selectedRegion);
    }

  }

  changeRegion(event, instance) {
    event.stopPropagation();
    this.resetFilters();
    this.currentRegionInstance = instance;
    this.selectedRegion = instance.instance_name;
    this.searchTerm.set('');
    this.getPackageList();
    this.getRoleData();
    this.regionChange.emit(instance);
  }

  copyEmailToClipboard(email: string): void {
    window.open(`mailto:${email}`);
  }

  getUserInitials(user) {
    let userName = user.getUserName == '' ? user?.email_id?.slice(0, 2) : user.getUserName;
    user['userInitials'] = userName?.split(' ').map((n) => n[0]).join('').slice(0, 2);
  }

  pinUnpinContact(user) {
    if(this.pinnedUserCount <= MAX_LIMIT_PROJECT_PINNING) {
      this.adminManagementService.pinUnpinContact({
        adminId: user.user_id,
        pinnedStatus: !user.pinnedStatus,
      }, this.currentRegionInstance).subscribe((response) => {
        if (response.status == 200 || response.status == 201) {
          this.refreshContactList();
        }
      },
      (error) => {
        this.refreshContactList();
      });
    } 
  }
  refreshContactList() {
     this.contactsEmitter.emit(this.currentRegionInstance);
  }

  stopDefaultMenuClose($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  clearSearch(event){
    event.stopPropagation();
    this.contactList.set(this.contactDetails);
    this.searchTerm.set('');
  }

}
