/* -- ANGULAR CORE -- */
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';

/* -- APPLICATION MODULES -- */
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { HttpServiceHelper } from './types/common/HttpServiceHelper';
// import { AuthenticationModule } from './authentication.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ErrorHandlerService } from './shared/services/error-handling.service';
import { environment } from 'src/environments/environment';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpPostService } from './multi-tranlsate-http-post-service';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, ROOT_REDUCERS } from '../../projects/maturity-assessment/src/app/core/store/reducers';



function createTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpPostService(http, [
        {prefix: `${environment.API_MICROSERVICE_URL.ADMIN}/i18n/Landing/`, suffix: ".json"},
        {prefix: `${environment.API_MICROSERVICE_URL.ADMIN}/i18n/`, suffix: ".json"}
      ]);
  }

  export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
  }
  
  export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.AUTH_CONFIG.clientID,
        authority: environment.AUTH_CONFIG.authority,
        redirectUri:  environment.AUTH_CONFIG.redirectUri,
        postLogoutRedirectUri:  environment.AUTH_CONFIG.postLogoutRedirectUri
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false
        }
      }
    });
  }
  
  export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.AUTH_RESOURCE_LINK.users, ['user.read']);
    protectedResourceMap.set(environment.AUTH_RESOURCE_LINK.self, ['user.read']);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
  }
  
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { 
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read',"openid","profile"]

      },
      loginFailedRoute: '/login-failed'
    };
  }

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        ScrollingModule,
        MsalModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        // AuthenticationModule,
        AppRoutingModule,
        SharedModule.forRoot(),
        FontAwesomeModule,
        StoreModule.forRoot(ROOT_REDUCERS, {
          metaReducers,
          runtimeChecks: {
            strictStateImmutability: true,
            strictActionImmutability: true,
            strictStateSerializability: false,
            strictActionSerializability: false
          },
        }),
    
        EffectsModule.forRoot([])
    ],
    providers: [
        HttpServiceHelper,
        TranslateService,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
          {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
          },
          {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
          },
          {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
          },
          {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
          },
          MsalService,
          MsalGuard,
          MsalBroadcastService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }
