<div class="popup">
    <!-- DIALOG HEADER -->
    <div class="header-part">
        <p class="title">{{ (popupData?.title ? popupData?.title : 'Global.Common.Label.Message') | translate }}</p>

    <!-- DIALOG CONTENT -->
        <p class="status_txt">{{popupData.message | translate}}</p>
    </div>

    <!-- DIALOG BUTTON -->
    <div class="bottom-part">
        <button class="button" mat-raised-button (click)="close()">
            {{ (popupData?.buttonText ? popupData?.buttonText : 'Global.Common.Button.Ok') | translate }}
        </button>
    </div>
</div>

