<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 311 282" style="enable-background:new 0 0 311 282;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#86BC25;fill-opacity:0.2;}
	.st1{fill:#86BC25;}
	.st2{fill:none;stroke:#86BC25;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;}
	.st3{fill:#FFFFFF;}
	.st4{fill:none;stroke:#86BC25;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}
	.st6{font-size:16px;}
	.st7{font-size:17.2315px;}
	.st8{fill:#FFFFFF;stroke:#86BC25;stroke-width:2;stroke-miterlimit:10;}
	.st9{display:none;}
	.st10{display:inline;fill:#86BC25;stroke:#86BC25;stroke-width:2;stroke-miterlimit:10;}
	.st11{display:inline;fill:#FFFFFF;}
	.st12{fill:#86BD40;}
	.st13{display:inline;fill:#86BD40;}
	.st14{display:inline;fill:#86BC25;}
</style>
<g id="Layer_1_1_">
	<g id="paths_circles">
		<circle id="Oval-Copy-8" class="st0" cx="130.1" cy="153.4" r="55"/>
		<circle id="Oval-Copy-9" class="st1" cx="130.1" cy="153.4" r="40"/>
		<path class="st2" d="M231.3,150.9c0,57.5-46.6,104.1-104.1,104.1S23.1,208.4,23.1,150.9l0,0c-0.2-7.7,0.7-15.5,2.5-23
			c9.7-38.5,48.6-81.2,101.5-81.1C181.6,46.9,231.3,92.3,231.3,150.9z"/>
		<rect x="26" y="101.3" class="st3" width="11.2" height="17.9"/>
		<path class="st3" d="M29.5,116.5c-0.6,1.6-1.2,3.2-1.9,4.8"/>
		<path class="st4" d="M28.6,118.7c-0.3,0.9-0.7,1.7-1,2.6"/>
		<path class="st1" d="M14.7,128c0.4,0.4,0.9,0.5,1.4,0.2l12.3-9.1l3,15c0.1,0.6,0.6,0.9,1.2,0.8s0.9-0.6,0.8-1.2l-3.3-16.5
			c-0.1-0.3-0.3-0.6-0.6-0.7c-0.3-0.1-0.7-0.1-1,0.1l-13.6,10c-0.2,0.1-0.3,0.2-0.3,0.4C14.5,127.3,14.6,127.7,14.7,128z"/>
		
			<rect x="57" y="227.1" transform="matrix(0.6703 -0.7421 0.7421 0.6703 -150.9581 125.6669)" class="st3" width="17.9" height="11.2"/>
		<path class="st3" d="M92.2,249.4c1.6,0.5,3.3,1.1,4.9,1.6"/>
		<path class="st4" d="M94.4,250.2c0.9,0.3,1.7,0.6,2.6,0.9"/>
		<path class="st1" d="M73.8,254.2c0.5-0.2,0.8-0.7,0.6-1.3l-4.2-14.7l15.2,2.4c0.5,0.1,1-0.3,1.1-0.8s-0.3-1-0.8-1.1L69,236.1
			c-0.3,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.2,0.9l4.7,16.2c0,0.2,0.2,0.4,0.3,0.5C73.2,254.3,73.5,254.3,73.8,254.2z"/>
		<polygon class="st3" points="182.2,72.2 195.5,59.6 204.8,73.2 191.5,85.8 		"/>
		<polygon class="st3" points="216,190.9 232.1,199.8 222,212.8 205.9,203.9 		"/>
		<path class="st3" d="M225.7,116.6c-0.7-1.6-1.5-3.1-2.2-4.7"/>
		<path class="st4" d="M187.5,67.5c-0.4-0.8-0.8-1.7-1.2-2.5"/>
		<path class="st1" d="M183.7,50.5c-0.5,0.1-0.8,0.7-0.7,1.3l0,0l4.2,14.7l-15.1-2.4c-0.6-0.1-1.1,0.2-1.2,0.8l0,0
			c-0.1,0.6,0.3,1.1,0.8,1.2l16.7,2.7c0.4,0.1,0.7-0.1,1-0.3c0.2-0.2,0.4-0.6,0.2-1L185,51.3c-0.1-0.2-0.2-0.4-0.3-0.5
			C184.5,50.6,184.1,50.4,183.7,50.5z"/>
		<path class="st1" d="M236.1,185.6c-0.2-0.4-0.9-0.6-1.4-0.3l0,0l-12.8,8.4l-2.3-15.1c-0.1-0.6-0.6-1-1.1-0.9l0,0
			c-0.6,0.1-0.9,0.6-0.9,1.1l2.5,16.7c0,0.4,0.3,0.7,0.6,0.8s0.7,0.2,1-0.1l14.1-9.3c0.2-0.1,0.3-0.3,0.4-0.5
			C236.3,186.4,236.3,186,236.1,185.6z"/>
		<polygon class="st3" points="91.6,54.4 95.9,52.3 113,45.1 131.6,44.2 151.5,44.3 155.7,47.8 153.5,58.2 93.6,54.7 		"/>
		<circle class="st1" cx="155.7" cy="51.1" r="3.7"/>
		<path class="st2" d="M72.3,63.8c31.1-18,72.1-34.7,121.1-38c5-0.3,9.8-0.5,14.6-0.5"/>
		<path class="st1" d="M189.7,38.5c-0.4-0.6-0.2-1.5,0.4-1.9l17.3-11.3l-17.6-10.8c-0.6-0.4-0.8-1.3-0.4-1.9s1.3-0.8,1.9-0.4
			l19.4,12c0.6,0.4,0.8,1.2,0.4,1.8c-0.1,0.2-0.2,0.3-0.4,0.4l-19,12.5c-0.2,0.1-0.5,0.2-0.7,0.2C190.5,39.1,190,38.9,189.7,38.5z"
			/>
	</g>
	<g id="copy">
		<text transform="matrix(1 0 0 1 5.7626 25.7219)" class="st5 st6">Sustain</text>
		<text transform="matrix(1 0 0 1 5.7626 44.9219)" class="st5 st6">and innovate</text>
		<text transform="matrix(1 0 0 1 180.3167 265.5786)" class="st5 st6">Transition</text>
		<text transform="matrix(1 0 0 1 236.2595 91.3998)" class="st5 st6">Initiate</text>
		<text transform="matrix(0.9285 0 0 1 2.5176 256.0312)" class="st5 st7">Stabilize</text>
		<text transform="matrix(0.9285 0 0 1 2.5176 276.7314)" class="st5 st7">and optimize</text>
	</g>
</g>
<g id="stabilize_off">
	<circle class="st8" cx="45.2" cy="212.8" r="22.6"/>
	<path class="st1" d="M55.9,218.8v-3h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4v-5.5c1.1,0.9,2.3,1.7,3.6,2.2c0.1,0,0.2,0.1,0.4,0.1
		c0.4,0,0.8-0.2,0.9-0.6c0.2-0.5-0.1-1.1-0.6-1.3l0,0l0,0c-1.7-0.7-3.2-1.8-4.3-3.3v-1.6c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.6
		c-1.9,2.4-4.9,3.9-8,3.9c-3.1,0.1-6.1-1.3-8-3.8v-1.7c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.6c-1.2,1.4-2.7,2.5-4.4,3.2
		c-0.5,0.2-0.8,0.8-0.6,1.3l0,0l0,0c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1c1.3-0.5,2.5-1.3,3.6-2.2v5.6h-4c-0.6,0-1,0.4-1,1
		s0.4,1,1,1h4v4.3c-0.5-0.2-0.9-0.5-1.2-0.9c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3c-0.5,0.5-1,0.8-1.6,1.1
		c-0.5,0.2-0.8,0.8-0.6,1.3s0.8,0.8,1.3,0.6c0.6-0.2,1.2-0.5,1.7-0.9c0.7,0.7,1.7,1.1,2.7,1.1c1.2,0.1,2.4-0.3,3.3-1.1
		c0.7,0.7,1.7,1.1,2.7,1.1c1.2,0.1,2.4-0.3,3.3-1.1c0.7,0.7,1.7,1.1,2.7,1.1c1.2,0.1,2.4-0.3,3.3-1.1c0.7,0.7,1.7,1.1,2.7,1.1h0.3
		c1.1,0,2.2-0.4,3-1.1c0.5,0.4,1.1,0.7,1.7,0.9c0.5,0,1-0.4,1.1-0.9c0-0.4-0.2-0.8-0.6-1c-0.6-0.2-1.1-0.6-1.5-1
		c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3c-0.4,0.4-0.8,0.7-1.3,0.9v-0.2L55.9,218.8L55.9,218.8z M37.9,208.4
		c2.2,1.9,5.1,3,8,2.9c2.9,0,5.8-1.1,8-3v5.5h-16V208.4z M52.7,219.2c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3
		c-0.6,0.7-1.5,1.1-2.5,1.2c-0.8-0.1-1.5-0.5-2-1.1c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3c-0.6,0.7-1.5,1.1-2.5,1.2
		c-0.8-0.1-1.5-0.5-2-1.1c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3c-0.4,0.4-0.8,0.7-1.3,0.9v-4.2h16v4.3L52.7,219.2L52.7,219.2z"
		/>
</g>
<g id="stabilize_on" class="st9" (click)="goto(OPTIMIZE)">
	<circle class="st10" cx="45.2" cy="212.8" r="22.6"/>
	<path class="st11" d="M55.9,218.8v-3h4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4v-5.5c1.1,0.9,2.3,1.7,3.6,2.2c0.1,0,0.2,0.1,0.4,0.1
	c0.4,0,0.8-0.2,0.9-0.6c0.2-0.5-0.1-1.1-0.6-1.3l0,0l0,0c-1.7-0.7-3.2-1.8-4.3-3.3v-1.6c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.6
	c-1.9,2.4-4.9,3.9-8,3.9c-3.1,0.1-6.1-1.3-8-3.8v-1.7c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.6c-1.2,1.4-2.7,2.5-4.4,3.2
	c-0.5,0.2-0.8,0.8-0.6,1.3l0,0l0,0c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1c1.3-0.5,2.5-1.3,3.6-2.2v5.6h-4c-0.6,0-1,0.4-1,1
	s0.4,1,1,1h4v4.3c-0.5-0.2-0.9-0.5-1.2-0.9c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3c-0.5,0.5-1,0.8-1.6,1.1
	c-0.5,0.2-0.8,0.8-0.6,1.3s0.8,0.8,1.3,0.6c0.6-0.2,1.2-0.5,1.7-0.9c0.7,0.7,1.7,1.1,2.7,1.1c1.2,0.1,2.4-0.3,3.3-1.1
	c0.7,0.7,1.7,1.1,2.7,1.1c1.2,0.1,2.4-0.3,3.3-1.1c0.7,0.7,1.7,1.1,2.7,1.1c1.2,0.1,2.4-0.3,3.3-1.1c0.7,0.7,1.7,1.1,2.7,1.1h0.3
	c1.1,0,2.2-0.4,3-1.1c0.5,0.4,1.1,0.7,1.7,0.9c0.5,0,1-0.4,1.1-0.9c0-0.4-0.2-0.8-0.6-1c-0.6-0.2-1.1-0.6-1.5-1
	c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3c-0.4,0.4-0.8,0.7-1.3,0.9v-0.2L55.9,218.8L55.9,218.8z M37.9,208.4
	c2.2,1.9,5.1,3,8,2.9c2.9,0,5.8-1.1,8-3v5.5h-16V208.4z M52.7,219.2c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3
	c-0.6,0.7-1.5,1.1-2.5,1.2c-0.8-0.1-1.5-0.5-2-1.1c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3c-0.6,0.7-1.5,1.1-2.5,1.2
	c-0.8-0.1-1.5-0.5-2-1.1c-0.2-0.2-0.4-0.4-0.7-0.4s-0.6,0.1-0.8,0.3c-0.4,0.4-0.8,0.7-1.3,0.9v-4.2h16v4.3L52.7,219.2L52.7,219.2z"
/>
</g>
<g id="optimize_off">
	<circle class="st8" cx="205.9" cy="219.2" r="22.6"/>
	<path class="st1" d="M217.7,219.6c0,6.6-5.4,12-12,12l0,0c-2.5,0-5-0.8-7-2.3v2.3c0,0.6-0.4,1-1,1s-1-0.4-1-1v-5
		c0-0.5,0.4-0.9,0.9-1h5.1c0.6,0,1,0.4,1,1s-0.4,1-1,1h-3c1.7,1.3,3.8,2,6,2c5.5,0,10-4.5,10-10c0-0.5,0.4-1,0.9-1h0.1
		C217.3,218.6,217.7,219.1,217.7,219.6z M205.7,209.6c2.2,0,4.3,0.7,6,2h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h5c0.5,0,0.9-0.4,1-0.9v-5.1
		c0-0.6-0.4-1-1-1s-1,0.4-1,1v2.3c-2-1.5-4.5-2.3-7-2.3c-6.6,0-12,5.4-12,12c0,0.6,0.4,1,1,1s1-0.4,1-1
		C195.7,214.1,200.2,209.6,205.7,209.6z"/>
</g>
<g id="optimize_on" class="st9" (click)="goto(TRANSITION)">
	<circle class="st10" cx="205.9" cy="219.2" r="22.6"/>
	<path class="st11" d="M217.7,219.6c0,6.6-5.4,12-12,12l0,0c-2.5,0-5-0.8-7-2.3v2.3c0,0.6-0.4,1-1,1s-1-0.4-1-1v-5
	c0-0.5,0.4-0.9,0.9-1h5.1c0.6,0,1,0.4,1,1s-0.4,1-1,1h-3c1.7,1.3,3.8,2,6,2c5.5,0,10-4.5,10-10c0-0.5,0.4-1,0.9-1h0.1
	C217.3,218.6,217.7,219.1,217.7,219.6z M205.7,209.6c2.2,0,4.3,0.7,6,2h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h5c0.5,0,0.9-0.4,1-0.9v-5.1
	c0-0.6-0.4-1-1-1s-1,0.4-1,1v2.3c-2-1.5-4.5-2.3-7-2.3c-6.6,0-12,5.4-12,12c0,0.6,0.4,1,1,1s1-0.4,1-1
	C195.7,214.1,200.2,209.6,205.7,209.6z"/>
</g>
<g id="innovate_off">
	<circle class="st8" cx="48.2" cy="83.4" r="22.6"/>
	<path class="st1" d="M36.8,93.4v2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2c0-0.5,0.4-1,0.9-1h0.1C36.4,92.4,36.8,92.9,36.8,93.4z M39.8,90.4
		c-0.5,0-0.9,0.4-1,0.9v4.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-4C40.8,90.9,40.5,90.5,39.8,90.4L39.8,90.4z M43.8,87.4
		c-0.5,0-0.9,0.4-1,0.9v7.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-7C44.8,87.9,44.5,87.5,43.8,87.4L43.8,87.4z M47.8,86.4
		c-0.5,0-0.9,0.4-1,0.9v8.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-8C48.8,86.9,48.5,86.5,47.8,86.4L47.8,86.4z M51.8,86.4
		c-0.5,0-0.9,0.4-1,0.9v8.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-8C52.8,86.9,52.5,86.5,51.8,86.4L51.8,86.4z M55.8,83.4
		c-0.5,0-0.9,0.4-1,0.9v11.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-11C56.8,83.9,56.5,83.5,55.8,83.4L55.8,83.4z M59.8,80.4
		c-0.5,0-0.9,0.4-1,0.9v14.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-14C60.8,80.9,60.5,80.5,59.8,80.4L59.8,80.4z M62.7,73
		c-0.1-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.2-0.1-0.4-0.1h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.6l-8,8h-7.6c-0.2,0-0.4,0.1-0.6,0.2l-8,6
		C34.8,89,34.7,89.5,35,90c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2l7.7-5.8h7.7c0.3,0,0.5-0.1,0.7-0.3l8.3-8.3v1.6
		c0,0.6,0.4,1,1,1s1-0.4,1-1v-4C62.9,73.3,62.8,73.1,62.7,73z"/>
</g>
<g id="innovate_on" class="st9" (click)="goto(INNOVATE)">
	<circle class="st10" cx="48.2" cy="83.4" r="22.6"/>
	<path class="st11" d="M36.8,93.4v2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2c0-0.5,0.4-1,0.9-1h0.1C36.4,92.4,36.8,92.9,36.8,93.4z M39.8,90.4
	c-0.5,0-0.9,0.4-1,0.9v4.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-4C40.8,90.9,40.5,90.5,39.8,90.4L39.8,90.4z M43.8,87.4
	c-0.5,0-0.9,0.4-1,0.9v7.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-7C44.8,87.9,44.5,87.5,43.8,87.4L43.8,87.4z M47.8,86.4
	c-0.5,0-0.9,0.4-1,0.9v8.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-8C48.8,86.9,48.5,86.5,47.8,86.4L47.8,86.4z M51.8,86.4
	c-0.5,0-0.9,0.4-1,0.9v8.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-8C52.8,86.9,52.5,86.5,51.8,86.4L51.8,86.4z M55.8,83.4
	c-0.5,0-0.9,0.4-1,0.9v11.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-11C56.8,83.9,56.5,83.5,55.8,83.4L55.8,83.4z M59.8,80.4
	c-0.5,0-0.9,0.4-1,0.9v14.1c0,0.6,0.4,1,1,1s1-0.4,1-1v-14C60.8,80.9,60.5,80.5,59.8,80.4L59.8,80.4z M62.7,73
	c-0.1-0.2-0.3-0.4-0.5-0.5c-0.1-0.1-0.2-0.1-0.4-0.1h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h1.6l-8,8h-7.6c-0.2,0-0.4,0.1-0.6,0.2l-8,6
	C34.8,89,34.7,89.5,35,90c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2l7.7-5.8h7.7c0.3,0,0.5-0.1,0.7-0.3l8.3-8.3v1.6
	c0,0.6,0.4,1,1,1s1-0.4,1-1v-4C62.9,73.3,62.8,73.1,62.7,73z"/>
</g>
<g id="initiate_off">
	<circle class="st12" cx="208" cy="88.5" r="23.5"/>
	<circle class="st3" cx="208" cy="88.5" r="21.5"/>
	<path class="st12" d="M200.8,88.5h4.4c0.3,0,0.6,0.1,0.8,0.4c0.2,0.2,0.2,0.6,0.2,0.9l-2.8,9.7l12.4-13.9h-4.7
		c-0.3,0-0.6-0.2-0.8-0.4c-0.2-0.3-0.2-0.6-0.1-0.9l3.3-7.5h-7.7L200.8,88.5L200.8,88.5z M201.3,104.3c-0.5,0-1-0.4-1-1
		c0-0.1,0-0.2,0-0.3l3.6-12.5h-4.6c-0.5,0-1-0.4-1-1c0-0.1,0-0.3,0.1-0.4l5.9-13.8c0.2-0.4,0.5-0.6,0.9-0.6h9.8c0.5,0,1,0.4,1,1
		c0,0.1,0,0.3-0.1,0.4l-3.3,7.5h5.4c0.5,0,1,0.4,1,1c0,0.2-0.1,0.5-0.3,0.7L202,103.9C201.8,104.1,201.6,104.3,201.3,104.3
		L201.3,104.3z"/>
</g>
<g id="initiate_on" class="st9" (click)="goto(INITIATE)">
	<circle class="st13" cx="208.3" cy="88.5" r="23.5"/>
	<circle class="st14" cx="208.3" cy="88.5" r="21.5"/>
	<path class="st11" d="M201.1,88.5h4.4c0.3,0,0.6,0.1,0.8,0.4c0.2,0.2,0.2,0.6,0.2,0.9l-2.8,9.7l12.4-13.9h-4.7
		c-0.3,0-0.6-0.2-0.8-0.4c-0.2-0.3-0.2-0.6-0.1-0.9l3.3-7.5h-7.7L201.1,88.5L201.1,88.5z M201.6,104.3c-0.5,0-1-0.4-1-1
		c0-0.1,0-0.2,0-0.3l3.6-12.5h-4.6c-0.5,0-1-0.4-1-1c0-0.1,0-0.3,0.1-0.4l5.9-13.8c0.2-0.4,0.5-0.6,0.9-0.6h9.8c0.5,0,1,0.4,1,1
		c0,0.1,0,0.3-0.1,0.4l-3.3,7.5h5.4c0.5,0,1,0.4,1,1c0,0.2-0.1,0.5-0.3,0.7l-16.7,18.6C202.1,104.1,201.9,104.3,201.6,104.3
		L201.6,104.3z"/>
</g>
</svg>