<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 305 282" style="enable-background:new 0 0 305 282;" xml:space="preserve">
	<style type="text/css">
		.stin0 {
			fill: none;
			stroke: #005587;
			stroke-width: 3;
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.stin1 {
			fill: #FFFFFF;
		}

		.stin2 {
			fill: none;
			stroke: #005587;
			stroke-width: 2;
			stroke-linecap: round;
			stroke-linejoin: round;
		}

		.stin3 {
			fill: #005587;
		}

		.stin4 {
			fill: #012169;
			fill-opacity: 0.2;
		}

		.stin5 {
			fill: none;
		}

		.stin6 {
			font-family: 'Open Sans';
		}

		.stin7 {
			font-size: 16px;
		}

		.stin8 {
			fill: #FFFFFF;
			stroke: #005587;
			stroke-width: 2;
			stroke-miterlimit: 10;
		}

		.stin9 {
			display: none;
		}

		.stin10 {
			display: inline;
			fill: #005587;
			stroke: #005587;
			stroke-width: 2;
			stroke-miterlimit: 10;
		}

		.stin11 {
			display: inline;
			fill: #FFFFFF;
		}
	</style>
	<g>
		<g id="Layer_1_1_">
			<g id="Isolation_Mode">
				<g id="paths_circles">
					<path class="stin0" d="M250.5,167c0,57.5-46.6,104.1-104.1,104.1S42.3,224.5,42.3,167c-0.2-7.7,0.7-15.5,2.5-23
					c9.7-38.5,48.6-81.2,101.5-81.1C200.8,62.9,250.5,108.3,250.5,167z" />

					<rect x="194" y="73.5" transform="matrix(0.7792 -0.6267 0.6267 0.7792 -4.8421 142.7055)"
						class="stin1" width="12.3" height="9.5" />
					<rect x="49.4" y="106.6" class="stin1" width="11.2" height="17.9" />
					<path class="stin1" d="M52.9,121.8c-0.6,1.6-1.2,3.2-1.9,4.8" />
					<path class="stin2" d="M52.1,124.1c-0.3,0.9-0.7,1.7-1,2.6" />
					<path class="stin3" d="M38.2,133.4c0.4,0.4,0.9,0.5,1.4,0.2l12.3-9.1l3,15c0.1,0.6,0.6,0.9,1.2,0.8s0.9-0.6,0.8-1.2l-3.3-16.5
					c-0.1-0.3-0.3-0.6-0.6-0.7s-0.7-0.1-1,0.1l-13.6,10c-0.2,0.1-0.3,0.2-0.3,0.4C37.9,132.7,38,133.1,38.2,133.4z" />

					<rect x="74" y="243.4" transform="matrix(0.4286 -0.9035 0.9035 0.4286 -177.5401 217.1838)"
						class="stin1" width="17.9" height="11.2" />
					<path class="stin1" d="M84.9,251.4c1.5,0.9,2.9,1.8,4.4,2.6" />
					<path class="stin2" d="M86.9,252.7c0.8,0.5,1.6,0.9,2.4,1.4" />
					<path class="stin3"
						d="M93.7,267.9c0.4-0.2,0.7-0.7,0.5-1.1c0-0.1,0-0.1-0.1-0.2l-6.9-13.7l15.3-0.4c0.6,0,1-0.4,1-1s-0.4-1-1-1
					L85.6,251c-0.3,0-0.7,0.2-0.8,0.5c-0.2,0.3-0.2,0.7,0,1l7.6,15.1c0.1,0.2,0.2,0.3,0.4,0.4C93.1,268,93.4,268.1,93.7,267.9z" />

					<rect x="238.7" y="191.8" transform="matrix(0.4933 -0.8698 0.8698 0.4933 -50.8779 314.247)"
						class="stin1" width="11.2" height="17.9" />
					<path class="stin1" d="M245,199.1c0.6-1.6,1.1-3.2,1.7-4.9" />
					<path class="stin2" d="M245.7,196.8c0.3-0.9,0.6-1.7,0.9-2.6" />
					<path class="stin3" d="M259.3,187.1c-0.4-0.4-0.9-0.5-1.4-0.2l-12,9.5l-3.5-14.9c0-0.6-0.5-1-1.1-0.9c-0.6,0-1,0.5-0.9,1.1
					c0,0.1,0,0.2,0.1,0.4l3.9,16.4c0.1,0.3,0.3,0.6,0.6,0.7c0.3,0.1,0.7,0.1,0.9-0.2l13.2-10.5c0.2-0.1,0.3-0.3,0.3-0.5
					C259.6,187.6,259.5,187.3,259.3,187.1z" />
					<polygon class="stin1"
						points="110.8,70.4 115.2,68.3 132.3,61.1 150.8,60.2 170.7,60.3 174.9,63.8 172.7,74.3 112.8,70.7 				" />
					<path class="stin0" d="M91.5,79.8c31.1-18,72.1-34.7,121.1-38c5-0.3,9.8-0.5,14.6-0.5" />
					<path class="stin3" d="M208.9,54.5c-0.4-0.6-0.2-1.5,0.4-1.9l17.3-11.3L209,30.5c-0.6-0.4-0.8-1.3-0.4-1.9s1.3-0.8,1.9-0.4
					l19.4,12c0.6,0.4,0.8,1.2,0.4,1.8c-0.1,0.2-0.2,0.3-0.4,0.4l-19,12.5c-0.2,0.1-0.5,0.2-0.7,0.2
					C209.7,55.1,209.2,54.9,208.9,54.5z" />
					<path class="stin1" d="M198.4,77.6c-1.5-0.8-3-1.6-4.6-2.4" />
					<path class="stin2" d="M196.2,76.6c-0.9-0.4-1.6-0.8-2.4-1.3" />
					<path class="stin3" d="M188.6,61.7c-0.5,0.3-0.6,0.9-0.4,1.4l7.7,13.2l-15.2,1.3c-0.6,0.1-1,0.6-0.9,1.1s0.6,1,1.1,0.9l16.8-1.5
					c0.3,0,0.6-0.2,0.8-0.5s0.2-0.7-0.1-0.9l-8.5-14.6c-0.1-0.2-0.2-0.3-0.4-0.4C189.2,61.6,188.8,61.5,188.6,61.7z" />
				</g>
			</g>
			<g id="paths_circles-2">
				<circle id="Oval-Copy-8" class="stin4" cx="147.5" cy="169" r="55" />
				<circle id="Oval-Copy-9" class="stin3" cx="147.5" cy="169" r="40" />
			</g>
			<g id="copy">
				<rect x="210.1" class="stin5" width="92.3" height="21.2" />
				<rect x="26.4" y="55.1" class="stin5" width="92.3" height="21.2" />
				<text transform="matrix(1 0 0 1 22.45 34.29)" class="stin6 stin7">Establish</text>
				<text transform="matrix(1 0 0 1 22.05 50.29)" class="stin6 stin7">value</text>
				<text transform="matrix(1 0 0 1 22.15 66.29)" class="stin6 stin7">case</text>
				<rect x="234.7" y="95.8" class="stin5" width="92.3" height="21.2" />
				<text transform="matrix(1 0 0 1 247.4308 96.7998)" class="stin6 stin7">Sense</text>
				<rect x="243" y="221" class="stin5" width="92.3" height="21.2" />
				<text transform="matrix(1 0 0 1 254.0083 243.2001)" class="stin6 stin7">Define</text>
				<text transform="matrix(1 0 0 1 257.5162 261.0822)" class="stin6 stin7">digital</text>
				<text transform="matrix(1 0 0 1 228.6003 280.0595)" class="stin6 stin7">ambitions</text>
				<rect y="256.8" class="stin5" width="92.3" height="21.2" />
				<text transform="matrix(1 0 0 1 2.7371 259.1)" class="stin6 stin7">Define</text>
				<text transform="matrix(1 0 0 1 3.6813 277.1)" class="stin6 stin7">journey</text>
			</g>
		</g>
		<g id="sense_off">
			<circle class="stin8" cx="217.6" cy="98.4" r="22.6" />
			<path class="stin3" d="M231.4,95.3l-2.1-7.7c-0.1-0.3-0.2-0.5-0.5-0.6c-0.2-0.1-0.5-0.2-0.8-0.1l-3.9,1.1c-0.5,0.1-0.8,0.7-0.7,1.2
			l-7.7,2.1c-0.4,0.1-0.7,0.5-0.7,0.9l-9.7,2.6l-0.2-0.6c-0.2-0.5-0.8-0.7-1.3-0.5c-0.4,0.2-0.6,0.6-0.6,1l2.1,7.7
			c0.1,0.4,0.5,0.7,1,0.7h0.3c0.5-0.1,0.8-0.7,0.7-1.2l-0.2-0.6l0,0l9.1-2.5l-4.8,13.4c-0.2,0.5,0.1,1.1,0.6,1.3l0,0
			c0.1,0,0.2,0.1,0.3,0.1c0.4,0,0.8-0.3,0.9-0.7l3.1-8.6v8.2c0,0.6,0.4,1,1,1s1-0.4,1-1v-8.2l3.1,8.6c0.1,0.4,0.5,0.7,0.9,0.7
			c0.1,0,0.2,0,0.3-0.1c0.5-0.2,0.8-0.8,0.6-1.3l0,0l-4.8-13.3l7.1-1.9l0,0c0.1,0.4,0.5,0.7,1,0.7h0.3l3.9-1.1
			c0.3-0.1,0.5-0.2,0.6-0.5C231.4,95.8,231.4,95.6,231.4,95.3z M206.8,96.5l8.7-2.4l0.7,2.6l-8.7,2.4L206.8,96.5z M218.3,96.8
			l-1-3.9l6.8-1.8l0.5,1.9l0.5,1.9L218.3,96.8z M227.3,95.4l-1.6-5.8l1.9-0.5l1.6,5.8L227.3,95.4z" />
		</g>
		<g id="sense_on" class="stin9" (click)="goto('Sense')">
			<circle class="stin10" cx="217.6" cy="98.4" r="22.6" />
			<path class="stin11" d="M231.4,95.3l-2.1-7.7c-0.1-0.3-0.2-0.5-0.5-0.6c-0.2-0.1-0.5-0.2-0.8-0.1l-3.9,1.1c-0.5,0.1-0.8,0.7-0.7,1.2
			l-7.7,2.1c-0.4,0.1-0.7,0.5-0.7,0.9l-9.7,2.6l-0.2-0.6c-0.2-0.5-0.8-0.7-1.3-0.5c-0.4,0.2-0.6,0.6-0.6,1l2.1,7.7
			c0.1,0.4,0.5,0.7,1,0.7h0.3c0.5-0.1,0.8-0.7,0.7-1.2l-0.2-0.6l0,0l9.1-2.5l-4.8,13.4c-0.2,0.5,0.1,1.1,0.6,1.3l0,0
			c0.1,0,0.2,0.1,0.3,0.1c0.4,0,0.8-0.3,0.9-0.7l3.1-8.6v8.2c0,0.6,0.4,1,1,1s1-0.4,1-1v-8.2l3.1,8.6c0.1,0.4,0.5,0.7,0.9,0.7
			c0.1,0,0.2,0,0.3-0.1c0.5-0.2,0.8-0.8,0.6-1.3l0,0l-4.8-13.3l7.1-1.9l0,0c0.1,0.4,0.5,0.7,1,0.7h0.3l3.9-1.1
			c0.3-0.1,0.5-0.2,0.6-0.5C231.4,95.8,231.4,95.6,231.4,95.3z M206.8,96.5l8.7-2.4l0.7,2.6l-8.7,2.4L206.8,96.5z M218.3,96.8
			l-1-3.9l6.8-1.8l0.5,1.9l0.5,1.9L218.3,96.8z M227.3,95.4l-1.6-5.8l1.9-0.5l1.6,5.8L227.3,95.4z" />
		</g>
		<g id="define_off">
			<circle class="stin8" cx="71" cy="226.1" r="22.6" />
			<path class="stin3" d="M70.6,211.3L70.6,211.3c-6.1,0-11,4.9-11,11c0,2,0.5,3.9,1.5,5.6l8.6,13c0.2,0.3,0.5,0.4,0.8,0.4l0,0
			c0.3,0,0.6-0.2,0.8-0.4l8.6-13.1c1-1.6,1.6-3.6,1.5-5.5c0.2-5.9-4.5-10.8-10.4-11C70.9,211.3,70.7,211.3,70.6,211.3z M78.4,226.8
			l-7.8,11.8l-7.8-11.7c-0.8-1.3-1.3-2.9-1.2-4.5c0-5,4-9,9-9l0,0c5,0,9,4,9,9C79.6,223.9,79.2,225.5,78.4,226.8z" />
			<path class="stin3" d="M70.6,217.3c-2.8,0-5,2.2-5,5s2.2,5,5,5s5-2.2,5-5S73.4,217.3,70.6,217.3z M70.6,225.3c-1.7,0-3-1.3-3-3
			s1.3-3,3-3s3,1.3,3,3c0.1,1.6-1.2,2.9-2.8,3C70.7,225.3,70.7,225.3,70.6,225.3z" />
		</g>
		<g id="define_on" class="stin9" (click)="goto('Define journey')">
			<circle class="stin10" cx="71" cy="226.1" r="22.6" />
			<path class="stin11" d="M70.6,211.3L70.6,211.3c-6.1,0-11,4.9-11,11c0,2,0.5,3.9,1.5,5.6l8.6,13c0.2,0.3,0.5,0.4,0.8,0.4l0,0
			c0.3,0,0.6-0.2,0.8-0.4l8.6-13.1c1-1.6,1.6-3.6,1.5-5.5c0.2-5.9-4.5-10.8-10.4-11C70.9,211.3,70.7,211.3,70.6,211.3z M78.4,226.8
			l-7.8,11.8l-7.8-11.7c-0.8-1.3-1.3-2.9-1.2-4.5c0-5,4-9,9-9l0,0c5,0,9,4,9,9C79.6,223.9,79.2,225.5,78.4,226.8z" />
			<path class="stin11" d="M70.6,217.3c-2.8,0-5,2.2-5,5s2.2,5,5,5s5-2.2,5-5S73.4,217.3,70.6,217.3z M70.6,225.3c-1.7,0-3-1.3-3-3
			s1.3-3,3-3s3,1.3,3,3c0.1,1.6-1.2,2.9-2.8,3C70.7,225.3,70.7,225.3,70.6,225.3z" />
		</g>
		<g id="define_dig_off">
			<circle class="stin8" cx="227.9" cy="221.1" r="22.6" />
			<path class="stin3" d="M242.6,222c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3v-2h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3v-5c0-0.5-0.4-1-0.9-1h-0.1h-5
			v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-2v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-2v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-5c-0.5,0-1,0.4-1,0.9
			v0.1v5h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v2h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v2h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v5
			c0,0.5,0.4,1,0.9,1h0.1h5v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3h2v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3h2v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3h5
			c0.5,0,1-0.4,1-0.9V231v-5h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3v-2H242.6z M237.6,230h-18v-18h18V230z" />
			<path class="stin3"
				d="M233.6,216.1h-10c-0.5,0-1,0.3-1,0.8c0,0,0,0,0,0.1v9.1c0,0.5,0.4,0.9,0.9,0.9c0,0,0,0,0.1,0h10
			c0.5,0,1-0.3,1-0.8c0,0,0,0,0-0.1V217C234.6,216.5,234.2,216.1,233.6,216.1L233.6,216.1z M232.6,225.2h-8V218h8V225.2z" />
		</g>
		<g id="define_dig_on" class="stin9" (click)="goto('Define digital ambitions')">
			<circle class="stin10" cx="227.9" cy="221.1" r="22.6" />
			<path class="stin11" d="M242.6,222c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3v-2h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3v-5c0-0.5-0.4-1-0.9-1h-0.1h-5
			v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-2v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-2v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-5c-0.5,0-1,0.4-1,0.9
			v0.1v5h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v2h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v2h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v5
			c0,0.5,0.4,1,0.9,1h0.1h5v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3h2v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3h2v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3h5
			c0.5,0,1-0.4,1-0.9V231v-5h3c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3v-2H242.6z M237.6,230h-18v-18h18V230z" />
			<path class="stin11"
			d="M233.6,216.1h-10c-0.5,0-1,0.3-1,0.8c0,0,0,0,0,0.1v9.1c0,0.5,0.4,0.9,0.9,0.9c0,0,0,0,0.1,0h10
			c0.5,0,1-0.3,1-0.8c0,0,0,0,0-0.1V217C234.6,216.5,234.2,216.1,233.6,216.1L233.6,216.1z M232.6,225.2h-8V218h8V225.2z" />
		</g>
		<g id="establish_off">
			<circle class="stin8" cx="72" cy="99" r="22.6" />
			<path class="stin3" d="M84.2,88.1h-4v-2c0-0.5-0.4-1-0.9-1h-0.1h-14c-0.5,0-1,0.4-1,0.9v0.1v2h-4c-0.5,0-1,0.4-1,0.9v0.1v22
			c0,0.5,0.4,1,0.9,1h0.1h24c0.5,0,1-0.4,1-0.9v-0.1v-22C85.2,88.6,84.8,88.1,84.2,88.1C84.3,88.1,84.2,88.1,84.2,88.1z M66.2,87.1
			h12v7.8c-2.2,3.3-6.7,4.2-10,2c-0.8-0.5-1.5-1.2-2-2V87.1z M83.2,110.1h-22v-20h3v5c0,0.1,0,0.3,0.1,0.4c1.2,2.7,4.4,4.6,7.9,4.6
			s6.7-1.8,7.9-4.6c0.1-0.1,0.1-0.3,0.1-0.4v-5h3V110.1z M68.5,92.8c-0.4-0.3-0.5-1-0.2-1.4s1-0.5,1.4-0.2c0.1,0.1,0.1,0.1,0.2,0.2
			l1.3,1.3v-2.6c0-0.6,0.4-1,1-1s1,0.4,1,1v2.6l1.3-1.3c0.3-0.4,1-0.5,1.4-0.2s0.5,1,0.2,1.4c-0.1,0.1-0.1,0.1-0.2,0.2l-3,3
			c-0.4,0.4-1,0.4-1.4,0L68.5,92.8z M81.2,103.1c0,0.5-0.4,1-0.9,1h-0.1h-16c-0.6,0-1-0.4-1-1s0.4-1,1-1h16
			C80.7,102.1,81.2,102.5,81.2,103.1L81.2,103.1z M81.2,107.1c0,0.5-0.4,1-0.9,1h-0.1h-16c-0.6,0-1-0.4-1-1s0.4-1,1-1h16
			C80.7,106.1,81.2,106.5,81.2,107.1L81.2,107.1z" />
		</g>
		<g id="establish_on" class="stin9" (click)="goto('Establish value case')">
			<circle class="stin10" cx="72" cy="99" r="22.6" />
			<path class="stin11" d="M84.2,88.1h-4v-2c0-0.5-0.4-1-0.9-1h-0.1h-14c-0.5,0-1,0.4-1,0.9v0.1v2h-4c-0.5,0-1,0.4-1,0.9v0.1v22
			c0,0.5,0.4,1,0.9,1h0.1h24c0.5,0,1-0.4,1-0.9v-0.1v-22C85.2,88.6,84.8,88.1,84.2,88.1C84.3,88.1,84.2,88.1,84.2,88.1z M66.2,87.1
			h12v7.8c-2.2,3.3-6.7,4.2-10,2c-0.8-0.5-1.5-1.2-2-2V87.1z M83.2,110.1h-22v-20h3v5c0,0.1,0,0.3,0.1,0.4c1.2,2.7,4.4,4.6,7.9,4.6
			s6.7-1.8,7.9-4.6c0.1-0.1,0.1-0.3,0.1-0.4v-5h3V110.1z M68.5,92.8c-0.4-0.3-0.5-1-0.2-1.4s1-0.5,1.4-0.2c0.1,0.1,0.1,0.1,0.2,0.2
			l1.3,1.3v-2.6c0-0.6,0.4-1,1-1s1,0.4,1,1v2.6l1.3-1.3c0.3-0.4,1-0.5,1.4-0.2s0.5,1,0.2,1.4c-0.1,0.1-0.1,0.1-0.2,0.2l-3,3
			c-0.4,0.4-1,0.4-1.4,0L68.5,92.8z M81.2,103.1c0,0.5-0.4,1-0.9,1h-0.1h-16c-0.6,0-1-0.4-1-1s0.4-1,1-1h16
			C80.7,102.1,81.2,102.5,81.2,103.1L81.2,103.1z M81.2,107.1c0,0.5-0.4,1-0.9,1h-0.1h-16c-0.6,0-1-0.4-1-1s0.4-1,1-1h16
			C80.7,106.1,81.2,106.5,81.2,107.1L81.2,107.1z" />
		</g>
	</g>
	<g id="Isolation_Mode-2">

		<ellipse transform="matrix(1.047153e-03 -1 1 1.047153e-03 101.411 225.1361)" class="stin1" cx="163.4" cy="61.8"
			rx="4.3" ry="10.2" />
		<path class="stin1" d="M104.5,76.2L110,73l8.3-4.3l6.2-3.1l5.5-2.4l4.5-2.1l5.2-2c0,0,9.3-0.5,9.5-0.5l6,0.1l1.3,1.9l-0.5,2.5
		l-23,8.3l-16.1,2.4L104.5,76.2z" />
		<circle class="stin3" cx="174.2" cy="66.3" r="3.7" />
	</g>
</svg>