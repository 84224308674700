import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from 'shared-lib';
import { HttpServiceHelper } from 'src/app/types/common/HttpServiceHelper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExternalUserService {
  graphApiUrl = 'https://graph.microsoft.com/v1.0/users';
  private userMicroserviceUrl: string;
  private userInfoSaved: BehaviorSubject<boolean>;

  constructor(private baseService: BaseService,
    private httpService: HttpServiceHelper) {
      this.userMicroserviceUrl = environment.API_MICROSERVICE_URL?.USER;
      this.userInfoSaved = new BehaviorSubject(true);
     }

  externalUserLogin(payload, apiBaseUrl) {
    return this.baseService.post(`/external-user/updateUser`, payload, apiBaseUrl);
  }

  getUsersFromGraphApi(value) {
    const params = new HttpParams().set('$filter', "((startswith(displayName,'" + value + "') or startswith(mail,'" + value + "')))")
    return this.httpService.httpGetRequestWithParams(this.graphApiUrl, params)
  }
  getUserDetails(): Observable<boolean> {
    return this.userInfoSaved.asObservable();
  }

  setUserDetails(data: boolean) {
    this.userInfoSaved.next(data);
  }
}
