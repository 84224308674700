<button class="close" mat-button mat-dialog-close (click)="closeModal('x')">X</button>
<h1 mat-dialog-title> {{responseItems.status}} </h1>

<div class="success-text center" [ngClass]="{'error': fail}">

    <p>
        {{responseItems.successMessage}}
    </p>
    <p *ngIf="fail && responseItems.warningMessage != undefined">
        ( {{responseItems.warningMessage}} )
    </p>

    <div class="success-items" *ngIf="warning && responseItems.successItemList.length > 2;else elseBlock1">
        <span *ngFor="let item of responseItems.successItemList; let i=index"> {{item.l1}} </span>
    </div>
    <ng-template #elseBlock1>
        <div class="success-items">
        <p *ngFor="let item of responseItems.successItemList; let i=index"> {{item.l1}} </p>
    </div>
    </ng-template>

</div>
<hr *ngIf="warning && divider">
<div *ngIf="warning" class="success-text center">

    <p>
        {{responseItems.warningMessage}}
    </p>

    <div class="success-items" *ngIf="responseItems.warningItemList.length > 2;else elseBlock2">
        <span *ngFor="let item of responseItems.warningItemList; let i=index"> {{item.l1}} </span>
    </div>
    <ng-template class="success-items" #elseBlock2>
        <div class="success-items">
            <p *ngFor="let item of responseItems.warningItemList; let i=index"> {{item.l1}} </p>
        </div>
    </ng-template>

</div>
<img class="modal-footer-image" src="../../../../../../assets/bottom_color.png">