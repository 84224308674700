import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  AfterContentInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map, debounceTime } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { DocumentRepositoryService } from '@projects/document-repository/src/app/document-repository/document-repository.service';
import { TranslateService } from '@ngx-translate/core';

export interface OptionName {
  groupTitle: String;
  optionName: String;
  stopName: String;
  activityName: String;
}

export interface StateGroup {
  title: String;
  names: OptionName[];
}

/**
 * @title Option groups autocomplete
 */
@Component({
  selector: 'search-document',
  templateUrl: './search-document.component.html',
  styleUrls: ['./search-document.component.scss']
})
export class SearchDocumentComponent implements OnInit, AfterContentInit {

  @Input() showDocInputSearch;
  @Input() placeholderText;
  @Input() searchDocumentData: any;
  @Output() closeSearchEvent = new EventEmitter<Boolean>();

  @Input() selectedOption;
  @Input() noData: String = 'Global.Common.Label.NoRecordFound';
  @Output() selectSearchEvent = new EventEmitter<OptionName>();
  @Output() selectDocSearchEvent = new EventEmitter();
  @Input() projectId: any;
  @Input() packageMethodId: number;
  noSearchResult: boolean;
  selectedInputText: String = '';
  documents: any;
  documentsData: any = [];
  wasInside: Boolean = false;
  resultData: any;
  toHighlight: string = '';
  stateCtrl: UntypedFormControl;
  stateForm: UntypedFormGroup = this._formBuilder.group({
    stateGroup: '',
  });

  stateDocumentGroups: StateGroup[] = [
    {
      title: 'Global.Common.Label.Name',
      names: [],
    },
  ];

  limitproject: Number = 3;
  limitclient: Number = 3;
  limitmanager: Number = 3;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private documentRepositoryService: DocumentRepositoryService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.searchDocumentData) {
      this.wasInside = true;
      this.setSelectList(this.searchDocumentData, 'document-repository');
    }


    this.stateGroupOptions = this.stateForm
      .get('stateGroup')!
      .valueChanges.pipe(
        debounceTime(400),
        startWith(''),
        map((value) => this._filterGroup(value))
      );
  }

  setSelectList(data, optional?) {
    if (optional === 'document-repository') {
      for (let solution of data) {
        let groupObject = {
          groupTitle: 'Name',
          optionName: solution.Name,
          activityName: solution.Activity,
          stopName: solution.Stop,
        };
        this.stateDocumentGroups[0].names.push(groupObject);
      }
    }
  }

  stateGroups: StateGroup[] = [
    {
      title: 'DocumentRepository.Search.Title.ProjectTitle',
      names: [],
    },
    {
      title: 'DocumentRepository.Search.Title.ClientName',
      names: [],
    },
    {
      title: 'Global.Common.Filter.Manager',
      names: [],
    },
  ];

  stateGroupOptions: Observable<StateGroup[]>;
  selectedTitle: string;

  ngAfterContentInit() {
    if (this.selectedOption?.groupTitle && this.selectedOption?.optionName) {
      let initializeSelectedOption: OptionName = this.selectedOption;

      this.stateForm.get('stateGroup').setValue(initializeSelectedOption);
    }


  }

  getTableData() {

  }


  unsorted() { }

  filterOption = (opt: OptionName[], value: any): OptionName[] => {
    if (opt.length) {
      return opt.filter(d=>{ return d.activityName?.toLowerCase().includes(value.toLowerCase())|| d.optionName?.toLowerCase().includes(value.toLowerCase()) || d.stopName?.toLowerCase().includes(value.toLowerCase())})
    }

  };

  private _filterGroup(value: String): StateGroup[] {
    this.limitproject = this.limitclient = this.limitmanager = 3;

    if (value && typeof value == 'string') {
      if (value.indexOf(': ') >= 0) {
        value = value.split(': ')[1];
        this.stateForm.get('stateGroup').setValue(value);
      }
      if (this.showDocInputSearch) {
        return this.stateDocumentGroups
          .map((group) => ({
            title: group.title,
            names: this.filterOption(group.names, value),
          }))
          .filter((group) => group.names.length > 0);
      }
    }
  }

  removeDocSearchText() {
    this.stateForm.get('stateGroup').setValue('');
    let resetRecord = ''
    this.selectDocSearchEvent.emit(resetRecord);
  }

  removeSearchText() {
    this.stateForm.get('stateGroup').setValue('');
    let resetRecord: OptionName = {
      groupTitle: '',
      optionName: '',
      stopName: '',
      activityName: ''
    };
    this.selectSearchEvent.emit(resetRecord);
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.closeSearchEvent.emit(false);
    }
    this.wasInside = false;
  }

  getSelectedData(selectedOption) {
    this.selectSearchEvent.emit(selectedOption.value);
  }

  getDocSelectedData(selectedOption) {
    this.selectDocSearchEvent.emit(selectedOption.value);
  }

  displayText(option) {

    if (option?.groupTitle && option?.optionName) {
      return option?.groupTitle + ': ' + option.optionName;
    } else return option;
  }

  closeAutocomplete() {
    this.wasInside = true;
  }


  highlightSearchResult(optionName, searchTerm): string {
    searchTerm = searchTerm.toLowerCase()
    let temp = ' ' + optionName;
    if (temp.indexOf(searchTerm)) {
      var re = new RegExp(searchTerm, 'gi');
      return temp.replace(re, '<mark>$&</mark>');
    }
    return optionName;
  }

  highlightSearchCategory() {
    const searchInputValue = (<HTMLInputElement>(
      document.getElementById('searchInput')
    )).value;
    const inputs = searchInputValue.split(':');
    return inputs.length == 2
      ? `<b>${inputs[0]}:</b>${inputs[1]}`
      : inputs.length > 2
        ? `<b>${inputs[0]}:</b>${inputs[1]}:${inputs[2]}`
        : searchInputValue;
  }

  filterDataAmpDeliverable = (opt, value: string): string[] => {
    let filterValue = value.toLowerCase();
    return opt.filter((item) => item.name.toLowerCase().includes(filterValue));
  };

  filterDataAmplifier = (opt, value: string): string[] => {
    let temp = [opt];

    let filterValue = value.toLowerCase();

    return temp.filter((item) =>
      item.toolName.toLowerCase().includes(filterValue)
    );
  };



}
