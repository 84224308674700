<app-loader *ngIf="!isWindowLoaded && isMaturityAssessment"></app-loader>
<ng-container *ngIf="((userData | async) && (langPreference | async)) && !islogoutscreen && !isExternalSignup && !isClientSurvey">
  <img *ngIf="currentroute=='/welcome' && currentroute!='/logout'" class="rectangle-home" src="../../assets/bottom_color.png" />
  <app-navigation-bar *ngIf="!hideNavigationBar" [ngStyle]="{'display':isMaturityAssessment?'none':''}"></app-navigation-bar>
  <!-- <mat-progress-bar *ngIf="showLoader | async" mode="indeterminate"></mat-progress-bar> -->
  <app-loader *ngIf="showLoader | async"></app-loader>
  <app-central-notification></app-central-notification>
  <div id="container_layout" [ngStyle]="{'overflow' : isLanguageSettings ? none : (isAmplifier || isContentRepo || isAuditLog) ? 'hidden' : (currentroute!='/welcome') ? 'auto' : 'visible', 'height': (currentroute == '/logout' || hideNavigationBar) ? '100%' : 'calc(100% - 2.75rem)'}"
       [ngClass]="{'scroll_width' : (currentroute=='/iidr') ? true : false, 'psg-main-container': ispsg, 'trainstop-main-container': isTrainstop}" >
    <router-outlet></router-outlet>
  </div>
</ng-container>
<ng-container *ngIf="islogoutscreen">
  <img *ngIf="currentroute!='/welcome' && currentroute!='/logout'" class="rectangle-home" src="../../assets/bottom_color.png" />
  <app-navigation-bar></app-navigation-bar>

  <div [ngStyle]="{'overflow' : (currentroute!='/welcome') ? 'auto' : 'visible', 'height': '100%'}" [ngClass]="{'scroll_width' : (currentroute=='/iidr') ? true : false}">
    <router-outlet></router-outlet>
  </div>
</ng-container>
<ng-container *ngIf="isExternalSignup">
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="isClientSurvey">
  <router-outlet></router-outlet>
</ng-container>
