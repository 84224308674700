import { DomSanitizer } from '@angular/platform-browser';
import { notificationData, notificationEventsTypes, PROJECT_TOOL } from './../../model/notification.config';
import { SystemNotificationService } from './../../services/system-notification.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SignalRService } from '../../services/signal-r.service';
import { CommonDialogueBoxComponent } from '../common-dialogue-box/common-dialogue-box.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DELETE_ALL_NOTIFICATIONS } from '../../constants/common-dialogue-box';
import {TranslateService} from '@ngx-translate/core';
import { ChatService } from '../../services/socket.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NOTIFICATION_LABELS } from '@projects/psg/src/app/core/utility/constants';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements OnInit {

  public notifications = [];
  isUnseenNotificationPresent: boolean;
  isUnreadNotificationPresent: boolean;
  totalRecords: number = 0;
  unreadRecords: number = 0;
  newMessage = '';
  messageList: any;
  pageSize: number = 20;
  pageNumber: number= 1;
  totalPages: number= 0;
  parsedTraceData;
  @ViewChild(CdkVirtualScrollViewport, {static: false}) public viewport: CdkVirtualScrollViewport;

  constructor(private sysNotificationService_: SystemNotificationService,
    private readonly sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private readonly signalRService_: SignalRService,
    private translate: TranslateService,
    private socket: ChatService
  ) { }

  ngOnInit(): void {
    this.sysNotificationService_.getNotificationAction().subscribe((res) => {
       if (res) {
        this.pageNumber = 1;
        this.notifications = [];
        this.getAllNotificationsData(this.pageNumber, this.pageSize);
      }
    })
    this.socket.getNewMessage().subscribe((message: string) => {
      this.messageList = message;
      this.notifications = [];
      this.getAllNotificationsData(1, this.pageSize);
    })
  }

  getAllNotificationsData(pageNumber, pageSize) {
    this.isUnreadNotificationPresent = false;
    this.sysNotificationService_.getallNotifications(pageNumber, pageSize).subscribe((res) => {
      if (res.data) {
        this.totalRecords =  res.data?.totalRecords;
        this.unreadRecords = res.data?.unreadRecords;
        this.totalPages = Math.ceil(this.totalRecords/this.pageSize);
        setTimeout(()=> {

        },2500)


        let data = res.data?.notificationData.map(notifObj => {
          let notificationConfigData = JSON.parse(JSON.stringify(notificationData));
          if(!notifObj.isSeen) {
            this.sysNotificationService_.setIsUnseenNotificationPresent(true);
          }
          if(!notifObj.isRead) {
            this.isUnreadNotificationPresent = true;
          }
          if (notifObj?.projectName) {
            notifObj.projectName = notifObj.projectName.length > 50 ? notifObj.projectName.substring(0, 50) + '...' : notifObj.projectName;
          }
          const projectApprovedRejectedURL = notificationConfigData.projectApprovedRejected.redirectionUrlData.url + notifObj.projectId;
          switch (notifObj.event) {
            case notificationEventsTypes.projectApproved:
              notifObj.notification = `The <span class="fw-bold">${notifObj.projectName}</span> has been <span class="fw-bold">Approved</span>`;
              notificationConfigData.projectApprovedRejected.redirectionUrlData.url = projectApprovedRejectedURL;
              notificationConfigData.project.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.projectApprovedRejected, ...notifObj };
            case notificationEventsTypes.projectCreated:
              notifObj.notification = `<span class="fw-bold">${notifObj.projectName}</span> is successfully created and is under review for approval</span>`;
              notificationConfigData.projectCreated.redirectionUrlData.url = notificationConfigData.projectCreated.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.projectCreated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.projectCreated, ...notifObj };
            case notificationEventsTypes.projectRejected:
              notifObj.notification = `The Project <span class="fw-bold">${notifObj.projectName}</span> has been <span class="fw-bold">Rejected</span> due to reason <span class="fw-bold">${notifObj?.rejectReason}</span> `;
              notificationConfigData.projectApprovedRejected.redirectionUrlData.url = projectApprovedRejectedURL;
              notificationConfigData.project.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.projectApprovedRejected, ...notifObj };
            case notificationEventsTypes.psgGenerated:
              notifObj.notification =`PSG for project <span class="fw-bold">${notifObj.projectName}</span> has been <span class="fw-bold">Generated</span>`;
              notificationConfigData.psg.redirectionUrlData.url = notificationConfigData.psg.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.psg.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.psg, ...notifObj };
            case notificationEventsTypes.psgUpdated:
              notifObj.notification = `PSG for project <span class="fw-bold">${notifObj.projectName}</span> has been <span class="fw-bold">Updated</span>`;
              notificationConfigData.psg.redirectionUrlData.url = notificationConfigData.psg.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.psg.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.psg, ...notifObj };
            case notificationEventsTypes.toolGranted:
              notifObj.notification = `<span class="fw-bold">${notifObj?.featureName ?? PROJECT_TOOL}</span> for project <span class="fw-bold">${notifObj.projectName}</span> has been provisioned`;
              notificationConfigData.toolGranted.redirectionUrlData.url = notificationConfigData.toolGranted.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.toolGranted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.toolGranted, ...notifObj };
            case notificationEventsTypes.userAdded:
              notifObj.notification = `Welcome Aboard! You have been <span class="fw-bold">added</span> to the <span class="fw-bold">${notifObj.projectName}</span> project.`;
              notificationConfigData.userAdded.redirectionUrlData.url = notificationConfigData.userAdded.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.userAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.userAdded, ...notifObj };
            case notificationEventsTypes.usersAdded:
              notifObj.notification =  `Multiple users have been added to the project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.userAdded.redirectionUrlData.url = notificationConfigData.userAdded.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.userAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.userAdded, ...notifObj };
            case notificationEventsTypes.jiraExportSuccess:
              notifObj.notification =  `Project <span class="fw-bold">${notifObj.projectName}</span> has exported Release Plan data to Jira.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.jiraExportFailed:
              notifObj.notification =  `Project <span class="fw-bold">${notifObj.projectName}</span> has failed to export Release Plan data to Jira. Please try again, or if persists, contact system administrator.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.testScenarioGenerationSuccess:
              notifObj.notification =  `Test scenarios for project <span class="fw-bold">${notifObj.projectName}</span> has been generated.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.testScenarioGenerationFailed:
              notifObj.notification =  `Project <span class="fw-bold">${notifObj.projectName}</span> has failed to generate test scenarios. Please try again, or if persists, contact system administrator.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.metaDataUpdated:
              notifObj.notification =  `${notifObj?.traceData}`;
              notificationConfigData.metaDataUpdated.redirectionUrlData.url = notificationConfigData.metaDataUpdated.redirectionUrlData.url;
              notificationConfigData.metaDataUpdated.metaDataLabels = [];
              return { ...notificationConfigData.metaDataUpdated, ...notifObj };
            case notificationEventsTypes.processTaxonomyNameChanged:
              notifObj.notification =  `Process taxonomies have been changed on <span class="fw-bold">${notifObj.projectName}</span> Project. Kindly initiate export to Jira to sync changes.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.processTaxonomyDescoped:
              notifObj.notification =  `Process taxonomies have been descoped for <span class="fw-bold">${notifObj.projectName}</span> Project. Kindly initiate export to Jira to sync changes.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.nonJiraProcessTaxonomyNameChanged:
                notifObj.notification =  `Since the last manual export/import to external agile management tool, taxonomy changes have been added to project <span class="fw-bold">${notifObj.projectName}</span>. Please manually update in your agile tool.`;
                notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
                notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.nonJiraProcessTaxonomyDescoped:
                notifObj.notification =  `Process taxonomies have been descoped for <span class="fw-bold">${notifObj.projectName}</span> Project. For details please go to Refine User Stories--> Create Sprint Plan. Please manually update in your agile tool.`;
                notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
                notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.genAIUserAdded:
              notifObj.notification =  `${notifObj?.traceData} successfully <span class="fw-bold">added</span> to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.genAIUserAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              notificationConfigData.genAIUserAdded.redirectionUrlData.url = notificationConfigData.genAIUserAdded.redirectionUrlData.url + notifObj.projectId;
              return { ...notificationConfigData.genAIUserAdded, ...notifObj };
            case notificationEventsTypes.genAIUserDeleted:
              notifObj.notification =  `${notifObj?.traceData} was successfully <span class="fw-bold">deleted</span> from <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.genAIUserDeleted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              notificationConfigData.genAIUserDeleted.redirectionUrlData.url = notificationConfigData.genAIUserDeleted.redirectionUrlData.url + notifObj.projectId;
              return { ...notificationConfigData.genAIUserDeleted, ...notifObj };
            case notificationEventsTypes.genAIUserNotDeleted:
                notifObj.notification =  `<span class="fw-bold">Failed</span> to <span class="fw-bold">delete</span> ${notifObj?.traceData} from <span class="fw-bold">Ascend Intelligence Platform</span> for project ${notifObj.projectName}. Please contact support for assistance.`;
                notificationConfigData.genAIUserNotDeleted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIUserNotDeleted, ...notifObj };
            case notificationEventsTypes.genAIUserNotAdded:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to <span class="fw-bold">add</span> ${notifObj?.traceData} to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.genAIUserNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIUserNotAdded, ...notifObj };
            case notificationEventsTypes.genAIBulkUsersAdded:
                notifObj.notification =  `The <span class="fw-bold">Users</span> are successfully added to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
                notificationConfigData.genAIBulkUsersAdded.redirectionUrlData.url = notificationConfigData.genAIBulkUsersAdded.redirectionUrlData.url + notifObj.projectId;
                notificationConfigData.genAIBulkUsersAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.genAIBulkUsersAdded, ...notifObj };
            case notificationEventsTypes.genAIBulkUsersNotAdded:
            if(notifObj?.traceData){
              const { failedCount,supportUri, sasUrl } = JSON.parse(notifObj?.traceData) ?? {};
              notifObj.notification = `<span class="fw-bold">Failed</span> to add <span class="fw-bold">${failedCount ?? ''}</span> users to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${supportUri ?? ''} target="_blank" style="color: #35c253;">support</a>  for assistance.`;
              notificationConfigData.genAIBulkUsersNotAdded.redirectionUrlData.label = NOTIFICATION_LABELS.FAILED_USERS_DATA
              notificationConfigData.genAIBulkUsersNotAdded.redirectionUrlData.url = sasUrl ?? '';
            }else{
              notifObj.notification =  `<span class="fw-bold">Failed</span> to add all <span class="fw-bold">users</span> to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
            }
            notificationConfigData.genAIBulkUsersNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIBulkUsersNotAdded, ...notifObj };
            case notificationEventsTypes.genAIAdminUserAdded:
              notifObj.notification =  `${notifObj?.traceData} successfully <span class="fw-bold">synced</span> with <span class="fw-bold">Ascend Intelligence Platform</span> Admin Management data.`;
              return { ...notificationConfigData.genAIAdminUserAdded, ...notifObj };
            case notificationEventsTypes.genAIAdminUserNotAdded:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to <span class="fw-bold">sync</span> ${notifObj?.traceData} with <span class="fw-bold">Ascend Intelligence Platform</span> Admin Management data. Please contact support for assistance.`;
              return { ...notificationConfigData.genAIAdminUserNotAdded, ...notifObj };
            case notificationEventsTypes.genAIBulkAdminUsersAdded:
              notifObj.notification =  `Admin Users successfully <span class="fw-bold">synced</span> with <span class="fw-bold">Ascend Intelligence Platform</span> Admin Management data.`;
              return { ...notificationConfigData.genAIBulkAdminUsersAdded, ...notifObj };
            case notificationEventsTypes.genAIBulkAdminUsersNotAdded:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to <span class="fw-bold">sync</span> Admin Users with <span class="fw-bold">Ascend Intelligence Platform</span> Admin Management Data. Please contact support for assistance.`;
              return { ...notificationConfigData.genAIBulkAdminUsersNotAdded, ...notifObj };
              case notificationEventsTypes.genAIProjectAdded:
              notifObj.notification =  `<span class="fw-bold">Ascend Intelligence Platform</span> has been successfully provisioned for project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.genAIProjectAdded.redirectionUrlData.url = notificationConfigData.genAIProjectAdded.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIProjectAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIProjectAdded, ...notifObj };
              case notificationEventsTypes.genAIProjectNotAdded:
                notifObj.notification =  `<span class="fw-bold">Failed</span> to provision <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
                notificationConfigData.genAIProjectNotAdded.redirectionUrlData.url = notificationConfigData.genAIProjectNotAdded.redirectionUrlData.url + notifObj.projectId;
                notificationConfigData.genAIProjectNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.genAIProjectNotAdded, ...notifObj };
            case notificationEventsTypes.genAIProjectMSTeamsIdUpdated:
              notifObj.notification =  `<span class="fw-bold">MS Teams</span> has been successfully synced with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>.`;
              notificationConfigData.genAIProjectMSTeamsIdUpdated.redirectionUrlData.url = notificationConfigData.genAIProjectMSTeamsIdUpdated.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIProjectMSTeamsIdUpdated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIProjectMSTeamsIdUpdated, ...notifObj };
            case notificationEventsTypes.genAIProjectMSTeamsIdNotUpdated:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to sync <span class="fw-bold">MS Teams</span> with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.genAIProjectMSTeamsIdNotUpdated.redirectionUrlData.url = notificationConfigData.genAIProjectMSTeamsIdNotUpdated.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIProjectMSTeamsIdNotUpdated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIProjectMSTeamsIdNotUpdated, ...notifObj };
            case notificationEventsTypes.aipSubUseCasesSynced:
              notifObj.notification =  `<span class="fw-bold">Gen AI Amplifier(s)</span> has been successfully synced with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>.`;
              notificationConfigData.aipSubUseCasesSynced.redirectionUrlData.url = notificationConfigData.aipSubUseCasesSynced.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.aipSubUseCasesSynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.aipSubUseCasesSynced, ...notifObj };
            case notificationEventsTypes.aipSubUseCasesNotSynced:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to sync <span class="fw-bold">Gen AI Amplifier(s)</span> with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.aipSubUseCasesNotSynced.redirectionUrlData.url = notificationConfigData.aipSubUseCasesNotSynced.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.aipSubUseCasesNotSynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.aipSubUseCasesNotSynced, ...notifObj };
            case notificationEventsTypes.genAIAmplifierDisabled:
              notifObj.notification =  `Gen AI Amplifier - <span class="fw-bold">${notifObj?.traceData}</span> is <span class="fw-bold">disabled</span> for the project <span class="fw-bold">${notifObj.projectName}</span>.`;
              notificationConfigData.genAIAmplifierDisabled.redirectionUrlData.url = notificationConfigData.genAIAmplifierDisabled.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIAmplifierDisabled.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIAmplifierDisabled, ...notifObj };
            case notificationEventsTypes.genAIProjectDetailsNotUpdated:
              notifObj.notification =  `<span class="fw-bold">Failed</span>  to update project details in <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.genAIProjectDetailsNotUpdated.redirectionUrlData.url = notificationConfigData.genAIProjectDetailsNotUpdated.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIProjectDetailsNotUpdated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIProjectDetailsNotUpdated, ...notifObj };
            case notificationEventsTypes.autonomousCodeProjectDataAdded:
                notifObj.notification =  `<span class="fw-bold">Autonomous Code</span> has been successfully provisioned for project <span class="fw-bold">${notifObj.projectName}</span>.`;
                notificationConfigData.autonomousCodeProjectDataAdded.redirectionUrlData.url = notificationConfigData.autonomousCodeProjectDataAdded.redirectionUrlData.url + notifObj.projectId;
                notificationConfigData.autonomousCodeProjectDataAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.autonomousCodeProjectDataAdded, ...notifObj };
            case notificationEventsTypes.autonomousCodeProjectDataNotAdded:
                  notifObj.notification =  `Failed to provision <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
                  notificationConfigData.autonomousCodeProjectDataNotAdded.redirectionUrlData.url = notificationConfigData.autonomousCodeProjectDataNotAdded.redirectionUrlData.url + notifObj.projectId;
                  notificationConfigData.autonomousCodeProjectDataNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                  return { ...notificationConfigData.autonomousCodeProjectDataNotAdded, ...notifObj };
            case notificationEventsTypes.autonomousCodeProjectDataUpdated:
              notifObj.notification = `Updated details for project <span class="fw-bold">${notifObj.projectName}</span> has been successfully synced in <span class="fw-bold">Autonomous Code</span>.`;
              notificationConfigData.autonomousCodeProjectDataUpdated.redirectionUrlData.url = notificationConfigData.autonomousCodeProjectDataUpdated.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.autonomousCodeProjectDataUpdated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.autonomousCodeProjectDataUpdated, ...notifObj };
            case notificationEventsTypes.autonomousCodeProjectDataNotUpdated:
              notifObj.notification = `Failed to update project details in <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.autonomousCodeProjectDataNotUpdated.redirectionUrlData.url = notificationConfigData.autonomousCodeProjectDataNotUpdated.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.autonomousCodeProjectDataNotUpdated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.autonomousCodeProjectDataNotUpdated, ...notifObj };
            case notificationEventsTypes.autonomousCodeUserAdded:
              notifObj.notification =  `${notifObj?.traceData} is <span class="fw-bold">added</span> successfully to <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.autonomousCodeUserAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              notificationConfigData.autonomousCodeUserAdded.redirectionUrlData.url = notificationConfigData.autonomousCodeUserAdded.redirectionUrlData.url + notifObj.projectId;
              return { ...notificationConfigData.autonomousCodeUserAdded, ...notifObj };
            case notificationEventsTypes.autonomousCodeUsersAdded:
              notifObj.notification =  `Users are successfully synced to <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.autonomousCodeUsersAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              notificationConfigData.autonomousCodeUsersAdded.redirectionUrlData.url = notificationConfigData.autonomousCodeUsersAdded.redirectionUrlData.url + notifObj.projectId;
              return { ...notificationConfigData.autonomousCodeUsersAdded, ...notifObj };
            case notificationEventsTypes.autonomousCodeUsersNotAdded:
              const { failedCount,supportUri, sasUrl } = JSON.parse(notifObj?.traceData) ?? {};
              notifObj.notification = `<span class="fw-bold">Failed</span> to add <span class="fw-bold">${failedCount ?? ''}</span> users to <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${supportUri ?? ''} target="_blank" style="color: #35c253;">support</a>  for assistance.`;
              notificationConfigData.autonomousCodeUsersNotAdded.redirectionUrlData.url = sasUrl ?? '';
              notificationConfigData.autonomousCodeUsersNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
               return { ...notificationConfigData.autonomousCodeUsersNotAdded, ...notifObj };
            case notificationEventsTypes.autonmousCodeUserDeleted:
              notifObj.notification =  `${notifObj?.traceData} was successfully <span class="fw-bold">deleted</span> from <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.autonomousCodeUserDeleted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              notificationConfigData.autonomousCodeUserDeleted.redirectionUrlData.url = notificationConfigData.autonomousCodeUserDeleted.redirectionUrlData.url + notifObj.projectId;
              return { ...notificationConfigData.autonomousCodeUserDeleted, ...notifObj };
            case notificationEventsTypes.autonomousCodeUserNotAdded:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to <span class="fw-bold">add</span> ${notifObj?.traceData} to <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.autonomousCodeUserNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.autonomousCodeUserNotAdded, ...notifObj };
            case notificationEventsTypes.autonmousCodeUserNotDeleted:
              notifObj.notification = `<span class="fw-bold">Failed</span> to <span class="fw-bold">delete</span> ${notifObj?.traceData} from <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.autonmousCodeUserNotDeleted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.autonmousCodeUserNotDeleted, ...notifObj };
            case notificationEventsTypes.autonomousCodeUserStoryNotSynced:
              this.parsedTraceData = JSON.parse(notifObj?.traceData);
              notifObj.notification = `<span class="fw-bold">Failed</span> to <span class="fw-bold">sync</span> ${this.parsedTraceData?.count} User Story(ies) with <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${this.parsedTraceData?.supportUri} target="_blank" style="color: #35c253;">support</a> for assistance.`
              notificationConfigData.autonomousCodeUserStoryNotSynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl;
              notificationConfigData.autonomousCodeUserStoryNotSynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.autonomousCodeUserStoryNotSynced, ...notifObj };
            case notificationEventsTypes.autonomousCodeUserStorySynced:
              this.parsedTraceData = JSON.parse(notifObj?.traceData);
              notifObj.notification = `<span class="fw-bold">${this.parsedTraceData?.count} User Story(ies)</span> have been successfully synced with <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>.`;
              notificationConfigData.autonomousCodeUserStorySynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl;
              notificationConfigData.autonomousCodeUserStorySynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.autonomousCodeUserStorySynced, ...notifObj };
            case notificationEventsTypes.alreadyExportedToJira:
              notifObj.notification = `Since the last push to Jira, additional user stories have been added to project <span class="fw-bold">${notifObj.projectName}</span>.Please initiate another export to Jira.`
              notificationConfigData.alreadyExportedToJira.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              notificationConfigData.alreadyExportedToJira.redirectionUrlData.url = notificationConfigData.alreadyExportedToJira.redirectionUrlData.url + notifObj.projectId;
              return { ...notificationConfigData.alreadyExportedToJira, ...notifObj };
            case notificationEventsTypes.alreadyManuallyUploadedOnBuild:
              notifObj.notification = `Since the last manual export/import to external agile management tool, additional user stories have been added to project <span class="fw-bold">${notifObj.projectName}</span>. Please initiate another manual export/import.`
              notificationConfigData.alreadyManuallyUploadedOnBuild.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              notificationConfigData.alreadyManuallyUploadedOnBuild.redirectionUrlData.url = notificationConfigData.alreadyManuallyUploadedOnBuild.redirectionUrlData.url + notifObj.projectId;
              return { ...notificationConfigData.alreadyManuallyUploadedOnBuild, ...notifObj };

              // autoconfig notifications
              case notificationEventsTypes.autonomousConfigBulkUsersAdded:
                notifObj.notification =  `Users are successfully added to <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
                notificationConfigData.autonomousConfigBulkUsersAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                notificationConfigData.autonomousConfigBulkUsersAdded.redirectionUrlData.url = notificationConfigData.autonomousConfigBulkUsersAdded.redirectionUrlData.url + notifObj.projectId;
                return { ...notificationConfigData.autonomousConfigBulkUsersAdded, ...notifObj };
              case notificationEventsTypes.autonomousConfigBulkUsersNotAdded:
                  this.parsedTraceData = JSON.parse(notifObj?.traceData)
                  notifObj.notification = `<span class="fw-bold">Failed</span> to add <span class="fw-bold"> ${this.parsedTraceData?.failedCount} users</span> to <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${this.parsedTraceData?.supportUri} target="_blank" style="color: #35c253;">support</a> for assistance.`;
                  notificationConfigData.autonomousConfigBulkUsersNotAdded.redirectionUrlData.url = this.parsedTraceData?.sasUrl;
                  notificationConfigData.autonomousConfigBulkUsersNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                  return { ...notificationConfigData.autonomousConfigBulkUsersNotAdded, ...notifObj };
              case notificationEventsTypes.autonomousConfigUserDeleted:
                notifObj.notification =  `${notifObj?.traceData} was successfully <span class="fw-bold">deleted</span> from <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
                notificationConfigData.autonomousConfigUserDeleted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                notificationConfigData.autonomousConfigUserDeleted.redirectionUrlData.url = notificationConfigData.autonomousConfigUserDeleted.redirectionUrlData.url + notifObj.projectId;
                return { ...notificationConfigData.autonomousConfigUserDeleted, ...notifObj };
              case notificationEventsTypes.autonomousConfigUserNotAdded:
                notifObj.notification =  `<span class="fw-bold">Failed</span> to <span class="fw-bold">add</span> ${notifObj?.traceData} to <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
                notificationConfigData.autonomousConfigUserNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.autonomousConfigUserNotAdded, ...notifObj };
              case notificationEventsTypes.autonomousConfigUserAdded:
                notifObj.notification =  `${notifObj?.traceData} successfully <span class="fw-bold">added</span> to <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>.`;
                notificationConfigData.autonomousConfigUserAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                notificationConfigData.autonomousConfigUserAdded.redirectionUrlData.url = notificationConfigData.autonomousConfigUserAdded.redirectionUrlData.url + notifObj.projectId;
                return { ...notificationConfigData.autonomousConfigUserAdded, ...notifObj };
              case notificationEventsTypes.autonomousConfigUserNotDeleted:
                notifObj.notification = `<span class="fw-bold">Failed</span> to <span class="fw-bold">delete</span> ${notifObj?.traceData} from <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
                notificationConfigData.autonomousConfigUserNotDeleted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.autonomousConfigUserNotDeleted, ...notifObj };
              case notificationEventsTypes.autonomousConfigProjectDataAdded:
               notifObj.notification = `<span class="fw-bold">Autonomous Config</span> has been successfully provisioned for project <span class="fw-bold">${notifObj.projectName}</span>.`;
               notificationConfigData.autonomousConfigProjectDataAdded.redirectionUrlData.url = notificationConfigData.autonomousConfigProjectDataAdded.redirectionUrlData.url + notifObj.projectId;
               notificationConfigData.autonomousConfigProjectDataAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
               return { ...notificationConfigData.autonomousConfigProjectDataAdded, ...notifObj };
              case notificationEventsTypes.autonomousConfigProjectDataNotAdded:
                notifObj.notification = `<span class="fw-bold">Failed</span> to provision <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
                notificationConfigData.autonomousConfigProjectDataNotAdded.redirectionUrlData.url = notificationConfigData.autonomousConfigProjectDataNotAdded.redirectionUrlData.url + notifObj.projectId;
                notificationConfigData.autonomousConfigProjectDataNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.autonomousConfigProjectDataNotAdded, ...notifObj };
              case notificationEventsTypes.autonomousConfigProjectDataNotUpdated:
               notifObj.notification = `<span class="fw-bold">Failed</span> to update project details in <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
               notificationConfigData.autonomousConfigProjectDataNotUpdated.redirectionUrlData.url = notificationConfigData.autonomousConfigProjectDataNotUpdated.redirectionUrlData.url + notifObj.projectId;
               notificationConfigData.autonomousConfigProjectDataNotUpdated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
               return { ...notificationConfigData.autonomousConfigProjectDataNotUpdated, ...notifObj };
              case notificationEventsTypes.autonomousConfigUserStorySynced:
                this.parsedTraceData = JSON.parse(notifObj?.traceData);
                notifObj.notification = `<span class="fw-bold">${this.parsedTraceData?.count} User Story(ies)</span> have been successfully synced with <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>.`;
                notificationConfigData.autonomousConfigUserStorySynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                notificationConfigData.autonomousConfigUserStorySynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.autonomousConfigUserStorySynced, ...notifObj };
              case notificationEventsTypes.autonomousConfigUserStoryNotSynced:
                this.parsedTraceData = JSON.parse(notifObj?.traceData);
                notifObj.notification = `<span class="fw-bold">Failed</span> to <span class="fw-bold">sync</span> ${this.parsedTraceData?.count} User Story(ies) with <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${this.parsedTraceData?.supportUri} target="_blank" style="color: #35c253;">support</a> for assistance.`;
                notificationConfigData.autonomousConfigUserStoryNotSynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                notificationConfigData.autonomousConfigUserStoryNotSynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.autonomousConfigUserStoryNotSynced, ...notifObj };
              case notificationEventsTypes.aipUserStorySynced:
                this.parsedTraceData = JSON.parse(notifObj?.traceData);
                notifObj.notification = `<span class="fw-bold">${this.parsedTraceData?.count} User Story(ies)</span> have been successfully synced with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>.`;
                notificationConfigData.aipUserStorySynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                notificationConfigData.aipUserStorySynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.aipUserStorySynced, ...notifObj };
              case notificationEventsTypes.aipUserStoryNotSynced:
                this.parsedTraceData = JSON.parse(notifObj?.traceData);
                notifObj.notification = `<span class="fw-bold">Failed</span> to <span class="fw-bold">sync</span> ${this.parsedTraceData?.count} User Story(ies) with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${this.parsedTraceData?.supportUri} target="_blank" style="color: #35c253;">support</a> for assistance.`;
                notificationConfigData.aipUserStoryNotSynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                notificationConfigData.aipUserStoryNotSynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.aipUserStoryNotSynced, ...notifObj };
              case notificationEventsTypes.autonomousConfigUserStoryPartiallySynced :
                this.parsedTraceData = JSON.parse(notifObj?.traceData);
                const count = this.parsedTraceData.count;
                notifObj.notification = `<span class="fw-bold">Failed</span> to <span class="fw-bold">sync</span> ${count.failedCount} User Story(ies) with <span class="fw-bold">Autonomous Config</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${this.parsedTraceData?.supportUri} target="_blank" style="color: #35c253;">support</a> for assistance.`;
                notificationConfigData.autonomousConfigUserStoryPartiallySynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                notificationConfigData.autonomousConfigUserStoryPartiallySynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.autonomousConfigUserStoryPartiallySynced, ...notifObj };
              case notificationEventsTypes.apiUserStoryPartiallySynced :
                this.parsedTraceData = JSON.parse(notifObj?.traceData);
                const counts = this.parsedTraceData.count;
                notifObj.notification = `<span class="fw-bold">Failed</span> to <span class="fw-bold">sync</span> ${counts.failedCount} User Story(ies) with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${this.parsedTraceData?.supportUri} target="_blank" style="color: #35c253;">support</a> for assistance.`;
                notificationConfigData.apiUserStoryPartiallySynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                notificationConfigData.apiUserStoryPartiallySynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.apiUserStoryPartiallySynced, ...notifObj };
              case notificationEventsTypes.autonomousCodeUserStoryPartiallySynced :
                  this.parsedTraceData = JSON.parse(notifObj?.traceData);
                  const cnt = this.parsedTraceData.count;
                  notifObj.notification = `<span class="fw-bold">Failed</span> to <span class="fw-bold">sync</span> ${cnt.failedCount} User Story(ies) with <span class="fw-bold">Autonomous Code</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${this.parsedTraceData?.supportUri} target="_blank" style="color: #35c253;">support</a> for assistance.`;
                  notificationConfigData.autonomousCodeUserStoryPartiallySynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                  notificationConfigData.autonomousCodeUserStoryPartiallySynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                  return { ...notificationConfigData.autonomousCodeUserStoryPartiallySynced, ...notifObj };
              case notificationEventsTypes.genAITaxonomySynced:
                   this.parsedTraceData = JSON.parse(notifObj?.traceData);
                   const sucsCount = this.parsedTraceData;
                    notifObj.notification = `<span class="fw-bold">${sucsCount.l1SuccessCount} L1(s), ${sucsCount.l2SuccessCount} L2(s), ${sucsCount.l3SuccessCount} L3(s) </span>and <span class="fw-bold"> ${sucsCount.l4SuccessCount} L4(s)</span> have been successfully synced with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>.`;
                    notificationConfigData.genAITaxonomySynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                    notificationConfigData.genAITaxonomySynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                    return { ...notificationConfigData.genAITaxonomySynced, ...notifObj };
              case notificationEventsTypes.genAITaxonomyNotSynced:
                this.parsedTraceData = JSON.parse(notifObj?.traceData);
                const failCount = this.parsedTraceData;
                notifObj.notification = `Failed to sync <span class="fw-bold"> ${failCount.l1FailedCount} L1(s), ${failCount.l2FailedCount} L2(s), ${failCount.l3FailedCount} L3(s) </span>and <span class="fw-bold"> ${failCount.l4FailedCount} L4(s)</span> to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${this.parsedTraceData?.supportUri} target="_blank" style="color: #35c253;">support</a> for assistance.`;
                notificationConfigData.genAITaxonomyNotSynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                notificationConfigData.genAITaxonomyNotSynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.genAITaxonomyNotSynced, ...notifObj };

              case notificationEventsTypes.genAITaxonomyPartiallySynced:
               this.parsedTraceData = JSON.parse(notifObj?.traceData);
                const patialfailCount = this.parsedTraceData;
                notifObj.notification = `Failed to sync <span class="fw-bold"> ${patialfailCount.l1FailedCount} L1(s), ${patialfailCount.l2FailedCount} L2(s), ${patialfailCount.l3FailedCount} L3(s) </span>and <span class="fw-bold"> ${patialfailCount.l4FailedCount} L4(s)</span> to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact <a href=${this.parsedTraceData?.supportUri} target="_blank" style="color: #35c253;">support</a> for assistance.`;
                notificationConfigData.genAITaxonomyPartiallySynced.redirectionUrlData.url = this.parsedTraceData?.sasUrl ;
                notificationConfigData.genAITaxonomyPartiallySynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.genAITaxonomyPartiallySynced, ...notifObj };

                default:
              break;
          }});

      this.notifications = [...this.notifications, ...data];

      }
    });
  }

  onScroll(event) {
    if (!event || !this.viewport || event === 0 || this.totalRecords < this.pageSize) {
      return;
    }
    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      // Reached the bottom, load more data
      if(this.pageNumber < this.totalPages) {
        this.pageNumber = this.pageNumber + 1;
        this.getAllNotificationsData(this.pageNumber, this.pageSize);
      }
    }
  }

  deleteAllNotifications() {
    const dialogRef = this.dialog.open(CommonDialogueBoxComponent, {
      data: {
        from: DELETE_ALL_NOTIFICATIONS,
        title: "Navigation.Menu.Notification.Dialog.DeleteTitle",
        deleteMessageText: "Navigation.Menu.Notification.Dialog.DeleteMessage",
        buttonText: this.translate.instant("Navigation.Menu.Notification.Dialog.Confirm"),
        buttonContinueClass: true,
        cancelBtn: 'Navigation.Menu.Notification.Dialog.Cancel',
        buttonCancelClass: true,
      },

      maxWidth: "40rem",
      width: "35rem",
      height: "12rem",
      panelClass: "custom-dialog-container",
    });

     dialogRef.afterClosed().subscribe(response => {
      if(response) {
        this.sysNotificationService_.deleteAllNotification().subscribe((resp) => {
          if(resp) {
            this.sysNotificationService_.setNotificationAction(true);
          }
        });
      }
    });
  }

  markAllAsRead() {
    if (this.isUnreadNotificationPresent) {
      this.sysNotificationService_.readAllNotifications().subscribe((res) => {
        if (res) {
          this.isUnreadNotificationPresent = false;
          this.pageNumber = 1;
          this.getAllNotificationsData(this.pageNumber,this.pageSize);
        }
      })
    }
  }

  ngOnDestroy() {
    this.pageNumber = 1;
    this.notifications = [];
  }
}
