import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SPRINT_COLORS, SPRINT_CAPACITY } from '@projects/projectsummary/src/app/core/utility/constants';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { DefineRoadmapService } from '../../../define-roadmap.service';

export interface Sprint {
  name: String;
  startDateOfSprint: String;
  endDateOfSprint: String;
  isSprintCompleted: Boolean;
  idealVelocity: number;
  minimumVelocity: number;
  sprintOrderNo: number;
  sprintId: String;
}
@Component({
  selector: 'app-edit-planned-capacity-popup',
  templateUrl: './edit-planned-capacity-popup.component.html',
  styleUrls: ['./edit-planned-capacity-popup.component.scss']
})

export class EditPlannedCapacityPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EditPlannedCapacityPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private defineRoadmapService: DefineRoadmapService) { }

  REGEX_ONLYNUMBER = /^\d+$/;
  isInvalidInput : boolean = true;
  showOverrunNote: boolean = false;
  showShortrunNote: boolean = false;
  minimumVelocityNote: boolean = false;
  errorMessage = '';
  checkValidInput = {};
  isTabClicked = true;
  isMouseClicked = true;
  sprintArrLength = 0;
  colors = SPRINT_COLORS;
  l1_obj:any;
  totalCapacity: number = 0;
  overrunPercentage: number;
  currentSprintMinVelocity: number = 0;
  sprints: Sprint[] = [];
  
  ngOnInit() {
    this.l1_obj = this.data.l1_obj;
    const rightMostPos = window.innerWidth - Number(this.data.left);
    this.dialogRef.updatePosition({ top: `${this.data.top + 40 }px`,
    right: `${rightMostPos}px`});
    this.getPlannedVelocity(this.data?.projectid, this.data?.l1);
  }

  getPlannedVelocity(projectId, l1Name) {
    this.defineRoadmapService.getPlannedVelocity(projectId, l1Name).subscribe(sprints => {
      this.sprints = sprints;
      this.sprintArrLength = this.sprints.length;
    })
  }

  closePopup() {
    this.dialogRef.close();
  }

  apply() {
      const sprintData = [];
      this.sprints.forEach(sprint => {
        sprintData.push({sprintId: sprint.sprintId, name: sprint.name,sprintOrderNo: sprint.sprintOrderNo,endDateOfSprint: sprint.endDateOfSprint, isSprintCompleted:sprint.isSprintCompleted,velocity: sprint.idealVelocity });
      });
      this.dialogRef.close(sprintData);
  }

  keyPress(event) {
    if (event.keyCode < 48 || event.keyCode > 57) { return false; }
  }

  keyDown(event, i) {
    if (event.keyCode === 9) {
      this.isTabClicked = true;
      this.isMouseClicked = false;
      if (i === (this.sprintArrLength - 1)) {
        this.isMouseClicked = true;
      }
    }
  }

  percentChange(sprint){
    this.totalCapacity = 0;
    this.sprints.forEach((sprint) => {
      this.totalCapacity = this.totalCapacity + Number(sprint?.idealVelocity);
    });
    if(Number(sprint?.idealVelocity) < sprint?.minimumVelocity) {
        this.isInvalidInput = true;
        this.minimumVelocityNote = true;
        this.showOverrunNote = false;
        this.showShortrunNote = false;
        this.currentSprintMinVelocity = sprint?.minimumVelocity;
     }else if( this.totalCapacity !== 100) {
        this.isInvalidInput = true;
        this.minimumVelocityNote = false;
        if(this.totalCapacity > 100) {
          this.overrunPercentage = this.totalCapacity - 100;
          this.showOverrunNote = true;
          this.showShortrunNote = false;
        } else {
          this.overrunPercentage = 100 - this.totalCapacity;
          this.showShortrunNote = true;
          this.showOverrunNote = false;
        }
     } else {
        this.isInvalidInput = false;
        this.showOverrunNote = false;
        this.showShortrunNote = false;
        this.minimumVelocityNote = false;
     }
  }

}
