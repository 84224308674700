// naming : export const SCREEN_variablename = '';
export const PROJECT_SUMMARY_TOOLTIP = 'You are currently in the role of an external User, so some functions and sections of the application will not be available to you.';
export const USER_INVITE_SUCCESS = 'User invited successfully';
export const USER_INVITE_FAILED = 'User already invited and is pending for Onboarding';
export const USER_ALREADY_ONBOARDED = 'Error: User already onboarded or Not invited yet';

//page names
export const PSG = 'PSG';
export const PROJECT_SUMMARY = 'Project Summary';
export const MY_PROJECTS = 'My Projects';

//Resource names
export const DOWNLOAD_SCOPE = 'Download Scope';
export const GENERATE_SCOPE = 'Generate Scope';
export const UPDATE_SCOPE = 'Update Scope';
export const RELEASE_PLANNING_DOWNLOAD = 'Release-Planning- Download';
export const NEXTGEN_EXTRACT = 'Download Scope- NextGen extract(.xlsx)';
export const DOWNLOAD_SCOPE_PROJ_DOC = 'Download Scope- Project document(.doc)';
export const PROJECT_TIMELINE_TILE = 'Project Timeline- Download';

// Common Regex
export const NAME_REGEX = /^[a-zA-Z ]*$/;
export const EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

//Unknown User
export const UNKNOWN = 'Unknown';
export const EXTERNAL = '(External)';
export const INTERNAL = 'internal';

export const EXTERNAL_USER_FEATURE_FLAG = 'externalUserFeatureFlag';

//No Data for donut chart
export const NO_DATA_STATUS = 'ProjectSummary.Tab.ProjectSummary.Tile.Common.Label.NoDataAvailableYet';

export const NO_DATA = [{
    "status": "",
    "counts": 1,
    "color": "#D1D3D6",
    "id": 1
}]

//project status for donut chart
export const NOT_STARTED = 'Global.Common.Label.NotStarted';
export const COMPLETED = 'ProjectSummary.Tab.ProjectSummary.Tile.ProjectStatus.Label.Status.Completed';
export const SKIPPED = 'ProjectSummary.Tab.ProjectSummary.Tile.ProjectStatus.Label.Status.Skipped';
//project chart
export interface PROJECT_CHART {
    status: string;
    counts: number;
    color: string;
    id?: number;
  }

  export interface PROJECT_STATUS {
    projectId: number;
    completedCount: number;
    notCompletedCount: number;
    skippedCount: number;
    count: number;
  }

  //color
  export const COMPLETED_COLOR = "#26890D";
  export const NOT_STARTED_COLOR = "#E5E6E8";
  export const SKIPPED_COLOR = "#00A3E0";

  
  export const CATEGORY= "status";
  export const VALUE= "counts";
  export const EXPECTED =  'ProjectSummary.Tab.ProjectSummary.Tile.Sprints.Label.Expected';
  export const PLANNED = 'ProjectSummary.Tab.ProjectSummary.Tile.Sprints.Label.Planned';

//user stories tile for donut chart
export const DONE = 'Global.Common.Label.Done';
export const IN_PROGRESS = 'Global.Common.Label.InProgress';

//color
export const DONE_COLOR = "#43B02A";
export const IN_PROGRESS_COLOR = "#FFCD00";
export const NOT_START_COLOR = "#E2E2E2";

export interface USER_STORIES {
  status: string;
  counts: number;
  color?: string;
  id?: number;
}