import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@projects/shared-lib/src/lib/services/base-http.service';
import { MessagingService } from './messaging.service';
import { BUS_MESSAGE_KEY } from '../constants/message-bus';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemNotificationService {
  private notificationData: BehaviorSubject<null>;
  private notificationAction: BehaviorSubject<boolean>;
  private isUnseenNotificationPresent: BehaviorSubject<boolean>;
  private isRedirectLinkClicked: BehaviorSubject<boolean>;
  userId: string;

  constructor(private http: HttpClient,
    private baseService: BaseService,
    private messagingBus: MessagingService) {
    this.notificationData = new BehaviorSubject(null);
    this.notificationAction = new BehaviorSubject(false);
    this.isUnseenNotificationPresent = new BehaviorSubject(false);
    this.isRedirectLinkClicked = new BehaviorSubject(false);
    this.messagingBus.subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
      this.userId = data.projectDetails.data.userId;
    })
  }

  setNotificationData(data) {
    return this.notificationData.next(data);
  }

  getNotificationData(): Observable<any> {
    return this.notificationData.asObservable();
  }

  setNotificationAction(data) {
    return this.notificationAction.next(data);
  }

  getNotificationAction(): Observable<any> {
    return this.notificationAction.asObservable();
  }

  setIsUnseenNotificationPresent(data) {
    return this.isUnseenNotificationPresent.next(data);
  }

  getIsUnseenNotificationPresent(): Observable<boolean> {
    return this.isUnseenNotificationPresent.asObservable();
  }

  getIsRedirectLinkClicked(): Observable<boolean> {
    return this.isRedirectLinkClicked.asObservable();
  }

  setIsRedirectLinkClicked(data) {
    return this.isRedirectLinkClicked.next(data);
  }

  getallNotifications(pageNumber, pageSize): Observable<any> {
    return this.http.get(environment.API_MICROSERVICE_URL?.NOTIFICATION + `/notifications/${this.userId}/${pageNumber}/${pageSize}`, {headers: new HttpHeaders({'skip': 'true'})});//
  }

  readAllNotifications(): Observable<any> {
    return this.http.put(environment.API_MICROSERVICE_URL?.NOTIFICATION + `/notifications/update/read/${this.userId}/all`, '');
  }

  readNotification(notificationId): Observable<any> {
    return this.http.put(environment.API_MICROSERVICE_URL?.NOTIFICATION + `/notifications/update/read/${notificationId}`, '');
  }

  seenNotifications(): Observable<any> {
    return this.http.put(environment.API_MICROSERVICE_URL?.NOTIFICATION + `/notifications/update/seen/${this.userId}/all`, '', {headers: new HttpHeaders({'skip': 'true'})});
  }

  deleteNotification(notificationId): Observable<any> {
    return this.http.delete(environment.API_MICROSERVICE_URL?.NOTIFICATION + `/notifications/${notificationId}`);
  }

  deleteAllNotification() {
    return this.http.delete(environment.API_MICROSERVICE_URL?.NOTIFICATION + `/notifications/${this.userId}/all`);
  }

  getProjectInfo(projectId, languageId=1, params?) {
    return this.http.get(environment.API_MICROSERVICE_URL?.PROJECT + `/project/` + projectId + `/${languageId}`, { params });
  }

  getUser(langId) : Observable<any>{
    return this.http.get(environment.API_MICROSERVICE_URL?.USER + `/user-management/` + this.userId + `?languageId=${langId}`);
  }

}
