import { BaseService } from '@projects/shared-lib/src/public-api';
import { options, Popup } from '@amcharts/amcharts4/core';
import { ElementRef, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogComponent } from '@projects/psg/src/app/shared/dialog/dialog.component';
import { AddToSprintPopupComponent } from '@projects/trainstops/src/app/feature/user-story-library/user-story-library-container/define-roadmap/sprint-generation/sprint-capacity/add-to-sprint-popup/add-to-sprint-popup.component';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { CMSFileType } from '../utility';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public defineRoadmapData = [];
  public updateTaxonomySuccessful: BehaviorSubject<boolean>;
  public updateUSSuccessful: BehaviorSubject<boolean>;
  public disableDefineRoadmapBtn: BehaviorSubject<boolean>;
  public downloadFileStatus: BehaviorSubject<any>;
  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private baseService: BaseService
  ) {
    this.updateTaxonomySuccessful = new BehaviorSubject(false);
    this.updateUSSuccessful = new BehaviorSubject(false);
    this.disableDefineRoadmapBtn = new BehaviorSubject(false);
    this.downloadFileStatus = new BehaviorSubject(null);

  }

  setUploadTaxanomyStatus(success: boolean) {
    this.updateTaxonomySuccessful.next(success);
  }

  setUploadUSStatus(success: boolean) {
    this.updateUSSuccessful.next(success);
  }

  public openDialog({ positionRelativeToElement,
    hasBackdrop = false,
    addToSprintData = {}, height = '400px', width = '500px' }:
    {
      positionRelativeToElement: ElementRef,
      hasBackdrop?: boolean,
      addToSprintData?: any,
      height?: string, width?: string
    }): MatDialogRef<AddToSprintPopupComponent> {

    const dialogRef: MatDialogRef<AddToSprintPopupComponent> =
      this.dialog.open(AddToSprintPopupComponent,
        {
          hasBackdrop: hasBackdrop,
          height: height,
          width: width,
          data: {
            positionRelativeToElement: positionRelativeToElement,
            addToSprintData: addToSprintData
          },

        })
    return dialogRef
  }

  isSprintPlanIdAvailable(tabData, tabName?) {
    if (tabData) {
      if (tabName) {
        this.defineRoadmapData = [];
        this.defineRoadmapData = tabData;
      }
      for (let i = 0; i < tabData.length; i++) {
        if (tabData[i] && tabData[i].sprint_plan) {
          let sprintArr = tabData[i].sprint_plan[0].sprint_plan_id;
          if (!sprintArr) {
            this.disableDefineRoadmapBtn.next(true);
            break;
          } else {
            this.disableDefineRoadmapBtn.next(false);
          }
        }
      }
    }
  }

  updateDefineRoadmapData(defineRoadmapL1Data) {
    for (let i = 0; i < this.defineRoadmapData.length; i++) {
      let obj = this.defineRoadmapData[i];
      if (obj.l1 == defineRoadmapL1Data.l1) {
        obj.sprint_plan = defineRoadmapL1Data.sprint_plan;
      }
    }
    this.isSprintPlanIdAvailable(this.defineRoadmapData);
  }

  downloadDocument(url: string, fileData: any) {
    return this.http.post(url, fileData, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  constructDocumentDownloadUrl(containerName?, cmsFileType?){
    let url:string = `${environment.API_MICROSERVICE_URL.CONTENT_MGMT}/File/Download`;

    url = url + `?containerName=${containerName ?? ''}&type=${cmsFileType ?? ''}`

    return url;
  }

  downloadDoc(doc_guid, fileName,  containerName?, cmsFileType?,deliverablesDto?) {
    const fileData = [];

    const extension = fileName.split(".")[(fileName.split(".").length - 1)];
    const guidName = `${doc_guid}.${extension}`
    fileData.push({
      "guidName": guidName,
      "projectId": deliverablesDto?.projectId,
      "packageId": deliverablesDto?.packageId,
      "functionId": deliverablesDto?.functionId,
      "activityId": deliverablesDto?.activityId,
      "stopId": deliverablesDto?.stopId,
      "deliverableId": deliverablesDto?.deliverableId,
      "deliverableName": deliverablesDto?.deliverableName,
      "phaseId": deliverablesDto?.phaseId,
      "constructId": deliverablesDto?.constructId,
      "downloadType": deliverablesDto?.downloadType
    });

    const url:string = this.constructDocumentDownloadUrl(containerName, cmsFileType);

    this.downloadDocument(url, fileData).subscribe(
      (data: any) => {
        const fileName = data.headers.get('content-disposition');
        const type = data.headers.get('content-type');
        const blob = new Blob([data.body], { type });
        saveAs(blob, fileName);
      },
      err => {
        console.log(err);
      });
  }

  downloadFile(downloadlink) {
    return this.http.get(`${downloadlink}`, {
     responseType: 'blob' as 'json',
     observe: 'response'
   }).subscribe(
     (data: any) => {
       const fileName = data.headers.get('content-disposition')
       .split(';')[1].split('filename')[1].split('=')[1].trim().split('"').join('');
       const type = data.headers.get('content-type');
       const blob = new Blob([data.body], { type });
       saveAs(blob, fileName);
     },
     err => {
       this.setDownloadFileStatus(err);
     });
  }

  downloadFileObservable(downloadlink) {
    return this.http.get(`${downloadlink}`, {
      responseType: 'blob' as 'json',
      observe: 'response'
    }).pipe( map(
      (data: any) => {
        const fileName = data.headers.get('content-disposition')
          .split(';')[1].split('filename')[1].split('=')[1].trim().split('"').join('');
        const type = data.headers.get('content-type');
        const blob = new Blob([data.body], { type });
        saveAs(blob, fileName);
      }
      )
    );
  }

  uploadFile(fileData) {
    let containerName = environment.CMS_CONTAINER_NAMES.DELIVERABLES;
    let cmsFileType = CMSFileType.Deliverable;
    let path = `File/Upload?containerName=${containerName}&type=${cmsFileType}`
    return this.baseService.uploadCMS(path, fileData)
  }
  setDownloadFileStatus(err: any) {
    this.downloadFileStatus.next(err);
  }

  getDownloadFileStatus() {
    return this.downloadFileStatus.asObservable();
  }

  getDocumentByGuid(fileData: any) {
    return this.http.post(`${environment.API_MICROSERVICE_URL.CONTENT_MGMT}/File/URIWithSAS`, fileData);
  }

  downloadErrorLog(templateUrl) {
    return this.http.get(templateUrl, {
      responseType: 'blob',
      observe: 'response'
    })
  }

}


