<div class="title">
    <span cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle><img class="drag-icon"  src="assets/userstories/drag-icon.svg"/></span>
    <span> {{'Global.Common.Label.MoveTo' | translate}} </span>
    <mat-icon [mat-dialog-close]="true" class="close-button">close</mat-icon>
</div>
<div class="sprint-scope">
  <ng-container  *ngFor="let sprint of sprintList"  >
   <div class="sprint-height">
    <button *ngIf="sprint?.sprint_name !== sprintData?.selectedSprint" mat-button class="sprint-button" (click)="addToSprint(sprint)">
      <span class="color-box" [ngStyle]="{'background-color':colors[sprint.sprint_order_no]}">{{sprint.sprint_order_no}}</span>
      <span> {{ sprint.sprint_name }} </span>
    </button>
    <button  mat-button *ngIf="sprint?.sprint_name === sprintData?.selectedSprint" class="selected-sprint" 
    [disabled]=true  #tooltip="matTooltip" 
    matTooltip="{{'MoveTo.Popup.Tooltip' | translate}}" 
    matTooltipPosition="above" matTooltipClass="current-sprint-tooltip">
      <span class="color-box" [ngStyle]="{'background-color':colors[sprint.sprint_order_no]}">{{sprint.sprint_order_no}}</span>
      <span> {{ sprint.sprint_name }} </span>
      <span class="current-badge"> {{'MoveTo.Popup.Badge' | translate}} </span>
    </button>
   </div>
  </ng-container>
</div>
<mat-divider style="margin-bottom: 8px"></mat-divider>
<div class="button-row">
    <button mat-raised-button class="btn-secondary-cancel" (click)="cancel()" > {{'Global.Common.Button.Cancel' | translate}} </button>
    <button mat-raised-button class="btn-primary-apply" (click)="apply()" [disabled] = "!sprintFlag"> {{'Global.Common.Button.Apply' | translate}} </button>
</div>

