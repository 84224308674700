<div class="market-solution-box" *ngIf="showDocInputSearch && searchDocumentData">
  <form [formGroup]="stateForm" >
    <mat-form-field class="formFieldClass"  floatLabel="never">
      <!--<mat-label>States Group</mat-label>-->
      <input type="text"
      id="searchInput"
      class="inputTextClass"
             matInput
             formControlName="stateGroup"
             [placeholder]="placeholderText | translate"
             [matAutocomplete]="autoGroup"
             #searchInput>
             <span id="text-highlighter" [innerHTML] = "highlightSearchCategory()"></span>
             <span class="pipe_search_count"></span>
             <span class="close-icon" (click)="removeDocSearchText()"></span>
      <!-- #docregion mat-autocomplete -->
      <mat-autocomplete #autoGroup="matAutocomplete" [displayWith]="displayText"  (closed)='closeAutocomplete($event)' (optionSelected)='getDocSelectedData($event.option)'>
        <mat-optgroup class="custom-search-box" *ngFor="let group of stateGroupOptions | async" [label]="group.title | translate">
          <span class="countClass">{{group.names.length}}</span>  
          <ng-container *ngFor="let name of group.names ; let i=index">
              <mat-option [value]="name" class="solutionOptions">
                <div class="solution-header" [innerHTML]="highlightSearchResult(name.optionName, searchInput.value)"></div>
                <div>
                  <span class="sub-header">{{name.activityName}}</span>
                  <span class="dot"></span>
                  <span class="sub-header">{{name.stopName}}</span>
                </div>
              </mat-option>  
            </ng-container>
  
        </mat-optgroup>
  
          <mat-option class="noData" [disabled]="true" *ngIf="(stateGroupOptions|async)?.length==0" [value]="noData">
            {{ noData | translate }}
          </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>