<div class="popup-container">
  <div class="header-container">
    <h4 class="header">{{data.primaryHeader | translate}}</h4>
  </div>
  <div class="body-container">
    <p class="body-para">{{data.bodyMessage | translate}}</p>
  </div>
  <mat-divider></mat-divider>
  <div class="footer-container">
    <mat-dialog-actions align="end">
      <button *ngIf="data?.secondaryButton" class="mat-raised-button ms-3 "  (click)="onDialogClose(false)">{{data.secondaryButton | translate}}</button>
      <button class="mat-raised-button ms-3 ok-button" (click)="onDialogClose(true)">{{data.primaryButton | translate}}</button>
    </mat-dialog-actions>
  </div>
</div>
