import { Injectable } from '@angular/core';
import { USERROLES } from '../utility/constants';
import { UserRoleAssociationDetails } from 'src/app/shared/model/project-global-info.model';
import { PassGlobalInfoService } from 'src/app/shared/services/pass-project-global-info.service';
import { MyProjectsService } from '@projects/workspace/src/app/my-projects/my-projects.service';
import { LANGUAGE_DATA } from '../../../../psg/src/app/core/utility/constants';

const SUPPER_USER_PERMISSIONS_MAP = {
  project: {
    approved: ['project_summary', 'manage_user', 'project_details', 'delete_project'],
    rejected: ['delete_project'],
    pending_approval: ['delete_project']
  }
}

const PROJECT_ADMIN_PERMISSIONS_MAP = {
  project: {
    approved: ['project_summary', 'manage_user', 'project_details', 'delete_project'],
    rejected: ['delete_project'],
    pending_approval: ['delete_project']
  }
}

const PROJECT_MEMBER_PERMISSIONS_MAP = {
  project: {
    approved: ['project_summary'],
    rejected: [],
    pending_approval: []
  }
}

const MENU = {
  USERS: 'Users',
  METHOD: 'Method',
  MARKET_SOLUTION: 'Market Solution',
  AMPLIFIERS: 'Amplifiers',
  MY_PROJECT: 'All Projects',
  TOOLS: 'Tools',
  AUDIT_LOGS: 'Audit Logs',
  TEST_CASES: 'Test Cases',
  DOCUMENT_REPOSITORY: 'Document Repository',
  USER_STORIES: 'User stories',
  BUSINESS_CASE: 'Business Case Management',
  TAXONOMY_DASHBOARD: 'Business Taxonomy',
  VELOCITY: 'Velocity',
  REPORTS: 'Reports',
  LANGUAGE_SETTINGS: 'Language Settings'
}
@Injectable({
  providedIn: "root",
})
export class UserPermissionService {
  userInfo: UserRoleAssociationDetails;
  masterUserRoledata: any;
  platformAdminRoleId: number = 0;
  supportAdminRoleId: number = 0;
  constructor(private globalData: PassGlobalInfoService,
    private myProjectsService : MyProjectsService) {
    globalData.share.subscribe(info => {
      this.userInfo = info.userRoleInfo
    })
    this.myProjectsService.getMasterUserRole(LANGUAGE_DATA.ENGLISH).subscribe((res) => {
      this.masterUserRoledata = res?.data;
    })
  }

  hasPermission(userType, entity, permission, options) {
    let entityPermissions;
    if (options.isAscendAdmin) {
      userType = 'SUPER_USER';
    }
    switch (userType) {
      case 'SUPER_USER':
        entityPermissions = SUPPER_USER_PERMISSIONS_MAP[entity];
        let permissions = [];
        if (entity === 'project') {
          permissions = [...entityPermissions[options.status.toLowerCase()]];
          if (options.status === 'APPROVED' && options.isPSGCompleted) {
            permissions.push('scope_generator');
          }
        }
        return permissions.includes(permission);

      case 'PROJECT_ADMIN':
        entityPermissions = PROJECT_ADMIN_PERMISSIONS_MAP[entity];
        let projectAdminPermissions = [];
        if (entity === 'project') {
          projectAdminPermissions = [...entityPermissions[options.status.toLowerCase()]];
          if (options.status === 'APPROVED' && options.isPSGCompleted) {
            projectAdminPermissions.push('scope_generator');
          }
        }
        return projectAdminPermissions.includes(permission);

      case 'PROJECT_MEMBER':
        entityPermissions = PROJECT_MEMBER_PERMISSIONS_MAP[entity];
        let projectMemberPermissions = [];
        if (entity === 'project') {
          projectMemberPermissions = [...entityPermissions[options.status.toLowerCase()]];
        }
        return projectMemberPermissions.includes(permission);

      default:
        return false;
    }
  }

  getMenu() {
    return MENU
  }

  canAccessMenu(menu: string) {
    this.masterUserRoledata?.forEach((ele) => {
      if (ele?.name?.toLowerCase() === USERROLES.PLATFORMADMIN) {
        this.platformAdminRoleId = ele?.id;
      }
      if (ele?.name?.toLowerCase() === USERROLES.SUPPORTADMIN) {
        this.supportAdminRoleId = ele?.id;
      }
    })
    if (this.userInfo) {
      if(menu === MENU.VELOCITY && (this.userInfo.role?.id ===  this.platformAdminRoleId || this.userInfo.role.isPackageAdmin)){
        return this.userInfo?.menu?.findIndex(m => m.menuName.toLowerCase() === menu.toLowerCase()) > -1;
      }
      else if (menu == MENU.USER_STORIES || menu == MENU.BUSINESS_CASE ) {
        if (this.userInfo.role.isPackageAdmin) {
          return this.userInfo?.menu?.findIndex(m => m.menuName.toLowerCase() === menu.toLowerCase()) > -1;
        } else {
          return false;
        }
      } 
      else if(menu === MENU.LANGUAGE_SETTINGS && (this.userInfo?.role?.name?.toLowerCase() === USERROLES.LANGUAGEADMIN || this.userInfo?.role?.name?.toLowerCase() === USERROLES.PLATFORMADMIN)) {
        return this.userInfo?.menu?.findIndex(m => m.menuName.toLowerCase() === menu.toLowerCase()) > -1;
      } 
      else {
        return this.userInfo?.menu?.findIndex(m => m.menuName.toLowerCase() === menu.toLowerCase()) > -1;
      }
    }
    return false;
  }

  canUserAccessObject(objectName: string, objectValue: string, permission: string, projectInfo?: any) {
    if (projectInfo && projectInfo?.accessObjects) {
      for(const accObj of projectInfo.accessObjects){
        if(accObj.objectName?.toLowerCase() === objectName?.toLowerCase() 
        && accObj.objectValue?.toLowerCase() === objectValue?.toLowerCase()
        && (accObj.permission?.toLowerCase() === permission?.toLowerCase() || 
        accObj.permission?.toLowerCase() === 'all')){
          return true
        }
      }
    }
    return false
  }

  // My Projects
  isMoreMenuButtonVisible(requiredPermissions: any, projectInfo: any) {
  if(projectInfo && projectInfo.accessObjects) {
    let result = false
    for(const obj of requiredPermissions){
      result = this.canUserAccessObject(obj.name,obj.value, obj.PERMISSION, projectInfo)
      if(true){
        break
      }
    }
    return result
  }
  }

  canUserAccess(objectName:string, objectValue:string, permissionRequired:string) : boolean {
    return this.userInfo?.accessObjects?.findIndex(obj => obj.attributeName?.toLowerCase() === objectName?.toLowerCase()
    && obj.objectValue?.toLowerCase() === objectValue.toLowerCase()
    && (obj.permission?.toLowerCase() === permissionRequired?.toLowerCase())) > -1
  }

  /*UserStory - 2855244 - Can be used to check a project has specific permission or not, if the access object of a project
  has some missing permissions object, will return false if there is no match for the permission*/ 
  isProjectPermissionNotAvailable(objectName:string, objectValue:string, permissionRequired:string, projectInfo: any) {
    if(projectInfo && projectInfo.accessObjects) {
      return projectInfo?.accessObjects?.findIndex(obj => obj.attributeName?.toLowerCase() === objectName?.toLowerCase()
      && obj.objectValue?.toLowerCase() === objectValue.toLowerCase()
      && (obj.permission?.toLowerCase() === permissionRequired?.toLowerCase())) === -1
    }
  }

}
