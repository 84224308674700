import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Router } from '@angular/router';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

    //readonly SCOPE = ["user.read"];
     SCOPE = [environment.AUTH_RESOURCE_LINK.webAPI];

    constructor(
      private msalAuthService: MsalService,
      private route: Router
    ) {}



  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!window.location.href.includes("logout") && !window.location.href.includes("client/survey")) {
      // if (!environment.isLocal && req.url.indexOf('graph.microsoft.com') == -1) {
      if (req.url.indexOf('graph.microsoft.com') == -1) {
        // console.log('In Intercept')
        let func = (environment.isLocal) ? 'readAuthToken' : 'getTokenFromMSAL';

        return fromPromise(
          this[func](req.url)
            .then(function (token) {
              if (token != 'logout-token') {
                var JWT = "Bearer " + token;
                // console.log(req.headers)
                const cookieheaderName = 'X-XSRF-TOKEN';

                return req.clone({
                  setHeaders: {
                    Authorization: JWT,
                    [cookieheaderName]: JWT
                  },
                });
              }
              else {
                this.router.navigate(['/logout']);
              }
            })
        ).pipe(
          mergeMap(
            (req: any) => {
              // console.log('In Merge Map')
              return next.handle(req).pipe(
  
                catchError((err) => {
                  // console.log(err)
                  if (err instanceof HttpErrorResponse && err.status == 401) {
                    console.error('ACTION TODO: SHOW A GENERIC 401 MODAL');
                  }
                  return throwError(err);
                })
              )
            }
          )
        )

      } else {
        return next.handle(req).pipe(
          catchError((err) => {
            return throwError(err);
          })
        )
      }
    } else if(window.location.href.includes("client/survey")){
      return next.handle(req);
    }
    }


    private async readAuthToken(url): Promise<any> {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem('auth-token');
        resolve(token);
      })
    }

    private async getTokenFromMSAL(url): Promise<any> {
        return new Promise(async (resolve, reject) => {
          console.log('In getTokenFromMSAL')
          if(url.indexOf('/logout') > -1){
              resolve('logout-token');
          }
          if(url.indexOf('graph.microsoft.com')>-1)
              this.SCOPE = ["user.read"];
          const account = await this.msalAuthService.instance.getActiveAccount()
          if (account) {
            var silentRequest = {
                scopes: this.SCOPE,
                account: account,
                forceRefresh: false
            };

            var request = {
                scopes: this.SCOPE,
                loginHint: account.username // For v1 endpoints, use upn from idToken claims
            };

            await  this.msalAuthService.instance.acquireTokenSilent(silentRequest)
            .then(token => {
                resolve(token.accessToken);
            },
            err => {
                // could also check if err instance of InteractionRequiredAuthError if you can import the class.
                console.log('error details ' + err.name);
                // this.msalAuthService.acquireTokenPopup({scopes:this.SCOPE})
                if (err.name === "InteractionRequiredAuthError") {
                    this.msalAuthService.acquireTokenPopup(request)
                    .subscribe(token => {
                        // get access token from response
                        resolve(token.accessToken);
                    },
                    err => {
                        console.error( `401 - Couldn't fetch access token!`);
                        reject('401');
                    });
                }
            });
          } else {
            console.error('401- Could not fetch access-token, login the user')
            reject('401')
          }
          
        }) 
    }
}
