export interface CommonDialogueBoxData {
  from: string;
  message?: string;
  userId?:string;
  errors?: { [key: string]: string };
  title?: any;
  contentButtonText?: any;
  content?: any[];
  messageItems?: any[];
  messageText?:string;
  deleteMessageText?:string;
  downloadMessageText?:string;
  provisionMessage?:string;
  provisionMessage2?:string;
  tool?:string;
  activePackages?:any[];
  activeFunctions?:any[];
  activeOffering?: any[];
  projectPackage?:any;
  selectedOffering?: any;
  selectedPackage?: any;
  selectedFunctions?:any[];
  userPrefOffering?: any;
  userPrefPackage?: any;
  userPrefFunction?:any[];
  projectId?:any;
  //userId?:any;
  deleteUserId?:any;
  projectName?:any;
  tabChange? : any;
  deleteAdminId?:any;
  isMultipleDelete?: boolean
  identifier?: any;
  apiURL: any;
}

export const EC_TITLE = 'Activities.ToolsProvision.DeliveryTools.ECProvisionPopup.Title';
export const TestAI_TITLE = 'Activities.ToolsProvision.DeliveryTools.TestAIProvisionPopup.Title';
export const SO_TITLE = 'Activities.ToolsProvision.DeliveryTools.SOProvisionPopup.Title';
export const DELETE_MESSAGE = 'Are you sure you want to delete all notifications?';
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS' ;
export const DELETE_TITLE = 'Delete Notifications';
export const CONFIRM = 'Confirm';
export const CANCEL = 'Cancel';

export const validationMessages = {
  noOfferings: 'Select project offerings to download reports',
  noStatuses: 'Select project statuses to download reports',
  noDate: 'Select project date period to download reports'
};
export const DOWNLOAD_REPORTS = "DOWNLOAD_REPORTS";
export const REPORT_GENERATE_TOOLTIP = "Report is being generated. If you want to generate another report, please cancel current generation or wait until the current report is downloaded.";
export const REPORT_DEFAULT_MIN_DATE = new Date('01/01/2000');
export const REPORT_DEFAULT_MAX_DATE = new Date();
export const SELECT_TYPE_STATUS = "STATUS";
export const REPORT_GENERATION_STATUS = {
  NEW_REPORT: 'New',
  CANCEL_REPORT: 'Cancelled',
  RETRY: 'Retry'
}
export const REPORT_STATUS_TYPE = {
  NEW: "New",
  IN_PROGRESS: "In Progress",
  READY_FOR_DOWNLAOD: "Ready for Download",
  ERROR: "Error",
  DOWNLOADED: "Downloaded",
  CANCELED: "Cancelled"
}
