export const ACCESS_OBJECTS = {
  PROJECT : 'PROJECT',
  TOOL : 'TOOLS',
  FILTER : 'FILTER',
  PACKAGE: 'PACKAGE'
}

export const Tools = {
  GenAI : 'GenAI',
  Traditional: 'Traditional'
}

export const PERMISSIONS = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW', //project_summary
  REQUEST: 'REQUEST', //submit and resubmit request
  CREATE: 'CREATE',
  ACCESS: 'ACCESS', // edit
  DELETE: 'DELETE',
  APPROVE: 'APPROVE',
  RESUBMIT: 'RESUBMIT',
  MANAGE_USER: 'MANAGE USER',
  PROJECT_SUMMARY: 'PROJECT SUMMARY',
  SCOPE_GENERATOR: 'SCOPE GENERATOR',
  ALL: 'all',
  MORE_BUTTON: [
  {name: 'PROJECT', value : 'MANAGE USER', PERMISSION: 'all'},
  {name: 'PROJECT', value : 'PROJECT SUMMARY', PERMISSION: 'all'},
  {name: 'PROJECT', value : 'PROJECT DETAILS', PERMISSION: 'all'},
  {name: 'PROJECT', value : 'SCOPE GENERATOR', PERMISSION: 'all'},
  {name: 'PROJECT', value : 'DELETE', PERMISSION: 'DELETE'}
  ]
}

export const OBJECT_VALUES = {
PROJECT: 'PROJECT',
MANAGE_USER: 'MANAGE USER',
PROJECT_SUMMARY: 'PROJECT SUMMARY',
SCOPE_GENERATOR: 'SCOPE GENERATOR',
PROVISIONING: 'PROVISIONING',
PROJECT_DETAILS: 'PROJECT DETAILS',
SETTINGS: 'SETTINGS',
TOOL_SETTINGS : 'Tool Settings'
}

export const TOOLS_TYPE_TITLE = {
  TRADITIONAL_TOOLS:'ToolsBody.Header.Title.DoNotRequireSettings'
};

export const TOOL_LABELS = {
  NOT_ACTIVATED:'Global.Common.Label.NotActivated',
  NOT_SELECTED:'Global.Common.Label.NotSelected'
}

export const PROJECT_STATUS = {
 APPROVED : 'APPROVED',
 REJECTED : 'REJECTED',
 PENDING_APPROVAL : 'PENDING_APPROVAL',
 DRAFT: 'DRAFT',
 EXPIRED: 'EXPIRED',
 PSG_COMPLETED: 'PSG_COMPLETED'
}

export const VIEW_TYPE = {
  CARD_VIEW: 'CARD_VIEW',
  GRID_VIEW: 'GRID_VIEW'
}

export const USERROLES = {
PLATFORMADMIN: 'platform admin',
METHODADMIN: 'methodology admin',
METHODLEAD: 'methodology lead',
PACKAGEADMIN: 'package admin',
PACKAGELEAD: 'package lead',
PROJECTADMIN: 'project admin',
LANGUAGEADMIN: 'language admin',
SUPPORTADMIN: 'support admin',
PROJECTUSER: 'project user',
EXTERNALUSER: 'external user',
}

export const WORKPRODUCT = 'Deliverables and Work Products';
export const BASEDELIVERABLE = 'Base Deliverables and Work Products';
export const PACKAGEDELIVERABLE = 'Package Deliverables and Work Products';

export const AMPLIFIER = 'Amplifier';
export const AMPLIFIERS = 'Amplifiers';


export const CMSFileType = {
Deliverable: 'Deliverable',
Utility: 'Util'
}

export const ActivityLvlColor = {
extension: '#E3E48D',
core: '#9DD4CF',
foundational: '#A0DCFF'
};

export const PhaseColor = {
Prepare: '#005587',
Imagine: '#0097A9',
Deliver: '#BAC700',
Run: '#26890D'
}

export const PACKAGE = 'Package';

export const METHOD = 'Method';
export const EXPIRED_PROJECT_TOOLTIP = 'ReviewProject.ProjectDetails.Tooltip.Expired';

export const SESSION_STORAGE_ROUTE_KEY = 'initalRoute';
export const DOWNLOAD_METHOD_TOOLTIP = 'Download Method and Resources';
export const CUSTOM_DELIVERABLES = 'Custom Deliverables and Work Products';

export const NAVIGATOR_SCREEN_DETAILS = [
  {name: 'Project Dashboard', path : '/projectsummary/', translatedName: 'MyProject.Map.ProjectDashboard.Text'},
  {name: 'Project Methodology', path : '/iidr', translatedName: 'MyProject.Map.ProjectMethodology.Text'},
  {name: 'Projects Setup', path : '/psg/general-details/engagement/project/', translatedName: 'MyProject.Map.ProjectSetup.Text'}, 
  {name: 'Project Site Map', path: '/sitemap/', translatedName: 'MyProject.Map.ProjectSiteMap.Text'},
  {name: 'Project Documents', path: '/document-repository/', translatedName: 'MyProject.Map.ProjectDocuments.Text'},
  {name: 'Current Activity', path: '', translatedName: 'MyProject.Map.CurrentActivity.Text'},
];

export const NAVIGATOR_THEME = ['dark', 'light'];

export const MAX_LIMIT_PROJECT_PINNING = 8;
export const MAX_COUNT_OF_PINNING_CONTACTS_TOOLTIPTEXT = 'Max count of pinned contacts is 8';
export const EVENT_TYPE = {
  PIN: 'PIN',
  UNPIN: 'UNPIN'
}