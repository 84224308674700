import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpServiceHelper {

  httpOptions: any = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      ...(environment.isLocal) && {"Authorization": "Bearer " + localStorage.getItem('auth-token')},
    },
    )
  };

  constructor(private http: HttpClient) {
  }



  public httpGetRequestWithParams(url: string, param: HttpParams) {
    return this.http.get(url, { ...this.httpOptions, params: param })
      .map(response => {
        return response;
      })
      .catch(response => (Observable.throw(response)
      ));
  }

  public httpGetRequest(url: string) {
    return this.http.get(url)
      .map(response => {
        return response;
      })
      .catch(response => (Observable.throw(response)
      ))
  }

}
