import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http2ServerRequest } from 'http2';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FinalizeSprintComponent } from './sprint-generation/finalize-sprint/finalize-sprint.component';
import { SprintCapacityComponent } from './sprint-generation/sprint-capacity/sprint-capacity.component';
import { SprintPlanComponent } from './sprint-generation/sprint-plan/sprint-plan.component';
import { DEFINEROADMAP_STEPS } from '@projects/trainstops/src/app/core/utility/constants';
import { MoscoPrioritizationComponent } from './sprint-generation/mosco-prioritization/mosco-prioritization.component';

export interface ISprintGenerationL1 {
  parentIndex: number;
  childIndex: number;
  direction?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DefineRoadmapService {
  private selectedL1: BehaviorSubject<any>;
  private enableGenerate: BehaviorSubject<any>;
  private sprintCapacityL1Confirm: BehaviorSubject<boolean>;
  private generated: BehaviorSubject<any>;
  private finalizeData: BehaviorSubject<any>;
  private acknowledgeFinalizeSprintData: BehaviorSubject<any>;
  private isSyncAllL1Applied: BehaviorSubject<any>;
  private switchParentStep: BehaviorSubject<any>;
  private switchCurrentStep: BehaviorSubject<any>;
  private l1InitialData: BehaviorSubject<any>;
  private sprintFilterBy: BehaviorSubject<any>;
  private isAtleastOneL1Finalized: BehaviorSubject<any>;
  public confirmedCountData: BehaviorSubject<any>;
  public finalizedL1CountData: BehaviorSubject<any>;
  public confirmedSprintCapacityL1Count: BehaviorSubject<any>;
  public l1List: BehaviorSubject<any>;
  public sprintGenerationStatus: BehaviorSubject<boolean>;
  public isAllL1Finalized: BehaviorSubject<boolean>;
  public l1StatuesData: BehaviorSubject<any>;
  public isAnyChangesInGSPFlyout: BehaviorSubject<any>;
  public isL1ListEmpty: BehaviorSubject<boolean>;
  public sprintPlanReset:BehaviorSubject<any>;

  capacity_data: BehaviorSubject<any>;
  public finalizedL1Names: BehaviorSubject<string>;

  sprintStepper=[
    {
      title: DEFINEROADMAP_STEPS.MOSCOW_PRIORITIZATION,
      component:MoscoPrioritizationComponent,
      stepDisplay:true,
      children:[]
    },
    {
      title: DEFINEROADMAP_STEPS.SPRINT_PLAN,
      component:SprintPlanComponent,
      stepDisplay:true,
      children:[]
    },
    {
      title: DEFINEROADMAP_STEPS.SPRINT_CAPACITY ,
      component:SprintCapacityComponent,
      stepDisplay:true,
      children:[]
    },
    {
      title: DEFINEROADMAP_STEPS.FINALIZE_SPRINT ,
      component:FinalizeSprintComponent,
      stepDisplay:true,
      children:[]
    },
    {
      title:"Invisible Step",
      stepDisplay:true,
      children:[]
    }
  ];
  isJiraProject;

  isMoscowApplied;

  constructor(private http: HttpClient) {
    this.selectedL1=new BehaviorSubject(null);
    this.switchParentStep=new BehaviorSubject(null);
    this.switchCurrentStep=new BehaviorSubject(null);
    this.enableGenerate = new BehaviorSubject(false);
    this.generated = new BehaviorSubject(false);
    this.finalizeData = new BehaviorSubject(false);
    this.acknowledgeFinalizeSprintData = new BehaviorSubject(false);
    this.isSyncAllL1Applied = new BehaviorSubject(null);
    this.sprintCapacityL1Confirm = new BehaviorSubject(false);
    this.l1InitialData = new BehaviorSubject(null);
    this.isAtleastOneL1Finalized = new BehaviorSubject(false);
    this.sprintFilterBy = new BehaviorSubject(null);
    this.confirmedCountData = new BehaviorSubject(0);
    this.confirmedSprintCapacityL1Count = new BehaviorSubject(0);
    this.finalizedL1CountData = new BehaviorSubject(0);
    this.l1List = new BehaviorSubject(null);
    this.sprintGenerationStatus = new BehaviorSubject(false);
    this.isAllL1Finalized=new BehaviorSubject(false);
    this.finalizedL1Names = new BehaviorSubject('');
    this.l1StatuesData = new BehaviorSubject(null);
    this.isAnyChangesInGSPFlyout=new BehaviorSubject(false);
    this.isL1ListEmpty = new BehaviorSubject(false);
    this.sprintPlanReset= new BehaviorSubject({reset:false});
  }

  getSprintGenerationStatus(){
    return this.sprintGenerationStatus.asObservable();
  }

  setSprintGenerationStatus(flag){
    this.sprintGenerationStatus.next(flag);
  }
  
  getSprintPlanReset(){
    return this.sprintPlanReset.asObservable();
  }
  setSprintPlanReset(resetObj){
    this.sprintPlanReset.next(resetObj);
  }

  setIsAllL1Finalized(isAllL1Finalized){
    this.isAllL1Finalized.next(isAllL1Finalized)
  }

  getIsAllL1Finalized(){
    return this.isAllL1Finalized.asObservable()
  }
  setIsAnyChangesInGSPFlyout(flag){
    this.isAnyChangesInGSPFlyout.next(flag)
  }
  getIsAnyChangesInGSPFlyout(){
    return this.isAnyChangesInGSPFlyout
  }

  setL1StatuesData(data){
    this.l1StatuesData.next(data)
  }

  getL1StatuesData(){
    return this.l1StatuesData.asObservable()
  }

  getMoscowData(projectId: number){
    return this.http.get<any>(`${environment.API_MICROSERVICE_URL.PROJECT}/sprint-planning/sprint-generation/priority/moscow/${projectId}`);
  }

  saveMoscowData(projectId,data: any){
     let url = environment.API_MICROSERVICE_URL.PROJECT +`/sprint-planning/sprint-generation/priority/moscow/${projectId}`;
     return this.http.put(url, data);
  }

  getTestAI(project_id: number){
    let testAIAPI = `${environment.API_BASE_URL}/process/start/TestDesignerActivity?projectid=${project_id}`;
    return this.http.post(testAIAPI, {});
 }

  isExportToJira(project_id: number, taxonomyId?){
    return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/sprints/isReadyToExport/${project_id}`);
  }

  getNonJiraDetails(package_id:number,function_id:number,activity_id:number,project_id:number, taxonomyId?) {
    let taxonomyIdValue =  taxonomyId;
      return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/plans/${package_id}/${function_id}/${activity_id}/${project_id}/0/${taxonomyIdValue}`);
  }

  getJiraDetails(package_id:number,function_id:number,activity_id:number,project_id:number, taxonomyId?) {
      let taxonomyIdValue =  taxonomyId;
      return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/plans/${package_id}/${function_id}/${activity_id}/${project_id}/1/${taxonomyIdValue}`);
  }

  setTaxonomyId(projectId, taxonomyId?) {
    return ( projectId==0 ) ? taxonomyId : 0;
  }

  getProjectInfo(projectId:any, params?:any) {
    return this.http.get(environment.API_MICROSERVICE_URL.PROJECT +'/project/' + projectId , {params});
  }

  getSelectedL1(): Observable<ISprintGenerationL1> {
    return this.selectedL1.asObservable();
  }

  setSelectedL1(l1: ISprintGenerationL1) {
    this.selectedL1.next(l1);
  }

  getInitialDataL1(): Observable<ISprintGenerationL1>  {
    return this.l1InitialData.asObservable();
  }

  setInitialDataL1(l1: any) {
    this.l1InitialData.next(l1);
  }

  getSwitchParent(): Observable<ISprintGenerationL1> {
    return this.switchParentStep.asObservable();
  }

  setSwitchParent(l1: ISprintGenerationL1) {
    this.switchParentStep.next(l1);
  }

  getUpdateStepper() {
    return this.switchCurrentStep.asObservable();
  }

  setUpdateStepper(l1:any) {
    this.switchCurrentStep.next(l1);
  }

  getenableGenerate(): Observable<any> {
    return this.enableGenerate.asObservable();
  }

  setenableGenerate(data: boolean) {
    this.enableGenerate.next(data);
  }

  getGenerated(): Observable<any> {
    return this.generated.asObservable();
  }

  setGenerated(data: boolean) {
    this.generated.next(data);
  }

  getFinalizeSprintData(): Observable<any> {
    return this.finalizeData.asObservable();
  }

  setFinalizeSprint(data: any) {
    this.finalizeData.next(data);
  }

  getConfirmedCountData(): Observable<any> {
    return this.confirmedCountData.asObservable();
  }

  setConfirmedCountData(data) {
    this.confirmedCountData.next(data);
  }

  getconfirmedSprintCapacityL1Count(): Observable<any> {
    return this.confirmedSprintCapacityL1Count.asObservable();
  }

  setconfirmedSprintCapacityL1Count(data) {
    this.confirmedSprintCapacityL1Count.next(data);
  }

  getFinalizedL1CountData(): Observable<any> {
    return this.finalizedL1CountData.asObservable();
  }

  setFinalizedL1CountData(data) {
    this.finalizedL1CountData.next(data);
  }

  getFinalizedL1Names(): Observable<any> {
    return this.finalizedL1Names.asObservable();
  }
  setFinalizedL1Names(data) {
    this.finalizedL1Names.next(data);
  }

  getIsL1ListEmpty(): Observable<any> {
    return this.isL1ListEmpty.asObservable();
  }
  setIsL1ListEmpty(data) {
    this.isL1ListEmpty.next(data);
  }

  getacknowledgeFinalizeSprintData(): Observable<any> {
    return this.acknowledgeFinalizeSprintData.asObservable();
  }

  setacknowledgeFinalizeSprintData(data: boolean) {
    this.acknowledgeFinalizeSprintData.next(data);
  }

  getIsSyncAllL1Applied(): Observable<any> {
    return this.isSyncAllL1Applied.asObservable();
  }

  setIsSyncAllL1Applied(data: boolean) {
    this.isSyncAllL1Applied.next(data);
  }

  getsprintCapacityL1Confirm(): Observable<any> {
    return this.sprintCapacityL1Confirm.asObservable();
  }

  setsprintCapacityL1Confirm(data: boolean) {
    this.sprintCapacityL1Confirm.next(data);
  }

  getSprintPlan(package_id:number,function_id:number,activity_id:number,project_id:number,taxonomyId?)
  {
    let taxonomyIdValue = taxonomyId;
      return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/plans/${package_id}/${function_id}/${activity_id}/${project_id}/${this.isJiraProject}/${taxonomyIdValue}`);
  }

  getSprintCapacity(package_id:number,function_id:number,activity_id:number,project_id:number, taxonomyId?, l1Id?, languageId?): Observable<any>
  {
    let listL1 = l1Id;
    if(project_id!=0)
      return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-capacity/sprints/calculation/${project_id}?l1Id=${listL1}&functionId=${function_id}&activityId=${activity_id}&isJiraProject=${this.isJiraProject}&languageId=${languageId}`);
    else
      return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-capacity/sprints/calculation/${project_id}?l1Id=${listL1}&functionId=${function_id}&activityId=${activity_id}&isJiraProject=${this.isJiraProject}&packageId=${package_id}&industryPrintId=${taxonomyId}&languageId=${languageId}`);
  }

  generateL1(project_id,payload){
    return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-capacity/sprints/calculation/save/${project_id}`, payload);
  }

  saveSprintCapacity_l1_specific(package_id:number,function_id:number,activity_id:number, project_id: number, body) {
    return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/capacity/save/${package_id}/${function_id}/${activity_id}/${project_id}/${this.isJiraProject}`, body);
  }

  saveAndConfirmSprintCapacity_l1_specific(package_id:number,function_id:number,activity_id:number, project_id: number, body) {
    return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/capacity/confirm/${package_id}/${function_id}/${activity_id}/${project_id}/${this.isJiraProject}`, body);
  }

  getDailyVelocity(projectId,packageId,industryPrintId){
    if(projectId !== 0) {
      return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/release/project/velocity/${projectId}`);
    } else {
    return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/release/project/velocity/${projectId}?packageId=${packageId}&industryPrintId=${industryPrintId}`);
    }
  }

  getFinalizeSprint(package_id:number,function_id:number,activity_id:number,project_id:number,industryPrintId?:number){
      return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/sprints/finalize/${package_id}/${function_id}/${activity_id}/${industryPrintId}/${project_id}/${this.isJiraProject}`);
  }

  updateIsGenerate(projectId, body) {
    return this.http.put(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/plans/generate/${projectId}`, body)
  }

  downloadSprint(projectId) {
    return this.http.get( environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/sprints/download/${projectId}`)
  }

  updateIsConsolidated(projectId, body) {
    return this.http.put(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/sprints/consolidate/${projectId}`, body)
  }

  saveSprintPlan(package_id:number,function_id:number,activity_id:number,project_id:number,payload:any)
  {
    return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/plans/save/${package_id}/${function_id}/${activity_id}/${project_id}/${this.isJiraProject}`,payload);
  }

  resetSprintPlan(package_id:number,function_id:number,activity_id:number,project_id:number,payload:any)
  {
    return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/plans/reset/${package_id}/${function_id}/${activity_id}/${project_id}/${this.isJiraProject}`,payload);
  }

  resetSprintCapacity(project_id:number,payload:any){
    return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/capacity/reset/${project_id}`,payload);
  }

  updateFinalizeSprintdetails(projectId, data) {
    return this.http.put(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/sprints/finalize/save/${projectId}`, data);
  }

  syncAllL1(package_id:number,function_id:number,activity_id:number,project_id:number,payload:any)
  {
    return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT+ `/sprint-planning/sprint-generation/plans/applyalltracks/${package_id}/${function_id}/${activity_id}/${project_id}/${this.isJiraProject}`,payload);
  }

  abortSyncAllL1(project_id:number,payload:any)
  {
    return this.http.put<any>(environment.API_MICROSERVICE_URL.PROJECT+ `/sprint-planning/sprint-generation/plans/abortsynchronization/${project_id}`,payload);
  }

  exportToJira(projectId, packageId, functionId, activityId, userroleid, payload){
    let exportToJiraURL = `${environment.API_MICROSERVICE_URL.PROJECT}/process/schedule/ExportToJiraActivity?projectid=${projectId}&packageid=${packageId}&functionid=${functionId}&activityid=${activityId}&userroleid=${userroleid}`;
    return this.http.post(exportToJiraURL, payload)
  }

  public getSatusForInstance(instanceId): Observable<any> {
    return this.http.get<any>(`${environment.API_MICROSERVICE_URL.PROJECT}/process/status/` + instanceId);
  }

  public updateLastSeenStatus(instanceId, projectId): Observable<any> {
    return this.http.patch<any>(`${environment.API_MICROSERVICE_URL.PROJECT}/process/status/` + instanceId, {processName:"ExportToJiraActivity", projectId:projectId});
  }

  public completeCR(projectId): Observable<any> {
    return this.http.put(`${environment.API_MICROSERVICE_URL.PROJECT}/psg/scope_change_request/${projectId}`,{});
  }

  setSprintFilter(data: string) {
    this.sprintFilterBy.next(data);
  }

  getSprintFilter(): Observable<any> {
    return this.sprintFilterBy.asObservable();
  }

  setAtleastOneL1Finalized(data: boolean) {
    this.isAtleastOneL1Finalized.next(data);
  }

  getAtleastOneL1Finalized(): Observable<any> {
    return this.isAtleastOneL1Finalized.asObservable();
  }

  public getL1AfterEditVelocity(data): Observable<any> {
    return this.http.get<any>(`${environment.API_MICROSERVICE_URL.PROJECT}/sprint-planning/sprint-capacity/sprints/calculation/` + data?.projectId +'?l1Id=' + data?.l1Id + '&functionId=' + data?.functionId + '&activityId=' + data?.activityId + '&isJiraProject=' + data?.isJiraProject + '&languageId=' + data?.languageId);
  }


  setL1List(list) {
    this.l1List.next(list);
  }

  getL1List(): Observable<any> {
    return this.l1List.asObservable();
  }

  getPlannedVelocity(projectId, l1Name){
    return this.http.get<any>(environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/release/velocity/?projectId=`+ projectId + '&l1Name=' + l1Name);
  }

  downloadFile(downloadlink) {
		return this.http.get(`${downloadlink}`, {
			responseType: 'blob' as 'json',
			observe: 'response'
		});
	}

  downloadTaxonomyChanges(projectId){
    const url = environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/nonJiraUpdateDescope/download/${projectId}`;
    return url;
  }
}
