import { Injectable } from '@angular/core'
import { BehaviorSubject, forkJoin } from 'rxjs'
import { ProjectGlobalInfoModel, UserRoleAssociationDetails } from 'src/app/shared/model/project-global-info.model'
import { AdminManagementService } from './admin-management.service'
import { SharedService } from 'src/app/shared/services/shared.service';
import { LANGUAGE_DATA } from '../../../../../psg/src/app/core/utility/constants';
import { MyProjectsService } from '@projects/workspace/src/app/my-projects/my-projects.service';

@Injectable({
    providedIn: 'root'
})
export class UsersDataSharedService {
    filterChanged = new BehaviorSubject<any>(null)
    usersListUpdate = new BehaviorSubject<any>(null)
    refreshList = new BehaviorSubject<any>(null)
    private _userRoleInfo: UserRoleAssociationDetails;
    languageId: number;
    masterUserRoledata: any = [];
    constructor(private adminManagementService: AdminManagementService,
        private sharedService: SharedService,     private myProjectsService: MyProjectsService) {
        this.sharedService.getCurrentLanguage().subscribe((res) => {
            this.languageId = res;
        })
         }

    set(data) {
        this.usersListUpdate.next(data)
    }

    setRefreshList(data) {
        this.refreshList.next(data)
    }

    updateUserRoleAssociation(projectGlobalInfo: ProjectGlobalInfoModel, userId, globalData, shouldFetch?) {
        if (this._userRoleInfo && this._userRoleInfo.userEmail === userId && !shouldFetch) {
            projectGlobalInfo.userRoleInfo = this._userRoleInfo
            globalData.updateGlobalData(projectGlobalInfo);
        } else {
            //refresh on language change
            this.sharedService.getUpdatedLangId().subscribe((data) => {
                if (data) {
                    this.languageId = data;
                    forkJoin({ response: this.adminManagementService.getUser(userId, this.languageId), result: this.myProjectsService.getMasterUserRole(LANGUAGE_DATA.ENGLISH) }).subscribe(({ response, result }) => {
                        this.masterUserRoledata = result?.data;
                        this._userRoleInfo = {
                            userEmail: response.userEmail,
                            role: response?.role?.length && response?.role[0],
                            menu: response.menu,
                            package: response?.package?.length && response?.package,
                            roleSubset: response?.roleSubset,
                            accessObjects: response?.accessObjects,
                            englishLanguage: this.masterUserRoledata.find(ele => {
                                return ele?.id === response?.role?.[0]?.id
                            })
                        }
                        if (projectGlobalInfo) {
                            projectGlobalInfo.userRoleInfo = this._userRoleInfo
                            globalData.updateGlobalData(projectGlobalInfo);
                        }
                    });
                }
            });
            forkJoin({ response: this.adminManagementService.getUser(userId, this.languageId), result: this.myProjectsService.getMasterUserRole(LANGUAGE_DATA.ENGLISH) }).subscribe(({ response, result }) => {
                this.masterUserRoledata = result?.data;
                this._userRoleInfo = {
                    userEmail: response.userEmail,
                    role: response?.role?.length && response?.role[0],
                    menu: response.menu,
                    package: response?.package?.length && response?.package,
                    roleSubset: response?.roleSubset,
                    accessObjects: response?.accessObjects,
                    englishLanguage: this.masterUserRoledata.find(ele => {
                        return ele?.id === response?.role?.[0]?.id
                    })
                }
                if (projectGlobalInfo) {
                    projectGlobalInfo.userRoleInfo = this._userRoleInfo
                    globalData.updateGlobalData(projectGlobalInfo);
                }
            });
        }
    }
}