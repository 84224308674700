import { NotificationDataModel } from './system-notification.model';

export const notificationData: NotificationDataModel =  {
    project: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToMyProject", url: "workspace/my-projects"},
    },
    projectCreated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"},
    },
    projectApprovedRejected: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "workspace/my-projects/"},
    },
    userAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
    },
    psg: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToPSG", url: "psg/general-details/engagement/project/"},
    },
    toolGranted: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToTools", url: "trainstops/activities/"},
    },
    activity: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToActivity", url: "trainstops/activities/"},
    },
    // PROCESS TAXONOMY CHANGED
    processTaxonomyNameChanged: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToActivity", url: "trainstops/activities/"},
    },
    // PROCESS TAXONOMY DESCOPED
    processTaxonomyDescoped: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToActivity", url: "trainstops/activities/"},
    },
     // NON JIRA PROCESS TAXONOMY CHANGED
     nonJiraProcessTaxonomyNameChanged: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToActivity", url: "trainstops/activities/"},
    },
    // NON JIRA PROCESS TAXONOMY DESCOPED
    nonJiraProcessTaxonomyDescoped: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToActivity", url: "trainstops/activities/"},
    },
    metaDataUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Go to Language Settings screen", url: "/language-settings/1"},
    },
    genAIUserAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
    },
    genAIUserDeleted: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
    },
    genAIUserNotDeleted: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    genAIUserNotAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    genAIBulkUsersAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
    },
    genAIBulkUsersNotAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    genAIAdminUserAdded: {
      metaDataLabels: [],
      redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "/user-management"},
    },
    genAIAdminUserNotAdded: {
      metaDataLabels: [],
      redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "/user-management"},
    },
    genAIBulkAdminUsersAdded: {
      metaDataLabels: [],
      redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "/user-management"},
    },
    genAIBulkAdminUsersNotAdded: {
      metaDataLabels: [],
      redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "/user-management"},
    },
    genAIProjectAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    genAIProjectNotAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    genAIProjectMSTeamsIdUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    genAIProjectMSTeamsIdNotUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    aipSubUseCasesSynced: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    aipSubUseCasesNotSynced: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    genAIProjectDetailsNotUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    genAIAmplifierDisabled: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    autonomousCodeProjectDataAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    autonomousCodeProjectDataNotAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    autonomousCodeProjectDataUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    autonomousCodeProjectDataNotUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
    },
    autonomousCodeUserAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
    },
    autonomousCodeUsersAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
    },
    autonomousCodeUsersNotAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: 'Navigation.Menu.Notification.FailedUsersData', url: ""},
    },
    autonomousCodeUserDeleted: {
      metaDataLabels: [],
      redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
  },
  autonomousCodeUserNotAdded: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
},
autonmousCodeUserNotDeleted: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
},
autonomousCodeUserStoryNotSynced: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.FailedUserStories", url: ""}
},
autonomousCodeUserStorySynced: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.SyncedUserStories", url: ""}
},
alreadyExportedToJira: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "projectsummary/"},

},
alreadyManuallyUploadedOnBuild: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "projectsummary/"},
},
autonomousConfigUserAdded: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
},
autonomousConfigBulkUsersAdded: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
},
autonomousConfigBulkUsersNotAdded: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.FailedUsersData", url: ""},
},
autonomousConfigUserDeleted: {
  metaDataLabels: [],
  redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users/"},
},
autonomousConfigUserNotAdded: {
metaDataLabels: [],
redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
},
autonomousConfigUserNotDeleted: {
metaDataLabels: [],
redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
},
autonomousConfigProjectDataAdded: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
},
autonomousConfigProjectDataNotAdded: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
},
autonomousConfigProjectDataNotUpdated: {
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "psg/general-details/engagement/project/"}
},
autonomousConfigUserStorySynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.SyncedUserStories", url: ""}
},
autonomousConfigUserStoryNotSynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.FailedUserStories", url: ""}
},
aipUserStorySynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.SyncedUserStories", url: ""}
},
aipUserStoryNotSynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.FailedUserStories", url: ""}
},
autonomousConfigUserStoryPartiallySynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.FailedUserStories", url: ""}
},
autonomousCodeUserStoryPartiallySynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.FailedUserStories", url: ""}
},
apiUserStoryPartiallySynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.FailedUserStories", url: ""}
},
genAITaxonomySynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.SyncedTaxonomy", url: ""}
},
genAITaxonomyNotSynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.FailedTaxonomy", url: ""}
},
genAITaxonomyPartiallySynced:
{
    metaDataLabels: [],
    redirectionUrlData: {label: "Navigation.Menu.Notification.FailedTaxonomy", url: ""}
}
}

export const notificationEventsTypes = {
    projectApproved: "PROJECT_APPROVED",
    projectCreated: "PROJECT_CREATED",
    projectRejected: "PROJECT_REJECTED",
    toolGranted: "TOOL_PROVISIONED",
    userAdded: "USER_ADDED",
    psgGenerated: "PSG_GENERATED",
    psgUpdated: "PSG_UPDATED",
    usersAdded: "USERS_ADDED",
    jiraExportSuccess: "JIRA_EXPORT_SUCCESS",
    jiraExportFailed: "JIRA_EXPORT_FAILED",
    testScenarioGenerationSuccess: "TEST_SCENARIO_GENERATION_SUCCESS",
    testScenarioGenerationFailed: "TEST_SCENARIO_GENERATION_FAILED",
    metaDataUpdated: "METADATA_UPDATED",
    processTaxonomyNameChanged: 'PROCESS_TAXONOMY_NAME_CHANGED',
    processTaxonomyDescoped: 'PROCESS_TAXONOMY_DESCOPED',
    nonJiraProcessTaxonomyNameChanged: 'NONJIRA_PROCESS_TAXONOMY_NAME_CHANGED',
    nonJiraProcessTaxonomyDescoped: 'NONJIRA_PROCESS_TAXONOMY_DESCOPED',
    // genai notifications
    genAIUserAdded: "GENAI_USER_ADDED",
    genAIUserDeleted:"GENAI_USER_DELETED",
    genAIUserNotDeleted:"GENAI_USER_NOT_DELETED",
    genAIUserNotAdded: "GENAI_USER_NOT_ADDED",
    genAIBulkUsersAdded: "GENAI_BULK_USERS_ADDED",
    genAIBulkUsersNotAdded: "GENAI_BULK_USERS_NOT_ADDED",
    genAIBulkAdminUsersAdded: "GENAI_BULK_ADMIN_USERS_ADDED",
    genAIBulkAdminUsersNotAdded: "GENAI_BULK_ADMIN_USERS_NOT_ADDED",
    genAIAdminUserAdded: "GENAI_ADMIN_USER_ADDED",
    genAIAdminUserNotAdded: "GENAI_ADMIN_USER_NOT_ADDED",
    genAIProjectAdded: "GENAI_PROJECT_ADDED",
    genAIProjectNotAdded: "GENAI_PROJECT_NOT_ADDED",
    genAIProjectMSTeamsIdUpdated: "GENAI_PROJECT_MSTEAMSID_UPDATED",
    genAIProjectMSTeamsIdNotUpdated: "GENAI_PROJECT_MSTEAMSID_NOT_UPDATED",
    aipSubUseCasesSynced: "AIP_SUB_USE_CASES_SYNCED",
    aipSubUseCasesNotSynced: "AIP_SUB_USE_CASES_NOT_SYNCED",
    genAIProjectDetailsNotUpdated:"GENAI_PROJECT_DETAILS_NOT_UPDATED",
    genAIAmplifierDisabled: "GENAI_AMPLIFIER_DISABLED",
    aipUserStoryNotSynced: "AIP_USER_STORY_NOT_SYNCED",
    aipUserStorySynced: "AIP_USER_STORY_SYNCED",
    apiUserStoryPartiallySynced : "AIP_USER_STORY_PARTIALLY_SYNCED",
    genAITaxonomySynced: "GENAI_TAXONOMY_SYNCED",
    genAITaxonomyNotSynced: "GENAI_TAXONOMY_NOT_SYNCED",
    genAITaxonomyPartiallySynced : "GENAI_TAXONOMY_PARTIALLY_SYNCED",
// autocode notifications
    autonomousCodeProjectDataAdded:'AUTONOMOUS_CODE_PROJECT_DATA_ADDED',
    autonomousCodeProjectDataNotAdded:'AUTONOMOUS_CODE_PROJECT_DATA_NOT_ADDED',
    autonomousCodeProjectDataUpdated:'AUTONOMOUS_CODE_PROJECT_DATA_UPDATED',
    autonomousCodeProjectDataNotUpdated:'AUTONOMOUS_CODE_PROJECT_DATA_NOT_UPDATED',
    autonomousCodeUserAdded: "AUTONOMOUS_CODE_USER_DATA_ADDED",
    autonomousCodeUsersAdded: "AUTONOMOUS_CODE_USERS_DATA_ADDED",
    autonomousCodeUsersNotAdded: "AUTONOMOUS_CODE_USERS_DATA_NOT_ADDED",
    autonmousCodeUserDeleted: "AUTONOMOUS_CODE_USER_DATA_DELETED",
    autonomousCodeUserNotAdded: "AUTONOMOUS_CODE_USER_DATA_NOT_ADDED",
    autonmousCodeUserNotDeleted:"AUTONOMOUS_CODE_USER_DATA_NOT_DELETED",
    autonomousCodeUserStoryNotSynced: "AUTONOMOUS_CODE_USER_STORY_NOT_SYNCED",
    autonomousCodeUserStorySynced: "AUTONOMOUS_CODE_USER_STORY_SYNCED",

    alreadyExportedToJira: "ALREADY_EXPORTED_TO_JIRA",
    alreadyManuallyUploadedOnBuild: "ALREADY_MANUALLY_UPLOADED_ON_BUILD",
    autonomousCodeUserStoryPartiallySynced : "AUTONOMOUS_CODE_USER_STORY_PARTIALLY_SYNCED",
// autoconfig notifications
    autonomousConfigBulkUsersAdded: "AUTONOMOUS_CONFIG_BULK_USERS_DATA_ADDED",
    autonomousConfigBulkUsersNotAdded: "AUTONOMOUS_CONFIG_BULK_USERS_DATA_NOT_ADDED",
    autonomousConfigUserAdded: "AUTONOMOUS_CONFIG_USER_DATA_ADDED",
    autonomousConfigUserNotAdded: "AUTONOMOUS_CONFIG_USER_DATA_NOT_ADDED",
    autonomousConfigUserDeleted: "AUTONOMOUS_CONFIG_USER_DATA_DELETED",
    autonomousConfigUserNotDeleted:"AUTONOMOUS_CONFIG_USER_DATA_NOT_DELETED",
    autonomousConfigProjectDataAdded:"AUTONOMOUS_CONFIG_PROJECT_DATA_ADDED",
    autonomousConfigProjectDataNotAdded:"AUTONOMOUS_CONFIG_PROJECT_DATA_NOT_ADDED",
    autonomousConfigProjectDataNotUpdated:"AUTONOMOUS_CONFIG_PROJECT_DATA_NOT_UPDATED",
    autonomousConfigUserStorySynced: "AUTONOMOUS_CONFIG_USER_STORY_SYNCED",
    autonomousConfigUserStoryNotSynced: "AUTONOMOUS_CONFIG_USER_STORY_NOT_SYNCED",
    autonomousConfigUserStoryPartiallySynced : "AUTONOMOUS_CONFIG_USER_STORY_PARTIALLY_SYNCED"
}

export const PROJECT_TOOL = "A project tool";
