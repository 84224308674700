export const LAUNCH_LAYOUT = {
  right: {
      title: 'Launch journey',
      colorScheme: 'rgb(0,151,169,1)',
      textColorScheme: '#28b2c4',
      activitiesColorScheme: 'rgb(0,151,169,1)',
      stopIcon: 'images/activity-icons/stops/Imagine/LaunchJourney.svg',
  },
  left: {
      title: 'Imagine',
      subTitle: 'Decide your personalized digital strategy',
      image: {
          normal: 'Imagine_wheel_small.svg',
          normalDimension: {
              left: '0%',
              height: '100',
              width: '100'
          },
          expandedDimension: {
              left: '3%',
              top: '45%',
              height: '100%',
              width: '100%'
          }
      }
  }
}
