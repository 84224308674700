<div class="addtosprint-container" *ngIf="addToSprintData && unique_sprint_wise_l3s_list">
<!-- <button class="sprint-edit-popup" (click)="$event.stopPropagation();openAddToSprintPopup()">+</button> -->
    <div class="popup-header">
      <span cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle><img class="drag-icon"  src="assets/userstories/drag-icon.svg"/></span>
      <span>{{'Global.Common.Label.AddingTo' | translate}} Sprint #</span><span class="sprint-color-box" [ngStyle]="{'background-color':colors[addToSprintData.selectedSprint.sprint_order_no]}">{{addToSprintData.selectedSprint.sprint_order_no}}</span>
      <mat-icon (click)="onCancel()" class="close-button">close</mat-icon> 
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-content>
            <div class="no-search" *ngIf="!wasInside">
              <span class="processes-label"> <mat-checkbox  class="custom-checkbox1"
                (change)="allSelection($event.checked)"
                [disabled]="false"
                [indeterminate]="someL3Selected()"
                [checked]="allSelected"
                ></mat-checkbox>
               Selected processes: {{totalTypesSelected()}}</span>
               <span class="search-btn"><button (click)="openSearchInput()" mat-button mat-icon-button><mat-icon>search</mat-icon></button></span>
            </div>
            <form [formGroup]="stateForm" *ngIf="wasInside" class="search-box">
              <mat-form-field class="formFieldClass"  floatLabel="never">
                <mat-label class="search-placeholder">{{'Global.Common.Label.Search' | translate}}</mat-label>
                <input type="text"
                id="searchInput"
                class="inputTextClass"
                       matInput
                       [(ngModel)]="searchTerm"
                       formControlName="stateGroup"
                       (input)="filterListBySearchTerm(searchInput.value)"
                       #searchInput>
                       <span class="close-icon" (click)="clearSearch()"><mat-icon>close</mat-icon></span>
              </mat-form-field>
            </form>
          <!-- level 01 - sprint level -->
          <div class="customize-sprints-action" *ngIf="unique_sprint_wise_l3s_list.length > 0">
            <mat-accordion multi="true"*ngFor="let l3_obj of unique_sprint_wise_l3s_list;" >
              <mat-expansion-panel class="level-scope" hideToggle="true" #panelState>
                <mat-expansion-panel-header  class="l1-header">
                   <mat-panel-title class="l1-label l-label">
                    <mat-checkbox  class="custom-checkbox"
                     (click)="$event.stopPropagation();"
                     [indeterminate]="someTypeSelected(l3_obj)"
                     (change)="l3Selection($event.checked,l3_obj)"
                     [disabled]="false"
                     [checked]="l3_obj.selected"></mat-checkbox>
                      <button mat-icon-button >
                        <img *ngIf="panelState.expanded" src="assets/psg/arrow-down.png" alt="" />
                        <img *ngIf="!panelState.expanded" src="assets/psg/arrow-right.png" alt="" />
                      </button>
                      <span class="pt-1">

                        {{ getDottedValue(l3_obj.l3_value_id) }} <span [innerHTML]="highlightSearchResult(''+l3_obj.l3_value)"></span>
                       </span>

                    </mat-panel-title>
                </mat-expansion-panel-header>

                <!-- level 02 - type level -->
                <ng-container *ngFor="let type of l3_obj.type_level_data">
                  <mat-accordion  multi="true">
                    <mat-expansion-panel [class.mat-expansion-panel]="false" hideToggle="true" #panelState>
                      <mat-expansion-panel-header>
                        <mat-panel-title class="l-label l2-border" [ngClass]="{'l2-label-expand ': panelState.expanded, 'l2-label-collapse ': !panelState.expanded}">
                        <mat-checkbox  class="custom-checkbox" *ngIf="true"
                        [(ngModel)]="type.selected"
                        [disabled]="false"
                        (ngModelChange)="updateAllTypeSelected(l3_obj)"
                        ></mat-checkbox>
                        <span class="color-box" [ngStyle]="{'background-color':colors[0]}">{{type.sprint_order_no}}</span>

                        <span class="story-type">{{ type.story_type_name }}
                         </span>
                        <ng-container>
                           <div class="header-cols">
                                <span class="header-col-name" *ngIf="isJiraProject">{{'Global.Common.Label.Effort' | translate}}: {{type.total_efforts}}</span>
                                <span class="header-col-name-stories" *ngIf="!isJiraProject">{{'Global.Common.Label.Stories' | translate}}: {{type.total_user_stories}}</span>
                            </div>
                        </ng-container>

                        </mat-panel-title>
                      </mat-expansion-panel-header>

                   </mat-expansion-panel>
                  </mat-accordion>
               </ng-container>
             </mat-expansion-panel>
           </mat-accordion>
           </div>

    </div>
<div class="mat-dialog-actions">
    <button mat-button class="btn-secondary-cancel" (click)="onCancel()">{{'Global.Common.Button.Cancel' | translate}}</button>
    <button mat-button class="btn-primary-apply" [disabled]="!isApplyEnable()" (click)="onApply()">{{'Global.Common.Button.Apply' | translate}}</button>
</div>

</div>

