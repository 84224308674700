
<div class="custom-height">
    <div class="title">
        <span>{{ data.title | translate }}</span>
        <mat-icon [mat-dialog-close]="true" class="close-button">close</mat-icon>  
    </div>
    <div class="description">
        <span [innerHTML]="data.description | translate"></span>
    </div>
</div>
<mat-divider></mat-divider>
<div class="button-row">
    <button class="btn-back" (click)="close()" > 
        <span>
            <img class="arrow-back" src="assets/psg/arrow-back.svg" alt="" /> {{ 'Global.Common.Label.Back' | translate}}
        </span> </button>
    <button *ngIf="!data?.isLeaveButtonEnable" class="btn-confirm" (click)="confirmAndContinue()"> {{'GenerateReleasePlan.Popup.UpdateDataWarning.Popup.ConfirmButton' | translate}} </button>
    <button *ngIf="data?.isLeaveButtonEnable" class="btn-leave" (click)="confirmAndContinue()"> {{'GenerateReleasePlan.Popup.Discard.ButtonText' | translate}} </button>

</div> 