export class Project {
    ProjectID: number;
    ProjectName: string;
    ProjectTeamMemberList: any[];
    ProjectNoteList: any[];
    MapList: any[];
    ProjectRequestStatus: number;
    ProjectStatus: number;
    WBSEnabled: boolean;
    WBSCode: null;
    ClientName: null;
    ProjectStartDate: string;
    ProjectEndDate: string;
    ValueOfProjectPursuit: null;
    MercuryIdEnabled: boolean;
    MercuryId: null;
    PursuitEnabled: boolean;
    PursuitStatus: number;
    ProjectMemberFirmId: number;
    ProjectBusinessId: number;
    ProjectOfferingPortfolioId: number;
    PMDLead: null;
    ProjectLead: number;
    ProjectLeadName: string;
    ProjectLeadDetails: null;
    IsNotesAvailable: boolean;
    ProjectUpdateFlag: number;
    TeamMemberCount: number;
    MapCount: number;
    IsSentForAdminAction: boolean;
    IsProjectLeadNotifiedOnApproval: boolean;
    IsProjectLeadNotifiedOnCreation: boolean;
    ExpireReminderSent: boolean;
    AlertType: null;
    ModifiedByName: string;
    Name: null;
    CreatedBy: number;
    CreatedDate: string;
    ModifiedBy: number;
    ModifiedDate: string;
    UserEmail: null;
    FirstName: null;
    LastName: null;
    Id: number;
    Action: number;
    StringProjectId: string;
}

export class ProjectFilter {
    Status: string = null;
    Limit: number = 50;
    Page: number = 1;
    AllProjects: boolean = false;
    SearchText: string =  null;
    ProjectStatus: string = "1,2,3";
    ProjectSortBy: string = "CreatedDate";
    ProjectSortOption:number = 0;
}
