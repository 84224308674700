export const CREATE_LAYTOUT = {
  right: {
    title: 'Create digital ambitions',
    colorScheme: 'rgb(0,85,135,1)',
    activitiesColorScheme: 'rgb(0,85,135,1)',
    // textColorScheme: '#002fba'
    textColorScheme: 'rgb(0,85,135,1)'

  },
  left: {
    title: 'Insights',
    subTitle: 'Explore digital platform, ambitions and value it provides',
    image: {
      normal: 'Insights_wheel_small.svg',
      normalDimension: {
        left: '0%',
        height: '100',
        width: '100'
      },
      expandedDimension: {
        left: '3%',
        top: '45%',
        height: '100%',
        width: '100%'
      }
    },

    activities: [
      "Conduct Enterprise Transformation Labs",
      "Perform Art of the Possible demonstrations",
      "Conduct Capability Maturity Assessments",
      "Pain points analysis, Activity analysis, Stakeholder Interviews",
      "Assess current state TCO and identify future state economic value targets"
    ],

    deliverables: [
      "Future State Vision and Digital Ambitions",
      "Current State Point Points and Process Optimization Opportunities",
      "Digital Capability Maturity Assessment Report",
      "Target Operating Model (TOM) Opportunities"
    ],

    stopDescription: "An experiential approach to conduct Labs that will support getting all client stakeholders aligned to jump-start the Digital Transformation journey",

    amplifiers: [
      {
        "name": "Digital lab assets",
        "progress": "1"
      },
      {
        "name": "Digital maturity assessment",
        "progress": "0"
      }
    ],
    digitalMaturity: [
      "Client is educated on Digital terminologies",
      "Client has defined Digital Ambitions and gaps to be addressed"
    ]

  }
}
