import { Injectable, ErrorHandler } from "@angular/core";
import { environment } from "src/environments/environment";
import { AppLoggerService } from "./app-logger.service";

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

    constructor(private appLoggerService: AppLoggerService) {
        super();
    }

    handleError(error: Error) {
        if (this.appLoggerService){
            this.appLoggerService.logException(error); 
        }
        else {
             console.log(error);
        }
    }
}