import * as watermarkActions from '../actions/watermark.actions';
import { Actions } from '../actions/watermark.actions';

export interface State {
    watermarkFlag:boolean,
    mapId:string
}

const initialState: State = {
    watermarkFlag: false,
    mapId:null
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case watermarkActions.UPDATE_WATERMARK_MAPID:
            return {
                ...state,
                mapId: action.payload
            }
        // case watermarkActions.LOAD_WATERMARK:
            // return {
            //     ...state,
            //     watermarkFlag: false
            // }
        case watermarkActions.LOAD_WATERMARK_SUCCESS:
            return {
                ...state,
                watermarkFlag: action.payload 
            }
        default:
            return state;
    }
}


export const watermarkFlag = (state: State) => state.watermarkFlag;
export const mapId = (state: State) => state.mapId;