import { CryptUtilService } from './crypt-util.service';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProjectGlobalInfoModel } from '../../shared/model/project-global-info.model';

@Injectable({
  providedIn: 'root'
})
export class PassGlobalInfoService {

  private content = new BehaviorSubject<ProjectGlobalInfoModel>(
    {
      userId: ""
      , projectId: ""
      , role: ""
      , viewMode: "EXPLORE"
      , uniqueId: ""
      , userroleId: ""
      , clientName: ""
      , projectName: ""
      , managerName: ""
      , packageName: ""
    });

    private selectedProject = new BehaviorSubject<any>(
      {
        project: {},
        viewMode: "EXPLORE"
      });
    
    public scrollToView = new Subject<any>();
   
    public exploreViewUserPref : BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public workspaceViewUserPref : BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public currentProjectInstance : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  //private content = new BehaviorSubject<string>("Hello");
  public share = this.content.asObservable();
  public getProjectDetails = this.selectedProject.asObservable();
  public getPackagePrefData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private cryptoUtilService: CryptUtilService) { }

  setProjectDetails(data) {
    let projectData=JSON.parse(JSON.stringify(data));
    delete projectData?.project?.clientLogoURL;
    this.cryptoUtilService.setItem('selectedProject', projectData, 'LOCAL');
    this.selectedProject.next(data);
  }

  updateGlobalData(projectPassingInfo) {
    let {clientUrl,...projectInfo}=projectPassingInfo
    this.cryptoUtilService.setItem('projectGlobalInfo', projectInfo, 'LOCAL');
    this.content.next(projectPassingInfo);
  }

  scrollToViewWelcome(data: any) {
    this.scrollToView.next(data);
  }

  sendToExploreUserPref(exploreView: any):void {
    this.exploreViewUserPref.next(exploreView);
  }

  gotToExploreViewUserPref(): Observable<any> {
    return this.exploreViewUserPref.asObservable();
  }

  sendToWorkspaceUserPref(workspaceView: any):void {
    this.workspaceViewUserPref.next(workspaceView);
  }

  gotToWorkspaceViewUserPref(): Observable<any> {
    return this.workspaceViewUserPref.asObservable();
  }

  sendToPackagePref(data: any):void {
    this.cryptoUtilService.setItem('packagePrefData', data, 'LOCAL');
    this.getPackagePrefData.next(data);
  }

  gotToPackagePref(): Observable<any> {
    return this.getPackagePrefData.asObservable();
  }

  setCurrentProjectInstance(currentProjectInstance: any):void {
    this.currentProjectInstance.next(currentProjectInstance);
  }

  getCurrentProjectInstance(): Observable<any> {
    return this.currentProjectInstance.asObservable();
  }
}
