import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from '../../authentication.service';

@Injectable()
export class IsAdminGuard  {
    constructor(
        private authService: AuthenticationService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const user = this.authService.getUser();
        const addProjectEnable = environment.DESIGNATIONS_LIST.findIndex(t => user.ssoUser.jobTitle == t) != -1;
        if (addProjectEnable || (user && user.projectDetails && user.projectDetails.isAscendAdmin === "true")) {
            return of(true)
        } else {
            this.router.navigateByUrl('/unauthorized');
        }
    }
}