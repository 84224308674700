<mat-toolbar color="primary" *ngIf="router.url != '/logout'" [ngStyle]="{'padding-top': (router.url == '/logout') ?  '1.5rem' : '0',
               'background':(router.url == '/welcome' || router.url == '/logout') ? '#051425': router.url == '/about' ? '#10212D' :'#101010'}">

  <div class="nav-elements">

    <div class="logo-align">
      <img *ngIf="router.url == '/welcome'" src="../../../assets/images/logo.svg" style="cursor: pointer;"
        (click)="goToHome()" width="140px" height="16px" />
      <img *ngIf="router.url != '/welcome'" src="../../../assets/images/Logo.png" style="cursor: pointer;"
        (click)="goToHome()" width="210px" height="48px" />
    </div>
    <div class="listItems" *ngIf="router.url != '/welcome' && router.url != '/maintenance' && router.url != '/about'" >
      <ul class="nav nav-pills">
        <li (click)="navigateToPageURL('/workspace/my-projects', 1)" [ngClass]="{'active': tab==='tab1'}">
          <a>{{"Navigation.Menu.MyProjects" | translate}}</a>
        </li>
        <li *ngIf="!isExternalUser" (click)="navigateToPageURL('/amplifier-exchange', 2)"
          [ngClass]="{'active': tab==='tab2'}"><a>{{"Navigation.Menu.AmplifierExchange" | translate}}</a></li>
        <li *ngIf="!isExternalUser" (click)="navigateToPageURL('/market-solution', 3)"
          [ngClass]="{'active': tab==='tab3'}"><a>{{"Navigation.Menu.MarketSolutions" | translate}}</a></li>
        <li *ngIf="!isExternalUser" (click)="navigateToPageURL('/solution-library', 4)"
          [ngClass]="{'active': tab==='tab4'}"><a>{{"Navigation.Menu.SolutionLibrary" | translate}}</a></li>
        <li *ngIf="canAccessAdminModule && !isExternalUser" (click)="navigateToPageURL('platform-admin', 5)"
          [ngClass]="{'active': tab==='tab5'}"><a>{{"Navigation.Menu.AdminModule" | translate}}</a></li>
      </ul>
    </div>

    <span class="spacer"></span>
    <div  class="nav-support-element" *ngIf="router.url != '/welcome'  && router.url != '/maintenance' && !isExternalUser && router.url != '/about'">
      <button  mat-button [matMenuTriggerFor]="serviceMenu" class="element-spacing user-initials-white"
        (menuClosed)="serviceMenuClosed()" (menuOpened)="serviceMenuOpen()" class="support-button"
        (mouseover)="isServiceMenuHover = true" (mouseout)="isServiceMenuHover = false" [matTooltip]="'Navigation.Menu.GeneralSupport' | translate" matTooltipClass="mat-tooltip-support"
        >
        <span [ngClass]="{'help-text-color':isServiceMenuOpen||isServiceMenuHover}" class="help-text">{{"Navigation.Menu.Help" | translate}}</span>
      </button>
    </div>
    <div class="nav-new-elements" *ngIf="router.url != '/welcome'  && router.url != '/maintenance' && !isExternalUser && router.url != '/about'">
      <button mat-button [matMenuTriggerFor]="notificationMenu" class="element-spacing user-initials"
        class="contact-button" [ngClass]="{'background-white':isNotificationMenuOpen}"
        (menuClosed)="notificationMenuClosed()" (menuOpened)="notificationMenuOpened()">
        <img
          *ngIf="(!isNotificationMenuOpen && !isUnseenNotificationPresent) || (isNotificationMenuOpen && !isUnseenNotificationPresent)"
          src="assets/system_notification/notification_icon.svg" />
        <img *ngIf="!isNotificationMenuOpen && isUnseenNotificationPresent"
          src="assets/system_notification/Button.svg" />
        <img *ngIf="isNotificationMenuOpen && isUnseenNotificationPresent"
          src="assets/system_notification/withNotifications.svg" />
      </button>
    </div>
    <div class="nav-new-elements" *ngIf="router.url != '/welcome'  && router.url != '/maintenance' && !isExternalUser && router.url != '/about'">
      <button mat-button [matMenuTriggerFor]="contactMenu" class="element-spacing user-initials"
        (menuClosed)="contactMenuClosed()" (menuOpened)="contactMenuOpen()" class="contact-button"
        [ngClass]="{'background-white':isContactMenuOpen}">
        <img src="../../../../assets/contact.png" />
      </button>
    </div>
    <!-- <div class="nav-new-elements" *ngIf="router.url != '/welcome'  && router.url != '/maintenance' && router.url != '/about'"> -->
    <div class="nav-new-elements">
      <button mat-button [matMenuTriggerFor]="belowMenu" class="element-spacing user-initials"
        (menuClosed)="menuClosed()" style="background: #3A3C3F; min-width: 30px !important;
      padding: 0px 0px;
      max-height: 30px;
      line-height: 30px;">
        {{userInitials}}
      </button>
    </div>
    <!-- <div class="nav-new-elements" *ngIf="router.url === '/welcome'">

      <button mat-button [matMenuTriggerFor]="onlyLogoutMenu" class="element-spacing user-initials" style="background: #3A3C3F; min-width: 32px !important;
      padding: 0px 0px;
      max-height: 30px;
    line-height: 30px;">
        {{userInitials}}
      </button>

    </div> -->
    <mat-menu #belowMenu="matMenu" class="my-menu">
      <mat-label>
        <div class="user-details">
          <span class="element-spacing user-initials-white">
            {{userInitials}}
          </span>
          <span class="sub-details">
            <span class="userName">
              {{displayUserName}}
            </span>
            <span class="user-id">
              {{userEmailId}}
            </span>
          </span>
        </div>
      </mat-label>
      <mat-divider class="hor-line-1"></mat-divider>
      <ng-container *ngIf="router.url !== '/welcome' && !isHome">
        <button *ngIf="!isExternalUser" mat-menu-item (click)="userPreference()">{{"Navigation.Menu.UserPreferences" | translate}}</button>
        <button *ngIf="!isExternalUser" mat-menu-item (click)="lauchExploreAscend()">{{"Navigation.Menu.ExploreAscend" | translate}}</button>
      </ng-container>
      
      <mat-accordion *ngIf="isLanguageShow">
        <mat-expansion-panel class="custom-panel">
          <mat-expansion-panel-header (click)="$event.stopPropagation();$event.preventDefault();" class="panel-header">
            <mat-panel-title class="title-header">
              {{ currentLanguageLocale?.toUpperCase() }} - {{ currentLanguage }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-list class="nested-menu" *ngFor="let lang of languageList">
            <span  mat-list-item class="nested-items" (click)="languageSelect(lang)">
              <i class="fa fa-circle bullet-class"></i>
              {{ lang.value?.toUpperCase() }} - {{ lang.label }}</span>

          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>
      <ng-container *ngIf="router.url !== '/welcome' && !isHome">
        <button *ngIf="!isExternalUser" mat-menu-item style="margin-bottom: 8px;" (click)="siteMap()">{{"Navigation.Menu.SiteMap" | translate}}</button>
        <button *ngIf="!isExternalUser" mat-menu-item (click)="ascendKX()">{{"Navigation.Menu.AscendKX" | translate}}</button>
      </ng-container>
     
      <button mat-menu-item (click)="cookies()">{{"Navigation.Menu.Cookies" | translate}}</button>
      <button mat-menu-item  (click)="cookieSettings()">
        <a id="ot-sdk-btn" rel="noopener" target="_blank">{{"MyProject.CookieSettings.Text" | translate}}</a>
      </button>
      <button mat-menu-item  (click)="goToReleasePage()" *ngIf="releaseBanner.length>0">
        <a id="ot-sdk-btn" rel="noopener" >{{"Global.Common.Label.ReleasesUpdates" | translate}}</a>
      </button>
      <button mat-menu-item (click)="navigateToTerms()">{{"LandingPage.Menu.TermsOfUse" | translate}}</button>
      <button mat-menu-item *ngIf="!isExternalUser" (click)="navigateToPolicy()">
        <a id="ot-sdk-btn" rel="noopener" target="_blank">{{"LandingPage.Menu.Privacy" | translate}}</a>
      </button>
      <mat-divider class="hor-line-2"></mat-divider>

      <button mat-menu-item (click)="logout()">
        <img class="hamburger logout-img" width="22px" src="../../../../assets/images/Logout.png" />
        {{"Navigation.Menu.LogOut" | translate}}</button>

    </mat-menu>
    <!-- <mat-menu #onlyLogoutMenu="matMenu" class="my-menu">
      <mat-label>
        <div class="user-details">
          <span class="element-spacing user-initials-white">
            {{userInitials}}
          </span>
          <span class="sub-details">
            <span class="userName">
              {{displayUserName}}
            </span>
            <span class="user-id">
              {{userEmailId}}
            </span>
          </span>
        </div>
      </mat-label>
      <mat-divider class="hor-line-1"></mat-divider>
      <mat-accordion *ngIf="isLanguageShow">
        <mat-expansion-panel class="custom-panel">
          <mat-expansion-panel-header (click)="$event.stopPropagation();$event.preventDefault();" class="panel-header">
            <mat-panel-title class="title-header">
              {{ currentLanguageLocale?.toUpperCase() }} - {{ currentLanguage }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-list class="nested-menu" *ngFor="let lang of languageList">
            <span  mat-list-item class="nested-items" (click)="languageSelect(lang)">
              <i class="fa fa-circle bullet-class"></i>
              {{ lang.value?.toUpperCase() }} - {{ lang.label }}</span>

          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>
      <button mat-menu-item (click)="cookies()">{{"Navigation.Menu.Cookies" | translate}}</button>
      <button mat-menu-item>
        <a class="ot-sdk-show-settings" id="ot-sdk-btn" rel="noopener" target="_blank">Cookie Settings</a>
      </button>
      <button mat-menu-item (click)="logout()">
        <img class="hamburger logout-img" width="22px" src="../../../../assets/images/Logout.png" />
        {{"Navigation.Menu.LogOut" | translate}}</button>
    </mat-menu> -->
    <mat-menu #serviceMenu="matMenu" class="support-menu">
      <img class="polygon-arrow" src="assets/polygon-up.png" alt="" />
      <button mat-menu-item (click)="openGeneralSupport()">{{"Navigation.Menu.GeneralSupport" | translate}}</button>
      <button mat-menu-item (click)="openReportIssue()">{{"Navigation.Menu.ReportAnIssue" | translate}}</button>
      <button mat-menu-item (click)="openActivationCoachingServices()">{{"Navigation.Menu.ActivationAndCoachingServices" | translate}}</button>
    </mat-menu>
    <mat-menu #contactMenu="matMenu" class="contact-menu">
      <app-contact-list [contactDetails]="contactDetails" (contactsEmitter)="getContacts($event)" [globalInstances]="globalInstances" (regionChange)="getContacts($event)" [pinnedUserCount]="pinnedUserCount"></app-contact-list>
    </mat-menu>
    <mat-menu #notificationMenu="matMenu" class="notification-menu">
      <app-notification-menu *ngIf="showNotificationIcon"></app-notification-menu>
    </mat-menu>
  </div>

</mat-toolbar>