export enum LAYOUT_TYPE {
  INSIGHTS = 'prepare',
  IMAGINE = 'imagine',
  DELIVER = 'deliver',
  RUN = 'run'
}

/* -- SUB NAVS -- */
/* -- SUB NAVS -- */
/* -- SUB NAVS -- */
/* -- SUB NAVS -- */
/* -- SUB NAVS -- */
/* -- SUB NAVS -- */
/* -- SUB NAVS -- */
/* -- SUB NAVS -- */
export enum LAYOUT_INSIGHTS_SUB_NAV {
    SENSE = "sense",
    DEFINE_DIGITAL = "define digital ambitions",
    DEFINE_JOURNEY = "define journey",
    CREATE = 'create digital ambitions',
    DEVELOP = 'develop roadmap',
    ESTIMATE = 'estimate costs',
    ESTABLISH = 'establish value case',
    DISCOVER = 'discover scope'
}

export enum LAYOUT_IMAGINE_SUB_NAV {
  DESIGN = 'adapt leading practices',
  ARCHITECT = 'apply design thinking',
  SUSTAINMENT = 'refine user stories',
  PURPOSE = 'define operate organization',
  LAUNCH = 'launch journey'
}

export enum LAYOUT_DELIVER_SUB_NAV {
  VALIDATE = 'validate',
  CONSTRUCT = 'sprint',
  DEPLOY = 'deploy',
  ACTIVATE = 'activate operate organization'
}

export enum LAYOUT_RUN_SUB_NAV {
  OPTIMIZE = 'stabilize and optimize',
  STABILIZE = 'stabilize',
  CONTINUE = 'innovate',
  TRANSITION = 'transition',
  INNOVATE = 'sustain and innovate',
  INITIATE = 'initiate'
}
