<mat-card >
    <mat-card-content [ngClass]="{'filter-validate' : (currentTab=='TEST_SCENARIOS')||(currentTab=='USER_STORY_SPRINT')||(currentTab=='USER_STORY_SPRINT_CEREMONY')||(currentTab=='USER_STORY_SPRINT_COMPLETION')||(currentTab=='USER_STORY_SPRINT_CONFIGURE')}" >
        <div class="filter-head">
            <div class="filter-title"> Select {{filterComponent?.title | lowercase}}  {{(filterComponent?.title =='Status'&& !isStatusFilterForTestCasesTab)?'in Jira':''}}</div>
            <div *ngIf="filterComponent?.l1Filter?.filterSelectType == FILTER_CONSTANT.MULTIPLE" class="reset-underline"  [disabled]="disableFilters(filterComponent)" (click)="disableFilters(filterComponent) ? '' : resetFilter()">Reset all</div>
        </div>

        <div class="filter-space" *ngIf="filterComponent?.title=='Status' || filterComponent?.title=='Type'"></div>

        <div class="filter-body">
            <!-- L1 level -->
            <div id="checkboxes-l1" class="levels">
                <div class="filter-subtitle"
                *ngIf="filterComponent?.l1Filter?.filterValues?.length > 0  && filterComponent?.l1Filter?.filterValues[0]?.childValues && filterComponent?.title!='Status' && filterComponent?.title!='Type'">
                    {{filterComponent?.l1Filter?.filterValues[0]?.childValues[0]?.entityType}}
                </div>

                <section class="example-section medium-font"
                    *ngIf="filterComponent?.l1Filter?.filterSelectType == FILTER_CONSTANT.MULTIPLE">

                    <mat-checkbox [name]="selectAll" class="example-section"
                        (change)="selectAllFilters($event, filterComponent?.l1Filter?.filterValues, null, 1)" [value]="selectAll"
                        [checked]="allSelected(filterComponent?.l1Filter?.filterValues,null)" [disabled]="disableFilters(filterComponent)">
                        {{selectAll}}
                    </mat-checkbox>

                </section>

                <section class="example-section medium-font"
                    *ngFor="let chk of filterComponent?.l1Filter?.filterValues[0]?.childValues; let i=index ">

                    <mat-checkbox name="chk.entityName" class="example-section"
                        (change)="filterChanged($event, chk, 1, filterComponent?.l1Filter?.filterSelectType)"
                        value="{{chk.entityName}}" [checked]="chk.selectedFlag == 'Y'" [disabled] = "disableFilter">
                        <img src="../../../../../assets/Imported.png" *ngIf="chk.entityName == 'Imported' && isStatusFilterForUserStoryTab && !isStatusFilterForTestCasesTab"/>
                        <img src="../../../../../assets/NotImported.png" *ngIf="chk.entityName == 'Not imported' && !isStatusFilterForTestCasesTab "/>
                        <i class="fa fa-circle" [ngStyle]="{'color':'#0D9305'}" *ngIf="chk.entityName == 'Signature' && chk.entityType == 'Type'">&nbsp;</i>
                        <i class="fa fa-circle" [ngStyle]="{'color':'#F9960D'}" *ngIf="chk.entityName == 'Core' && chk.entityType == 'Type'">&nbsp;</i>
                        <i class="fa fa-circle" [ngStyle]="{'color':'#F90D0D'}" *ngIf="chk.entityName == 'Cancelled' && chk.entityType == 'Type'">&nbsp;</i>
                        {{((sequenceExist ? calculateTaxonomySequence('level1', chk.l1_id) : '') + ' ')}}{{chk.entityName}}
                    </mat-checkbox>

                </section>
            </div>

            <!-- L2 level -->
            <div id="checkboxes-l2" class="levels"
                *ngIf="childElementPresent( filterComponent?.l2Filter?.filterValues, filterComponent?.l1Filter?.filterValues)">
                <div class="filter-subtitle">
                    {{filterComponent?.l2Filter?.filterValues[0]?.childValues[0]?.entityType}}
                </div>

                <section class="example-section medium-font"
                    *ngIf="filterComponent?.l2Filter?.filterSelectType == FILTER_CONSTANT.MULTIPLE">

                    <mat-checkbox [name]="selectAll" class="example-section"
                        (change)="selectAllFilters($event, filterComponent?.l2Filter?.filterValues, filterComponent?.l1Filter?.filterValues, 2)"
                        [checked]="allSelected(filterComponent?.l2Filter?.filterValues, filterComponent?.l1Filter?.filterValues)"
                        [value]="selectAll" [disabled] = "disabled">
                        {{selectAll}}
                    </mat-checkbox>

                </section>

                <ng-container *ngFor="let filter of filterComponent?.l2Filter?.filterValues; let p = index">
                    <ng-container *ngIf="filterAdded(filter, filterComponent?.l1Filter?.filterValues)">
                        <section class="example-section medium-font"
                            *ngFor="let chk of filter.childValues; let i=index ">

                            <mat-checkbox name="chk.entityName" class="example-section"
                                (change)="filterChanged($event, chk, 2, filterComponent?.l2Filter?.filterSelectType)"
                                value="{{chk.entityName}}" [checked]="chk.selectedFlag == 'Y'" [disabled] = "disabled">
                                {{((sequenceExist ? calculateTaxonomySequence('level2', chk.l1_id, chk.l2_id) : '') + ' ')}}{{chk.entityName}}
                            </mat-checkbox>

                        </section>
                    </ng-container>
                </ng-container>
            </div>

            <!-- L3 level -->
            <div id="checkboxes-l3" class="levels"
                *ngIf="childElementPresent( filterComponent?.l3Filter?.filterValues, filterComponent?.l2Filter?.filterValues)">
                <div class="filter-subtitle">
                    {{filterComponent?.l3Filter?.filterValues[0]?.childValues[0]?.entityType}}
                </div>

                <section class="example-section medium-font"
                    *ngIf="filterComponent?.l3Filter?.filterSelectType == FILTER_CONSTANT.MULTIPLE">

                    <mat-checkbox [name]="selectAll" class="example-section"
                        (change)="selectAllFilters($event, filterComponent?.l3Filter?.filterValues, filterComponent?.l2Filter?.filterValues, 3)"
                        [checked]="allSelected(filterComponent?.l3Filter?.filterValues, filterComponent?.l2Filter?.filterValues)"
                        [value]="selectAll" [disabled] = "disabled">
                        {{selectAll}}
                    </mat-checkbox>

                </section>

                <ng-container *ngFor="let filter of filterComponent?.l3Filter?.filterValues">
                    <ng-container *ngIf="filterAdded(filter, filterComponent?.l2Filter?.filterValues)">
                        <section class="example-section medium-font"
                            *ngFor="let chk of filter.childValues; let i=index ">

                            <mat-checkbox name="chk.entityName" class="example-section"
                                (change)="filterChanged($event, chk, 3, filterComponent?.l3Filter?.filterSelectType)"
                                value="{{chk.entityName}}" [checked]="chk.selectedFlag == 'Y'" [disabled] = "disabled">
                                {{((sequenceExist ? calculateTaxonomySequence('level3', chk.l1_id, chk.l2_id, chk.l3_id) : '') + ' ')}}{{chk.entityName}}
                            </mat-checkbox>
                        </section>
                    </ng-container>
                </ng-container>
            </div>

            <!-- L4 level -->
            <div id="checkboxes-l4" class="levels"
                *ngIf="childElementPresent( filterComponent?.l4Filter?.filterValues, filterComponent?.l3Filter?.filterValues)">
                <div class="filter-subtitle">
                    {{filterComponent?.l4Filter?.filterValues[0]?.childValues[0]?.entityType}}
                </div>

                <section class="example-section medium-font"
                    *ngIf="filterComponent?.l4Filter?.filterSelectType == FILTER_CONSTANT.MULTIPLE">

                    <mat-checkbox [name]="selectAll" class="example-section"
                        (change)="selectAllFilters($event, filterComponent?.l4Filter?.filterValues, filterComponent?.l3Filter?.filterValues, 4)"
                        [checked]="allSelected(filterComponent?.l4Filter?.filterValues, filterComponent?.l3Filter?.filterValues)"
                        [value]="selectAll" [disabled] = "disabled">
                        {{selectAll}}
                    </mat-checkbox>

                </section>

                <ng-container *ngFor="let filter of filterComponent?.l3Filter?.filterValues">
                    <ng-container *ngIf="filterAdded(filter, filterComponent?.l3Filter?.filterValues)">
                        <section class="example-section medium-font"
                            *ngFor="let chk of filter.childValues; let i=index ">
                            <mat-checkbox name="chk.entityName" class="example-section"
                                (change)="filterChanged($event, chk, 4, filterComponent?.l4Filter?.filterSelectType)"
                                value="{{chk.entityName}}" [checked]="chk.selectedFlag == 'Y'" [disabled] = "disabled">
                                {{chk.entityName}}
                            </mat-checkbox>
                        </section>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </mat-card-content>

    <hr />
    <div class="filter-buttons">
        <button mat-raised-button [ngClass]="{'btn-validate' : (currentTab=='TEST_SCENARIOS')||(currentTab=='USER_STORY_SPRINT')||(currentTab=='USER_STORY_SPRINT_CEREMONY')||(currentTab=='USER_STORY_SPRINT_COMPLETION')||(currentTab=='USER_STORY_SPRINT_CONFIGURE')}" class="btn-secondary-cancel" (click)="cancel()" id="2">Cancel</button>
        <button mat-raised-button [disabled]="disableFilter || isAmpDelFilterApplyDisabled" [ngClass]="{'btn-validate' : (currentTab=='TEST_SCENARIOS')||(currentTab=='USER_STORY_SPRINT')||(currentTab=='USER_STORY_SPRINT_CEREMONY')||(currentTab=='USER_STORY_SPRINT_COMPLETION')||(currentTab=='USER_STORY_SPRINT_CONFIGURE'), 'btn-disable' : isAmpDelFilterApplyDisabled}" class="btn-primary-apply" (click)="apply()" id="3">Apply</button>
    </div>
</mat-card>