import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from "socket.io-client";
import { MessagingService } from './messaging.service';
import { BUS_MESSAGE_KEY } from '../constants/message-bus';
import { environment } from 'src/environments/environment';
import { TestCasesService } from '@projects/trainstops/src/app/feature/test-cases/test-cases/service/test-cases.service';

import { CryptUtilService } from './crypt-util.service';
import { SystemNotificationService } from './system-notification.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  public wbFeedback$: BehaviorSubject<string> = new BehaviorSubject('');
  private userId: string;
  socket;
  private testCaseGenerationData: any[] = [];
  public manualSyncUpdate$:  BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private messagingBus: MessagingService,
    private cryptUtilService: CryptUtilService,
    private readonly sysNotificatioService_ : SystemNotificationService,
    private readonly testCaseService_: TestCasesService,
  ) {
      this.messagingBus.subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
        this.userId = data.userId;
      });
  }

  public getNewMessage = () => {
    this.socket = io(`${environment.API_MICROSERVICE_URL?.SOCKET_HUB}?userid=${this.userId}`, { transports: ["websocket"] });
    this.socket.on('newMessage', (message) =>{
      this.message$.next(message);
      //this.sysNotificatioService_.setNotificationAction(true)     //commenting this line to check if duplicate api calls are happening or not and in hub are we getting unique messages or not.

    });

    this.socket.on('webJobFeedback', (update) =>{
      this.setWebJobFeedback(update);
      this.saveWebJobUpdateLocally(update);
    });

    this.socket.on('testAIProgress', (data)=> {
      data = JSON.parse(data);
      this.createTestData(data);
      this.testCaseService_.setIsNewDataPushed(true);
    });

    this.socket.on('webJobManualUserStoriesSync', (syncUpdate)=> {
      this.setSyncUpdate(syncUpdate);
    })
    return this.message$.asObservable();
  };
  
  get getTestCaseGenerationData() {
    return this.testCaseGenerationData;
  }

  createTestData(data) {
    let isDataAvailable: boolean;
    this.testCaseGenerationData?.forEach((statusData,i) => {
      if(statusData?.project_id === data?.project_id) {
        this.testCaseGenerationData[i]=data
        isDataAvailable = true;
      }
    });
    if(!isDataAvailable) {
      this.testCaseGenerationData.push(data);
    }
  }

  removedata(projectId) {
    let removeIndex;
    this.testCaseGenerationData?.forEach((statusData,i) => {
      if(statusData?.project_id === projectId) {
        removeIndex = i;
      }
    });
    if(removeIndex >= 0){ 
      this.testCaseGenerationData?.splice(removeIndex, 1);
     }
  }

  saveWebJobUpdateLocally(update) {
    this.cryptUtilService.setItem('PROJECT_WEB_JOB_UPDATE_NON_JIRA',update, 'LOCAL');
  }

  setWebJobFeedback(update) {
    this.wbFeedback$.next(update);
  }

  getWebJobFeedback(): Observable<any> {
    return this.wbFeedback$.asObservable();
  }

  setSyncUpdate(syncUpdate: any) {
    this.manualSyncUpdate$.next(syncUpdate);
  }

  getSyncUpdate(): Observable<any>{
    return this.manualSyncUpdate$.asObservable()
  }
}
