import { Action } from '@ngrx/store';

import { uniqueAction } from '@app/shared/utility/utility-helpers';

export const UPDATE_WATERMARK_MAPID = '[WATERMARK] Set Map Id';
export const LOAD_WATERMARK = uniqueAction('[WATERMARK] Load Watermark');
export const LOAD_WATERMARK_SUCCESS = uniqueAction('[WATERMARK] Load Watermark Success');
export const LOAD_WATERMARK_FAIL = uniqueAction('[WATERMARK] Load Watermark Fail');


export class UpdateWatermarkMapIdAction implements Action {
  readonly type = UPDATE_WATERMARK_MAPID;
  constructor(public payload: any) {

  }
}


/**
 * Load Auth Actions
 */
export class LoadWatermarkAction implements Action {
    readonly type = LOAD_WATERMARK;

    constructor(public payload:any) {
    }
  }

  export class LoadWatermarkSuccessAction implements Action {
    readonly type = LOAD_WATERMARK_SUCCESS;

    constructor(public payload: any) {
    }
  }

  export class LoadWatermarkFailAction implements Action {
    readonly type = LOAD_WATERMARK_FAIL;
  }

  export type Actions = UpdateWatermarkMapIdAction
    | LoadWatermarkAction
    | LoadWatermarkSuccessAction
    | LoadWatermarkFailAction;
