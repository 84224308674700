
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { cookieConsent } from './cookie-consent';

if (environment.production) {
  enableProdMode();
}

if (!environment.isLocal && window) {
  console.log = () => {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  const primaryInstance= environment.GlOBAL_INSTANCE_DOMAINS.US?.toLowerCase();
  const secondaryInstance = [environment.GlOBAL_INSTANCE_DOMAINS.EU].map(i=> i.toLowerCase())
  cookieConsent(primaryInstance, secondaryInstance);

 (<any> window).OptanonWrapper = function() {
  console.log("--------- OptanonWrapper called");
  (<any> window).setConsentShareCookie()
 }
  let cookieScript = document.createElement("script");
  cookieScript.type = "text/javascript";
  cookieScript.async = true;
  cookieScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  cookieScript.setAttribute("data-domain-script",environment.COOKIE_CONSENT);
  document.body.appendChild(cookieScript);

  var launchScript = document.createElement('script');
  launchScript.type = 'text/javascript';
  launchScript.async = true;
  launchScript.src = environment.ADOBE_ANALYSTICS;
  document.getElementsByTagName('head')[0].appendChild(launchScript);