<section class="sprint-plan-grid" *ngIf="l1_obj">
    <section class="header">
        <div class="header-child">
            <span class="sprint-header-text">{{'Activities.UserStoryLibrary.SprintPlan.Label.DefineSprints' | translate}} / {{l1_obj.l1}}</span>
            <div class="toggle-timeline">
                <button *ngIf="!isAllL1Finalized" mat-raised-buttom  [disabled]="(projectId == '0') || disableConfirmAllSprintsButton || !isMoscowProject" class="btn-confirmall" (click)="confirm(true)">
                    <span> {{'GenerateReleasePlan.Popup.DefineSprints.ConfirmAllSprints' | translate}} </span> 
                    <span class="tooltiptext-confirmall">
                        <span *ngIf="disableConfirmAllSprintsButton"> {{'GenerateReleasePlan.Popup.SprintPlan.ConfirmAllDisableStateTooltip' | translate}} </span>
                        <span *ngIf="!disableConfirmAllSprintsButton">{{'GenerateReleasePlan.Popup.SprintPlan.ConfirmAllEnableStateTooltip' | translate}}</span>
                    </span>
                    <span *ngIf="!isMoscowProject" class="tooltiptext-confirmall">{{'GenerateReleasePlan.Popup.SprintPlan.ConfirmAllFlow1Tooltip' | translate}}</span> 
                </button>
            </div>
        </div>
        <div class="step-sub-header">{{'Activities.UserStoryLibrary.SprintPlan.stepDetails' | translate}}</div>
    </section>
    <section class="sprint-input">
        <form [formGroup]="sprintPlanForm">
            <div class="row ">
                <div class="col-4 sprint-number-mat-size abortSyncEditText ">
                    <span  *ngIf="isSyncAllL1Enabled" class="tooltipbox" data-container="body">
                        <label class="tooltiplbl">
                          {{'Activities.UserStoryLibrary.SprintPlan.EditL1Warning' | translate}}
                        </label> </span>
                    <mat-label class="label-sprintplan">
                      {{'Global.Common.Label.Number' | translate}}
                    </mat-label>
                    <mat-form-field appearance="outline" floatLabel="never" matTooltipDisabled="true">
                        <input matInput [disabled]="(isSyncAllL1Enabled || disableConfirmAllSprintsButton) || projectId =='0'" name="numberOfSprint"
                            formControlName="numberOfSprint" type="number" min="1" max="20"
                            [placeholder]="'Global.Common.Label.EnterSprintNumber' | translate" required />
                    </mat-form-field>
                </div>
                <div class="col-4 sprint-week-mat-size abortSyncEditText">
                    <span  *ngIf="isSyncAllL1Enabled" class="tooltipbox" data-container="body">
                        <label class="tooltiplbl">
                          {{'Activities.UserStoryLibrary.SprintPlan.EditL1Warning' | translate}}
                        </label> </span>
                    <mat-label class="label-sprintplan">
                        {{'Global.Common.Label.Duration' | translate}}
                    </mat-label>
                    <mat-form-field appearance="outline" floatLabel="never" class="btn-spacing">
                        <mat-select [disabled]="(isSyncAllL1Enabled || disableConfirmAllSprintsButton) || projectId =='0'" formControlName="numberOfWeek"
                            panelClass="phase_planning_mat-select-panel" [placeholder]="'Global.Common.Label.SelectWeek' | translate">
                            <mat-option *ngFor="let item of totalWeeks" [value]="item">
                                {{ item }} {{item > 1 ? 'weeks' : 'week' }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-4 sprint-startdate-mat-size abortSyncEditText">
                    <span *ngIf="isSyncAllL1Enabled" class="tooltipbox" data-container="body">
                        <label class="tooltiplbl">
                          {{'Activities.UserStoryLibrary.SprintPlan.EditL1Warning' | translate}}
                        </label> </span>
                    <mat-label class="label-sprintplan">
                        {{'PSG.Review.Scope.Planning.StartDate' | translate}}
                    </mat-label>
                    <mat-form-field appearance="outline" floatLabel="never" matTooltipPosition="above"
                        matTooltipDisabled="false">
                        <input class="start-date-input" matInput [disabled]="(isSyncAllL1Enabled || !isMoscowProject || l1_obj.is_finalized) || projectId =='0'"  [matDatepickerFilter]="weekdays" [matDatepicker]="picker"
                            placeholder="MM / DD / YYYY" formControlName="startDate" [min]="todayDate"
                        >
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <mat-icon class="sprint-input-calender" matDatepickerToggleIcon>                                        
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker panelClass="create-project-datepicker" ascend-datepicker-actions>
                        </mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </section>
    <section class="sprint-detail">
        <div class="tab-toggle" *ngIf="l1_obj?.sprint_plan?.length > 0">
            <mat-button-toggle-group #group="matButtonToggleGroup" value="VIEW_1" >
                <mat-button-toggle class="view" value="VIEW_1" (click)="toggle(false)" [ngClass]="{'selected' : group.value == 'VIEW_1', 'not-selected' : group.value == 'VIEW_2'}">
                    {{'Global.Common.Label.DataTable' | translate}} 
                </mat-button-toggle>
                <mat-button-toggle *ngIf="!disableProcessTab" class="view" value="VIEW_2" (click)="toggle(true)" [disabled]='false' [ngClass]="{'selected' : group.value == 'VIEW_2', 'not-selected' : group.value == 'VIEW_1'}">
                    {{'Global.Common.Label.Timeline' | translate}}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="!enableSprintTimeline && (l1_obj?.sprint_plan?.length > 0)">
            <table mat-table [dataSource]="l1_obj?.sprint_plan[0]?.sprint" style="width: 100%;" class="tbl-sprint">

                <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="hash">#</div>
                    </th>
                    <td mat-cell *matCellDef="let sprint">
                        <div class="sprint-number" [ngStyle]="{'background-color':colors[(sprint.sprint_order_no-1)%10]}"> {{sprint.sprint_order_no}}  </div>   
                    </td>
                </ng-container>

                <ng-container matColumnDef="sprint_name">
                    <th mat-header-cell *matHeaderCellDef><span class="t-header">{{'Global.Common.Label.Sprint' | translate}}</span></th>
                    <td mat-cell *matCellDef="let sprint;let i = index;"> {{sprint.sprint_name}} </td>
                </ng-container>

                <ng-container matColumnDef="start_date">
                    <th mat-header-cell *matHeaderCellDef><span class="t-header">{{'Global.Common.Label.SprintStartDate' | translate}}</span></th>
                    <td mat-cell *matCellDef="let sprint; let row = index">
                        <input matInput [matDatepicker]="picker" [matDatepickerFilter]="weekdays" class="custom-input" style="margin-bottom: 10px;"
                                [value]="sprint.start_date" [min]="setminDateForStartDatePicker(row)"
                                (dateInput)="sprintStartDateChange('input', $event, row)"
                                (dateChange)="sprintStartDateChange('change', $event, row)"
                                [disabled]="isAllL1Finalized || disableConfirmAllSprintsButton || isSyncAllL1Enabled || projectId == '0'">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <mat-icon class="table-calender-cls" matDatepickerToggleIcon>   
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </td>
                </ng-container>

                <ng-container matColumnDef="end_date">
                    <th mat-header-cell *matHeaderCellDef><span class="t-header">
                      {{'Global.Common.Label.SprintEndDate' | translate}}
                    </span></th>
                    <td mat-cell *matCellDef="let sprint; let row = index">
                        <input matInput [matDatepicker]="picker" [matDatepickerFilter]="weekdays" class="custom-input" style="margin-bottom: 10px;"
                                [value]="sprint.end_date" [min]="setminDateForEndDatePicker(row)"
                                (dateInput)="sprintEndDateChange('input', $event, row)"
                                (dateChange)="sprintEndDateChange('change', $event, row)"
                                [disabled]="isAllL1Finalized || disableConfirmAllSprintsButton || isSyncAllL1Enabled || projectId =='0'">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <mat-icon class="table-calender-cls" matDatepickerToggleIcon>
                                
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </div>
        <div *ngIf="enableSprintTimeline">
                <app-sprint-plan-gantt-chart [sprintPlanFormData]="sprintPlanForm.value" [selectedL1]="l1_obj"></app-sprint-plan-gantt-chart>
        </div>
    </section>
    <section class="sprint-button">
        <div class="footer-button">
            <button [disabled]="projectId == '0' || l1_obj?.is_finalized" [ngClass]="{'disableState' : projectId=='0'}" class="btn-reset" (click)="reset()">
                {{'GenerateReleasePlan.Popup.Label.ResetChanges'| translate}}
            </button>
            <div class="right-fix">
                <button [matTooltip]="sprintCapacitySuccess.PREVIOUS_STEP_TOOLTIP | translate" [matTooltipPosition]="'above'"  class="btn-arrow-secondary" type="button" (click)="previousSTep()">
                    <img class="arrow-up" src="assets/psg/arrow-back.svg" alt="" />
                </button>
                <button [matTooltip]="sprintCapacitySuccess.NEXT_STEP_TOOLTIP | translate" [matTooltipPosition]="'above'"class="btn-arrow-secondary" type="button" (click)="nextStep()">
                    <img class="arrow-up" src="assets/psg/arrow-up.svg" alt="" />
                </button>
                <button mat-raised-buttom *ngIf="!isAllL1Finalized" [disabled]="(projectId == '0') || disableConfirmAllSprintsButton || !isMoscowProject" class="btn-confirm" (click)="confirm(false)">
                  {{'Global.Common.Button.Confirm' | translate}} {{l1_obj?.l1_tab}} {{"Global.Common.Label.Sprints" | translate}}<span class="tooltiptext" *ngIf="isMoscowProject">
                  {{'Global.Common.Label.ConfirmSettings' | translate}}
                </span>
                <span *ngIf="!isMoscowProject" class="tooltiptext">{{'GenerateReleasePlan.Popup.SprintPlan.ConfirmFlow1Tooltip' | translate}}</span>
                </button>
            </div>
        </div>
    </section>

</section>


<ng-template #snackBarTemplate>
    <img src="assets/white_tick.svg"> {{successMessage}}
  </ng-template>