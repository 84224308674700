import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NAME_REGEX, EMAIL_REGEX, USER_ALREADY_ONBOARDED } from 'src/app/shared/constants/external-user-constants';
import { ExternalUserService } from '../service/external-user.service';
import { CryptUtilService } from 'src/app/shared/services/crypt-util.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { GlobalInstancesService } from '@projects/shared-lib/src/lib/services/global-instances.service';

@Component({
  selector: 'app-external-user-signup',
  templateUrl: './external-user-signup.component.html',
  styleUrls: ['./external-user-signup.component.scss']
})
export class ExternalUserSignupComponent implements OnInit, OnDestroy {

  externalUserForm: UntypedFormGroup;
  userEmail: any;
  aliasEmail: any;
  globalInstances = [];

  constructor(
    private externalUserService: ExternalUserService,
    private cryptUtilService: CryptUtilService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService,
    private globalInstancesService: GlobalInstancesService, 
  ) { 
  }

  ngOnInit(): void {
    this.getGlobalIntances();
    this.externalUserService.getUserDetails().subscribe((res) => {
      if (res)
        this.userInfoDetail();
    })
  }
  
  userInfoDetail() {
    let userInfo = this.cryptUtilService.getItem('userInfo', 'SESSION');
    if (userInfo) {
      this.aliasEmail = userInfo?.userId;
      this.userEmail = userInfo?.userPrincipalName;
      this.createForm();
    }
  }

  createForm() {
    this.externalUserForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required, this.noWhitespaceValidator, this.validateName, Validators.pattern(NAME_REGEX)]),
      lastName: new UntypedFormControl('', [Validators.required, this.noWhitespaceValidator, this.validateName, Validators.pattern(NAME_REGEX)]),
      email: new UntypedFormControl({ value: this.aliasEmail, disabled: true })
    })
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  validateName(control: UntypedFormControl) {
    let isValid = true;
    let name = control.value.trim();
    if (name.toLowerCase() == 'unknown') {
      isValid = false;
    }
    return isValid ? null : { 'whitespace': true };
  }

  externalUserLogin() {
    let formData = this.externalUserForm.value;
    if (!this.externalUserForm.invalid) {
      let tempObj = {
        email: this.userEmail,
        alias_email: this.aliasEmail,
        givenName: formData.lastName,
        familyName: formData.firstName
      };
    
    const promise = [];
    const regionIndex = [];
    this.globalInstances?.forEach((element,index) => {
        if(element['is_active']) {
          const apiBaseUrl = element['api_base_url'] + '/Project/api/v1';
      
          regionIndex.push(index);
          promise.push(this.externalUserService.externalUserLogin(tempObj, apiBaseUrl).toPromise())
        }    
      });


      Promise.any(promise)
      .then((res: any) => {
          if (res){
            this.redirectToWelcomePage(formData);
          }
        })
      .catch((err)=>{
            this.sharedService.setIsExternalSignupCompleted(false);
            console.log(err)
      })
    }
  
  }

  getGlobalIntances() {
    this.globalInstancesService.getGlobalInstances().subscribe((res) => {
      this.globalInstances = res?.data?.instances;
    });
  }

  private redirectToWelcomePage(formData) {
    const userDetails = { userName: `${formData.lastName}, ${formData.firstName}` };
    setTimeout(() => {
      this.sharedService.setIsExternalSignupCompleted(true);
    }, 100);
    this.authenticationService.fetchUserInfo(this.aliasEmail, this.userEmail, userDetails);
  }

  ngOnDestroy(): void { }
}
