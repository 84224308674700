import { STABILIZE_LAYTOUT } from './stabilize-layout.config';
import { CONTINUE_LAYTOUT } from './continue-layout.config';
import { OPTIMIZE_LAYTOUT } from './optimize-layout.config';
import { LAYOUT_RUN_SUB_NAV } from './../standard-constants.model';
import { TRANSITION_LAYTOUT } from './transition-layout.config';
import { INITIATE_LAYTOUT } from './initiate-layout.config';
import { INNOVATE_LAYTOUT } from './innovate-layout.config';

export default {
    [LAYOUT_RUN_SUB_NAV.OPTIMIZE]  : OPTIMIZE_LAYTOUT,
    [LAYOUT_RUN_SUB_NAV.CONTINUE]  : CONTINUE_LAYTOUT,
    [LAYOUT_RUN_SUB_NAV.INNOVATE] : INNOVATE_LAYTOUT,
    [LAYOUT_RUN_SUB_NAV.INITIATE] : INITIATE_LAYTOUT,
    [LAYOUT_RUN_SUB_NAV.TRANSITION] : TRANSITION_LAYTOUT
}
