import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { env } from 'process';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseService } from 'shared-lib';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectSummaryService {


  public package_function_obj: BehaviorSubject<any> = new BehaviorSubject({});
  public featureData$: BehaviorSubject<any> = new BehaviorSubject([]);
  public projectInfoData$: BehaviorSubject<any> = new BehaviorSubject([]);
  public popup_flag: Subject<Boolean> = new Subject();
  public cr_tooltip_msg: BehaviorSubject<string> = new BehaviorSubject('');
  public isCRPending: boolean;
  public isDownload: BehaviorSubject<boolean>;
  public isRedirectionBtn: BehaviorSubject<boolean>;
  public tileDescription: BehaviorSubject<string> = new BehaviorSubject('');
  private userStoryRedirection: BehaviorSubject<boolean>;
  constructor(private baseService: BaseService, private http: HttpClient) { 
    this.isDownload =new BehaviorSubject(false);
    this.isRedirectionBtn =new BehaviorSubject(false);
    this.userStoryRedirection =new BehaviorSubject(false);
  }

  setToolTipMsg(msg: string){
    this.cr_tooltip_msg.next(msg);
  }

  getToolTipMsg(): Observable<string>{
    return this.cr_tooltip_msg as Observable<string>;
  }

  setUserStoryRedirection(isEnabled: boolean){
    this.userStoryRedirection.next(isEnabled);
  }

  getUserStoryRedirection(): Observable<boolean>{
    return this.userStoryRedirection as Observable<boolean>;
  }

  setFeatureData(data){
    this.featureData$.next(data);
  }

  getFeatureData(): Observable<any>{
    return this.featureData$.asObservable();
  }

  setProjectInfoData(data){
    this.projectInfoData$.next(data);
  }

  getProjectInfoData(): Observable<any>{
    return this.projectInfoData$.asObservable();
  }

  getFeatureList() {
    return this.http.get(environment.API_MICROSERVICE_URL?.ADMIN + '/package/features/0');
  }
  getSummaryMasterData(packageId: any, functionId: any, featureId: any, langId) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.ADMIN}/package/preferences/${packageId}/${functionId}/${featureId}?languageId=${langId}`);
  }

  getProjectInfo(projectId: any, params?: any,languageId=1) {
    return this.http.get(environment.API_MICROSERVICE_URL?.PROJECT + `/project/` + projectId + `/${languageId}`, { params });
  }

  getRespectiveProjectInfo(projectId: any, params?: any,languageId=1, BASE_URL= environment.API_MICROSERVICE_URL?.PROJECT) {
    return this.http.get(BASE_URL + `/project/` + projectId + `/${languageId}`, { params });
  }

  getProjectGenAiStatus(projectId: any) {
    return this.http.get(environment.API_MICROSERVICE_URL?.PROJECT  + `/project/gen-ai-enable-status/` + projectId);
  }

  getProjectAutonomousDevStatus(projectId: any) {
    return this.http.get(environment.API_MICROSERVICE_URL?.PROJECT + `/project/autonomous-dev-enabled-status/` + projectId);
  }

  getProcessTaxonomy(projectId: any, packageId: any, functionId: any) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/process/${projectId}/${packageId}/${functionId}`);
  }

  getTaxonomyCountData(projectId: any) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/process-count-summary/${projectId}`);
  }

  getProcessTaxonomySummary(projectId: any){
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/process-taxonomy-summary/${projectId}`);
  }

  getProcessTaxonomyCreateProject(projectId: any, packageId: any, functionId: any, industryPrintId) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/process/${projectId}/${packageId}/${functionId}/${industryPrintId}`);
  }

  getSprintsDetail(projectId: any, packageId: any, functionId: any) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/sprints/${projectId}/${packageId}/${functionId}`);
  }

  getTestCaseTileData(projectId: any, packageId: any, functionId: any) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/testCases/${projectId}/${packageId}/${functionId}`);
  }

  getUserStoriesTileData(projectId: any, packageId: any, functionId: any) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/userStories/${projectId}/${packageId}/${functionId}`);
  }

  getMethodologyDetail(projectId: any, packageId: any, functionId: any, languageId: number) {
    let data = this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/methodology/${projectId}/${packageId}/${functionId}/${languageId}`);
    return data;
  }
  getProjectStatusDetail(projectId: any, packageId: any, functionId: any) {
    let data = this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/methodology/${projectId}/${packageId}/${functionId}`);
    return data;
  }

  getProjectMapData(projectId: any, packageId: any, functionId: any, languageId? : number) {
    if(languageId == undefined || languageId == null){
        languageId = 1
    }
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/amplifiersDeliverables/${projectId}/${packageId}/${functionId}/${languageId}`);
  }

  getIsExportCompleted(project_id, taxonomyId?) {
    return this.http.get<any>(`${environment.API_MICROSERVICE_URL?.PROJECT}/sprint-planning/sprint-generation/sprints/isReadyToExport/${project_id}`);
  }

  getProjectTools(projectId) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/summary/project-tools/${projectId}`);
  }

  getMsTeamsStatus(projectId) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/tools/msteams-status/${projectId}`);
  }

  createGenAIRoot(projectId) {
    const url = `${environment.API_MICROSERVICE_URL?.PROJECT}/tools/create-genai-root/${projectId}`;
    return this.http.post(url, {});
  }

  getAdditionalTools(projectId) {
    const url = `${environment.API_MICROSERVICE_URL?.PROJECT}/project/additional-tools/${projectId}`;
    return this.http.get(url);
  }
  //set download
  setDownloadFlag(data) {
    this.isDownload.next(data);
  }

  getDownloadFlag(): Observable<any> {
    return this.isDownload.asObservable();
  }
  setTileDescription(desc: string) {
    this.tileDescription.next(desc);
  }

  getTileDescription(): Observable<string> {
    return this.tileDescription as Observable<string>;
  }

  getTraditionalAmplifierData(projectId, packageId, pageNumber, langId){
    const url = `/project/dashboard/traditional-amplifiers/${projectId}/${packageId}?pageNumber=${pageNumber}&pageSize=${8}&languageId=${langId}`;
    
    return this.baseService.get(url,environment.API_MICROSERVICE_URL?.PROJECT);
  }

  getMarketSolutionsData(projectId: any, packageId: number, pageNumber: number, languageId: number): Observable<any> {
    const url = `/project/dashboard/market-solutions/${projectId}/${packageId}?pageNumber=${pageNumber}&pageSize=${8}&languageId=${languageId}`;
    return this.baseService.get(url, environment.API_MICROSERVICE_URL?.PROJECT);
  }
  getGenAIAmplifier(projectId, packageId, pageNumber, langId) {
    const url = `/project/dashboard/genai-amplifiers/${projectId}/${packageId}?pageNumber=${pageNumber}&pageSize=${8}&languageId=${langId}`;
    return this.baseService.get(url, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  getConstructIds(projectId: number): Observable<any> {
    return this.baseService.get(`/project/pmg-construct-ids/${projectId}`, environment.API_MICROSERVICE_URL?.PROJECT)
  }

  setRedirection(data: boolean) {
    this.isRedirectionBtn.next(data);
  }

  getRedirection(): Observable<boolean> {
    return this.isRedirectionBtn as Observable<boolean>;
  }
}

