import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { IIDRService } from '@projects/iidr/src/app/iidr/iidr.service';
import { TEST_SCENARIOS } from '@projects/shared-lib/src/lib/core/polling-constants';
import { ApiPollingService } from '@projects/shared-lib/src/lib/services/api-polling.service';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';
import { TestCasesService } from '@projects/trainstops/src/app/feature/test-cases/test-cases/service/test-cases.service';
import { from, Subject, Subscription, timer } from 'rxjs';
import { retry, switchMap, tap, share, takeUntil, map, delay, toArray, mergeMap } from 'rxjs/operators';
import { BUS_MESSAGE_KEY } from '../../constants/message-bus';
import { CryptUtilService } from '../../services/crypt-util.service';
import { MessagingService } from '../../services/messaging.service';

@Component({
  selector: 'app-central-notification',
  templateUrl: './central-notification.component.html',
  styleUrls: ['./central-notification.component.scss']
})
export class CentralNotificationComponent implements OnInit {
  private connection: signalR.HubConnection;
  connectionEstablished = new Subject<Boolean>();
  showSuccessNotification: boolean = true;
  notify: boolean = false;
  isInProgress: boolean = false;
  msgs: string ='';
  private _http: HttpClient;
  PROCESS_NAME = "TestDesignerActivity";
  promiseArray = [];
  context;
  isExploreMode;
  projectId;
  private flag;
  cancel: boolean = false;
  private activityTabCodeRoute;
  activityTabCode;
  private stopPolling = new Subject();
  previousUrl;
  currentUrl;
  instanceIDSubscription: Subscription;
  processStatusSubscription : Subscription;
  state_instanceIDsGeneratedSubscription: Subscription;
  currentRouteSubscription : Subscription;
  offsetSubscription : Subscription;
  depthlimitexists: boolean = false;
  l1Names : any = [];
  avgValue: any;
  constructor(  
    private router: Router,  
    private pollingService: ApiPollingService,
    private injector: Injector,
    private globalContext: ActivitiesGlobalContextService,
    private testCasesService: TestCasesService,
    public messagingService: MessagingService,
    private iidrService: IIDRService,
    private cryptUtilService: CryptUtilService,
    ) {
      this._http = this.injector.get(HttpClient);
    }

  ngOnInit(): void {
    this.globalContext.getGenerateTestScenariosData().subscribe((flag) => {
      this.flag = flag;
      if(flag) {
        this.getGlobalActivityContext();
      }
    })
    this.globalContext.getActivityTabCode().subscribe((data) => {
      if (data.code) {
        this.activityTabCode = data.code;
      }
    })
  }
  
  setShowNotification() {
    this.notify = false;
  }


  getInstanceIDsOfGenerateTestScenarios() {
      if(this.projectId && this.flag) {
        this.state_instanceIDsGeneratedSubscription = this.globalContext.getState_instanceIDsGenerated().subscribe((res) => {
          if(res) {
            let verify = setInterval (()=> {
              this.stopLoader();
               this.instanceIDSubscription = this.testCasesService.fetchAlreadyRunningProcesses(this.projectId, this.PROCESS_NAME).subscribe((processes:any) => {
                this.promiseArray = processes.map((process) => process?.instance_id);
                this.stopLoader();
                if(this.promiseArray.length>0) {
                  this.globalContext.setState_triggerGenerateTestScenariosButton(false);
                  this.getTriggeredProcessStatus(this.promiseArray);
                  clearInterval(verify);
                  this.state_instanceIDsGeneratedSubscription?.unsubscribe();
                  this.instanceIDSubscription?.unsubscribe();
                }
                else {
                  clearInterval(verify);
                  this.state_instanceIDsGeneratedSubscription?.unsubscribe();
                  this.instanceIDSubscription?.unsubscribe();
                }
              })
            },1000)
          }
        })
      }
    }

  getTriggeredProcessStatus(promiseArray) {
    let instance_ids =  promiseArray.join(',');
    let getStatus =  setInterval(() => {
      this.processStatusSubscription =  new ApiPollingService(this._http, this.globalContext).getProcessStatus(instance_ids).subscribe((res) => {
        
        if(res) {
         this.stopLoader();
         if(res.find((ele)=>  ele.status == "terminated")) {
          this.notify = false;
          clearInterval(getStatus);
          this.processStatusSubscription?.unsubscribe();
        }
         else if(!res.find((ele)=>  ele.status == "in progress") && !res.find((ele)=>  ele.status == 'depth limit reached')) {
          clearInterval(getStatus);
          this.processStatusSubscription?.unsubscribe();
          this.currentUrl = this.router.url;
          this.currentRouteSubscription = this.router.events.filter((event) => event instanceof NavigationEnd)
          .subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
          });
          if(this.activityTabCode==TEST_SCENARIOS && (this.currentUrl.includes("/trainstops/activities/deliver") || this.currentUrl.includes("/trainstops/activities/Deliver") ) ) {
           this.offsetSubscription = this.globalContext.getAvgPercentageObervable().subscribe((value) => {
              if(value.avg == 100 && value?.dashoffset == 0) {
                this.depthlimitexists = false;
                if(res.find((ele)=>  ele.status.trim() == "failed")) {
                  this.showSuccessNotification  = false;
                  this.notify = true;
                  this.offsetSubscription.unsubscribe();
                }
                else {
                  this.showSuccessNotification  = true;
                  this.notify = true;
                  this.offsetSubscription.unsubscribe();
                }
              }
            })
          }
          else {
            if(res.find((ele)=>  ele.status.trim() == "failed")) {
              this.showSuccessNotification  = false;
              this.notify = true;
            }
            else {
              this.showSuccessNotification  = true;
              this.notify = true;
            }
            this.cryptUtilService.setItem('centralNotificationNavigateState',true,'SESSION');
          }
         } 
         else if(res.find((ele)=>  ele.status == 'depth limit reached')) {
          if(res.every((ele) =>  ele.status == 'depth limit reached')) {
            this.depthlimitexists = true;
            this.showSuccessNotification = true;
            this.notify = true;
            this.offsetSubscription = this.globalContext.getDepthLimitReachedL1s().subscribe((value) => {            
              if(value) {
                this.l1Names = value;
              }
              this.offsetSubscription.unsubscribe();
            })
            clearInterval(getStatus);
          }
            this.offsetSubscription = this.globalContext.getAvgPercentageObervable().subscribe((value) => {
              if(value.avg == 100 && value?.dashoffset == 0) {                
                this.depthlimitexists = true;
                this.showSuccessNotification = true;
                this.notify = true;
                this.offsetSubscription = this.globalContext.getDepthLimitReachedL1s().subscribe((value) => {            
                  if(value) {
                    this.l1Names = value;
                  }
                  this.offsetSubscription.unsubscribe();
                })
              }
            })
            clearInterval(getStatus);
         } else {
          this.depthlimitexists = false;
          this.showSuccessNotification  = true;
         }
        }
        })
    },20000)
  }

  getGlobalActivityContext() {
    this.globalContext.getGlobalActivityContext().subscribe((data) => {
      if(this.flag) {
        this.context = data; 
        this.isExploreMode = this.context.viewmode == 'EXPLORE' ? true : false;
        this.projectId =  this.isExploreMode ? 0 : this.context.project.projectId;
        this.getInstanceIDsOfGenerateTestScenarios();
      }
    })
    this.globalContext.getActivityTabCode().subscribe((data) => {
      if(this.flag) {
        if (data.code) {
          this.activityTabCodeRoute = data.code;
        }
      }
    })
  }

  openActivity() {
    this.cryptUtilService.removeItem('SelectedL1Ids-TestScenario','SESSION');
    this.notify = false;
    this.router.navigate([`/trainstops/activities/${this.context.stop.phaseName}/${this.context.stop.stopId}/${this.context.selectedActivity.entityId}/${this.activityTabCodeRoute}`]);
    this.iidrService.getTab(7);
    this.stopLoader();
  }

  stopLoader(){
    this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, false);
  }

  ngOnDestroy() {
    this.state_instanceIDsGeneratedSubscription?.unsubscribe();
    this.instanceIDSubscription?.unsubscribe();
    this.processStatusSubscription?.unsubscribe();
    this.currentRouteSubscription?.unsubscribe();
  }


  // connect(url) {
  //   if (!this.connection) {
  //     this.connection = new signalR.HubConnectionBuilder()
  //     .withUrl(url)
  //     .build();

  //   this.connection.start().then(() => {
  //     console.log('Hub connection started');
  //     this.connectionEstablished.next(true);
  //   }).catch(err => console.log(err));

  
  //   this.connection.on('Notify', ( payload: string, showNotification) => {
  //     this.msgs = payload;
  //     this.showSuccessNotification = showNotification;
  //   });
  //  }
  // }

  // disconnect() {
  //   if (this.connection) {
  //     this.connection.stop();
  //     this.connection = null;
  //   }
  // }

}
