import {Action, createReducer, on} from '@ngrx/store';
import * as coreActions from '../actions/app-settings.actions';

export interface State {
  PrivacyUrl: string;
  TermsOfUseUrl: string;
  CookieConsent: { [s: string]: string; };
}
export const initialState: State = {
  PrivacyUrl: null,
  TermsOfUseUrl: null,
  CookieConsent: null
};
const appSettingsReducers = createReducer(
  initialState,
  on(coreActions.getAppLinksSuccessAction, (state, { appConstants}) => ({
    ...state,
    PrivacyUrl: appConstants.PrivacyUrl,
    TermsOfUseUrl: appConstants.TermsOfUseUrl,
    CookieConsent: appConstants.CookieConsent
  }))
);

export function appSettingsReducer(state: State, action: Action) {
  return appSettingsReducers(state, action);
}
