import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SPRINT_COLORS } from '@projects/projectsummary/src/app/core/utility/constants';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';
import { DefineRoadmapService } from '../../../define-roadmap.service';

interface DialogData {
  sprintPlan: any;
};

export interface OptionName {
  groupTitle: String;
  optionName: String;
}

export interface StateGroup {
  title: String;
  names: OptionName[];
}

@Component({
  selector: 'app-add-to-sprint-popup',
  templateUrl: './add-to-sprint-popup.component.html',
  styleUrls: ['./add-to-sprint-popup.component.scss']
})
export class AddToSprintPopupComponent implements OnInit {
  showAddtoSprintDialog: boolean = false;
  searchControl = new UntypedFormControl();
  selectedL3s: any[] = new Array<any>();
  filteredL3s: Observable<any[]>;
  lastFilter: string = '';

  private positionRelativeToElement: ElementRef
  public addToSprintData : any;

  colors = SPRINT_COLORS;
  unique_sprint_wise_l3s_list: any[];


  // Search L3's declarations 
  @Input() showInputSearch =false;
  @Input() placeholderText;
  @Input() searchDropData:any;
  @Input() selectedOption;
  stateForm: UntypedFormGroup = this._formBuilder.group({
    stateGroup: ''
  });
  @Output() selectSearchEvent=new EventEmitter<OptionName>();
  wasInside:Boolean=false;
  limitTypeL3Options: number;
  stateGroups: StateGroup[] = [
    {
      title: '',
      names: []
    }
  ];

  allSelected:boolean=false;
  stateGroupOptions: Observable<StateGroup[]>;
  selectedTitle: string;
  @Input() noData:String='No Record Found.';
  allOptions: StateGroup[];
  selected_processes_count: number = 0;
  searchTerm: any = '';
  addedL3s = [];
  isJiraProject:boolean;

  constructor(public dialog: MatDialog, 
    // @Inject(MAT_DIALOG_DATA) public sprintPlan: DialogData,
    // private dialogRef: MatDialogRef<AddToSprintPopupComponent>,
    private _formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddToSprintPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public options: { positionRelativeToElement: ElementRef, addToSprintData : any },
    private defineRoadmapService: DefineRoadmapService,
    private sharedService: SharedService
    ) {

      this.positionRelativeToElement = options.positionRelativeToElement;
      this.addToSprintData = options.addToSprintData;

     }

  ngOnInit(): void {
    this.isJiraProject = this.defineRoadmapService.isJiraProject; 
    const matDialogConfig = new MatDialogConfig()
    const rect: DOMRect = this.positionRelativeToElement.nativeElement.getBoundingClientRect()
    
    matDialogConfig.position = { right: `366px`, top: `${rect.bottom / 2 }px` }
    this.dialogRef.updatePosition(matDialogConfig.position);

    this.makeUniqueL3List(this.addToSprintData)
  
     this.stateForm
      .get('stateGroup')!
      .valueChanges.pipe(
        startWith(''),
        map(searchTerm =>  {
          // this.filterListBySearchTerm(searchTerm);
        })
      );
        
  }

  filterListBySearchTerm(searchTerm: any) {
    this.makeUniqueL3List(this.addToSprintData);
    this.searchTerm = searchTerm;
    let data = JSON.parse(JSON.stringify(this.unique_sprint_wise_l3s_list));
    let searchResult = [];
    data.filter(l3 => {        
      let l3Copy = JSON.parse(JSON.stringify(l3));
        if(l3.l3_value.toLowerCase().includes(searchTerm.toLowerCase())) {
          { searchResult.push(l3Copy); }
        }
    });
    this.unique_sprint_wise_l3s_list = JSON.parse(JSON.stringify(searchResult));

  }

  openSearchInput() {
    this.wasInside = true;
  }

  clearSearch() {
    this.searchTerm = '';
    this.stateForm.get('stateGroup').setValue('');
    this.makeUniqueL3List(this.addToSprintData);
    this.wasInside = false;
  }

  ngAfterContentInit(){
  }

  makeUniqueL3List(addToSprintData: any) {
    let tempSprintData = JSON.parse(JSON.stringify(addToSprintData))
     this.unique_sprint_wise_l3s_list = [];
     tempSprintData.sprintsCanBeAddedList.filter(sprint => 
    {
      
      if(this.unique_sprint_wise_l3s_list.length == 0 ) 
        {
          sprint.l3_group.filter((l3) =>  {
            l3.selected = false;
            l3.type_level_data.map((type) => (type.selected = false));
            this.unique_sprint_wise_l3s_list.push(l3);
            
          });
        } 
        else 
        { 
          sprint.l3_group.forEach(l3 => 
          { 
            l3.selected = false;
            let l3Index = this.unique_sprint_wise_l3s_list.findIndex(l3_obj => l3_obj.l3_value_id == l3.l3_value_id);
            if(l3Index  == -1)
            {               
                l3.type_level_data.map((type) => (type.selected = false));
                this.unique_sprint_wise_l3s_list.push(l3);
          } 
            else {
              l3.type_level_data.map((type) => (type.selected = false));
              l3.type_level_data.forEach(item => this.unique_sprint_wise_l3s_list[l3Index].type_level_data.push(item) );
              
            }
            
          });
        }
        
    });

  }

  markAsChecked(checked, checkedData: any, entity: any, typeData?: any) {
    if (entity === 'TYPE') {
      if (checked) {
        checkedData.selected = true;
        if (checkedData?.l3_group) {
          for (const l3Obj of checkedData?.l3_group) {
            l3Obj.selected = true;
            this.addedL3s.push({
              sprint_order_no:
                this.addToSprintData.selectedSprint.sprint_order_no,
              sprint_id: this.addToSprintData.selectedSprint.sprint_id,
              type: checkedData.story_type_name,
              l3: l3Obj.l3_value,
              l3_value_id: l3Obj.l3_value_id,
              type_id: checkedData.type_id,
            });
          }
        }
      } else if (this.addedL3s?.length > 0) {
        checkedData.selected = false;
        // TODO: deselect selected l3 => l3Obj.selected = false;
        this.addedL3s = this.addedL3s.filter(
          (obj) => obj.type !== checkedData.story_type_name
        );
      }
    } else if (entity === 'L3') {
      if (checked) {
        checkedData.selected = true;
        if (checkedData?.l3_value) {
          this.addedL3s.push({
            sprint_order_no:
              this.addToSprintData.selectedSprint.sprint_order_no,
            sprint_id: this.addToSprintData.selectedSprint.sprint_id,
            type: typeData.story_type_name,
            l3: checkedData.l3_value,
            l3_value_id: checkedData.l3_value_id,
            type_id: typeData.type_id,
          });
        }
      } else if (this.addedL3s?.length > 0) {
        checkedData.selected = false;
        // TODO: deselect selected l3 checkedData.selected = false;
        this.addedL3s = this.addedL3s.filter(
          (obj) =>
            !(
              obj.type == typeData.story_type_name &&
              obj.l3 == checkedData.l3_value
            )
        );
      }
    }
  }

  l3Selection(checkedFlag,l3){
      l3.selected=checkedFlag;
      for(const type of l3.type_level_data)
      {
        type.selected=checkedFlag;
      }

      this.allSelected = this.unique_sprint_wise_l3s_list.every(l3_obj => l3_obj.selected);
  }

  updateAllTypeSelected(l3)
  {
    l3.selected = l3.type_level_data.every(t => t.selected);
    this.allSelected = this.unique_sprint_wise_l3s_list.every(t => t.selected);
  }

  someTypeSelected(l3)
  {
    return l3.type_level_data.filter(t => t.selected).length > 0 && !l3.selected;
  }

  someL3Selected()
  {
    return this.unique_sprint_wise_l3s_list.filter(t => t.selected).length > 0 && !this.allSelected;
  }

  allSelection(checkedFlag){
    this.allSelected=checkedFlag;
    for(const l3 of this.unique_sprint_wise_l3s_list)
    {
      l3.selected=checkedFlag;
      for(const type of l3.type_level_data)
      {
        type.selected=checkedFlag;
      }
    }
  }
  
  isApplyEnable(){
     return this.unique_sprint_wise_l3s_list.some(l3=>{
         if(l3.selected)
         {
           return true;
         }
         else{
           return l3.type_level_data.some(type=>type.selected);
         }
     })
  }

  totalTypesSelected(){
    let totalTypes=0;
    for(const l3 of this.unique_sprint_wise_l3s_list)
    {
      totalTypes+=l3.type_level_data.filter(t=>t.selected).length;
    }

    return totalTypes;
  }

    // to highlight search key in search result
    highlightSearchResult(optionName) : string {
      let temp = '  ' + optionName;
      if(temp.indexOf(this.searchTerm)) {
          var re = new RegExp(this.searchTerm, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
          return temp.replace(re, "<mark>$&</mark>");
      }
      return optionName;
    }

    getDottedValue(number: any) {
      let arrayValue = Array.from(number + '');
      let dottedValue = arrayValue.join(".").concat(". ");
      return dottedValue;
    }


  onCancel() {
    this.dialogRef.close(false);
  }

  onApply() {
    let typeList = [];
    for(const l3 of this.unique_sprint_wise_l3s_list)
    {
      for(const type of l3.type_level_data)
      {
        if(type.selected){
          typeList.push({
              storyType: type.story_type_name,
              l3Name: l3.l3_value,
              sourceSprintName: type.l4_group[0].sprint_order_no
          });
        }
      }
    }
    const submitUrl = `${environment.API_MICROSERVICE_URL.PROJECT}/sprint-planning/sprint-capacity/sprints/calculation/save/addToSprint/${this.addToSprintData.projectId}`;
    const postData = {
       l1Id: this.addToSprintData.l1_obj.l1_value_id,
       functionId: this.addToSprintData.context.package.functionId,
       activityId: this.addToSprintData.context.selectedActivity.entityId,
       isJiraProject: this.addToSprintData.isJiraProject,
       languageId: this.addToSprintData.languageId,
       targetSprintName: this.addToSprintData.selectedSprint.sprint_name,
       l3List: typeList
    };

    this.sharedService.postData(submitUrl, postData).subscribe((l1Data) => {
      if (l1Data) {
        this.dialogRef.close(l1Data);
      } else {
        this.dialogRef.close('error');
      }
    }, (err) => {
      this.dialogRef.close('error');
    });

  }
}
