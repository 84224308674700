<div [ngStyle]="{'margin-left':router.url==='/home'? '42px':'0px'}" class="filter-bar">

  <!-- [ngStyle]="{'background-color': filterBarHeadColor ? filterBarHeadColor : 'rgb(0,151,169,0.2)', 'opacity': filterLoaded && filters?.length == 0 ? '0.6':'1'}" -->

  <div class="filter-component" *ngIf="router.url!='/home'">
    <!-- <img src="../../../assets/filter-icon.svg" height="20px" /> -->
    <span class="medium-font">{{filterBarHead}}</span>
    <span style="padding-left: 20px; line-height: 1.5;font-size: 25px; color: rgb(117,120,123,1)">|</span>
    <!-- <div class="right-triangle"
      [ngStyle]="{'border-left-color': filterBarHeadColor ? filterBarHeadColor : 'rgb(0,151,169,0.2)', 'opacity': filterLoaded && filters?.length == 0 ? '0.6':'1'}">
    </div> -->
  </div>

  <div class="filter-component" style="padding: 0.25em 0em" *ngIf="router.url==='/home'">
    <!-- <img src="../../../assets/filter-icon.svg" height="20px" /> -->
    <span class="medium-font">Filters</span>
    <span style="padding-left: 18px; line-height: 1.5;font-size: 25px; color: #0097A9">|</span>
    <!-- <div class="right-triangle"
      [ngStyle]="{'border-left-color': filterBarHeadColor ? filterBarHeadColor : 'rgb(0,151,169,0.2)', 'opacity': filterLoaded && filters?.length == 0 ? '0.6':'1'}">
    </div> -->
  </div>

  <!-- <div class="filter-component" *ngIf="router.url==='/home'">
    <!-- <img src="../../../../../assets/Ellipse 1.png" class="filter-image" title="abc">
    <p class="first-filter-number">1</p> -->
  <!-- <span class="medium-font" >Filters</span>
    <div class="filter-vertical"></div>
  </div> -->

  <!-- Disable filters for 6032 Prioratize user stories activity -->
  <ng-container *ngIf="!activityName || (activityName && activityName.toUpperCase() != 'PRIORITIZE USER STORIES')">
    <div *ngFor="let filter of filters;let p=index" style="margin-top: 3px;">
      <!-- <div class="industry-filter filter-component" *ngIf="filter?.l1Filter?.childValuesLength > 0"> -->
      <div class="industry-filter filter-component"
        *ngIf="((view == 'PROJECT') && ((hidestatusfilter && filter.title == 'Status') || (hideSprintFilter && filter.title == 'Sprint')) ? false : (view == 'PROJECT') && ((!hidestatusfilter && filter.title == 'Status') || (!hideSprintFilter && filter.title == 'Sprint')) ? true : filter?.l1Filter?.childValuesLength > 0)">
        <button *ngIf="!showAttributeFilter(filter) || this.sharedService.selectedPackage.entityName == 'SAP ERP'"
          mat-button (click)="filterClicked($event, filter)" (mouseover)="onHover(filter)"
          matTooltip="{{hoveredString}}" matTooltipClass="filter-tooltip" matTooltipPosition="below">
          <span style="font-size: 14px;">{{filter.title}}</span>
          <i class="fas fa-chevron-down"></i>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="industry-filter filter-component"
    *ngIf="(filterLoaded && filters?.length == 0) || (activityName && activityName.toUpperCase() == 'PRIORITIZE USER STORIES')">
    <span class="filter-disable">Filters are not applicable for {{tabName}} tab</span>
  </div>
</div>
<!-- style="display: block;margin: 10px 0;content: ' ';" -->

<!-- <br> -->
<!-- <div class="filter-bar-text" *ngIf="router.url==='/home'">
  <img src="../../../../../assets/Ellipse 1.png" class="filter-image" title="abc">
  <p class="filter-number">2</p>
  <p class="filter-text">Select a train stop below to start your journey</p>
</div> -->