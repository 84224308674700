import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FilterOverlay } from '../../filter-overlay/filter-overlay.service';
import { FilterContentComponent } from '../../filter-custom/filter-content/filter-content.component';
import { FilterData, FilterArray } from '../../../model/filter-content.model';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DfteService } from 'src/app/shared/services/dfte.service';

@Component({
  selector: "app-filter-list",
  templateUrl: "./filter-list.component.html",
  styleUrls: ["./filter-list.component.scss"],
})
export class FilterListComponent implements OnInit {
  @Output() parentEmitter = new EventEmitter();
  @Input() filters: any[];
  @Input() filterBarHead: string;
  @Input() filterBarHeadColor: any;
  @Input() filterLoaded: boolean;
  @Input() tabName: string;
  @Input() readOnly: boolean;
  @Input() activityId: any;
  @Input() activityName: string;
  @Input() view: string;
  @Input() projectId: string;
  // @Input() jiraProject: string;
  @Input() tabCode: string;
  @Input() jiraProvisionInstance: any;
  hoveredString: string;
  hidestatusfilter: boolean = false;
  hideSprintFilter: boolean = false;
  almInstanceCheck: boolean;
  constructor(
    private filterOverlay: FilterOverlay,
    public router: Router,
    public sharedService: SharedService,
    private dfteService: DfteService
  ) { }

  ngOnInit() {
    if (this.view == 'PROJECT' &&
      ((this.tabCode == 'USER_STORIES' && this.activityId == '6026') ||
        (this.tabCode == 'TEST_SCENARIOS' && this.activityId == '6027') ||
        (this.tabCode == 'USER_STORY_SPRINT_CONFIGURE' && this.activityId == '6149') ||
        (this.tabCode == 'USER_STORY_SPRINT' && this.activityId == '6010') ||
        (this.tabCode == 'USER_STORY_SPRINT_CEREMONY' && this.activityName.toUpperCase() == 'EXPERIENCE: EXECUTE SPRINT CEREMONIES'))
    ) {
      this.checkForALMInstance();
    }
    // console.log("Jira Project: ", this.jiraProvisionInstance, " Tab code: ", this.tabCode, " Activity ID: ", this.activityId);
  }

  showAttributeFilter(filter): boolean {
    if (this.sharedService.selectedPackage && (this.sharedService.selectedPackage.entityName != 'SAP ERP') && (filter.title.toLowerCase() == 'Attributes'.toLowerCase())) {
      return true
    }
    return false;
  }
  statusFilterForNonJiraProjects() {
    // if (this.jiraProvisionInstance == false) {
    if (this.activityId == 6026 && this.tabCode == "USER_STORIES") {
      this.hidestatusfilter = true;
    } else if (
      (this.activityId == 6149 &&
        this.tabCode == "USER_STORY_SPRINT_CONFIGURE") ||
      (this.activityId == 6010 && this.tabCode == "USER_STORY_SPRINT") ||
      (this.activityName.toUpperCase() == 'EXPERIENCE: EXECUTE SPRINT CEREMONIES' &&
        this.tabCode == "USER_STORY_SPRINT_CEREMONY")
    ) {
      this.hidestatusfilter = true;
      this.hideSprintFilter = true;
    }
    // }
  }

  checkForALMInstance() {
    this.dfteService.getDfteObj()
      .subscribe((response) => {
        console.log(response);
        for (let item of response) {
          if (item.entityName.toLowerCase() == "alm") {
            if (item.entityEnabledFlag == 'Y' && item.status == 'Approved') {
              this.almInstanceCheck = true;
            }
            else {
              this.almInstanceCheck = false;
            }
          }
          else if (item.entityName.toLowerCase() == "jira") {
            if (item.entityEnabledFlag == null || item.entityEnabledFlag == "N") {
              this.statusFilterForNonJiraProjects();
            }
          }
        }
        if (this.activityId == 6027 && this.view == "PROJECT") {
          this.hidestatusfilter = !this.almInstanceCheck;
        }
      });
  }
  filterClicked(event, filterObj: FilterData) {
    this.showAttributeFilter(filterObj);
    filterObj.readOnly = this.readOnly;
    const ref = this.filterOverlay.open<FilterData>({
      content: FilterContentComponent,
      origin: event.target.parentElement,
      data: filterObj,
      height: "0%",
    });

    ref.afterClosed$.subscribe((res) => {
      if (res.data) {
        this.parentEmitter.emit(res.data);
      }
    });
  }

  onHover(filterObj: FilterData) {
    this.hoveredString = "";

    if (filterObj.title == "Processes") {
      this.hoveredString += this.formHoverString(
        filterObj.l1Filter.filterValues
      );
    } else {
      this.hoveredString += this.formHoverString(
        filterObj.l1Filter.filterValues
      );
      this.hoveredString += this.formHoverString(
        filterObj.l2Filter.filterValues
      );
      this.hoveredString += this.formHoverString(
        filterObj.l3Filter.filterValues
      );
      this.hoveredString += this.formHoverString(
        filterObj.l4Filter.filterValues
      );
    }
  }

  formHoverString(filterArray: FilterArray[]) {
    let title = "";
    let hoveredArray = [];

    if (filterArray) {
      for (let obj of filterArray) {
        if (obj.childValues) {
          for (let childEle of obj.childValues) {
            if (
              hoveredArray.indexOf(childEle.entityName) == -1 &&
              childEle.selectedFlag == "Y"
            ) {
              if (!title) title = (childEle.entityType && childEle.entityType != "") ? childEle.entityType + ": " : "";
              hoveredArray.push(childEle.entityName);
            }
          }
        }
      }

      if (hoveredArray) {
        title += hoveredArray.join(", ") + "  ";
      }
    }

    return title;
  }
}