<div class="card-header">
  <div class="row">
    <div class="col-6">
      <h2 class="title">{{'Global.Common.Label.Successful' | translate}}</h2>
      <p class="para"> {{text | translate}}</p>
    </div>
    <div class="col-6">
      <!-- check for the dismiss icon later -->
      <i class="icon close" (click)="dismiss()"></i>
    </div>
  </div>
</div>
