import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { INTERNAL_USER, MODAL_PRIMARY_TEXT, UPLOAD_POPUP } from '../my-projects/utility/constants';
import { BaseService } from 'shared-lib';
import { HttpServiceHelper } from 'src/app/types/common/HttpServiceHelper';
import { environment } from 'src/environments/environment';
import { CommonUploadPopupComponent } from './manage-users/common-upload-popup/common-upload-popup.component';
import { saveAs } from 'file-saver';
import { AcknowledgeModalComponent } from './acknowledge-modal/acknowledge-modal.component';
import { catchError, finalize, shareReplay, tap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { ProjectUserResponse } from './utility/interfaces';

@Injectable({
  providedIn: 'root',
})
export class MyProjectsService {
  graphApiUrl = 'https://graph.microsoft.com/v1.0/users';
  private userMicroserviceUrl: string;
  private BASE_PROJECT_URL: string;
  private projectUserCache = null; 
  private cacheProjectId: number | null = null;  
  private isFetchingProjectUsers = false;   
  private fetchProjectUsersSubject = new Subject<ProjectUserResponse>();

  constructor(private baseService: BaseService, private httpService: HttpServiceHelper, private dialog: MatDialog ) {
    this.userMicroserviceUrl = environment.API_MICROSERVICE_URL?.USER;
    this.BASE_PROJECT_URL = environment.API_MICROSERVICE_URL?.PROJECT;
  }

  getSuperUsers() {
    return this.baseService.get(`/user/superusers`);
  }

  addAminUser(data) {
    return this.baseService.post('/user/add-admin', data);
  }

  addUser(data) {
    return this.baseService.post('/project/save-project-users', data, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  getProjectUsers(projectId:any, langId) {
    return this.baseService.get(`/project/project-users/all?projectId=${projectId}&languageId=${langId}`, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  getProjectInfo(projectId: any,languageId=1) {
    return this.baseService.get(`/project/` + projectId + `/${languageId}`,environment.API_MICROSERVICE_URL?.PROJECT);
    
  }

  // getProjectUsers(projectId:any, langId){
  //     const normalizedProjectId = Number(projectId); 
  //   if (this.projectUserCache && this.cacheProjectId === normalizedProjectId) {      
  //     return of(this.projectUserCache);     
  //   }     
  //   if (this.isFetchingProjectUsers && this.cacheProjectId === projectId) {          
  //     return this.fetchProjectUsersSubject.asObservable(); 
  //       }     
  //   if (this.cacheProjectId !== normalizedProjectId) {    
  //     this.projectUserCache = null;   
  //       this.cacheProjectId = normalizedProjectId;    
  //   }     
  //   this.isFetchingProjectUsers = true;  
  //     return this.baseService.get(`/project/project-users/all?projectId=${projectId}&languageId=${langId}`, environment.API_MICROSERVICE_URL?.PROJECT)       
  //   .pipe(        
  //   tap((data) => {               
  //   this.projectUserCache = data;          
  //   this.fetchProjectUsersSubject.next(data);    
  //   this.isFetchingProjectUsers = false;             
  //   }),        
  //   catchError((error) => {                
  //       this.fetchProjectUsersSubject.error(error);       
  //       return of(null);        
  //   }),        
  //   finalize(() => {         
  //     this.isFetchingProjectUsers = false;         
  //     this.fetchProjectUsersSubject.complete();          
  //     this.fetchProjectUsersSubject = new Subject<ProjectUserResponse>();  
  //       }),       
  //     shareReplay(1) 
  //     );  
  // }

  getRejectedUser(projectId: any) {
    return this.baseService.get('/project/Rejected-Users?projectId=' + projectId, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  deleteProjectUser(options:any){
    return this.baseService.deleteByObject('/project/delete-project-user', options, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  deleteAdminUser(adminDetails:any){
    return this.baseService.deleteByObject('/user/delete-admin', adminDetails, this.userMicroserviceUrl);
  }

  softDeleteProject(options:any, BASE_URL=this.BASE_PROJECT_URL){
    return this.baseService.deleteByObject('/project', options,BASE_URL);
  }

  getProjectRoles(langId){
    return this.baseService.get(`/user-management/roles?languageId=${langId}`, this.userMicroserviceUrl);
  }

  getUsersFromGraphApi(value) {
    const params = new HttpParams().set('$filter', "((startswith(displayName,'" + value + "') or startswith(mail,'" + value + "')))")
    return this.httpService.httpGetRequestWithParams(this.graphApiUrl, params)
}

  getProjectDomains(projectId: any) {
    return this.baseService.get(`/project/project-domains?projectId=${projectId}` , environment.API_MICROSERVICE_URL?.PROJECT);
  }

  addExternalUser(data) {
    return this.baseService.post(`/external-user/registerUser`, data, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  downloadUserData(data) {
    return this.baseService.getBlobAsJson(`user/download-users`, data, this.userMicroserviceUrl);
  }

  openUploadPopup(projectId, domainData, featureConfigurationObj) {
    return this.dialog.open(CommonUploadPopupComponent, {
      data: {
        title: UPLOAD_POPUP.TITLE,
        primaryButtonText: UPLOAD_POPUP.CONFIRM_BUTTON,
        secondaryButtonText: UPLOAD_POPUP.CANCEL_BUTTON,
        message:  UPLOAD_POPUP.MESSAGE,
        projectId: projectId,
        domainData: domainData,
        featureConfigurationObj: featureConfigurationObj
      },
      disableClose: true,
      autoFocus: false,
      width: '25vw',
    });
  }

  uploadFileData(url, file) {
    return this.baseService.uploadFile(url, file, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  downloadTemplate(userType){
    return this.baseService.getBlobAsJson(`project/templatedownload/${userType}`, null, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  createFileNameAndSave(data) {
    let filename = data.headers
    .get('content-disposition')
    .split('filename=')[1]
    .split(';')[0];
    filename = filename.replaceAll('"', '');
    const type = data.headers.get('content-type');
    const blob = new Blob([data.body], { type });
    saveAs(blob, filename);
  }

  commonErrorSuccessPopup(primaryHeader: string, bodyMessage: string, popupData?: any){
    return this.dialog.open(AcknowledgeModalComponent, {
      width:'600px',
      data: {
        primaryHeader: primaryHeader,
        bodyMessage: bodyMessage,
        popupData: popupData ? popupData : null,
        primaryButton: MODAL_PRIMARY_TEXT,
      },
      disableClose: true,
    })
  }

  getMasterUserRole(langId){
    return  this.baseService.get(`/user/access-user-roles?languageId=${langId}`,this.userMicroserviceUrl)
  }

  getCanAddExternalUserForProject(projectId){
    return this.baseService.get(`/user/externalUserProjectAccess/${projectId}`, this.userMicroserviceUrl);
  }
}
