import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of, BehaviorSubject, Subject, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpServiceHelper } from 'src/app/types/common/HttpServiceHelper';
import { CryptUtilService } from './crypt-util.service';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
import { take } from 'rxjs/operators';
import { SpinnerComponent } from '@projects/workspace/src/app/my-projects/spinner/spinner.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BaseService } from '@projects/shared-lib/src/public-api';
import { saveAs } from 'file-saver';
import { SSOUSERI, User } from '../constants/ascend-user-info';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';
import { REPORT_GENERATION_STATUS, REPORT_STATUS_TYPE } from '../constants/common-dialogue-box';

export interface userData {
	first_name: string,
	last_name: string,
	user_name: string,
	email_id: string,
	designation: string,
	job_title: string
}

@Injectable({
	providedIn: 'root',
})
export class SharedService {

	private searchLinkURL: string = `${environment.BASE_URL}/searchlinks`;
	private currentTabIndex = new BehaviorSubject<number>(0);
	private jiraProjectKey = new BehaviorSubject<string>("");
	public tabIndexShare = this.currentTabIndex.asObservable();
	public aboutScreen = new Subject();
	private bgColor = new BehaviorSubject<string>("");
	public bgColorShare = this.bgColor.asObservable();
	private redirectToPsg: BehaviorSubject<boolean>;

	public tabCountEvent = new EventEmitter();
	public defineRoadmapSprintPlan = new EventEmitter();
	public dataChangeEvent = new EventEmitter();

	public toggled: string = '';
	public currentTab: string = '';
	filterSelected: boolean = false;
	public IsImportedSelected: boolean = false;
	public IsFailedSelected: boolean = false;
	public activitySelectedFn: any;
	public UserPermissionData: any;
	public selectedPackage: any = this.cryptUtilService.getItem('SELECTED_PACKAGE', 'SESSION') ? this.cryptUtilService.getItem('SELECTED_PACKAGE', 'SESSION') : undefined;
	public isSitemapPackageReset: boolean = false;
	public languageFlag: BehaviorSubject<number>;
	public currentLangId: BehaviorSubject<number>;
	public userRoleData :any;
	public activeFlags: BehaviorSubject<any>;

	publishBgColor(color) {
		this.bgColor.next(color);
	}

	public docAddEvent = new EventEmitter();

	public toggledEvent = new EventEmitter();

	public selectedPageEvent = new EventEmitter();

	public selectedTabEvent = new EventEmitter();

	public summaryFilterEvent = new EventEmitter();
	public getDataEvent = new EventEmitter();
	public getDataEventAlm = new EventEmitter();
	public userData: BehaviorSubject<any> = new BehaviorSubject<userData>(null);


	options: any[] = [
		{
			value: 'Leading Practices',
			route: '/imagine/architect/Leading Practices',
			tab: 'Leading Practices',
			L0: '',
			L1: '',
			L2: '',
			industry: '',
			sector: '',
			region: '',
			persona: ''
		},
		{
			value: 'Userstories',
			route: '/imagine/architect/Userstories',
			tab: 'Userstories',
			L0: '',
			L1: '',
			L2: '',
			industry: '',
			sector: '',
			region: '',
			persona: ''
		},
		{
			value: 'Business Processes',
			route: '/imagine/architect/Business Processes',
			tab: 'Business Processes',
			L0: '',
			L1: '',
			L2: '',
			industry: '',
			sector: '',
			region: '',
			persona: ''
		},
		{
			value: 'ERP Configurations',
			route: '/imagine/architect/ERP Configurations',
			tab: 'ERP Configurations',
			L0: '',
			L1: '',
			L2: '',
			industry: '',
			sector: '',
			region: '',
			persona: ''
		}
	];

	public updateTaxonomySuccessful: BehaviorSubject<boolean>;
	public disableDefineRoadmapBtn: BehaviorSubject<boolean>;
	public disableSprintPlanSaveBtn: BehaviorSubject<boolean>;
	public disableSprintPlanApplyAllTracksBtn: BehaviorSubject<boolean>;
	public disableSprintPlanResetBtn: BehaviorSubject<boolean>;
	public disableGenerateSprintBtn: BehaviorSubject<boolean>;
	public disableConfirmAllGenerateSprintBtn: BehaviorSubject<boolean>;
	public disableFinalizeSprintEditIcon: BehaviorSubject<Object>;
	public disableFinalizeSprintSaveBtn: BehaviorSubject<boolean>;
	public disableFinalizeSprintConsolidateBtn: BehaviorSubject<boolean>;
	public disableFinalizeSprintConfirmBtn: BehaviorSubject<boolean>;
	public enablePrioritizeUSViewMode: BehaviorSubject<boolean>;
	public enablGenerateSprintViewMode: BehaviorSubject<boolean>;
	public enableFinalizeSprintViewMode: BehaviorSubject<boolean>;
	public isFinalizeSprintConfirmAll: BehaviorSubject<boolean>;
	public isFinalizeSprintConfirmL1: BehaviorSubject<String>;
	public userPermissionDataUpdated: BehaviorSubject<boolean>;
	public hideNavigationBar: BehaviorSubject<boolean>;
	public isExternalSignupCompleted: BehaviorSubject<boolean>;
	public userRelatedAPI$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public isFeatureFlagUpdated: BehaviorSubject<boolean>;


	public isFinalizeSprintPageDirty = false;
	public defineRoadmapData = [];
	private generateSprintData = [];
	private _finalizeSprintPayload = [];
	private _generateSprintPayload = {
		assignedSprints: {
			sprintCapacities: [],
			children: []
		}
	};
	private finalizeSprintMasterData = [];
	private dialogRef;
	private reportPollingTimer;
	private reportRequestId;
	private reportRequestPayload;
	private reportUserId;
	private reportType: string;
	private packageContentMicroservice: string;

	constructor(private http: HttpClient, private baseService: BaseService, private router: Router, private httpService: HttpServiceHelper, public cryptUtilService: CryptUtilService, public dialog: MatDialog, private globalContext: ActivitiesGlobalContextService,
	) {
		this.updateTaxonomySuccessful = new BehaviorSubject(false);
		this.disableDefineRoadmapBtn = new BehaviorSubject(false);
		this.disableSprintPlanSaveBtn = new BehaviorSubject(false);
		this.disableSprintPlanResetBtn = new BehaviorSubject(false);
		this.disableSprintPlanApplyAllTracksBtn = new BehaviorSubject(false);
		this.disableGenerateSprintBtn = new BehaviorSubject(true);
		this.disableConfirmAllGenerateSprintBtn = new BehaviorSubject(false);
		this.disableFinalizeSprintEditIcon = new BehaviorSubject(false);
		this.disableFinalizeSprintSaveBtn = new BehaviorSubject(false);
		this.disableFinalizeSprintConsolidateBtn = new BehaviorSubject(true);
		this.disableFinalizeSprintConfirmBtn = new BehaviorSubject(true);
		this.enablePrioritizeUSViewMode = new BehaviorSubject(true);
		this.enablGenerateSprintViewMode = new BehaviorSubject(true);
		this.enableFinalizeSprintViewMode = new BehaviorSubject(true);
		this.isFinalizeSprintConfirmAll = new BehaviorSubject(null);
		this.userPermissionDataUpdated = new BehaviorSubject(false);
		this.hideNavigationBar = new BehaviorSubject(false);
		this.isExternalSignupCompleted = new BehaviorSubject(null);
		this.isFeatureFlagUpdated = new BehaviorSubject(false);
		this.redirectToPsg = new BehaviorSubject(false);
		this.languageFlag = new BehaviorSubject(null);
		this.currentLangId = new BehaviorSubject(null);
		this.packageContentMicroservice = environment.API_MICROSERVICE_URL.REPORTING;
		this.activeFlags = new BehaviorSubject(false);
	}

	getTabs(): Observable<any[]> {
		//  return of(this.options);
		return this.http.get<any>(this.searchLinkURL);
	}

	getIndex(tabs, URL) {
		URL = decodeURI(URL);
		URL = URL.charAt(0) == '/' ? URL.substr(1) : URL
		let currentTab = tabs.find(t => t.route == URL)
		if (currentTab) {
			this.currentTabIndex.next(currentTab.tab);
		}
	}

	getPackagePref(package_id: number, function_id: number, feature_id: number, langId) {
		return this.http.get(`${environment.API_MICROSERVICE_URL.ADMIN}/package/preferences/${package_id}/${function_id}/${feature_id}?languageId=${langId}`);
	}

	getFeaturesData(id: any) {
		return this.http.get(environment.API_MICROSERVICE_URL?.ADMIN + '/package/features/0');
	}

	getData(URL): Observable<any[]> {
		return this.http.get<any>(URL);
	}

	postData(URL, body): Observable<any> {
		return this.http.post<any>(URL, body);
	}

	getUserData(): Observable<any> {
		return this.userData.asObservable();
	}

	setUserData(data: userData) {
		this.userData.next(data);
	}

	saveUserData(userData) {
		return this.http.post<any>(`${environment.API_MICROSERVICE_URL?.USER}/user/add`, userData);
	}

	getADUser(emailId): Observable<User> {
		return this.httpService.httpGetRequestWithParams(`https://graph.microsoft.com/v1.0/users`, new HttpParams().set('$filter', "((startswith(displayName,'" + emailId + "') or startswith(mail,'" + emailId + "')))"))
			.map(res => {
				const obj = (res as any).value[0];
				const userObject: userData = {
					first_name: obj.givenName,
					last_name: obj.surname,
					user_name: obj.displayName,
					email_id: obj.mail,
					designation: 'SOFTWARE ENGINEER',
					job_title: obj.jobTitle
				}
				this.setUserData(userObject);
				const user: User = new User();
				let email = obj.mail;

				if (email) {
					user.userId = obj.mail;
					user.userName = obj.displayName;
					user.ssoUser = obj;
				}
				return user;
			})
	}

	checkDataForDownload(url: string) {
		return this.http.get<any>(url);
	}

	checkDataForDownloadStories(url: string) {
		return this.http.get<any>(url);
	}
	checkDataForDownloadSprintStories(url: string) {
		return this.http.get<any>(url);
	}
	checkDataForDownloadValidStories(url: string) {
		return this.http.get<any>(url);
	}
	checkDataForJiraInstance(projectId: String, params?: any): any {
		return this.http.get<any>(`${this.baseService.BASE_URL}/project/${projectId}`, { params });
	}

	setUploadTaxanomyStatus(success: boolean) {
		this.updateTaxonomySuccessful.next(success);
	}

	checkSequenceNumber(tabCode: string) {
		const params = new HttpParams()
			.set('tab_code', tabCode);
		return this.http.get(environment.BASE_URL + "/architect/getSequenceNumberByTabCode", { params });
	}

	isSprintPlanIdAvailable(tabData, tabName?) {
		if (tabData) {
			if (tabName) {
				this.defineRoadmapData = [];
				this.defineRoadmapData = tabData;
			}
			for (let i = 0; i < tabData.length; i++) {
				if (tabData[i] && tabData[i].sprint_plan) {
					let sprintArr = tabData[i].sprint_plan[0].sprint_plan_id;
					if (!sprintArr) {
						this.disableDefineRoadmapBtn.next(true);
						break;
					} else {
						this.disableDefineRoadmapBtn.next(false);
					}
				}
			}
		}
	}

	updateDefineRoadmapData(defineRoadmapL1Data) {
		for (let i = 0; i < this.defineRoadmapData.length; i++) {
			let obj = this.defineRoadmapData[i];
			if (obj.l1 == defineRoadmapL1Data.l1) {
				obj.sprint_plan = defineRoadmapL1Data.sprint_plan;
			}
		}
		this.isSprintPlanIdAvailable(this.defineRoadmapData);
	}

	setProvisionTool(id, tool) {
		return this.http.post<any>(`${environment.API_BASE_URL}/tools/imagine/launchjourney/provisiontools/${id}/${tool[0].entityName}`, tool);
	}

	setProvisionApp(id, app) {
		return this.http.post<any>(`${environment.API_BASE_URL}/tools/dfte/projectProvisioning/${id}`, app);
	}

	setJiraProjectKey(data){
		return this.jiraProjectKey.next(data);
	}

	getJiraProjectKey(){
		return this.jiraProjectKey.asObservable();
	}

	setProvisionUser(id, app) {
		return this.http.post<any>(`${environment.BASE_URL}/dfteUserProvision/${id}`, app);
	}
	setSmartForceProvisionUser(id, app) {
		return this.http.post<any>(`${environment.BASE_URL}/smartforceUserProvision/${id}`, app);
	}
	setSmartForceProvisionApp(id, app) {
		return this.http.post<any>(`${environment.API_BASE_URL}/tools/dfte/projectProvisioning/${id}`, app);
	}

	checkDataForALMInstance(projectId: String): any {
		return this.http.get<any>(`${environment.API_MICROSERVICE_URL?.PROJECT}/tools/dfteTools/${projectId}`);
	}

	confirmDefineRoadmapStep2(projectId, isJiraProject, queryParams) {
		return this.http.put<any>(`${environment.API_BASE_URL}/imagin/refineuserstories/sprints/confirm/${projectId}/${isJiraProject}?${queryParams}`, {});
	}

	resetDefineRoadmapStep2(projectId, bodyParams) {
		return this.http.post<any>(`${environment.API_BASE_URL}/imagin/refineuserstories/sprints/reset/${projectId}`, bodyParams);
	}

	saveDefineRoadmapStep2(functionPackageURL, activityId, projectUniqueId, projectId, isJiraProject, bodyParams) {
		return this.http.post<any>(`${environment.API_BASE_URL}/imagin/refineuserstories/sprints/save${functionPackageURL}/${activityId}/${projectUniqueId}/${projectId}/${isJiraProject}`, bodyParams);
	}

	saveAllDefineRoadmapStep2(functionPackageURL, activityId, projectUniqueId, projectId, isJiraProject, bodyParams) {
		return this.http.post<any>(`${environment.API_BASE_URL}/imagin/refineuserstories/sprints/saveAll${functionPackageURL}/${activityId}/${projectUniqueId}/${projectId}/${isJiraProject}`, bodyParams);
	}

	confirmAllDefineRoadmapStep2(functionPackageURL, activityId, projectUniqueId, projectId, isJiraProject, bodyParams) {
		return this.http.post<any>(`${environment.API_BASE_URL}/imagin/refineuserstories/sprints/confirmAll${functionPackageURL}/${activityId}/${projectUniqueId}/${projectId}/${isJiraProject}`, bodyParams);
	}

	checkDataForConfirmAllPrioritizeUS(url, requiredTabData) {
		if (requiredTabData) {
			return this.http.post<any>(url, requiredTabData);
		}
	}

	isGenerateSprintAllL3Confirmed(tabData, tabName?) {
		if (tabData) {
			if (tabName) {
				this.generateSprintData = [];
				this.generateSprintData = tabData;
			}
			for (let i = 0; i < tabData.length; i++) {
				if (!tabData?.[i].isconfirm_sprint_us_allocation) {
					this.disableConfirmAllGenerateSprintBtn.next(false);
					break;
				} else {
					this.disableConfirmAllGenerateSprintBtn.next(true);
				}
			}
		}
	}

	isGenerateSprintAnyL3Confirmed(tabData, tabName?) {
		if (tabData) {
			if (tabName) {
				this.generateSprintData = [];
				this.generateSprintData = tabData;
			}
			for (let i = 0; i < tabData.length; i++) {
				if (tabData?.[i].isconfirm_sprint_us_allocation) {
					this.disableGenerateSprintBtn.next(false);
					break;
				} else {
					this.disableGenerateSprintBtn.next(true);
				}
			}
		}
	}

	updateGenerateSprintData(l1_value) {
		if (l1_value) {
			for (let i = 0; i < this.generateSprintData.length; i++) {
				let obj = this.generateSprintData[i];
				if (obj.l1_value == l1_value) {
					obj['isconfirm_sprint_us_allocation'] = true;
				}
			}
			this.isGenerateSprintAllL3Confirmed(this.generateSprintData)
			this.isGenerateSprintAnyL3Confirmed(this.generateSprintData)

		}
	}

	updateConfirmFlagGenerateSprintData() {
		for (let i = 0; i < this.generateSprintData.length; i++) {
			let obj = this.generateSprintData[i];
			obj['isconfirm_sprint_us_allocation'] = true;
		}
		this.isGenerateSprintAllL3Confirmed(this.generateSprintData)
		this.isGenerateSprintAnyL3Confirmed(this.generateSprintData)
	}

	getSiteMapData(projectId: String, packageId, functionId) {
		return this.http.get<any>(`${environment.API_MICROSERVICE_URL.PROJECT}/sitemap-details/${projectId}?packageId=${packageId}&functionId=${functionId}`);

	}
	checkjiraexport(projectId: String): Observable<boolean> {
		return this.http.get<boolean>(`${environment.API_BASE_URL}/deliver/isexportedtojira/${projectId}`);
	}

	saveDefineRoadmapStep3(projectId, bodyParams) {
		return this.http.post<any>(`${environment.BASE_URL}/refineuserstories/finalizesprints/save/${projectId}`, bodyParams);
	}
	confirmpollingDefineRoadmapStep3(projectId, bodyParams) {
		return this.http.post<any>(`${environment.API_BASE_URL}/process/start/TargetSprintMapping?projectid=${projectId}`, bodyParams);
	}
	getDFTEUsersData(projectId): Observable<any> {
		return this.http.get<any>(`${environment.BASE_URL}/projectDfteUsers/${projectId}`);
	}

	getDFTEAppsData(projectId): Observable<any> {
		return this.http.get<any>(`${environment.BASE_URL}/dfte/digitalaccelerators/${projectId}/3`);
	}
	getSmartForceUsersData(projectId): Observable<any> {
		return this.http.get<any>(`${environment.BASE_URL}/projectSmartforceUsers/${projectId}`);
	}

	getSmartForceAppsData(projectId): Observable<any> {
		return this.http.get<any>(`${environment.BASE_URL}/smartforce/amplifiers/${projectId}/4`);
	}

	confirmDefineRoadmapStep3(projectId, bodyParams) {
		return this.http.post<any>(`${environment.BASE_URL}/refineuserstories/sprints/generateinjira/${projectId}`, bodyParams);
	}
	updateProvisionDetails(projectId, tool, bodyParams) {
		return this.http.post<any>(`${environment.BASE_URL}/imagine/launchjourney/updateprovisiondetails/${projectId}/${tool}`, bodyParams);
	}

	imaginecheckjiraexport(projectId: String): Observable<boolean> {
		return this.http.get<boolean>(`${environment.BASE_URL}/imagine/isexportedtojira/${projectId}`);
	}

	public get finalizeSprintPayload() {
		return this._finalizeSprintPayload;
	}
	public set finalizeSprintPayload(value) {
		this._finalizeSprintPayload = value;
	}

	public get generateSprintPayload() {
		return this._generateSprintPayload;
	}
	public set generateSprintPayload(value) {
		this._generateSprintPayload = value;
	}
	public checkIfAllUsAreRefined(l2_group) {
		let flag = true;
		l2_group.forEach((l2, index) => {
			l2.l3_group.forEach(l3 => {
				l3.l4_group.forEach(l4 => {
					if (l4.status == 'Unrefined') {
						flag = false
					}
				});
			});
		});
		return flag;
	}

	public getfinalizeSprintMasterData() {
		return this.finalizeSprintMasterData;
	}

	public setfinalizeSprintMasterData(value, l1?) {
		if (l1) {
			this.finalizeSprintMasterData.forEach((a) => {
				if (a && a.l1_tab === l1) {
					a.tabContent[0].sprint = [];
					a.tabContent[0].sprint = value.sprint;
				}
			});
		} else {
			let result = [];
			if (value.length) {
				result = result.concat(value);
			}
			this.finalizeSprintMasterData = JSON.parse(JSON.stringify(result));
		}
		console.log(this.finalizeSprintMasterData, l1);
	}

	public confirmL1finalizeSprintMasterData(l1?) {
		if (l1) {
			for (let i = 0; i < this.finalizeSprintMasterData.length; i++) {
				if (this.finalizeSprintMasterData[i]?.l1_tab == l1) {
					for (let j = 0; j < this.finalizeSprintMasterData[i]?.tabContent?.[0].sprint.length; j++) {
						this.finalizeSprintMasterData[i].tabContent[0].sprint[j].isSprintsFinalizeForJira = true;
					}
				}
			}
		}
	}

	public isAlll1ConfirmfinalizeSprintMasterData() {
		let isAllConfirmFlag = true;
		for (let i = 0; i < this.finalizeSprintMasterData.length; i++) {
			if (!this.finalizeSprintMasterData[i].tabContent?.[0].sprint?.[0].isSprintsFinalizeForJira) {
				isAllConfirmFlag = false;
			}
		}

		return isAllConfirmFlag;
	}

	public isMoreThanOneL1RemainToConfirmfinalizeSprintMasterData() {
		let isMoreThanOneL1RemainToConfirmFlag = false;
		let confirmCount = 0;
		for (let i = 0; i < this.finalizeSprintMasterData.length; i++) {
			if (this.finalizeSprintMasterData[i].tabContent?.[0].sprint?.[0].isSprintsFinalizeForJira) {
				confirmCount++;
			}
		}

		if ((this.finalizeSprintMasterData.length - confirmCount) > 1) {
			isMoreThanOneL1RemainToConfirmFlag = true;
		}
		return isMoreThanOneL1RemainToConfirmFlag;
	}

	public setUnsavedFlagfinalizeSprintMasterData(flag, l1?) {
		if (l1) {
			for (let i = 0; i < this.finalizeSprintMasterData.length; i++) {
				if (this.finalizeSprintMasterData[i].l1_tab == l1) {
					this.finalizeSprintMasterData[i].isFinalizeSprintPageDirty = flag;
				}
			}
		}
		else {
			for (let i = 0; i < this.finalizeSprintMasterData.length; i++) {
				this.finalizeSprintMasterData[i].isFinalizeSprintPageDirty = flag;
			}
		}
	}

	public checkIfUnsavedFlagExistfinalizeSprintMasterData() {
		let finalizeUnsaveFlag = false;
		for (let i = 0; i < this.finalizeSprintMasterData.length; i++) {
			if (this.finalizeSprintMasterData[i].isFinalizeSprintPageDirty) {
				finalizeUnsaveFlag = true;
			}
		}
		return finalizeUnsaveFlag;
	}
	openAboutPage(aboutScreenShow) {
		this.aboutScreen.next(aboutScreenShow);
	}

	openSpinner(): void {
		this.dialogRef = this.dialog.open(SpinnerComponent, {
			height: '74px',
			width: '74px',
			panelClass: 'custom-dialog-container'
		});
		this.dialogRef.afterOpened().subscribe(() => {
			timer(1000).pipe(take(1)).subscribe(() => {

			});
		})
	}

	closeSpinner() {
		this.dialogRef?.close();
	}


	downloadFile(downloadlink) {
		return this.http.get(`${downloadlink}`, {
			responseType: 'blob' as 'json',
			observe: 'response'
		}).subscribe(
			(data: any) => {
				const fileName = data.headers.get('content-disposition')
					.split(';')[1].split('filename')[1].split('=')[1].trim().split('"').join('');
				const type = data.headers.get('content-type');
				const blob = new Blob([data.body], { type });
				saveAs(blob, fileName);
			},
			err => {
				console.log(err);
			});
	}

	getMsUserDetails(emailId) {
		return this.httpService.httpGetRequestWithParams(`https://graph.microsoft.com/v1.0/users`, new HttpParams().set('$filter', "((startswith(displayName,'" + emailId + "') or startswith(userPrincipalName,'" + emailId + "')))"))
			.map(res => {
				const obj = (res as any)?.value?.[0];
				return obj;
			});
	}

	getUserPermissionDataUpdated(): Observable<any> {
		return this.userPermissionDataUpdated.asObservable();
	}

	setExternalUserDataUpdated(data) {
		this.userPermissionDataUpdated.next(data);
	}

	getExternalUserData(pageName?) {
		let externalUserData = this.cryptUtilService.getItem("external-user-data", "SESSION");
		return pageName ? { isExternalUser: externalUserData?.isExternalUser, pages: externalUserData?.pages[pageName] } : externalUserData;
	}

	getHideNavigationBar(): Observable<any> {
		return this.hideNavigationBar.asObservable();
	}

	setHideNavigationBar(data) {
		this.hideNavigationBar.next(data);
	}

	getIsExternalSignupCompleted(): Observable<any> {
		return this.isExternalSignupCompleted.asObservable();
	}

	setIsExternalSignupCompleted(data) {
		this.isExternalSignupCompleted.next(data);
	}

	callUserRelatedAPI(): Observable<boolean> {
		return this.userRelatedAPI$.asObservable();
	}

	getDesignCompleteStatus(projectId) {
		return this.baseService.get(`/project/getStatus/isDesignComplete/${projectId}`, environment.API_MICROSERVICE_URL?.PROJECT);
	}

	triggerUserRelatedAPI(data: boolean) {
		this.userRelatedAPI$.next(data);
	}

	getIsFeatureFlagUpdated(): Observable<any> {
		return this.isFeatureFlagUpdated.asObservable();
	}

	setIsFeatureFlagUpdated(data) {
		this.isFeatureFlagUpdated.next(data);
	}

	getFeatureConfiguration(property?) {
		const configurationObj = this.cryptUtilService.getItem("commonFeatureFlag", "LOCAL");
		return property ? configurationObj[property] : configurationObj;
	}

	setRedirectToPsg(data: boolean) {
		this.redirectToPsg.next(data);
	}

	getRedirectToPsg(): Observable<boolean> {
		return this.redirectToPsg.asObservable();
	}
	requestReportDownload(data?,url='/ReportsGeneration/RequestReport') {
		if (data != undefined)
			this.reportRequestPayload = data
		else
			data = this.reportRequestPayload
		this.reportRequestPayload.reportStatus = REPORT_GENERATION_STATUS.NEW_REPORT
		this.reportRequestPayload.reportFilters.ReportRequestId = 0
		return this.baseService.post(url, data,this.packageContentMicroservice)
	}

	lookForReport(reportRequestId: number) {
		const url = `${environment.API_MICROSERVICE_URL.REPORTING}/ReportsGeneration/ReportStatus/${reportRequestId}`;
		this.getReportRequestIdAndStatus(url)
		this.reportPollingTimer = setInterval(() => {
			this.getReportRequestIdAndStatus(url)
		}, 5000)
		return this.reportPollingTimer
	}
	downloadReport(reportRequestId: number) {
		let url: string = `${environment.API_MICROSERVICE_URL.REPORTING}/ReportsGeneration/Report`;
		url = url + `?reportrequestId=${reportRequestId ?? ''}`;
		var data = this.http.post(url, {}, {
			responseType: 'blob',
			observe: 'response'
		});

		data.subscribe(
			(data: any) => {
				const fileName = data.headers.get('Content-Disposition');
				const type = data.headers.get('Content-Type');
				const blob = new Blob([data.body], { type });
				saveAs(blob, fileName);
				this.globalContext.setNewReport({ active: true, status: 'COMPLETED' })
				this.deleteReportFromBlobRequest()
			},
			err => {
				this.globalContext.setNewReport({ active: true, status: 'ERROR' })
			});
	}
	getPackagesAndProjectStatuses() {
		return this.baseService.get(`/ReportsGeneration/ReportFilters`,this.packageContentMicroservice)
	}
	getUserRole(userId, langId) {
		return this.http.get<any>(`${environment.API_BASE_URL}/user-management/${userId}?languageId=${langId}`)
	}
	getSupportedLanguages() {
		return this.http.get(`${environment.API_MICROSERVICE_URL.ADMIN}}/i18n/supportedLanguages`);
	}
	updatePreferredLanguage(body) {
		return this.http.post(`${environment.API_MICROSERVICE_URL.USER}/user-preferences/language`, body);
	}

	getPreferredLanguage() {
		return this.http.get(`${environment.API_MICROSERVICE_URL.ADMIN}/i18n/languages`);
	}
	getReportRequestIdAndStatus(url: string) {
		const headers = new HttpHeaders().set('skip', 'true');
		this.http.get<any>(url, { headers})
			.subscribe(_ => {
				this.reportRequestId = _.requestID
				if (_.reportStatus == REPORT_STATUS_TYPE.READY_FOR_DOWNLAOD) {
					clearInterval(this.reportPollingTimer)
					this.downloadReport(_.requestID as number)
				}
				else if (_.reportStatus == REPORT_STATUS_TYPE.ERROR) {
					this.globalContext.setNewReport({ active: true, status: 'ERROR' })
					clearInterval(this.reportPollingTimer)
				}
			}, _ => {
				clearInterval(this.reportPollingTimer)
				this.globalContext.setNewReport({ active: true, status: 'ERROR' })
			})
	}
	cancelAndRetryReportGeneration(retryRequest = false) {
		clearInterval(this.reportPollingTimer)
		this.reportRequestPayload.reportFilters.ReportRequestId = this.reportRequestId
		if (retryRequest) {
			this.reportRequestPayload.reportStatus = REPORT_GENERATION_STATUS.RETRY
			this.baseService.post(`/ReportsGeneration/CancelorRetryRequest`, this.reportRequestPayload, this.packageContentMicroservice)
				.subscribe(_ => {
					this.lookForReport(this.reportRequestId)
				}, _ => {
					this.globalContext.setNewReport({ active: true, status: 'ERROR' })
				})
		}
		else {
			this.reportRequestPayload.reportStatus = REPORT_GENERATION_STATUS.CANCEL_REPORT
			this.baseService.post(`/ReportsGeneration/CancelorRetryRequest`, this.reportRequestPayload, this.packageContentMicroservice)
				.subscribe(_ => { })
		}
	}
	getOwenedTeamChannels() {
		return this.baseService.getWithoutLoader(`/project/get-owned-channels`,environment.API_MICROSERVICE_URL?.PROJECT)
	}
	postMsTeamsChannel(body) {
		return this.baseService.post(`/project/link-existing-channel`, body,environment.API_MICROSERVICE_URL?.PROJECT)
	
	}

	getTypesOfReports() {
		return this.baseService.get(`/ReportsGeneration/ReportTypes`, this.packageContentMicroservice)
	}

	deleteReportFromBlobRequest() {
		this.baseService.delete(`/ReportsGeneration/DeleteBlobFile/${this.reportRequestId}`, this.packageContentMicroservice)
			.subscribe(_ => { })
	}

	setUpdatedLangId(data) {
		this.languageFlag.next(data);
	}

	getUpdatedLangId(): Observable<any> {
		return this.languageFlag.asObservable();
	}

	setCurrentLanguage(data) {
		this.currentLangId.next(data);
	}

	getCurrentLanguage(): Observable<any> {
		const currentLangId = this.currentLangId.asObservable();
		currentLangId.subscribe((langId) => {
			if (!langId) {
				this.setCurrentLanguage(1);
			}
		});
		return this.currentLangId.asObservable();
	}

	getUserDetails() {
		return this.baseService.get(`/user/details`, environment.API_MICROSERVICE_URL?.USER)
	}

	getActiveFlag(): Observable<any> {
		return this.activeFlags.asObservable();
	}

	setActiveFlag(data) {
		this.activeFlags.next(data);
	}

	getFullPackageData()
	{
		return this.http.get(`${environment.API_MICROSERVICE_URL.METHOD}/package/getFiltersForDeliverablesReport`);
	}
	getToolsUtilizationReportFiltersData()
	{
		return this.http.get(`${environment.API_MICROSERVICE_URL.ADMIN}/tools/toolsUtilizationReport/filters`);
	}
	
	/**
	 * GetTypesOfReportsForProjectSummary: This method is to fetch
	 * project summary report types
	 */
	getTypesOfReportsForProjectSummary = () =>  this.baseService.get(`/ReportsGeneration/ReportTypes/Project`, this.packageContentMicroservice)
	
	/**
	 * Fetches the release banner details from the server.
	 *
	 * @returns Observable
	 */
	getReleaseBannerDetails = () =>
		this.http.get(
		`${environment.API_MICROSERVICE_URL?.ADMIN}/tools/release-banner-details`
		);
}