import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ICountObj } from '../core/utility/ICountObj';
import { CommonPopupComponent } from './shared/common-popup/common-popup.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root'
})

export class LanguageSettingsService {
  private isUnsavedData$: BehaviorSubject<boolean>;
  private isBlankData$: BehaviorSubject<boolean>;
  private publishData$: BehaviorSubject<boolean>;
  private searchText$: BehaviorSubject<string>;
  private subHeaderAction$: BehaviorSubject<{}>;

  constructor(private http: HttpClient, private dialog: MatDialog) {
  this.isUnsavedData$ = new BehaviorSubject(false);
  this.isBlankData$ = new BehaviorSubject(false);
  this.publishData$ = new BehaviorSubject(false);
  this.subHeaderAction$ = new BehaviorSubject({});
  this.searchText$ = new BehaviorSubject('');

  }

  private selectedLanguage$: BehaviorSubject<any> = new BehaviorSubject({});
  private prevSelectedLanguage$: BehaviorSubject<any> = new BehaviorSubject({});

  // Used below static variable for temporary purpose till microservice setup for admin module is done
  private adminMicroservice: string = environment.API_MICROSERVICE_URL.ADMIN;
  private countObj :  BehaviorSubject<ICountObj> = new BehaviorSubject(null);
  private domainModuleObject : BehaviorSubject<any> = new BehaviorSubject({});

  setSearchText(searchText: string) {
    this.searchText$.next(searchText);
  }

  getSearchText(): Observable<string> {
    return this.searchText$.asObservable();
  }

  setCount(obj){
    this.countObj.next(obj);
  }
  getCount(){
    return this.countObj.asObservable();
  }

  getSelectedLanguage(): Observable<any> {
    return this.selectedLanguage$.asObservable();
  }

  setSelectedLanguage(languageInfo: any) {
    this.selectedLanguage$.next(languageInfo);
  }

  getPrevSelectedLanguage(): Observable<any> {
    return this.prevSelectedLanguage$.asObservable();
  }

  setPrevSelectedLanguage(languageInfo: any) {
    this.prevSelectedLanguage$.next(languageInfo);
  }

  getSupportedLanguages() {
    return this.http.get<any>(
        `${this.adminMicroservice}/i18n/supported-languages`
    );
  }

  getLanguageTranslationsTableData(pageChangeObj, translateToLanguageId, payload) {
    let reqObj = {pageIndex: pageChangeObj?.pageIndex ? pageChangeObj?.pageIndex + 1 : 1,
      pageSize : pageChangeObj?.pageSize ? pageChangeObj?.pageSize : 10
    };
    return this.http.post(`${this.adminMicroservice}/i18n/getTranslatedValues/${reqObj.pageIndex}/${reqObj.pageSize}/${translateToLanguageId}`, payload);
  }

  getLanguageTranslationsTableData_Metadata(pageChangeObj, translateToLanguageId, payload) {
    let reqObj = {pageIndex: pageChangeObj?.pageIndex ? pageChangeObj?.pageIndex + 1 : 1,
      pageSize : pageChangeObj?.pageSize ? pageChangeObj?.pageSize : 10
    };
    return this.http.post(`${this.adminMicroservice}/i18n/getMetadataTranslatedValues/${reqObj.pageIndex}/${reqObj.pageSize}/${translateToLanguageId}`, payload);
  }


  publishTranslations(languageId, payload) {
    return this.http.post<any>(
      `${this.adminMicroservice}/i18n/publish/static-translations?languageId=${languageId}`, payload
    );
  }

  publishTranslations_MetaData(languageId, domain, module, payload) {
    return this.http.post<any>(
      `${this.adminMicroservice}/i18n/publish/metaData-translations?languageId=${languageId}&domainName=${domain}&moduleName=${module}`, payload
    );
  }

  setIsUnsavedData(obj){
    this.isUnsavedData$.next(obj);
  }
  getIsUnsavedData(){
    return this.isUnsavedData$.asObservable();
  }

  setIsBlankRecordsPresent(obj) {
    this.isBlankData$.next(obj);
  }
  getIsBlankRecordsPresent() {
    return this.isBlankData$.asObservable();
  }

  setPublishData(obj){
    this.publishData$.next(obj);
  }

  getPublishData(){
    return this.publishData$.asObservable();
  }


  getSubHeaderAction(){
    return this.subHeaderAction$.asObservable();
  }

  setSubHeaderAction(obj){
    this.subHeaderAction$.next(obj);
  }

  openCommonModal(title, message, primaryButtonText, secondaryButtonText, cancelButton, isBlankData?,selectedLanguage?) {
    this.dialog.closeAll();
    return this.dialog.open(CommonPopupComponent, {
      data: {
        title: title,
        primaryButtonText: primaryButtonText,
        secondaryButtonText: secondaryButtonText,
        cancelButtonText: cancelButton,
        message: selectedLanguage ? message.replace('#languagename', selectedLanguage) : message,
        isBlankData: isBlankData ? isBlankData : false
      },
      panelClass: 'user-dialog-container',
    });
  }

  getModule() {
    return this.http.get<any>(`${this.adminMicroservice}/i18n/meta-data/module-list`);
  }

  getDomainModule() {
    return this.domainModuleObject.asObservable();
  }

  setDomainModule(payload) {
    this.domainModuleObject.next(payload);
  }

  uploadStaticLabels(languageId, payload){
    return this.http.post<any>(
      `${this.adminMicroservice}/i18n/upload-translations?languageId=${languageId}`, payload, { responseType: "json" });
  }

  uploadStaticMetaData(languageId,domain,module,payload){
    return this.http.post<any>(
      `${this.adminMicroservice}/i18n/upload/metadata-translations?languageId=${languageId}&domain=${domain}&module=${module}`, payload, { responseType: "json" });
  }
}
