import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenance-break',
  templateUrl: './maintenance-break.component.html',
  styleUrls: ['./maintenance-break.component.scss']
})
export class MaintenanceBreakComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
