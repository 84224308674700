import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CryptUtilService } from 'src/app/shared/services/crypt-util.service';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';
import { HighlightSearch } from '@projects/maturity-assessment/src/app/shared/pipes/highlight-search.pipe';

@Injectable({
  providedIn: 'root'
})
export class SitemapService {

  public filteredData = new Subject<any>();
  public projectInfo = new Subject<any>();
  public filters = new Subject<any>();
  private sitemapURLProject: string = `${environment.API_MICROSERVICE_URL.PROJECT}/`;
  private sitemapFilterURLProject: string = `${environment.API_MICROSERVICE_URL.PROJECT}/`;
  private sitemapURLMethod: string = `${environment.API_MICROSERVICE_URL.METHOD}/`;
  private sitemapFilterURLMethod: string = `${environment.API_MICROSERVICE_URL.METHOD}/`;
  public setIsSitemapExplore = new Subject();
  public data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public exploreData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public functionId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public packageId: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(private http: HttpClient,
    private cryptUtilService: CryptUtilService,
    private userPrefService: UserPreferenceService
  ) { }

  getSitemapFiltersData(projectId): Observable<any> {
    if (!+projectId) {
      return this.http.get<any>(this.sitemapFilterURLMethod + 'explore/sitemap-details/filters/' + "?ampdel=1&attributes=1&source=sitemap");
    } else {
      return this.http.get<any>(this.sitemapFilterURLProject + 'sitemap-details/filters/' + projectId + "?ampdel=1&attributes=1&source=sitemap");
    }
  }

  getSitemapPageData(projectId: string | number = null, packageId: number = null, functionId: number = null, isSAP: boolean = false, languageId: number = null): Observable<any> {
    if (+projectId === 0) {
      return this.http.get<any>(this.sitemapURLMethod + 'explore/sitemap-details/' + "?packageId=" + packageId + "&functionId=" + functionId + "&isSAP=" + isSAP + "&languageId=" + languageId);
    } else {
      return this.http.get<any>(this.sitemapURLProject + 'sitemap-details/sitemap-details' + "?projectId=" + projectId + "&packageId=" + packageId + "&functionId=" + functionId + "&isSAP=" + isSAP + "&languageId=" + languageId);
    }
  }


  getProjectInfo(projectId: any) {
    return this.http.get(this.sitemapURLProject + 'project/' + projectId);
  }

  setFilteredData(data) {
    this.filteredData.next(data);
  }
  setProjectInfo(data) {
    this.projectInfo.next(data);
  }
  setFilters(data) {
    this.filters.next(data);
  }

  getData(dataVal: any): void {
    this.data.next(dataVal);
  }
  setData(): Observable<any> {
    return this.data.asObservable();
  }
  getAttributeFilter(dataVal: any): void {
    this.data.next(dataVal);
  }
  setAttributeFilter(): Observable<any> {
    return this.data.asObservable();
  }
  getExploreData(dataVal: any): void {
    this.exploreData.next(dataVal);
  }
  setExploreData(): Observable<any> {
    return this.exploreData.asObservable();
  }

  /**
   * SetSearchData: method will set search result variables 
   * (functionId and packageId) for sitemap (Project Mode)
   * 
   * @param packageId Integer
   * @param functionId Integer
   */
  setSearchData = (packageId?: number, functionId?: number) => {
    const functionName = this.cryptUtilService.getItem('Previous-Session-Function-Selected-IIDR-Project', 'SESSION')
    
    if (!functionName) return;

    if (functionId) {
      this.functionId.next(functionId);
      return;
    }

    packageId ? this.packageId.next(packageId) 
    : this.projectInfo.subscribe((data) => this.packageId.next(data.package_id))
   
    this.setFunctionId(functionName);
  }

  /**
   * SetFunctionId : method will set selected functionId (BehaviorSubject) 
   * 
   * @param functionName String
   */
  setFunctionId = (functionName: string) => {
    this.userPrefService.getUserPackageFunction(this.packageId.value)
      .subscribe((functionData) => 
        functionData.data.forEach((item) => 
          item.packageFunctionName === functionName && this.functionId.next(item.packageFunctionId)
        )
      )
  }

  /**
   * SetSearchExploreData : method will set search result variables 
   * (functionId and packageId) for sitemap (Explore Mode)
   * 
   * @param packageId Integer
   * @param functionId Integer
   */
  setSearchExploreData = (packageId?: number, functionId?: number) => {
    const functionName = this.cryptUtilService.getItem('Previous-Session-Function-Selected-IIDR-Project', 'SESSION'), 
    functionExploreName = this.cryptUtilService.getItem('Previous-Session-Function-Selected-IIDR-Explore', 'SESSION'),
    filterData = this.cryptUtilService.getItem("Session-Sitemap-Filter", "SESSION");
    
    if (functionName) return;

    if (packageId && functionId) {
      this.packageId.next(packageId);
      this.functionId.next(functionId);
      return;
    }

    filterData.forEach(element => {
      const label = element.filterLabel,
      val = element.filterValues[0];

      label === 'Packages' &&
      val.childValues.forEach(child => {
        if (child.selectedFlag === 'Y') {
          this.packageId.next(child.entityId);
          this.setFunctionId(functionExploreName);
        }
      })
    })
  }

}
