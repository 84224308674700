export class FilterArray {
    entityId: string;
    entityType: string;
    entityName: string;
    filterSelectType: string;
    changed?: string
    childValues: ChildArray[];
}

export class ChildArray {
    entityId: string;
    entityType: string;
    entityName: string;
    selectedFlag?: string;
    packageId?: string[];
    childValues?: any[];
    parentId?: string;
}

export class FilterContentModel {
    parentFilterId: string;
    filterLabel: string;
    filterId: string;
    advFilterApplicable?: string;
    filterValues: FilterArray[];
    childValuesLength?: number;
    filterSelectType?: any;
}

export class FilterData {
    title: string;
    readOnly?: boolean;
    l1Filter: FilterContentModel;
    l2Filter?: FilterContentModel;
    l3Filter?: FilterContentModel;
    l4Filter?: FilterContentModel;
}

export enum FILTER_CUSTOM_CONSTANTS {
    SITEMAP_FILTER = 'sitemap_filter',
    IIDR_FILTER = 'iidr_filter',
    DELIVERABLES = 'deliverables',
    SINGLE = 'Single',
    MULTIPLE = 'Multiple',
    ADVANCED_FILTER = 'advanced_filter'
}

export function formFilterArray(data) {
    let filterComponents: FilterData[] = [];

    if (data) {
        let l1Filters = data.filter(t => (t.parentFilterId == null));
        
        

        for (let obj of l1Filters) {

            let l1Obj = obj;
            l1Obj.childValuesLength = calculateLength(l1Obj);
            let l2Obj = formLevelObject(data, l1Obj);
            let l3Obj = formLevelObject(data, l2Obj);
            let l4Obj = formLevelObject(data, l3Obj);

            filterComponents.push({
                title: obj.filterLabel,
                l1Filter: l1Obj,
                l2Filter: l2Obj,
                l3Filter: l3Obj,
                l4Filter: l4Obj
            });
        }
    }

    return filterComponents;
}

export function calculateLength(obj: FilterContentModel) {
    let count = 0;
    if(obj && obj.filterValues) {
        count = obj.filterValues.reduce(reducer, 0)
    }
    return count;
}

function reducer(accumulator, currentValue: FilterArray) {
    return accumulator + (currentValue.childValues ? currentValue.childValues.length : 0 );
}

function formLevelObject(data, parentElement) {

    let obj;

    if (parentElement) {
        obj = data.find(t => (t.parentFilterId) && t.parentFilterId == parentElement.filterId);
        obj ? obj.childValuesLength = calculateLength(obj) : null;
    }

    return obj ? obj : new FilterContentModel()
}