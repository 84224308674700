import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@projects/shared-lib/src/public-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ESTABLISH_PROJECT_TOOLS } from '@projects/shared-lib/src/lib/core/polling-constants';
import { PollingService } from '@projects/shared-lib/src/lib/services/polling.service';

@Injectable({
  providedIn: 'root'
})
export class ToolsAcceleratorsControlsService {

  public toolData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public featureData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public clickData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public moduleData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private packageContentMicroservice: string = environment.API_MICROSERVICE_URL.PACKAGE_CONTENT;
  private projectMicroservice: string = environment.API_MICROSERVICE_URL.PROJECT;
  private adminMicroServiceUrl: string = environment.API_MICROSERVICE_URL.ADMIN;
  private userMicroserviceUrl: string;
  constructor(private http: HttpClient, private baseService: BaseService, private pollingService: PollingService) {
    this.userMicroserviceUrl = environment.API_MICROSERVICE_URL?.USER;
  }

  requestToolsInstance(projectId: string): any {
    const exploreModeEndpoint = `/tools/dfteTools/explore`;
    const projectModeEndpoint = `/tools/dfteTools/${projectId}`;
    const url = projectId == '0' ? exploreModeEndpoint : projectModeEndpoint;
    const baseUrl = projectId == '0' ? this.adminMicroServiceUrl : this.projectMicroservice;
    return this.baseService.get(url,baseUrl);
  }

  getAcceleratorAppsData(projectId): Observable<any> {
    const exploreModeEndpoint = '/tools/dfte/digitalaccelerators/explore/3';
    const projectModeEndpoint = `/tools/dfte/digitalaccelerators/${projectId}/3`;
    const url = projectId == '0' ? exploreModeEndpoint : projectModeEndpoint;
    const baseUrl =  projectId == '0' ? this.adminMicroServiceUrl : this.projectMicroservice;
    return this.baseService.get(url,baseUrl);
  }

  getDFTEUsersData(projectId, isSmartForceEstablish): Observable<any> {
    if (isSmartForceEstablish) {
      return this.baseService.get(
        `/tools/projectSmartforceUsers/${projectId}`, this.projectMicroservice
      );
    }
    else {
      return this.baseService.get(`/tools/projectDfteUsers/${projectId}`, this.projectMicroservice);
    }
  }

  getECUsersData(projectId,toolId?): Observable<any> {
    return this.baseService.get(`/tools/projectToolUsers/${projectId}/${toolId}`, this.projectMicroservice);
  }

  getToolUserRoleData(toolId): Observable<any> {
    return this.baseService.get(`/tools/user-roles/${toolId}`, this.projectMicroservice);
  }



  setDFTEManageUsers(projectId, data) {
      return this.baseService.post(
        `/tools/dfte/user-provisioning/${projectId}`,
        data, this.projectMicroservice
      );
  }

  setSmartforceManageUsers(projectId, data) {
      return this.baseService.post(
        `/tools/smartforce/user-provisioning/${projectId}`,
        data, this.projectMicroservice
      );
  }

  provisionECManageUsers(projectId, data) {
    return this.baseService.post(`/tools/user-provisioning/${projectId}`,data, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  getToolDetails(appId, languageId) {
    return this.baseService.get(`/marketplace/tool-info?toolid=${appId}&languageId=${languageId}`,this.packageContentMicroservice);
  }

  setProvisionApp(projectId, data, isSmartForceEstablish) {
    if (isSmartForceEstablish) {
      return this.baseService.post(
        `/tools/smartforce/project-provisioning/${projectId}`,
        data, this.projectMicroservice
      );
    } else {
      return this.baseService.post(
        `/tools/dfte/projectProvisioning/${projectId}`,
        data, this.projectMicroservice
      );
    }
  }

  setToolProvision(id, tool) {

    if(tool?.entityName) {
      return this.http.post<any>(`${environment.API_MICROSERVICE_URL?.PROJECT}/tools/provision/${id}/${tool.entityName}`, JSON.parse(JSON.stringify(tool)));
    }
    return this.http.post<any>(`${environment.API_MICROSERVICE_URL?.PROJECT}/tools/provision/${id}/${tool[0].entityName}`, tool);
  }

  public sendToolData(data: any): void {
    this.toolData.next(data);
  }

  getToolData(): Observable<any> {
    return this.toolData.asObservable();
  }

  public sendFeatureData(data: any): void {
    this.featureData.next(data);
  }

  getFeatureData(): Observable<any> {
    return this.featureData.asObservable();
  }

  public sendValue(val: any): void {
    this.clickData.next(val);
  }

  getValue(): Observable<any> {
    return this.clickData.asObservable();
  }

  updateParams(projectId, feature, toolData) {
    return this.http.post<any>(`${environment.API_MICROSERVICE_URL.PROJECT}/tools/updateprovisiondetails/${projectId}/${feature}`, toolData);
  }

  getaccessToMSTeamTool(postData) {
    const exportToTeamsDurableUrl = `/api/process/start/JoinMSTeamsActivity?projectid=${postData?.projectId}&featureId=${postData?.featureId}`;
    const url = `${environment.API_BASE_URL}${exportToTeamsDurableUrl}`;
    this.http.post(url, {}).subscribe(
      (data: any) => {
        this.pollingService.startPolling(data.instanceId, ESTABLISH_PROJECT_TOOLS);
        this.pollingService.getPollingData().subscribe((res) => { });
      },
      (err) => {
      }
    );
  }

  public sendModuleData(data: any): void {
    this.moduleData.next(data);
  }

  getModuleData(): Observable<any> {
    return this.moduleData.asObservable();
  }

  getUserStorytypes(projectId) {
    return this.http.get<any>(`${environment.API_BASE_URL}/deliver/userStoryTypes/${projectId}`);
  }

  getChangeRequestStatus(projectId) {
    return this.baseService.get(`/psg/scope_change_request/details/${projectId}`,environment.API_MICROSERVICE_URL.PROJECT);
  }

  getExportedUsersData(projectId): Observable<any> {
    return this.baseService.get(`/user/export/project-users?projectId=${projectId}`, this.userMicroserviceUrl);
  }

  provisionTestAIUsers(data) {
    return this.baseService.post(`/user/export/project-users`, data, this.userMicroserviceUrl);
  }

  getUserStorySettingsStatus(projectId){
    return this.baseService.get(`/tools/getReverseIntegrationDetails/${projectId}`,environment.API_MICROSERVICE_URL.PROJECT); 
  }


  getTestAIProvisionInfo(toolId, projectId) {
    return this.baseService.get(`/tools/getTestAIProvisionInfo/${projectId}/${toolId}`, environment.API_MICROSERVICE_URL.PROJECT)
  }

  saveUserStoriesSettings(settings){
    return this.baseService.post('/tools/update-reverse-integration-details',settings, environment.API_MICROSERVICE_URL.PROJECT);
  }

  syncUserStories(projectId, packageId, functionId, activityId, userroleid, payload){
    let syncUSUrl = `${environment.API_MICROSERVICE_URL.PROJECT}/process/schedule/user_stories_sync?projectid=${projectId}&packageid=${packageId}&functionid=${functionId}&activityid=${activityId}&userroleid=${userroleid}`;
    return this.http.post(syncUSUrl, payload)
  }

  getGenAITools(projectId, packageId, toolCategoryId, languageId){
    return this.baseService.get(`/project/genai-amplifiers/${projectId}/${packageId}/${toolCategoryId}?languageId=${languageId}`, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  saveProjectToolConsent(projectId, toolId, body?){
    return this.baseService.post(`/project/saveConsent/${projectId}/${toolId}`, body, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  saveGenAiTools(projectId, toolCategoryId, body){
    return this.baseService.post(`/project/genaitools/${projectId}/${toolCategoryId}`, body, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  saveOtherTools(projectId, body){
    return this.baseService.post(`/project/tool-provision/${projectId}`, body, environment.API_MICROSERVICE_URL?.PROJECT);
  }

  getProjectInfo(projectId: any, userId) {
    return this.http.get(
      environment.API_MICROSERVICE_URL.PROJECT + '/project/' + projectId+`/?userid=${userId}`);
  }

  getMsTeamsStatus(projectId) {
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/tools/msteams-status/${projectId}`);
  }

  

}
