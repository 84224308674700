import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

interface acknowledgeMetaData {
  primaryHeader: string;
  bodyMessage?: string;
  primaryButton: string;
  secondaryButton?: string;
}

@Component({
  selector: 'app-acknowledge-modal',
  templateUrl: './acknowledge-modal.component.html',
  styleUrls: ['./acknowledge-modal.component.scss']
})
export class AcknowledgeModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: acknowledgeMetaData, public dialogRef: MatDialogRef<AcknowledgeModalComponent>) { }

  ngOnInit(): void {
  }

  onDialogClose(status) {
    this.dialogRef.close(status);
  }

}
