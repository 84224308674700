import { MasterData } from './masterdata.model'

export class Opportunity extends MasterData {
    CapabilityId: number;
    Description: string;
    AdditionalDetails: AttachmentDetails;
    OpportunityTypeIds: number[];
    CaseStudies: AttachmentDetails[];
    OpportunityTypes: OpportunityType[];
    VideoLink: string;
    IsPublic: boolean;
    MapId?: number;
    CapabilityAssigned: boolean;
    ClusterAssigned: boolean;
    HasAdditionalDetails: boolean;
    HasCaseStudies: boolean;
    AttachmentExist: boolean;
    ParentId?: number;
    MapType: MapType;
    IsDataAvailable(): Boolean {
        return this.Name.length > 0 && this.CapabilityId > 0;
    }
    BenefitDriver: BenefitDriver[];
    Duration?: number;
    IsSelectedForRoadMap: Boolean;
    StartMonth: number;
    RoadMapDuration: number;
    OpportunityId: number;
    driverList: string[];
    IsDeleted: boolean;
    Edit: boolean;
    nameExist?: boolean;
    CustomOpportunity: boolean;
    isSelected: boolean;

    constructor() {
      super();
      this.HasAdditionalDetails = !!(this.AdditionalDetails && this.AdditionalDetails.Id > 0);
      this.HasCaseStudies = (this.CaseStudies && this.CaseStudies.length > 0);
    }
}

export class AttachmentDetails extends MasterData {
    AttachmentId: number;
    Name: string;
    Data: any[];
    File: any;
}

export class OpportunityType extends MasterData {
    Definition: string;
    IconId: OpportunityTypeIcon;
    IsDeleted: Boolean;
    IconClass: any;
}
export class BenefitDriver extends MasterData {
    OpportunityId: number;
}
export enum OpportunityTypeIcon {
    Hana = 1,
    Analytics,
    Digital,
    Cloud,
    Sct,
    MAndA,
    RoboticsAndCognitive,
    CognitiveEngagement,
    CognitiveInsights
}
export enum MapType {
    CapabilityMap = 0,
    DigitalEnterpriseMap = 1
}
