<div class="contact-header">
  <span class="header-title">
    {{'Admin.Contacts.Popup.Title' | translate}}
  </span>
  <span class="region-toggle">
    <mat-button-toggle-group [value]="selectedRegion">
      <mat-button-toggle  *ngFor="let instance of globalInstances" [value]="instance.instance_name" (click)="selectedRegion = instance.instance_name; changeRegion($event, instance)">
        {{instance.instance_name}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </span>
</div>
<mat-divider></mat-divider>
<div class="contact-subheader">
  <div class="row">
    <div class="col-5">
      <mat-label class="filter-label"> {{ 'Global.Common.Label.Role' | translate }} </mat-label>
      <mat-select #roleSelect disableOptionCentering panelClass="custom-panel-role"
        placeholder="{{'Adminmgmt.Usermgmt.Header.Filters.Placeholder.SelectRole' | translate}}" name="roleSelected" (click)="$event.stopPropagation();" [(ngModel)]="selectedRoles" (selectionChange)="setRoles()" multiple>
        <mat-option class="mat-options-custom" *ngFor="let role of roles" [value]="role.name">
          {{ role.name }}
        </mat-option>
      </mat-select>
    </div>

    <div class="col-5">
      <mat-label class="filter-label"> {{ 'Global.Common.Label.Package' | translate }} </mat-label>
          <mat-select #packageSelect disableOptionCentering panelClass="custom-panel-package"
              placeholder="{{'Navigation.Menu.UserPreferences.Dialog.Form.Package.Placeholder' | translate}}" name="packageSelected"
              [(ngModel)]="selectedParentPackage" (click)="$event.stopPropagation();" [disabled]="disablePackageSelect"
              >
              <mat-option class="mat-options-custom" *ngFor="let package of packages"
                  [value]="package.id">
                  {{ package.name }}
              </mat-option>
          </mat-select>
    </div>

    <div class="col-2 reset-filter">
      <span class="reset-button" (click)="resetFilters($event)">{{'Global.Common.Button.Reset' | translate}}</span>
    </div>
  </div>
    <div class="search-box">
      <img class="search-img" src="assets/search-icon.png" alt="">
      <input class="search-input" matInput type="text" [(ngModel)]="searchTerm" autofocus
          autocomplete="off" placeholder="{{'Admin.Contacts.Popup.SearchPlaceholder' | translate}}" (click)="stopDefaultMenuClose($event);"
          >
        @if(searchTerm().length > 0){
        <img class="separate-img" src="assets/Separate.png" alt="">
        <img class="cross-img" (click)="clearSearch($event)" src="assets/cross.png"
          alt="">
        }

    </div>
  </div>
<div class="pinned-section" *ngIf="pinnedContactList?.length > 0">
  <mat-divider class="hor-line-2"></mat-divider>
  <div class="contact-designation"><span>Pinned
  </span></div>
  <div class="pinned-container">
  <div class="contact-details" *ngFor="let userItem of pinnedContactList">
    <span class="user-initials-white">
      {{userItem?.userInitials | uppercase}}
    </span>
    <div class="contact-sub-details">
      <span class="contact-user-name">
        {{userItem?.getUserName}}
      </span>
      <span class="contact-user-email">
        {{userItem.email_id}}
      </span>
      <span class="contact-role">
        {{userItem.role_name}}
      </span>
      <span class="contact-role" *ngIf="userItem.package" [matTooltip]="userItem.package" #tooltip="matTooltip"
          matTooltipPosition="above" matTooltipDisabled="{{userItem.package?.length < 20}}">
          {{userItem.package}}
      </span>
    </div>
    <div class="contact-copy-action">
      <button class="copy-button" (click)="copyEmailToClipboard(userItem.email_id);stopDefaultMenuClose($event);"><img
          src="../../../../assets/mail-icon.svg" /></button>
          @if (userItem?.pinnedStatus) {
            <button class="copy-button unpin-btn"
            (click)="pinUnpinContact(userItem);stopDefaultMenuClose($event);" mat-icon-button >
              <img src="../../../../assets/pinned-dark.svg" />
            </button>
          }
    </div>
  </div>
  </div>
</div>

<!-- <mat-divider></mat-divider> -->

<div class="contact-list-container" *ngIf="contactList()?.length > 0" (click)="$event.stopPropagation();">
  <div *ngFor="let contactItem of filteredContactList(); index as i">
    <div class="contact-designation" *ngIf="contactItem.users?.length > 0"><span>{{contactItem.roleTitle}}</span></div>
    <div class="contact-details" *ngFor="let userItem of contactItem.users;index as j">
      <span class="user-initials-white">
        {{userItem?.userInitials | uppercase}}
      </span>
      <div class="contact-sub-details">
        <span class="contact-user-name">
          {{userItem?.getUserName}}
        </span>
        <span class="contact-user-email">
          {{userItem.email_id}}
        </span>
        <span class="contact-role">
          {{userItem.role_name}}
        </span>
        <span class="contact-role" *ngIf="userItem.package" [matTooltip]="userItem.package" #tooltip="matTooltip"
          matTooltipPosition="above" matTooltipDisabled="{{userItem.package?.length < 20}}">
          {{userItem.package}}
        </span>
      </div>
      <div class="contact-copy-action">
        <button class="copy-button" (click)="copyEmailToClipboard(userItem.email_id);"><img
            src="../../../../assets/mail-icon.svg" /></button>
              <button class="copy-button pin-btn" [disabled]="userItem?.pinnedStatus || pinnedUserCount === MAX_LIMIT_PROJECT_PINNING" 
              (click)="pinUnpinContact(userItem);stopDefaultMenuClose($event);"
              [matTooltip]="(pinnedUserCount === MAX_LIMIT_PROJECT_PINNING) ? MAX_COUNT_OF_PINNING_CONTACTS_TOOLTIPTEXT : ''"
              #tooltip="matTooltip" matTooltipPosition="above" matTooltipClass="viewDetails-toolTipText" 
               mat-icon-button
              >
              <img [src]="userItem?.pinnedStatus ? '../../../../assets/pinned-dark.svg' : '../../../../assets/pinned-light.svg'" />
              </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="usersSearchStatus()">
    <div class="no-data-container">
      <img src="assets/search_feature.png" alt="" class="search-no-data-icon" />
      <div class="no-data-heading">{{'Admin.Contacts.Popup.NoResultsFound' | translate}}</div>
      <div class="no-data-text">{{'Global.Common.Label.NoResultsFromFilter' | translate}}</div>
    </div>
  </ng-container>
</div>

<div *ngIf="contactList().length === 0">
  <div class="no-contact-found"><span>{{"Navigation.Menu.NoContactsFound" | translate}}</span></div>
</div>