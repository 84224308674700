import { TestCasesService } from '@projects/trainstops/src/app/feature/test-cases/test-cases/service/test-cases.service';
import { BehaviorSubject } from 'rxjs';
import { SystemNotificationService } from './system-notification.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';
import { BUS_MESSAGE_KEY } from '../constants/message-bus';
import { MessagingService } from './messaging.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private readonly _baseUrl: string = environment.AZURE_CONNECTION;
  private userId: string;
  private testCaseGenerationData: any[] = [];

  constructor(
    private readonly testCaseService_: TestCasesService,
    private readonly sysNotificatioService_ : SystemNotificationService,
    private messagingBus: MessagingService
  ) {
    this.messagingBus.subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
      this.userId = data.userId;
    });
   }

  public startConnection = () => {
    const connection = new signalR.HubConnectionBuilder()
    .withUrl(this._baseUrl + `/api?userId=${this.userId}`)
    .configureLogging(signalR.LogLevel.Information)
    .build(); 
    connection
    .on('newMessage', (message) => { 
      this.sysNotificatioService_.setNotificationAction(true)
    });
    connection.on('testAIProgress', (data)=> {
      data = JSON.parse(data);
      this.createTestData(data);
      this.testCaseService_.setIsNewDataPushed(true);
    })     
    connection
    .start()
    .catch(console.error);
  }

  get getTestCaseGenerationData() {
    return this.testCaseGenerationData;
  }

  createTestData(data) {
    let isDataAvailable: boolean;
    this.testCaseGenerationData?.forEach((statusData,i) => {
      if(statusData?.project_id === data?.project_id) {
        this.testCaseGenerationData[i]=data
        isDataAvailable = true;
      }
    });
    if(!isDataAvailable) {
      this.testCaseGenerationData.push(data);
    }
  }

  removedata(projectId) {
    let removeIndex;
    this.testCaseGenerationData?.forEach((statusData,i) => {
      if(statusData?.project_id === projectId) {
        removeIndex = i;
      }
    });
    if(removeIndex >= 0){ 
      this.testCaseGenerationData?.splice(removeIndex, 1);
     }
  }

}
