import {MapFilter, FilterOutput, FilterDataItem} from './filter.model';

export class MapOverview {
    CanArchive: boolean;
    CanDelete: boolean;
    CanEdit: boolean;
    CanExportMap: boolean;
    CanMakePrivate: boolean;
    CanMakePublic: boolean;
    CanSaveAsNewMap: boolean;
    CanShare: boolean;
    CanUnArchive: boolean;
    CanUploadMap: boolean;
    CreatedBy: number;
    Description: string;
    HasProjectInfo: boolean;
    ImageName: string;
    IndustryName: string;
    IsArchived: boolean;
    IsDEM: boolean;
    IsFavorite: boolean;
    IsPublic: boolean;
    IsShared: boolean;
    MapId: number;
    MapType: string;
    MapTypeId: number;
    ModifiedDate: Date;
    Name: string;
    Owner: string;
    RoleName: string;
    SectorName: string;
    ProjectName: string;
    SharedBy: number;
    SharedOn: Date;
    SharedTo: number[];
    ShowSectorOverview: boolean;
    SurveyId: number;
    UserId: number;
    ValueMapId: number;
    WorkshopId: number;
    makePublic: boolean;
    HasError: boolean;
    HasInformation: boolean;
    HasMapTypeError: boolean;
    Errors: string[];
    publicmaptype: number;
    CanArchiveMap: boolean;
    CanDeleteMap: boolean;
    CanSeeValueMapSettings: boolean;
    CanShareMap: boolean;
    CanUpdateMap: boolean;
    IsCreator: boolean;
    IsPasswordProtected: boolean;
    IsPasswordRequired: boolean;
    IsSurveyAdmin: boolean;
    IsSurveyData: boolean;
    IsWorkshopAdmin: boolean;
    MapName: string;
    RoleId: number;
    SharedToUserIds: any[];
    ShowSurveyOverrideMessage: boolean;
    SurveyLock: boolean;
    SurveyStatusId: number;
    WorkShopStatus: number;
    ProjectStatus: number;
    IsConfiguredTestMap: boolean;
    IsDemoMap: boolean;

    constructor(data: any = {}) {
        this.HasError = data.HasError;

    }
}

export class MapStrategy {
    Action: number;
    Definition: string;
    Id: number;
    MapId: number;
    Name: string;
    StrategyId: number;
}

export class Sector extends FilterDataItem {
    CreatedBy: number;
    CreatedDate: Date;
    FirstName: string;
    Id: number;
    SectorGuid: any;
    ImageName: string;
    LastName: string;
    MapCount: number;
    ModifiedBy: number;
    ModifiedByName: string;
    ModifiedDate: string;
    Name: string;
    SVGImageName: string;
    UserEmail: string;
    isChecked: boolean;

    constructor() {
        super();
        this.filterLabel = this.Name;
        this.propertyId = this.Id;
    }
}

export class Cluster {
    AverageCurrentMaturity: number;
    AverageFutureMaturity: number;
    ClusterCapabilities: any;
    ClusterCapabilityCount: number;
    Description: string;
    Gaps: number;
    HasAnyData: boolean;
    HasMandatoryData: boolean;
    Id: number;
    IsArchived: boolean;
    IsMicroRoadMapCreated: boolean;
    IsSurveyData: boolean;
    MapId: number;
    MapName: string;
    MapTypeId: number;
    MarkerText: string;
    Name: string;
    ParentId: number;
    SerialNumber: number;
    ShowClusterInfo: boolean;
    SortOrder: number;
    ValueChainId: number;
    ValueChainName: string;
}

export class ValueChain {
    Id: number;
    MapId: number;
    Name: string;
    SortOrder: number;
    Clusters: Cluster[];
}

export class Map {
    Action: number;
    ActiveWorkshop: number;
    ActivityCode: number;
    CapabilityCount: number;
    CreatedBy: number;
    CreatedDate: Date;
    Description: string;
    FirstName: string;
    Id: number;
    IsArchived: boolean;
    IsDeleted: boolean;
    IsMyMap: boolean;
    IsPasswordProtected: boolean;
    IsPasswordRequired: boolean;
    IsPublic: boolean;
    IsShareWithMe: boolean;
    IsSurveyData: boolean;
    LastName: string;
    LoggedInUserEmail: string;
    MapStrategies: MapStrategy[];
    MapTypeId: number;
    MapTypeName: string;
    Maptype: number;
    MarkerText: string;
    MarkerTextDefinition: string;
    ModifiedBy: number;
    ModifiedByName: string;
    ModifiedDate: Date;
    Name: string;
    Owner: any;
    OwnerEmail: string;
    Project: any;
    ProjectInformationId: number;
    RoleId: number;
    Sector: Sector;
    ShowSectorOverview: boolean;
    SurveyId: number;
    SurveyName: string;
    Type: number;
    UserEmail: string;
    UserId: number;
    ValueChains: ValueChain[];
    ValueMapId: number;
    Version: number;
    WorkshopId: number;
    WorkshopName: string;
}

export class MapsList {
    list: MapOverview[];
    filter: MapFilter;
    filterOutput: FilterOutput;
    isloading: boolean;

    constructor() {
        this.list = [];
        this.filter = new MapFilter();
        this.filterOutput = new FilterOutput();
        this.isloading = false;

    }
}

export class MapMenu {
    UserId: number;
    RoleId: number;
    MapTypeId: number;
    IsDEM: boolean;
    IsCreator: boolean;
    IsPasswordProtected: boolean;
    IsPublic: boolean;
    SharedToUserIds: number[];
    WorkshopId: number;
    SurveyId: number;
    IsWorkshopAdmin: boolean;
    IsSurveyAdmin: boolean;
    SurveyStatusId?: number;
    IsOmdaMap?: boolean;
    ValueMapId?: number;
    IsPasswordRequired: boolean;
    CanUpdateMap: boolean;
    CanDeleteMap: boolean;
    CanArchiveMap: boolean;
    CanSaveAsNewMap: boolean;
    CanShareMap: boolean;
    CanMakePublic: boolean;
    CanMakePrivate: boolean;
    CanSeeValueMapSettings: boolean;
    SurveyLock: boolean;
    IsSurveyData: boolean;
    ShowSurveyOverrideMessage: boolean;
    IsArchived: boolean;
    MapName: string;
    IsFavorite: boolean;
    WorkShopStatus: number;
    CanNotebookDisabled: boolean;
    CanOperatingModelDisabled: boolean;
    CanRoadMapDisabled: boolean;
    CanValueMapDisabled: boolean;
    IsReadOnlyAccess: boolean;
    MapDescription?: string;
}

