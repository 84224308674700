import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TEST_SCENARIOS } from '@projects/shared-lib/src/lib/core/polling-constants';
import { PollingService } from '@projects/shared-lib/src/lib/services/polling.service';
import { BaseService } from '@projects/shared-lib/src/public-api';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TestCasesService {
  readonly downloadUserStories = `${environment.API_BASE_URL}/deliver/validate/test/download/`;
  readonly generateTestScenariosL1URL = `${environment.API_MICROSERVICE_URL?.PROJECT}/process/start`;
  readonly terminateGenerateTestScenariosL1URL = `${environment.API_MICROSERVICE_URL?.PROJECT}/process/terminate`;
  readonly verifyRunningProcessURL = `${environment.API_MICROSERVICE_URL?.PROJECT}/process/verify`;
  readonly getInstanceMapping = `${environment.API_BASE_URL}/process/l1/instance/map`;
  private isNewDataPushed: BehaviorSubject<boolean>;
  constructor(
    private baseService: BaseService,
    private http: HttpClient,
    private pollingService: PollingService
  ) { 
    this.isNewDataPushed = new BehaviorSubject(false);
   }

  getTestCasesData(params) {
    const {
      packageId,
      functionId,
      activityId,
      projectId,
      status,
      l1,
      l2,
      l3,
      taxonomyId,
    } = params;

    return this.http.get(
      `${environment.API_MICROSERVICE_URL?.PROJECT}/deliver/validate/test/${packageId}/${functionId}/${activityId}/${projectId}/${status}/${l1}/${l2}/${l3}/${taxonomyId}`
    );
  }

  getDataForALMInstance(projectId): Observable<any> {
    return this.http.get<any>(`${environment.API_MICROSERVICE_URL?.PROJECT}/tools/dfteTools/${projectId}`);
  }

  exportActivityToAlm(postData) {
    let exportToALMUrl = `/api/process/start/ExportToALMActivity?projectid=${postData.projectid}&packageid=${postData.packageId}&functionid=${postData.functionId}&activityid=${postData.activityId}&userroleid=${postData.userroleid}&l1_ids=${postData.l1_ids}`;
    const url = `${environment.API_BASE_URL}${exportToALMUrl}`;

    this.http.post(url, {}).subscribe(
      (data: any) => {
        this.pollingService.startPolling(data.instanceId, TEST_SCENARIOS);
        this.pollingService.getPollingData().subscribe((res) => { });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  downloadTestScenarios(projectId) {
    const body = [{
        "guidName": `${projectId}/AscendTestDesigner.xlsm`
    }]
    const endpoint = `File/Download?containerName=${environment.CMS_CONTAINER_NAMES.PROJECT_WORKSPACE}&type=Util`;
    return this.baseService.downloadCMS(endpoint, body)
  }

  generateAndDownTestDesigner(params) {
    const { packageId, functionId, activityId, projectId, l1Ids } = params;
    return this.http.get(`${environment.API_MICROSERVICE_URL?.PROJECT}/deliver/download/testDesigner/${packageId}/${functionId}/${activityId}/${projectId}/${l1Ids}`, {
      responseType: 'blob' as 'json',
      observe: 'response'
    })
  }

  saveAsBlob(data: any, fileName: string) {
    if (data && data.body) {
      const blob = new Blob([data.body], { type: "json" });
      const a = document.createElement("a");
      a.setAttribute("download", fileName || `Ascend Test Designer ${new Date()}.xlsm`);
      a.setAttribute("href", window.URL.createObjectURL(blob));
      a.click();
    }
  }

  generateTestScenarioPerL1(processName, projectId, l1Ids, userId, strategy, packageId, functionId, activityId): Observable<any> {
    const body = {
      projectId,
      l1Ids,
      userId,
      strategy,
      packageId, 
      functionId, 
      activityId
    }
    return this.http.post(`${this.generateTestScenariosL1URL}/${processName}`, body);
  }

  terminateGenerateTestScenariosPerL1(processName, instanceId, projectId, l1Id) {
    return this.http.get<any>(`${this.terminateGenerateTestScenariosL1URL}/${processName}/${instanceId}?projectId=${projectId}&l1=${l1Id}`, {});
  }

  fetchAlreadyRunningProcesses(projectId, processName) {
    return this.http.get<any>(`${this.verifyRunningProcessURL}/${projectId}/${processName}`);
  }  

  getL1mapping(instanceIdString) {
    return this.http.get<any>(`${this.getInstanceMapping}?id=${instanceIdString}`)
  }

  setIsNewDataPushed(val: boolean) {
    this.isNewDataPushed.next(val);
  }

  getIsNewDataPushed(): Observable<boolean> {
    return this.isNewDataPushed.asObservable();
  }

  getL1Data(projectId, levelId = ''): Observable<any> {
    return this.http.get<any>(`${environment.API_MICROSERVICE_URL?.PROJECT}/project/taxonomy/l1?projectId=${projectId}&levelId=${levelId}`);
  }

}
