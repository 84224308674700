import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sprint-generation-popup',
  templateUrl: './sprint-generation-popup.component.html',
  styleUrls: ['./sprint-generation-popup.component.scss']
})
export class SprintGenerationPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SprintGenerationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService
  ){

  }


  ngOnInit(){
    let description = this.translateService.instant(this.data.description);
    let stepName = this.translateService.instant(this.data.for);
    let level = this.data?.level;
    if(level){
      stepName = stepName+'/'+level;
    }
    this.data.description = description.replace('step_name', stepName);
  }

  close(){
    this.dialogRef.close();
  }

  confirmAndContinue(){
    this.dialogRef.close('confirm');
  }



}
