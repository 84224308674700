import { Sector } from './map.model';
import * as _ from 'lodash';
import { allMapsTilesList } from '@app/constants/maps';

export class MapFilter {
    Archive: boolean = false;
    Custom: boolean = false;
    DEM: boolean = false;
    Favorite: boolean = false;
    Functional: boolean = false;
    IsAscSort: boolean  = false;
    Limit: number = 50;
    MyMaps: boolean = false;
    MyProjects: boolean = false;
    AllProjects: boolean = false;
    SearchedProjects: boolean = false;
    isProjectsList: boolean = false;
    OtherPrivateMap: boolean = false;
    Page: number = 1;
    Sector: boolean = false;
    Shared: boolean = false;
    SortColumn: string = 'CreatedDate';
    SearchText: string = null;
    Owners: string = '';
    Sectors: any;
    MapTypes: number;
    MapId: number;
    MapType: string;
    MapTypeId: number;
    IsFilterApplied: boolean = false; // used for UI
    IsSearchFilterApplied: boolean = false;
}


// this.Archive = true;
// this.Custom = true;
// this.Functional = true;
// this.MyMaps = true;
// this.OtherPrivateMap = true;
// this.Shared = true;
// this.Sector = true;

export class FilterOutput {
    IsArchived: boolean;
    Items: any[];
    MapTypeId: number;
    ShowIcon: boolean;
    TotalCount: number;
    TotalCountForMap: number;
    mapName: string;
    MapId: number;
    MapType: string;
}
export class userDetails {
    RoleId: number;
    UserAlias: string;
    FirstName: string;
    LastName: string;
    Email: string;
    DPNUserDetailsUrl: string;
}
export class UpdateParam {
    CapabilityId: number = 0;
    ClusterId: number = 0;
    MapId: number = 0;
}
export class clientWBS {
    WBSCod: string;
    ProjectName: string;
}
export class MapTypeOutput {
    HasError: boolean;
    HasInformation: boolean;
    HasMapTypeError: boolean;
    Errors: string[];

}

export class SearchFilter {
    SearchText: string = null;
    Limit: number = 5;
    Page: number = 1;
    SortColumn: string = "Name";
    IsAscSort: boolean = true;
    ClientName: string;
}

export class ShareOutput {

    Action: number;
    CanEditPresentation: boolean;
    CreatedBy: number;
    Email: string;
    FirstName: string;
    FullName: string;
    Groups: number;
    Id: number;
    ImageUrl: string;
    IsActive: boolean;
    IsAnyDataAvailable: boolean;
    IsDeleted: boolean;
    IsExternalUser: boolean;
    LastName: string;
    MemberFirm: string;
    ModifiedBy: string;
    ModifiedByName: string;
    Name: string;
    Office: string;
    Role: any[];
    Status: string;
    UserEmail: string;
    UserName: string;

}

export class SortByItem {
    Name: string;
    SortColumn: string;
    IsAscSort: boolean;
    isSelected: boolean;
}
export class FilterDataItem {
    filterLabel: string;
    propertyLabel?: string;
    propertyId?: number;
    isChecked: boolean;
}

export class MapsListFilter  {
    MapTypeFilterItems: FilterDataItem[];
    SectorFilterItems: FilterDataItem[];

    constructor( sectors: Sector[]) {
        this.MapTypeFilterItems = [];
        this.SectorFilterItems = [];

        _.each(allMapsTilesList.mapTypes, (mapTypesDataItem) => {
            this.MapTypeFilterItems.push(mapTypesDataItem);
        });
        _.each(sectors, (sector) => {
            sector.propertyId = sector.Id;
            sector.filterLabel = sector.Name;
            sector.isChecked = true;
            this.SectorFilterItems.push(sector);
        })
    }
}
export class LandingPageFilter {
    sectors: Sector[];
    OwnerName: string[];
    PublicMapType: number;
    Isloading: Boolean;
    ErrorInfo: any;
    DefaultMapFilter: MapFilter;

}
