import {
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
  Action,
  ActionReducerMap,
} from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import * as mapFilters from './map-filters.reducer';
import * as watermarkReducer from './watermark.reducer';


/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import { InjectionToken } from '@angular/core';
import { RouterStateUrl } from '@app/shared/utils';
import {appSettingsReducer, State as AppSettingsState } from '@app/core/store/reducers/app-settings.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  mapFilters: mapFilters.State;
  watermarkReducers: watermarkReducer.State;
  router: fromRouter.RouterReducerState<any>;
  appSettings: AppSettingsState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>('Root reducers token', {
  factory: () => ({
    mapFilters: mapFilters.reducer,
    routerReducer: fromRouter.routerReducer,
    watermarkReducers: watermarkReducer.reducer,
    router: fromRouter.routerReducer,
    appSettings:  appSettingsReducer
  }),
});

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];

/**
 * Reducers
 */

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('routerReducer');
export const getRouterPathState = createSelector(getRouterState, (state: any) => state.state);

export const getMapFilterState = createFeatureSelector<mapFilters.State>('mapFilters');
export const getAppliedMapFilters = createSelector(getMapFilterState, mapFilters.appliedFilters);
export const getLandingPageFilters = createSelector(getMapFilterState, mapFilters.landingPageFilters);
export const getWatermarkState = createFeatureSelector<watermarkReducer.State>('watermarkReducers');
export const getMapId = createSelector(getWatermarkState, watermarkReducer.mapId);
export const getWaterMarkFlag = createSelector(getWatermarkState, watermarkReducer.watermarkFlag);

export const getConstants = createFeatureSelector<AppSettingsState>('appSettings');

