<app-home-description [view]="view" [projectName]="projectName" [clientName]="clientName"
  [clientLogoURL]="clientLogoURL"></app-home-description>

<!-- <div class="iidr-filters">
  <app-filter-list (parentEmitter)="emitFilter($event)" [tabName]="tabName" filterBarHead="Filters" [filters]="filters"
    [filterLoaded]="filterLoaded" readOnly="false"></app-filter-list>
</div> -->

<div class="carousel">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let card of cardData">
      <ng-template carouselSlide>
        <app-home-carouselcard [cardData]="card"></app-home-carouselcard>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
