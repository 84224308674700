import { Component, Inject, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SPRINT_COLORS } from '@projects/projectsummary/src/app/core/utility/constants';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';
import * as EventEmitter from 'events';
import { Subscription } from 'rxjs';
import { DefineRoadmapService } from '../../../define-roadmap.service';

@Component({
  selector: 'app-move-to-sprint-popup',
  templateUrl: './move-to-sprint-popup.component.html',
  styleUrls: ['./move-to-sprint-popup.component.scss']
})
export class MoveToSprintPopupComponent implements OnInit {

   globalContextSubscription: Subscription;
   context: any;
   sprintData:any;
   colors = SPRINT_COLORS;
   sprintList: any[] = [];
   selectedType:any;
   sprintFlag = false;
   finalSelectedSprint:any;
   fromSprint:any;
   fromSprintNo:any;
   story_type_name:any;

  constructor(
    public dialogRef: MatDialogRef<MoveToSprintPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private defineRoadmapService: DefineRoadmapService,
    private globalContext: ActivitiesGlobalContextService
  ) { }

  ngOnInit(): void {
    this.sprintData = this.data;
    this.selectedType = this.sprintData.type;

    const rightMostPos = window.innerWidth - Number(this.sprintData.left);
     this.dialogRef.updatePosition({ top:  `300px`, right:  `${rightMostPos - 30}px`});

    let selectedSprint = this.sprintData.selectedSprint;
    this.fromSprint = selectedSprint;
    this.fromSprintNo = this.sprintData.type.l4_group[0].sprint_order_no;
    this.story_type_name = this.sprintData.story_type_name
    this.getSprintDetails(selectedSprint);
    this.globalContextSubscription = this.globalContext.getGlobalActivityContext().subscribe((data) => {
    this.context = data; 
    });
  }

  cancel(): void {
    this.dialogRef.close("cancel");
  }
  
  getSprintDetails(selectedSprint:any ){
    let sprintPlanLength = this.sprintData?.sprintPlan?.length;
    if(this.sprintData?.sprintPlan?.length >0){
    for (let i = 0; i < sprintPlanLength; i++) {
      let sprint = this.sprintData.sprintPlan[i];
      let sprintPlanDetails = {
        'sprint_order_no':sprint.sprint_order_no, 
        'sprint_name':sprint.sprint_name,
        'sprint_id':sprint.sprint_id
        }
      this.sprintList.push(sprintPlanDetails);
    }
  }
  }


  addToSprint(sprint:any){
   this.sprintFlag = true;
   this.finalSelectedSprint = sprint;
  }
  

  apply(){  
   let payLoad =  {
      type:  this.selectedType,
      toSprint :this.finalSelectedSprint,
      fromSprint: this.fromSprint,
      fromSprintNo:this.fromSprintNo,
      story_type_name: this.story_type_name    
   }

   this.dialogRef.close(payLoad);

  }

 
}
