<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 311 300"
  style="enable-background: new 0 0 311 300"
  xml:space="preserve"
>
  <style type="text/css">
    .std0 {
      fill: #ffffff;
      stroke: #c6d70c;
      stroke-width: 2;
      stroke-miterlimit: 10;
    }

    .std1 {
      fill: none;
      stroke: #c6d70c;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-linejoin: round;
    }

    .std2 {
      fill: #ffffff;
    }

    .std3 {
      fill: none;
      stroke: #c6d70c;
      stroke-width: 3;
      stroke-miterlimit: 10;
    }

    .std4 {
      fill: #c6d70c;
    }

    .std5 {
      fill: none;
      stroke: #c6d70c;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
    }

    .std6 {
      fill: #c6d70c;
      fill-opacity: 0.2;
    }

    .std7 {
      font-family: "Open Sans";
    }

    .std8 {
      font-size: 16px;
    }

    .std9 {
      fill: #8f9c07;
    }

    .std10 {
      font-family: "Open Sans Semibold";
    }

    .std11 {
      font-size: 10px;
    }

    .std12 {
      fill: none;
    }

    .std13 {
      display: none;
    }

    .std14 {
      display: inline;
      fill: #c6d70c;
      stroke: #c6d70c;
      stroke-width: 2;
      stroke-miterlimit: 10;
    }

    .std15 {
      display: inline;
      fill: #ffffff;
    }

    .std16 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #c6d70c;
    }

    .std17 {
      display: inline;
    }

    .std18 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #ffffff;
    }

    .std19 {
      fill: #8e9d3a;
    }
  </style>
  <circle class="std0" cx="93.5" cy="75.7" r="35.8" />
  <g id="Layer_1_1_">
    <g id="Layer_1-2">
      <g id="background">
        <path
          class="std1"
          d="M254.7,165.6c0,57.5-46.6,104.1-104.1,104.1S46.5,223.1,46.5,165.6c-0.2-7.7,0.7-15.5,2.5-23
				c9.7-38.5,48.6-81.2,101.5-81.1C205.1,61.6,254.7,106.9,254.7,165.6z"
        />

        <rect
          x="55.5"
          y="97.4"
          transform="matrix(0.5768 -0.8169 0.8169 0.5768 -52.5433 102.4559)"
          class="std2"
          width="34.3"
          height="9"
        />
        <line class="std3" x1="261.6" y1="40.9" x2="308.7" y2="40.9" />
        <path
          class="std4"
          d="M294.1,51.1c-0.3-0.5-0.2-1.2,0.3-1.5l13.7-8.8L294.4,32c-0.5-0.3-0.6-1-0.3-1.5c0.3-0.5,1-0.6,1.5-0.3
				l15.2,9.7c0.5,0.3,0.6,1,0.3,1.5c-0.1,0.1-0.2,0.3-0.3,0.3l-15.2,9.7c-0.2,0.1-0.4,0.2-0.6,0.2C294.7,51.6,294.3,51.4,294.1,51.1
				z"
        />
        <path
          class="std4"
          d="M115.8,97.2c0.3,0.2,0.5,0.5,0.3,0.8l-3.7,8.3l9.1,0.5c0.3,0,0.6,0.3,0.6,0.7c0,0.3-0.3,0.6-0.6,0.6l-10-0.5
				c-0.3,0-0.6-0.3-0.6-0.7c0-0.1,0-0.2,0-0.2l4.1-9.2c0.1-0.1,0.1-0.2,0.2-0.3C115.4,97.1,115.6,97.1,115.8,97.2z"
        />
        <path
          class="std4"
          d="M65,82.7c-0.3,0.2-0.7,0.3-0.9,0l-6.7-6.3L54,85c-0.1,0.3-0.5,0.5-0.8,0.4c-0.3-0.1-0.5-0.5-0.4-0.8l3.7-9.5
				c0.1-0.3,0.5-0.5,0.8-0.4c0.1,0,0.2,0.1,0.2,0.1l7.4,7c0.1,0.1,0.2,0.2,0.2,0.3C65.2,82.3,65.1,82.5,65,82.7z"
        />
        <path
          class="std4"
          d="M78,50.1c-0.3-0.2-0.4-0.6-0.2-0.9l5.2-7.6l-8.9-2.1c-0.3-0.1-0.5-0.4-0.5-0.8c0-0.3,0.4-0.5,0.7-0.5
				l9.9,2.2c0.3,0.1,0.5,0.4,0.5,0.8c0,0.1,0,0.2-0.1,0.2L78.9,50c-0.1,0.1-0.2,0.2-0.3,0.2C78.4,50.2,78.2,50.2,78,50.1z"
        />
        <path class="std2" d="M61.2,112.5c-0.6,1.6-1.2,3.2-1.9,4.8" />
        <path class="std5" d="M60.3,114.7c-0.3,0.9-0.7,1.7-1,2.6" />
        <path
          class="std4"
          d="M46.5,124c0.4,0.4,0.9,0.5,1.4,0.2l12.3-9.1l3,15c0.1,0.6,0.6,0.9,1.2,0.8c0.6-0.1,0.9-0.6,0.8-1.2
				l-3.3-16.5c-0.1-0.3-0.3-0.6-0.6-0.7s-0.7-0.1-1,0.1l-13.6,10c-0.2,0.1-0.3,0.2-0.3,0.4C46.2,123.3,46.2,123.7,46.5,124z"
        />

        <rect
          x="78.2"
          y="242"
          transform="matrix(0.4286 -0.9035 0.9035 0.4286 -173.8518 220.2107)"
          class="std2"
          width="17.9"
          height="11.2"
        />

        <rect
          x="38.7"
          y="174.6"
          transform="matrix(0.9289 -0.3702 0.3702 0.9289 -63.3295 30.4411)"
          class="std2"
          width="17.9"
          height="11.2"
        />
        <path class="std2" d="M89.1,250.1c1.5,0.9,2.9,1.8,4.4,2.6" />
        <path class="std5" d="M91.2,251.3c0.8,0.5,1.6,0.9,2.4,1.4" />
        <path
          class="std4"
          d="M98,266.5c0.4-0.2,0.7-0.7,0.5-1.1c0-0.1,0-0.1-0.1-0.2l-6.9-13.7l15.3-0.4c0.6,0,1-0.4,1-1s-0.4-1-1-1
				l-16.9,0.5c-0.3,0-0.7,0.2-0.8,0.5c-0.2,0.3-0.2,0.7,0,1l7.6,15.1c0.1,0.2,0.2,0.3,0.4,0.4C97.4,266.7,97.8,266.7,98,266.5z"
        />

        <rect
          x="167.1"
          y="53.6"
          transform="matrix(0.3714 -0.9285 0.9285 0.3714 49.9751 204.2671)"
          class="std2"
          width="17.5"
          height="23.3"
        />

        <rect
          x="244.5"
          y="139.7"
          transform="matrix(0.9859 -0.1673 0.1673 0.9859 -20.7366 44.4507)"
          class="std2"
          width="17.9"
          height="11.2"
        />
        <path class="std2" d="M252.6,144.2c-0.4-1.7-0.8-3.3-1.2-5" />
        <path class="std5" d="M252,141.9l-0.6-2.7" />
        <path
          class="std4"
          d="M258.3,126.4c-0.5-0.2-1.1,0.1-1.3,0.6l0,0l-5.1,14.4L241,130.7c-0.4-0.3-1.1-0.2-1.4,0.2
				c-0.3,0.4-0.3,0.8,0,1.2l12.1,11.8c0.3,0.2,0.6,0.3,0.9,0.3c0.3-0.1,0.6-0.3,0.7-0.6l5.6-15.9c0.1-0.2,0.1-0.4,0-0.6
				C258.8,126.8,258.6,126.5,258.3,126.4z"
        />
        <path
          class="std1"
          d="M100.1,75.5c25.4-15,58.8-28.9,98.8-31.7c4.1-0.3,8-0.4,11.9-0.5"
        />
        <path
          class="std4"
          d="M196.4,54.3c-0.3-0.5-0.2-1.2,0.3-1.6l14.1-9.4l-14.4-8.8c-0.5-0.3-0.7-1-0.3-1.5c0,0,0,0,0-0.1
				c0.3-0.5,0.9-0.7,1.4-0.4l0.1,0.1l15.9,9.8c0.5,0.3,0.6,1,0.3,1.5c-0.1,0.1-0.2,0.3-0.3,0.3l-15.4,10.4
				C197.6,55,196.8,54.8,196.4,54.3z"
        />
        <circle id="Oval-Copy-8" class="std6" cx="151.8" cy="167.6" r="55" />
        <circle id="Oval-Copy-9" class="std4" cx="151.8" cy="167.6" r="40" />
        <ellipse class="std2" cx="150.4" cy="62.9" rx="19.6" ry="4.3" />
        <circle class="std4" cx="188.5" cy="68.8" r="3.7" />
      </g>
      <g id="small_circle_around_sprints">
        <circle class="std0" cx="65.7" cy="214.7" r="35.8" />
        <rect
          x="89"
          y="227.1"
          transform="matrix(0.5 -0.866 0.866 0.5 -151.6432 199.9309)"
          class="std2"
          width="16.7"
          height="8.4"
        />
        <polygon
          class="std2"
          points="36.8,234 34.4,236.1 26.5,215.1 35.5,215.1 			"
        />
        <polygon
          class="std2"
          points="43.7,189 36.9,184.8 80,171.2 86.4,186.5 			"
        />
        <rect x="81.7" y="179.4" class="std2" width="5.8" height="11.5" />
        <path class="std2" d="M43,187.4l-2,1.7" />
        <path class="std5" d="M41.9,188.3c-0.3,0.3-0.6,0.5-0.9,0.8" />
        <path
          class="std4"
          d="M32.3,189.1c0,0.3,0.3,0.5,0.7,0.5l8.8-1.2l-2.7,8.5c-0.1,0.3,0.1,0.6,0.4,0.7s0.6-0.1,0.7-0.4l2.9-9.3
				c0.1-0.2,0-0.4-0.1-0.5c-0.1-0.2-0.3-0.2-0.5-0.2l-9.7,1.3c-0.1,0-0.2,0.1-0.3,0.1S32.3,188.9,32.3,189.1z"
        />
        <path class="std2" d="M100.2,224.6c0.2-0.9,0.3-1.7,0.5-2.6" />
        <path class="std5" d="M100.5,223.3c0.1-0.4,0.2-0.8,0.2-1.2" />
        <path
          class="std4"
          d="M107.5,216.6c-0.2-0.2-0.6-0.2-0.8,0l-6.2,6.4l-3.2-8.3c-0.1-0.3-0.5-0.4-0.7-0.2c-0.2,0.1-0.4,0.4-0.3,0.6
				l3.5,9.1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0,0.4,0,0.5-0.2l6.8-7c0.1-0.1,0.1-0.2,0.2-0.3C107.7,216.9,107.6,216.8,107.5,216.6z"
        />
        <path class="std2" d="M34.9,233.8c0.6,0.7,1.2,1.3,1.7,2" />
        <path class="std5" d="M35.9,234.8c0.3,0.3,0.5,0.6,0.8,0.9" />
        <path
          class="std4"
          d="M37,244.5c0.3-0.1,0.5-0.4,0.5-0.7L36,235l8.5,2.4c0.3,0.1,0.6-0.1,0.7-0.4c0.1-0.3-0.1-0.6-0.4-0.7l0,0
				l-9.4-2.7c-0.2-0.1-0.4,0-0.5,0.1s-0.2,0.3-0.2,0.5l1.6,9.7c0,0.1,0.1,0.2,0.1,0.3C36.6,244.4,36.8,244.5,37,244.5z"
        />
      </g>
      <g id="copy">
        <text transform="matrix(1 0 0 1 165.23 20.57)" class="std7 std8">
          Deploy
        </text>
        <text transform="matrix(1 0 0 1 20.8082 24.41)" class="std7 std8">
          Validate
        </text>
        <text transform="matrix(1 0 0 1 249.72 221.39)" class="std7 std8">
          Activate
        </text>
        <text transform="matrix(1 0 0 1 246.32 239.39)" class="std7 std8">
          operate
        </text>
        <text transform="matrix(1 0 0 1 217.32 257.39)" class="std7 std8">
          organization
        </text>
        <text transform="matrix(1 0 0 1 37.2834 278.5876)" class="std7 std8">
          Sprint
        </text>
        <text transform="matrix(1 0 0 1 40.66 184.45)" class="std9 std10 std11">
          Construct
        </text>
        <text transform="matrix(1 0 0 1 92.19 234.62)" class="std9 std10 std11">
          Experience
        </text>
        <text transform="matrix(1 0 0 1 0 228.52)" class="std9 std10 std11">
          Feedback
        </text>
        <polygon
          class="std12"
          points="128.7,75.5 102.1,80.9 98.1,64.8 124.7,59.4 			"
        />
      </g>
    </g>
    <g id="validate_off">
      <circle class="std0" cx="93.1" cy="73.6" r="22.6" />
      <path
        class="std4"
        d="M107.2,63.3c-0.4-0.4-1-0.4-1.4,0L86.5,82.6l-6.3-6.3c-0.4-0.3-1.1-0.2-1.4,0.2c-0.3,0.4-0.3,0.8,0,1.2l7,7
			c0.4,0.4,1,0.4,1.4,0l20-20C107.6,64.3,107.6,63.7,107.2,63.3z"
      />
    </g>
    <g id="validate_on" class="std13" (click)="goto('Validate')">
      <circle class="std14" cx="93.1" cy="73.6" r="22.6" />
      <path
        class="std15"
        d="M107.2,63.3c-0.4-0.4-1-0.4-1.4,0L86.5,82.6l-6.3-6.3c-0.4-0.3-1.1-0.2-1.4,0.2c-0.3,0.4-0.3,0.8,0,1.2l7,7
			c0.4,0.4,1,0.4,1.4,0l20-20C107.6,64.3,107.6,63.7,107.2,63.3z"
      />
    </g>
    <g id="sprint_off">
      <circle class="std0" cx="66" cy="215" r="20" />
      <g id="ExploreAscend">
        <g id="Deliver">
          <g id="deliver---wheel">
            <g id="sprint">
              <g id="Group-3">
                <path
                  id="Fill-1"
                  class="std16"
                  d="M75.6,214.9c-0.1,0.1-0.3,0.1-0.4,0.1l-4-0.5h-0.1c-0.2,0-0.3-0.2-0.4-0.4V214l-0.9-1.8
								c-0.2-0.3-0.5-0.4-0.8-0.3l-0.3,0.3l-1.7,2.9c-0.1,0.2-0.1,0.3,0,0.5l1.9,3.5c0,0.1,0,0.1,0.1,0.1l0,0
								c0.2,0.2,0.2,0.5,0.1,0.7l-2.4,5.4c-0.2,0.3-0.5,0.4-0.8,0.4h-0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.2-0.4-0.7-0.2-1l2-4.4
								c0.1-0.2,0.1-0.5-0.1-0.6l-2.9-3.6c-0.6-0.8-0.7-1.9-0.2-2.8l2-3c0.2-0.2,0.1-0.6-0.1-0.8h-0.1h-0.1l-1.9-1.2
								c-0.2-0.1-0.5-0.1-0.7,0.1l-2.4,2.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.3-0.2-0.5,0.1-0.7l2.8-2.8
								c0.2-0.2,0.4-0.2,0.5-0.1l0.4,0.1c1.3,0.4,2.5,1,3.7,1.6c0.5,0.3,1.2,0.2,1.6-0.3c0.2-0.2,0.3-0.6,0.2-0.9
								c-0.1-0.4-0.1-0.7,0-1.1c0.3-0.8,1.1-1.3,1.9-1.2c1,0.1,1.7,1,1.6,2l0,0v0.2c-0.2,0.9-1,1.5-1.9,1.4H71
								c-0.4-0.1-0.9,0.2-1,0.6c-0.1,0.3,0,0.5,0.2,0.7s0.4,0.4,0.4,0.7l1,2.3c0.1,0.2,0.3,0.4,0.4,0.4l3.3,0.4
								c0.3,0,0.5,0.2,0.5,0.5C75.8,214.6,75.8,214.8,75.6,214.9L75.6,214.9z M60.5,219.6L60.5,219.6c-0.3,0.2-0.4,0.3-0.7,0.2h-0.1
								l-4.1-1.5c-0.2-0.1-0.4-0.3-0.4-0.4c-0.1-0.2-0.1-0.4,0-0.6c0.2-0.4,0.6-0.5,1-0.4l3.3,1.2c0.2,0.1,0.4,0,0.6-0.1l2.8-2.7
								c0.2,0.4,0.4,0.8,0.6,1.2l0.4,0.4L60.5,219.6z M75.4,212.8l-3-0.4l-0.8-1.9c-0.1-0.2-0.2-0.4-0.3-0.4
								c1.7-0.1,2.9-1.5,2.8-3.2s-1.5-2.9-3.2-2.8c-1.6,0.1-2.9,1.5-2.8,3.2c0,0.2,0,0.3,0.1,0.4h-0.1c-1.3-0.7-2.6-1.2-3.9-1.7
								l-0.4-0.1c-0.6-0.2-1.2,0-1.7,0.4l-2.8,2.8c-0.6,0.6-0.7,1.6-0.1,2.3c0.6,0.7,1.7,0.8,2.4,0.2l0.1-0.1l2.1-2l1,0.5l-1.7,2.6
								l0,0c-0.3,0.4-0.4,0.9-0.4,1.4l-3.3,3l-2.9-1.1c-0.9-0.4-2,0.1-2.4,1c-0.3,0.5-0.3,1.1,0,1.6c0.2,0.5,0.7,0.9,1.2,1.1
								l4.1,1.5c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.8-0.2,1.2-0.4l3.5-2.5l1.6,2l-1.9,4.1c-0.4,0.8-0.2,1.7,0.4,2.3
								c0.3,0.2,0.5,0.4,0.8,0.4h0.5c0.8,0,1.5-0.5,1.8-1.2l2.4-5.2c0.3-0.6,0.2-1.4-0.2-1.9l-1.7-3.1l1-1.8l0.4,0.7
								c0.2,0.5,0.7,0.9,1.2,1.1h0.2l4,0.5c0.5,0.1,0.9-0.1,1.3-0.4c0.4-0.3,0.6-0.7,0.6-1.2C77.1,213.7,76.4,212.9,75.4,212.8
								L75.4,212.8z"
                />
              </g>
              <path
                id="Fill-4"
                class="std16"
                d="M54.6,210.1h2.3c0.4,0,0.6-0.2,0.6-0.4s-0.3-0.4-0.6-0.4h-2.3c-0.4,0-0.6,0.2-0.6,0.4
							S54.2,210.1,54.6,210.1"
              />
              <path
                id="Fill-6"
                class="std16"
                d="M56.3,207.5h2.3c0.4,0,0.6-0.2,0.6-0.4s-0.3-0.4-0.6-0.4h-2.3c-0.4,0-0.6,0.2-0.6,0.4
							C55.8,207.3,56.1,207.5,56.3,207.5"
              />
              <path
                id="Fill-8"
                class="std16"
                d="M55.7,213.2c0,0.3,0.3,0.4,0.6,0.4h2.3c0.4,0,0.6-0.2,0.6-0.4s-0.3-0.4-0.6-0.4h-2.3
							C56,212.8,55.7,212.9,55.7,213.2"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="sprint_on" class="std13" (click)="goto('Sprint')">
      <circle class="std14" cx="66" cy="215" r="20" />
      <g id="ExploreAscend_1_" class="std17">
        <g id="Deliver_1_">
          <g id="deliver---wheel_1_">
            <g id="sprint_1_">
              <g id="Group-3_1_">
                <path
                  id="Fill-1_1_"
                  class="std18"
                  d="M75.6,214.9c-0.1,0.1-0.3,0.1-0.4,0.1l-4-0.5h-0.1c-0.2,0-0.3-0.2-0.4-0.4V214l-0.9-1.8
								c-0.2-0.3-0.5-0.4-0.8-0.3l-0.3,0.3l-1.7,2.9c-0.1,0.2-0.1,0.3,0,0.5l1.9,3.5c0,0.1,0,0.1,0.1,0.1l0,0
								c0.2,0.2,0.2,0.5,0.1,0.7l-2.4,5.4c-0.2,0.3-0.5,0.4-0.8,0.4h-0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.3-0.2-0.4-0.7-0.2-1l2-4.4
								c0.1-0.2,0.1-0.5-0.1-0.6l-2.9-3.6c-0.6-0.8-0.7-1.9-0.2-2.8l2-3c0.2-0.2,0.1-0.6-0.1-0.8h-0.1h-0.1l-1.9-1.2
								c-0.2-0.1-0.5-0.1-0.7,0.1l-2.4,2.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.3-0.2-0.5,0.1-0.7l2.8-2.8
								c0.2-0.2,0.4-0.2,0.5-0.1l0.4,0.1c1.3,0.4,2.5,1,3.7,1.6c0.5,0.3,1.2,0.2,1.6-0.3c0.2-0.2,0.3-0.6,0.2-0.9
								c-0.1-0.4-0.1-0.7,0-1.1c0.3-0.8,1.1-1.3,1.9-1.2c1,0.1,1.7,1,1.6,2l0,0v0.2c-0.2,0.9-1,1.5-1.9,1.4H71
								c-0.4-0.1-0.9,0.2-1,0.6c-0.1,0.3,0,0.5,0.2,0.7s0.4,0.4,0.4,0.7l1,2.3c0.1,0.2,0.3,0.4,0.4,0.4l3.3,0.4
								c0.3,0,0.5,0.2,0.5,0.5C75.8,214.6,75.8,214.8,75.6,214.9L75.6,214.9z M60.5,219.6L60.5,219.6c-0.3,0.2-0.4,0.3-0.7,0.2h-0.1
								l-4.1-1.5c-0.2-0.1-0.4-0.3-0.4-0.4c-0.1-0.2-0.1-0.4,0-0.6c0.2-0.4,0.6-0.5,1-0.4l3.3,1.2c0.2,0.1,0.4,0,0.6-0.1l2.8-2.7
								c0.2,0.4,0.4,0.8,0.6,1.2l0.4,0.4L60.5,219.6z M75.4,212.8l-3-0.4l-0.8-1.9c-0.1-0.2-0.2-0.4-0.3-0.4
								c1.7-0.1,2.9-1.5,2.8-3.2s-1.5-2.9-3.2-2.8c-1.6,0.1-2.9,1.5-2.8,3.2c0,0.2,0,0.3,0.1,0.4h-0.1c-1.3-0.7-2.6-1.2-3.9-1.7
								l-0.4-0.1c-0.6-0.2-1.2,0-1.7,0.4l-2.8,2.8c-0.6,0.6-0.7,1.6-0.1,2.3c0.6,0.7,1.7,0.8,2.4,0.2l0.1-0.1l2.1-2l1,0.5l-1.7,2.6
								l0,0c-0.3,0.4-0.4,0.9-0.4,1.4l-3.3,3l-2.9-1.1c-0.9-0.4-2,0.1-2.4,1c-0.3,0.5-0.3,1.1,0,1.6c0.2,0.5,0.7,0.9,1.2,1.1
								l4.1,1.5c0.2,0.1,0.4,0.1,0.6,0.1c0.4,0,0.8-0.2,1.2-0.4l3.5-2.5l1.6,2l-1.9,4.1c-0.4,0.8-0.2,1.7,0.4,2.3
								c0.3,0.2,0.5,0.4,0.8,0.4h0.5c0.8,0,1.5-0.5,1.8-1.2l2.4-5.2c0.3-0.6,0.2-1.4-0.2-1.9l-1.7-3.1l1-1.8l0.4,0.7
								c0.2,0.5,0.7,0.9,1.2,1.1h0.2l4,0.5c0.5,0.1,0.9-0.1,1.3-0.4c0.4-0.3,0.6-0.7,0.6-1.2C77.1,213.7,76.4,212.9,75.4,212.8
								L75.4,212.8z"
                />
              </g>
              <path
                id="Fill-4_1_"
                class="std18"
                d="M54.6,210.1h2.3c0.4,0,0.6-0.2,0.6-0.4s-0.3-0.4-0.6-0.4h-2.3c-0.4,0-0.6,0.2-0.6,0.4
							S54.2,210.1,54.6,210.1"
              />
              <path
                id="Fill-6_1_"
                class="std18"
                d="M56.3,207.5h2.3c0.4,0,0.6-0.2,0.6-0.4s-0.3-0.4-0.6-0.4h-2.3c-0.4,0-0.6,0.2-0.6,0.4
							C55.8,207.3,56.1,207.5,56.3,207.5"
              />
              <path
                id="Fill-8_1_"
                class="std18"
                d="M55.7,213.2c0,0.3,0.3,0.4,0.6,0.4h2.3c0.4,0,0.6-0.2,0.6-0.4s-0.3-0.4-0.6-0.4h-2.3
							C56,212.8,55.7,212.9,55.7,213.2"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="activate_off">
      <circle class="std0" cx="255.1" cy="172" r="22.6" />
      <path
        class="std4"
        d="M264,179.8v-7.2c0-1.6-1.2-3-2.8-3c-0.1,0-0.1,0-0.2,0h-5v-8.2c1.2-0.4,2-1.5,2-2.8c0-1.7-1.3-3-3-3
			s-3,1.3-3,3c0,1.3,0.8,2.4,2,2.8v8.2h-5c-1.6,0-3,1.2-3,2.8c0,0.1,0,0.1,0,0.2v7.2c-1.2,0.4-2,1.5-2,2.8c0,1.7,1.3,3,3,3
			s3-1.3,3-3c0-1.3-0.8-2.4-2-2.8v-7.2c0-0.6,0.4-1,1-1h5v8.2c-1.2,0.4-2,1.5-2,2.8c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3
			c0-1.3-0.8-2.4-2-2.8v-8.2h5c0.5-0.1,0.9,0.3,1,0.8c0,0.1,0,0.2,0,0.2v7.2c-1.2,0.4-2,1.5-2,2.8c0,1.7,1.3,3,3,3s3-1.3,3-3
			C266,181.3,265.2,180.2,264,179.8z M255,157.6c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1c0-0.5,0.4-1,0.9-1H255z M247,183.6
			c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1c0,0.5-0.4,1-0.9,1C247.1,183.6,247.1,183.6,247,183.6z M255,183.6c-0.6,0-1-0.4-1-1
			s0.4-1,1-1s1,0.4,1,1c0,0.5-0.4,1-0.9,1C255.1,183.6,255.1,183.6,255,183.6z M263,183.6c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1
			c0,0.5-0.4,1-0.9,1C263.1,183.6,263.1,183.6,263,183.6z"
      />
    </g>
    <g
      id="activate_on"
      class="std13"
      (click)="goto('Activate operate organization')"
    >
      <circle class="std14" cx="255.1" cy="172" r="22.6" />
      <path
        class="std15"
        d="M264,179.8v-7.2c0-1.6-1.2-3-2.8-3c-0.1,0-0.1,0-0.2,0h-5v-8.2c1.2-0.4,2-1.5,2-2.8c0-1.7-1.3-3-3-3
			s-3,1.3-3,3c0,1.3,0.8,2.4,2,2.8v8.2h-5c-1.6,0-3,1.2-3,2.8c0,0.1,0,0.1,0,0.2v7.2c-1.2,0.4-2,1.5-2,2.8c0,1.7,1.3,3,3,3
			s3-1.3,3-3c0-1.3-0.8-2.4-2-2.8v-7.2c0-0.6,0.4-1,1-1h5v8.2c-1.2,0.4-2,1.5-2,2.8c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3
			c0-1.3-0.8-2.4-2-2.8v-8.2h5c0.5-0.1,0.9,0.3,1,0.8c0,0.1,0,0.2,0,0.2v7.2c-1.2,0.4-2,1.5-2,2.8c0,1.7,1.3,3,3,3s3-1.3,3-3
			C266,181.3,265.2,180.2,264,179.8z M255,157.6c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1c0-0.5,0.4-1,0.9-1H255z M247,183.6
			c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1c0,0.5-0.4,1-0.9,1C247.1,183.6,247.1,183.6,247,183.6z M255,183.6c-0.6,0-1-0.4-1-1
			s0.4-1,1-1s1,0.4,1,1c0,0.5-0.4,1-0.9,1C255.1,183.6,255.1,183.6,255,183.6z M263,183.6c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1
			c0,0.5-0.4,1-0.9,1C263.1,183.6,263.1,183.6,263,183.6z"
      />
    </g>
    <g id="deploy_off">
      <circle class="std0" cx="250.2" cy="36" r="22.6" />
      <path
        class="std4"
        d="M262.9,23.8h-2v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2.6l-0.6,0.6c-2.3-2-5.3-3.1-8.4-3.1c-7.2,0-13,5.8-13,13l0,0
			c0,3.6,1.5,7,4.1,9.5l-1.8,1.8c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l2-2c2.2,1.5,4.7,2.3,7.3,2.3s5.2-0.8,7.3-2.3l2,2
			c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4l-1.8-1.8c5.1-4.7,5.5-12.6,1-17.9l0.6-0.6h2.5c0.5,0,1-0.4,1-0.9v-0.1c0.1-0.5-0.3-1-0.9-1.1
			C263,23.8,263,23.8,262.9,23.8z M260.9,34.8c0,6.1-4.9,11-11,11s-11-4.9-11-11s4.9-11,11-11l0,0c2.6,0,5.1,0.9,7,2.6l-1.4,1.4
			c-1.6-1.3-3.6-2-5.6-2c-5,0-9,4-9,9s4,9,9,9s9-4,9-9l0,0c0-2-0.7-4-2-5.6l1.4-1.4C260,29.8,260.9,32.2,260.9,34.8z M256.9,34.8
			c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7c1.5,0,3,0.5,4.2,1.4l-1.4,1.4c-0.8-0.5-1.8-0.8-2.8-0.8c-2.8,0-5,2.2-5,5s2.2,5,5,5
			s5-2.2,5-5c0-1-0.3-2-0.8-2.8l1.4-1.4C256.4,31.8,256.9,33.3,256.9,34.8z M252.9,34.8c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3
			c0.5,0,0.9,0.1,1.3,0.3l-2,2c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l2-2C252.8,33.9,252.9,34.4,252.9,34.8z"
      />
    </g>
    <g id="deploy_on" class="std13" (click)="goto('Deploy')">
      <circle class="std14" cx="250.2" cy="36" r="22.6" />
      <path
        class="std15"
        d="M262.9,23.8h-2v-2c0-0.6-0.4-1-1-1s-1,0.4-1,1v2.6l-0.6,0.6c-2.3-2-5.3-3.1-8.4-3.1c-7.2,0-13,5.8-13,13l0,0
			c0,3.6,1.5,7,4.1,9.5l-1.8,1.8c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l2-2c2.2,1.5,4.7,2.3,7.3,2.3s5.2-0.8,7.3-2.3l2,2
			c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4l-1.8-1.8c5.1-4.7,5.5-12.6,1-17.9l0.6-0.6h2.5c0.5,0,1-0.4,1-0.9v-0.1c0.1-0.5-0.3-1-0.9-1.1
			C263,23.8,263,23.8,262.9,23.8z M260.9,34.8c0,6.1-4.9,11-11,11s-11-4.9-11-11s4.9-11,11-11l0,0c2.6,0,5.1,0.9,7,2.6l-1.4,1.4
			c-1.6-1.3-3.6-2-5.6-2c-5,0-9,4-9,9s4,9,9,9s9-4,9-9l0,0c0-2-0.7-4-2-5.6l1.4-1.4C260,29.8,260.9,32.2,260.9,34.8z M256.9,34.8
			c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7c1.5,0,3,0.5,4.2,1.4l-1.4,1.4c-0.8-0.5-1.8-0.8-2.8-0.8c-2.8,0-5,2.2-5,5s2.2,5,5,5
			s5-2.2,5-5c0-1-0.3-2-0.8-2.8l1.4-1.4C256.4,31.8,256.9,33.3,256.9,34.8z M252.9,34.8c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3
			c0.5,0,0.9,0.1,1.3,0.3l-2,2c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0l2-2C252.8,33.9,252.9,34.4,252.9,34.8z"
      />
    </g>
  </g>
  <polygon class="std2" points="66.8,61.6 57.6,73.4 50.3,69.5 59.5,57.7 " />
  <polygon class="std2" points="86.1,43.1 85,38.2 113.4,32.4 108.5,47.8 " />
  <rect
    x="68.6"
    y="100.1"
    transform="matrix(0.987 0.1605 -0.1605 0.987 18.3691 -12.674)"
    class="std2"
    width="38"
    height="14.6"
  />
  <rect
    x="106.8"
    y="106.6"
    transform="matrix(6.861423e-02 -0.9976 0.9976 6.861423e-02 -7.9251 213.6904)"
    class="std2"
    width="7.3"
    height="8.9"
  />
  <rect
    x="104.4"
    y="99.3"
    transform="matrix(-0.9551 -0.2961 0.2961 -0.9551 180.478 234.8476)"
    class="std2"
    width="7.3"
    height="8.9"
  />
  <polygon class="std2" points="127.2,59.3 131.9,57.3 137.5,81 123.1,79.7 " />
  <text transform="matrix(1 0 0 1 87.4999 43.3002)" class="std19 std10 std11">
    Test
  </text>
  <text transform="matrix(1 0 0 1 123.9414 77.6771)" class="std19 std10 std11">
    Prepare
  </text>
  <text transform="matrix(1 0 0 1 26.6917 67.7845)" class="std19 std10 std11">
    Convert
  </text>
  <text
    transform="matrix(0.8821 0 0 1 67.4132 109.6819)"
    class="std19 std10 std11"
  >
    Configure
  </text>
  <rect
    x="56.6"
    y="70.5"
    transform="matrix(0.7741 0.633 -0.633 0.7741 59.4817 -20.8061)"
    class="std2"
    width="4.6"
    height="4.9"
  />
  <rect
    x="52.4"
    y="71.3"
    transform="matrix(0.8968 0.4425 -0.4425 0.8968 38.3002 -16.6029)"
    class="std2"
    width="4.6"
    height="4.9"
  />
  <rect
    x="81.3"
    y="38.2"
    transform="matrix(0.9709 0.2395 -0.2395 0.9709 11.8744 -18.9871)"
    class="std2"
    width="5.5"
    height="2.3"
  />
  <rect
    x="82.1"
    y="42.3"
    transform="matrix(0.5809 -0.814 0.814 0.5809 0.2439 87.2744)"
    class="std2"
    width="5.5"
    height="2.3"
  />
  <rect
    x="115.5"
    y="62.8"
    transform="matrix(0.9428 -0.3335 0.3335 0.9428 -15.2169 42.7091)"
    class="std2"
    width="2.6"
    height="5.7"
  />
  <rect
    x="118"
    y="60.1"
    transform="matrix(0.4381 0.8989 -0.8989 0.4381 126.3262 -73.3953)"
    class="std2"
    width="7.7"
    height="8.5"
  />
  <rect
    x="116.4"
    y="66.7"
    transform="matrix(0.9887 -0.1498 0.1498 0.9887 -9.1008 18.4305)"
    class="std2"
    width="2.6"
    height="5.7"
  />
  <rect
    x="117.3"
    y="60.1"
    transform="matrix(0.8215 0.5702 -0.5702 0.8215 56.5092 -59.1161)"
    class="std4"
    width="10.7"
    height="1.2"
  />
  <rect
    x="122.3"
    y="57.9"
    transform="matrix(-0.1253 0.9921 -0.9921 -0.1253 201.692 -60.7556)"
    class="std4"
    width="10.7"
    height="1.2"
  />
  <circle class="std4" cx="118.4" cy="57.7" r="0.6" />
  <circle class="std4" cx="128.3" cy="53.6" r="0.6" />
  <circle class="std4" cx="127" cy="63.7" r="0.6" />
</svg>
