export const SENSE_LAYOUT = {
    right: {
      title: 'Sense',
      colorScheme: 'rgb(0,85,135,1)',
      // activitiesColorScheme: 'rgb(195, 212, 222)',
      // textColorScheme: '#002fba'
      textColorScheme: 'rgb(0,85,135,1)',
      stopIcon: 'images/activity-icons/stops/Insights/Sense.svg',
    }
  }
  