import { MarketingMaterialsComponent } from './shared/components/marketing-materials/marketing-materials.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FourOOneComponent } from './shared/components/401/four-o-one.component';
import { FourOFourComponent } from './shared/components/404/four-o-four.component';
import { AboutComponent } from './shared/components/about/about.component';
import { TutorialsComponent } from './shared/components/tutorials/tutorials.component';
import { BaseModule } from './base/base.module';

const routes: Routes = [
  { path: '', loadChildren: () => import('./base/base.module').then(m => m.BaseModule) },
  {
    path: 'workspace',
    loadChildren: () => import('@projects/workspace/src/app/app.module').then(m => m.AppModule)
  },

  // {
  //   path: 'createproject',
  //   loadChildren: () => import('@projects/create-project/src/app/app.module').then(m => m.AppModule)
  // },

  {
    path: 'iidr',
    loadChildren: () => import('@projects/iidr/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'trainstops',
    loadChildren: () => import('@projects/trainstops/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'sitemap',
    loadChildren: () => import('@projects/sitemap/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'projectsummary',
    loadChildren: () => import('@projects/projectsummary/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'psg',
    loadChildren: () => import('@projects/psg/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'market-solution',
    loadChildren: () => import('@projects/market-solution/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'solution-library',
    loadChildren: () => import('@projects/solution-library/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'amplifier-exchange',
    loadChildren: () => import('@projects/amplifier-exchange/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'admin',
    loadChildren: () => import('@projects/admin/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'document-repository',
    loadChildren: () => import('@projects/document-repository/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'content',
    loadChildren: () => import('@projects/content-management/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'platform-admin',
    loadChildren: () => import('@projects/platform-admin/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'methodology',
    loadChildren: () => import('@projects/methodology/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'user-management',
    loadChildren: () => import('@projects/user-management/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'tools-settings',
    loadChildren: () => import('@projects/tools-settings/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'audit-log',
    loadChildren: () => import('@projects/audit-log/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'test-cases-taxonomy',
    loadChildren: () => import('@projects/test-cases/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'user-stories',
    loadChildren: () => import('@projects/user-stories/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'business-case',
    loadChildren: () => import('@projects/business-case-management/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'velocity',
    loadChildren: () => import('@projects/velocity/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'reports',
    loadChildren: () => import('@projects/reports/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'taxonomy-dashboard',
    loadChildren: () => import('@projects/taxonomy-dashboard/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'maturity-assessment',
    loadChildren: () => import('@projects/maturity-assessment/src/app/app.module').then(m => m.AppModule)
  },

  {
    path: 'taxonomy-documents',
    loadChildren: () => import('@projects/taxonomy-documents/src/app/taxonomy-documents/taxonomy-documents.module').then(m => m.TaxonomyDocumentsModule)
  },
  {
    path: 'language-settings',
    loadChildren: () => import('@projects/language-settings/src/app/app.module').then(m => m.AppModule)
  },

  { path: 'about', component: AboutComponent },
  { path: 'tutorials', component: TutorialsComponent },
  { path: 'marketing', component: MarketingMaterialsComponent },
  { path: 'unauthorized', component: FourOOneComponent },
  { path: '**', component: FourOFourComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false }),
    BaseModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

