import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseService } from '@projects/shared-lib/src/public-api';
import { PRIORITISE_ACTIONS } from '@projects/trainstops/src/app/core/utility/constants';

@Injectable({
  providedIn: 'root'
})
export class PrioritizeUserstoriesService {
  private getPrioritizeUserstoriesURL: string  = `${environment.API_MICROSERVICE_URL.PACKAGE_CONTENT}/business-taxonomy/getDeliverablesExploreMode`
  private checkSequenceNumberURL: string = `${environment.API_MICROSERVICE_URL.PACKAGE_CONTENT}/common/architect/getSequenceNumberByTabCode`;
  PRIORITISE_ACTIONS = PRIORITISE_ACTIONS;
  constructor(private http: HttpClient, private baseService: BaseService) {}

  getPrioritizeData(
    tabcode,
    project_id: number,
    package_id: number,
    function_id: number,
    activity_id: number,
    isJira: number,
    taxonomyId?
  ) {
    const taxonomyIdValue = (project_id == 0) ? taxonomyId: 0;
    if(project_id == 0)
    return this.http.get<any>( 
      `${this.getPrioritizeUserstoriesURL}?tabCode=${tabcode.toUpperCase()}&packageId=${package_id}&functionId=${function_id}&activityId=${activity_id}&isJira=${isJira}&industryPrintId=${taxonomyIdValue}`);
    else 
      return this.http.get<any>(
        environment.API_MICROSERVICE_URL.PROJECT +
          `/sprint-planning/prioritize/${package_id}/${function_id}/${activity_id}/${project_id}/${isJira}/${taxonomyIdValue}`
      );
  }

  getProjectInfo(projectId: any, userId, params?: any) {
    return this.http.get(
      environment.API_MICROSERVICE_URL.PROJECT + '/project/' + projectId+`/?userid=${userId}`,
      { params }
    );
  }

  checkSequenceNumber(tabCode: string) {
    const params = new HttpParams().set('tab_code', tabCode);
    return this.http.get(`${this.checkSequenceNumberURL}`, { params });
  }

  prioritizeUserStories(data,l1Id?) {
    const {
      packageId,
      functionId,
      activityId,
      userRoleId,
      projectId,
      isJiraProject,
      action,
      postData,
    } = data;
    if(action === PRIORITISE_ACTIONS.RESET) {
    return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT +`/sprint-planning/prioritize/${action}/${packageId}/${functionId}/${activityId}/${userRoleId}/${projectId}/${isJiraProject}?l1Id=${l1Id}`, postData);
    } else {
      return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT +`/sprint-planning/prioritize/${action}/${packageId}/${functionId}/${activityId}/${userRoleId}/${projectId}/${isJiraProject}`, postData);
    }
  }

  prioritizeUserStoriesConfirmAll(data) {
    const {
      packageId,
      functionId,
      activityId,
      userRoleId,
      projectId,
      isJiraProject,
      postData,
    } = data;
    return this.http.post<any>(environment.API_MICROSERVICE_URL.PROJECT +`/sprint-planning/prioritize/confirmall/${packageId}/${functionId}/${activityId}/${userRoleId}/${projectId}/${isJiraProject}`, postData);
  }

  sortTypeByPriority(types: any, key: any): any {
    return types.sort(function (a, b) {
      let x = a[key];
      let y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
}
