<div class="filter-popup-component">
  <!-- <img
    class=""
    style="
      width: 32px;
      padding: 11px 6px;
      cursor: pointer;
      border: 3px solid white;
      background-color: white;
      border-radius: 4px;
    "
    (click)="openFilter()"
    src="assets/psg/filter-icon.svg"
    alt=""
  /> -->
  <img class="polygon-arrow" [ngStyle]="{'visibility' : showFilter ? 'visible' : 'hidden' }" src="assets/polygon-up.png" alt=""/>

  <button (click)="openFilter()" class="filter-btn" [ngStyle]="{'background-color' : showFilter ? '#fff' : '#41464E' }">
    <ng-container *ngIf="activeFlag && !showFilter"><span class="blueDot"></span></ng-container>
    <div class="filter-icon" [ngClass]="{'open': showFilter}"></div>
    <span class="tooltiptext filter-tooltip-alignment">{{"Global.Common.Filter.Tooltip" | translate}}</span>
  </button>

  <div *ngIf="showFilter" class="dropdown-container">
    <div class="left-nb-Panel">
      <div class="filter-nb-label">
        <div>{{"Global.Common.Filter.Title" | translate}}</div>
        <div>{{ filteredClients[0]}}</div>
        <div (click)="resetAll()" class="reset-link">{{"Global.Common.Filter.ResetAll" | translate}}</div>
      </div>
      <div class="filter-nb-body">
        <mat-accordion #accordion="matAccordion" multi="true">
          <mat-expansion-panel
            [expanded]="openClientSection"
            (opened)="OpenExpansion('openClientSection')"
            (closed)="CloseExpansion('openClientSection')"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span> {{"Global.Common.Filter.Client" | translate}} </span>
                <span
                  class="count-class"
                  *ngIf="clientLength && clientLength > 0"
                >
                  <span>{{ clientLength }}</span>
                  <img
                    src="assets/psg/cross.svg"
                    width="8px"
                    style="padding-left: 2px; margin-left: 4px;
                    padding-bottom: 1px;"
                    (click)="clearClientsList()"
                  />
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
          <mat-form-field
            class="example-chip-list"
            *ngIf="openClientSection"
            appearance="outline"
          >
            <mat-chip-list #chipList aria-label="Client selection">
              <mat-chip
                *ngFor="let cName of clientNames"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeClient(cName)"
              >
                {{ cName }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input style="line-height: 16px;"
                [placeholder]="'Global.Common.Filter.Client.Placeholder' | translate"
                #clientInput
                [formControl]="clientCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addClient($event)"
              />
            </mat-chip-list>
            <mat-autocomplete
              #auto="matAutocomplete"
              class="filter-autocomplete-panel"
            >
            <mat-option [value]="'all'" (click)="toggleClientData($event, 'all');">
              <div class="color-checkbox-row">
                <span>
                  <input
                    id="all"
                    name="all"
                    type="checkbox"
                    class="checkbox-custom-style"
                    [(ngModel)]="allClientSelected"
                    [checked]="allClientSelected"
                  />
                  <label for="all" class="filter-dropdown-item">
                    <span>{{"Global.Common.Filter.Client.Dropdown.All" | translate}} ({{currentClientList?.length}})</span>
                  </label>
                </span>
              </div>
            </mat-option>
              <mat-option
                *ngFor="let item of filteredClients | async"
                [value]="item"
                (click)="toggleClientData($event, item);"
              >
              <div class="filter-dropdown-sub-item">
                <span>
                  <input
                    id="{{ item.Name }}"
                    name="{{ item.Name }}"
                    type="checkbox"
                    class="checkbox-custom-style"
                    [(ngModel)]="item.Checked"
                    [checked]="item.Checked"
                  />

                  <label for="{{ item.Name }}" class="filter-dropdown-item">
                    <span style="font-weight: normal;" [innerHTML]="highlightSearchResult(item.Name, clientInput.value)"></span>
                    </label>
                </span>
              </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-accordion>

        <mat-accordion #accordion="matAccordion" multi="true">
          <mat-expansion-panel
            [expanded]="openERP"
            (opened)="OpenExpansion('openERP')"
            (closed)="CloseExpansion('openERP')"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span>{{"Global.Common.Filter.Package" | translate}}</span>
              <span
              class="count-class"
              *ngIf="selectedPackage && selectedPackage?.length > 0"
            >
              <span>{{ selectedPackage?.length }}</span>
              <img
              src="assets/psg/cross.svg"
              width="8px"
              style="padding-left: 2px; margin-left: 4px;
              padding-bottom: 1px;"
              (click)="clearPackagesList()"
            />
            </span>
          </mat-panel-title>

            </mat-expansion-panel-header>
          </mat-expansion-panel>
          <div
            *ngIf="openERP"
            style="
              box-shadow: inset 0 -1px 0 0 rgb(0 0 0 / 8%);
              padding-left: 12px;
              max-height: 150px;
              overflow-y: auto;
            "
          >
            <div
              *ngFor="let item of packages"
              style="margin-bottom: 8px; color: #59616c; font-size: 13px;margin-left: 4px;"
            >
              <div class="color-checkbox-row">
                <span>
                  <input
                    id="{{ item.Name }}"
                    name="{{ item.Name }}"
                    type="checkbox"
                    class="checkbox-custom-style"
                    (change)="toggleData($event);"
                    [(ngModel)]="item.Checked"
                    [checked]="item.Checked"
                  />
                  <label for="{{ item.Name }}" class="checkbox-custom-label-style"
                    ><span>{{ item.Name }}</span></label
                  >
                </span>
              </div>
            </div>
          </div>
        </mat-accordion>
        <mat-accordion #accordion="matAccordion" multi="true">
          <mat-expansion-panel
            [expanded]="openManagerSection"
            (opened)="OpenExpansion('openManagerSection')"
            (closed)="CloseExpansion('openManagerSection')"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span> {{"Global.Common.Filter.Manager" | translate}} </span>
                <span
                  class="count-class"
                  *ngIf="managerLength && managerLength > 0"
                >
                  <span>{{ managerLength }}</span>
                  <img
                  src="assets/psg/cross.svg"
                  width="8px"
                  style="padding-left: 2px; margin-left: 4px;
                  padding-bottom: 1px;"
                  (click)="clearManagersList()"
                />
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
          <mat-form-field
            class="example-chip-list"
            *ngIf="openManagerSection"
            appearance="outline"
          >
            <mat-chip-list #chipList aria-label="Client selection">
              <mat-chip
                *ngFor="let mName of managerNames"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(mName)"
              >
                {{ mName }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                [placeholder]="'Global.Common.Filter.Manager.Placeholder' | translate"
                #managerInput
                style="line-height: 16px;"
                [formControl]="managerCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>
            <mat-autocomplete
              #auto="matAutocomplete"
              class="filter-autocomplete-panel"
            >
            <mat-option [value]="'all'" (click)="toggleManagerData($event, 'all');">
              <div class="color-checkbox-row">
                <span>
                  <input
                    id="all"
                    name="all"
                    type="checkbox"
                    class="checkbox-custom-style"
                    [(ngModel)]="allManagerSelected"
                    [checked]="allManagerSelected"
                  />
                  <label for="all" class="filter-dropdown-item">
                    <span>All ({{currentManagerList?.length}})</span>
                    </label>
                </span>
              </div>
            </mat-option>
              <mat-option
                *ngFor="let item of filteredManagers | async"
                [value]="item"
                (click)="toggleManagerData($event, item);"
              >
              <div class="filter-dropdown-sub-item">
                <span>
                  <input
                    id="{{ item.Name }}"
                    name="{{ item.Name }}"
                    type="checkbox"
                    class="checkbox-custom-style"
                    [(ngModel)]="item.Checked"
                    [checked]="item.Checked"
                  />

                  <label for="{{ item.Name }}" class="filter-dropdown-item">
                    <span style="font-weight: normal;" [innerHTML]="highlightSearchResult(item.Name, managerInput.value)"></span></label>
                </span>
              </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </mat-accordion>
        <mat-accordion #accordion="matAccordion" multi="true">
          <mat-expansion-panel
            [expanded]="openCountrySection"
            (opened)="OpenExpansion('openCountrySection')"
            (closed)="CloseExpansion('openCountrySection')"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span>{{ "CreateProject.ProjectDetails.LeadCountry" | translate }}</span>
                <span class="count-class" *ngIf="countryLength && countryLength > 0">
                  <span>{{ countryLength }}</span>
                  <img
                    src="assets/psg/cross.svg"
                    width="8px"
                    style="padding-left: 2px; margin-left: 4px; padding-bottom: 1px;"
                    (click)="clearCountriesList()"
                  />
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        
          <mat-form-field class="example-chip-list" *ngIf="openCountrySection" appearance="outline">
            <mat-chip-list #chipList aria-label="Client selection">
              <mat-chip
                *ngFor="let cName of countryNames"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="removeCountry(cName)"
              >
                {{ cName }}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
              </mat-chip>
              <input
                style="line-height: 16px;"
                [placeholder]="'CreateProject.ProjectDetails.SelectLeadCountry' | translate"
                #countryInput
                [formControl]="countryCtrlNew"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addCountry($event)"
              />
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" class="filter-autocomplete-panel">
              <!-- Select All Option -->
              <mat-option [value]="'all'" (click)="toggleAllSelection()">
                <div class="color-checkbox-row">
                  <span>
                    <input
                      id="all"
                      name="all"
                      type="checkbox"
                      class="checkbox-custom-style"
                      [(ngModel)]="allCountrySelected"
                      [checked]="allCountrySelected"
                    />
                    <label for="all" class="filter-dropdown-item">
                      <span>{{ "Global.Common.Filter.Client.Dropdown.All" | translate }} ({{ countryLength }})</span>
                    </label>
                  </span>
                </div>
              </mat-option>
        
              <!-- Parent-Child Dropdown Structure -->
              <div *ngFor="let instance of filteredCountries | async">
                <mat-option (click)="toggleInstance($event, instance)">
                  <!-- Region Checkbox -->
                  <div>
                    <button mat-icon-button (click)="toggleInstanceExpansion(instance, $event)">
                      <img *ngIf="instance.expanded" src="assets/psg/arrow-down.png" alt="Expand" />
                      <img *ngIf="!instance.expanded" src="assets/psg/arrow-right.png" alt="Collapse" />
                    </button>
                    <span>
                      <input
                        id="{{ instance.instanceValue }}-checkbox"
                        type="checkbox"
                        class="checkbox-custom-style"
                        [ngModel]="instance.checked"
                        [checked]="instance.checked"
                        
                      />
                      <label for="{{ instance.instanceValue }}-checkbox" class="filter-dropdown-item">
                        <span style="font-weight: normal;">{{ instance.instanceName }}</span>
                      </label>
                    </span>
                  </div>
                </mat-option>
        
                <!-- Iterate over the countries in the current instance -->
                <div *ngIf="instance.expanded">
                  <div *ngFor="let item of instance.leadCountry">
                    <mat-option (click)="toggleCountryData($event, item, instance)">
                      <div class="filter-dropdown-sub-item-country">
                        <span>
                          <input
                            id="{{ item.Name }}"
                            name="{{ item.Name }}"
                            type="checkbox"
                            class="checkbox-custom-style"
                            [(ngModel)]="item.Checked"
                            [checked]="item.Checked"
                          />
                          <label for="{{ item.Name }}" class="filter-dropdown-item">
                            <span style="font-weight: normal;" [innerHTML]="highlightSearchResult(item.Name, countryInput.value)"></span>
                          </label>
                        </span>
                      </div>
                    </mat-option>
                  </div>
                </div>
              </div>
            </mat-autocomplete>
          </mat-form-field>
        </mat-accordion>
        <mat-divider id="custom-divider"></mat-divider>
        <div class="cancel-apply-btn">
          <button class="cancel-btn cancel-btn-alignment" (click)="cancel()">{{'Global.Common.Filter.Cancel' | translate}}</button>
          <button class="apply-btn apply-btn-alignment" (click)="apply()"
            [ngClass]="{'btn-disable': (!clientNames?.length && !managerNames?.length && !selectedPackage?.length && !countryNames?.length && !isUpdated) ||
              (!clientNames?.length && !managerNames?.length && !selectedPackage?.length && !countryNames?.length && !activeFlag)}">{{'Global.Common.Filter.Apply' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
