<header class="header-section">
  <h2 class="header-text">
    <h2 class="header-text">
      {{ "Global.Common.Label.ReleasesUpdates" | translate }},
      {{ startDate | date : "MMMM d, y, hh:mm a" }}
      {{ timeZoneAbbreviation }} -
      {{ endDate | date : "MMMM d, y, hh:mm a" }}
      {{ timeZoneAbbreviation }}
    </h2>
  </h2>
  <button class="close-button" (click)="closePopup()">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.29275 9.29304C9.68326 8.9025 10.3164 8.90248 10.707 9.29299L13.9998 12.5856L17.2926 9.29265C17.6832 8.90213 18.3163 8.90212 18.7069 9.29265C19.0974 9.68317 19.0974 10.3163 18.7069 10.7069L15.414 13.9997L18.7072 17.2925C19.0977 17.683 19.0978 18.3162 18.7073 18.7067C18.3168 19.0972 17.6836 19.0973 17.2931 18.7068L13.9998 15.4139L10.7068 18.7062C10.3162 19.0967 9.68307 19.0966 9.29258 18.7061C8.90209 18.3155 8.90215 17.6823 9.29272 17.2919L12.5855 13.9997L9.29279 10.7073C8.90226 10.3167 8.90224 9.68358 9.29275 9.29304Z"
        fill="#2E3238"
      />
    </svg>
  </button>
</header>
<hr />
<div class="content-section">
  <div class="sub-content-section">
    <div class="content" >      
      <div [innerHTML]="releaseBannerDetails"></div>
    </div>
  </div>
</div>
<hr />
<footer class="footer-section">
  <div class="button-section" mat-dialog-actions>
    <button (click)="closePopup()" mat-button>
      <span>{{ "Global.Common.Button.Close" | translate }}</span>
    </button>
  </div>
</footer>
