

export const MapConstant = Object.freeze({
    allowedSolutionFileMimes: [
        'application/pdf',
        'image/png',
        'image/jpeg'
    ],
    mapTypes: [
        { text: 'My maps', value: 5, mapCount: 0 },
        // { text: 'Shared with me', value: 6, mapCount: 0 },
        { text: 'Sector maps', value: 1, mapCount: 0 },
        { text: 'Custom maps', value: 2, mapCount: 0 },
        { text: 'Functional maps', value: 3, mapCount: 0 },
        { text: 'Archives', value: 4, mapCount: 0 },
        { text: 'Private', value: 0, mapCount: 0 }
    ],
    multiSelectOptions: {
        defaultText: 'Select sector',
        isRequired: false,
        validationMessage: 'Select atleast one parameter',
        idProperty: 'Id',
        nameProperty: 'Name',
        isSearchEnabled: false,
        selectAllEnabled: false,
        selectAll: {
            Id: 0, Name: 'All', isChecked: false
        }
    },
    multiSelectNewOptions: {
        idProperty: 'Id',
        nameProperty: 'Name',
        countProperty: 'Count',
        isSearchEnabled: false,
        selectAllEnabled: true,
        isRequired: false,
        selectAll: { Id: 0, Name: 'Select all', isChecked: false },
        displayLabel: { Name: 'All' }
    },
    filterMenu: {
        mapTypes: [
            { Id: 0, Name: 'All' },
            { Id: 1, Name: 'Private' },
            { Id: 2, Name: 'Public' },
        ],
        Sort: {
            IsAscSort: false,
            activeSortColumn: 'CreatedDate',
            icons: [
                { IsAscSort: true, iconClass: 'icon_sortAsc' },
                { IsAscSort: false, iconClass: 'icon_sortDesc' },
            ],
            values: [
                { label: "Map name", SortColumn: "MapName" },
                { label: "Creation date", SortColumn: "CreatedDate" }
            ]
        }
    }
    ,
    capabilityEdit: {
        getCapabilityOverviewApi: 'capability/',
        getSolutionApi: 'solution/GetSolutions',
        uploadSolutionApi: 'api/solution/Upload/Attachment',
        createSolutionApi: 'api/solution/create',
        deleteSolutionAPi: 'api/solution/delete/',
        getKPIApi: 'capability/GetKPIs/',
        saveKPIApi: 'capability/SaveKPI',
        getOpportunityListApi: 'capability/GetOpportunity',
        deleteOpportunityApi: 'capability/deleteopportunity/',
        getOpportunityTypesListApi: 'opportunitytype/GetOpportunityTypeList',
        allowedSolutionFileMimes: [
            'application/pdf',
            'image/png',
            'image/jpeg'
        ]
  },
  demoMapName: 'Demo Map'
});

export const legends = {
    performanceMaturity: [
        { iconClass: 'leading-hexagon legend-icon', color: '#86BC25', text: 'Leading' },
        { iconClass: 'performing-hexagon legend-icon', color: '#97999B', text: 'Performing' },
        { iconClass: 'lagging-hexagon legend-icon', color: '#E86161', text: 'Lagging' },
    ],
    performanceMaturityGap: [
        { iconClass: 'gap0-hexagon legend-icon', color: '#EAEAEA', text: 'No gap' },
        { iconClass: 'gap1-hexagon legend-icon', color: '#F9DCE0', text: '1' },
        { iconClass: 'gap2-hexagon legend-icon', color: '#F3BFC3', text: '2' },
        { iconClass: 'gap3-hexagon legend-icon', color: '#E8848C', text: '3' },
        { iconClass: 'gap4-hexagon legend-icon', color: '#E0616A', text: '4' },
    ],
    otherIcons: [
        { iconClass: 'core-hexagon legend-icon', color: '#ED8B00', text: 'Core' },
        { iconClass: 'strategic-hexagon legend-icon', color: '#00A3E0', text: 'Strategic' },
        { iconClass: 'foundational-hexagon legend-icon', color: '#53565A', text: 'Foundational' },
        { iconClass: 'capability-marker-icon legend-icon', color: '#FFCD00', text: 'High value' },
    ],
    serviceDeliveries: [
        { iconClass: 'icon-flag-sepcial delivery-icon', color: '#86BC25', text: 'COE' },
        { iconClass: 'icon-users delivery-icon', color: '#43B02A', text: 'Shared' },
        { iconClass: 'icon-outsourced delivery-icon', color: '#000000', text: 'Outsourced' },
        { iconClass: 'icon-decrease delivery-icon', color: '#F39200', text: 'Centralized' },
        { iconClass: 'icon-increase delivery-icon', color: '#E30613', text: 'Decentralized' },
        { iconClass: 'icon-offshore delivery-icon', color: '#012169', text: 'Offshore' },
        { iconClass: 'icon_onshore delivery-icon', color: '#1697A8', text: 'Onshore' }
    ]
}

export const allMapsTilesList = Object.freeze({
    allmaps: [
        { filterLabel: "All", propertyLabel: "All", isChecked: true, consolidatedType: [0, 1, 2] }
    ],
    mapTypes: [
        { filterLabel: "My maps", propertyLabel: "Mymaps", isChecked: true, consolidatedType: [0, 1, 2] },
        // { filterLabel: "Shared with me", propertyLabel: "Shared", isChecked: true, consolidatedType: [0, 1, 2] },
        { filterLabel: "Sector", propertyLabel: "Sector", isChecked: true, consolidatedType: [0, 2] },
        { filterLabel: "Functional", propertyLabel: "Functional", isChecked: true, consolidatedType: [0, 2] },
        { filterLabel: "Custom client", propertyLabel: "Custom", isChecked: true, consolidatedType: [0, 2] },
        { filterLabel: "Other private maps", propertyLabel: "OtherPrivateMap", isChecked: true, consolidatedType: [0, 1] },
        { filterLabel: "Archive", propertyLabel: "Archive", isChecked: true, consolidatedType: [0, 1, 2] },
    ],
    mapTypesForGenUser: [
        { filterLabel: "My maps", propertyLabel: "Mymaps", isChecked: true, consolidatedType: [0, 1, 2] },
        { filterLabel: "Sector", propertyLabel: "Sector", isChecked: true, consolidatedType: [0, 2] },
        { filterLabel: "Functional", propertyLabel: "Functional", isChecked: true, consolidatedType: [0, 2] }
    ],
    sortMenu: [
        { Name: "Last updated date ascending", SortColumn: 'ModifiedDate', IsAscSort: true, isSelected: false },
        { Name: "Last updated date descending", SortColumn: 'ModifiedDate', IsAscSort: false, isSelected: false },
        { Name: "Map name ascending", SortColumn: 'MapName', IsAscSort: true, isSelected: false },
        { Name: "Map name descending", SortColumn: 'MapName', IsAscSort: false, isSelected: false },
        { Name: "Last created date ascending", SortColumn: 'CreatedDate', IsAscSort: true, isSelected: false },
        { Name: "Last created date descending", SortColumn: 'CreatedDate', IsAscSort: false, isSelected: false },
    ],
    filterSection: {
        mapTypes: {
            title: "Map type", showCount: null, hideMoreData: false,
            allOption: { label: "All", isChecked: true, isPartial: false }
        },
        sectors: {
            title: "Sector", showCount: 3, hideMoreData: true, showAllTextTag: "sectors", showLessText: "Show less",
            allOption: { label: "All", isChecked: true, isPartial: false }
        }
    }

})

