import { MasterData } from './masterdata.model'
import { Sector } from './map.model'
import { AttachmentDetails, MapType } from './opportunity.model'

export class Solution extends MasterData {
    IsDeleted: Boolean;
    LoggedInUserEmail: string;
    CapabilityId: number;
    Sectors: Sector[];
    Description: string;
    AttachmentDetails: AttachmentDetails;
    attachmentExist: boolean;
    IsPublic: Boolean;
    VideoLink: string;
    MapId?: number;
    CapabilityAssigned: Boolean;
    ClusterAssigned: Boolean;
    SectorNames: string;
    HasSolutionFile: Boolean;
    ParentId?: number;
    MapType: MapType;
    Prepare?: number;
    Build?: number;
    Deploy?: number;
    Vendor: Vendor[];
    IsSelectedForRoadMap: Boolean;
    StartMonth: number;
    PrepareRoadMap: number;
    BuildRoadMap: number;
    DeployRoadMap: number;
    SolutionId: number;
    Industries: any[];
    selectedIndustryIds: any[];
    industriesandsectors: any[];
    selectedSectorIds: any[];
    productNameError: any[];
    Edit: boolean;
    sectorLimit: number;
    CustomSolution: boolean;
    vendorProductError: boolean;
    errorInSector: boolean;
    errorMsgInSector: string;
    errorInName: boolean;
    errorMsgInName: string;
    errorInDesc: boolean;
    errorMsgInDesc: string;
    maxMonthsError: boolean;
    nameExist: boolean;
    msgError: any;
    show: boolean;
    isSelected: boolean;
    expandTile?: boolean;

    constructor() {
      super();
      this.HasSolutionFile = !!(this.AttachmentDetails != null && this.AttachmentDetails.Id > 0);
    }
}
export class Vendor extends MasterData {
    Id: number;
    VendorName: string;
    VendorProductName: string;
    SolutionId: number;
    show: boolean;
}
