import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject  } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { BaseService } from "shared-lib";
import { UserInfo } from 'src/app/shared/constants/ascend-user-project-info';
import {
  erpPkgs
} from './file-handle';
import { FormControl } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class CreateProjectService {
  public createProjHasData = new Subject();
  public formDataCreateProj : any;
  public showConfrmatnPopup = new Subject();
  public getNavigationName = new Subject();
  public getDisableMultiIndustryPrintId: BehaviorSubject<boolean>;

  constructor(private http: HttpClient,private baseService: BaseService) {
     this.getDisableMultiIndustryPrintId = new BehaviorSubject(false);
   }

  getMultipleIndustryPrintFlag(packageId, featureId, langId){
    return this.http.get<any>(`${environment.API_MICROSERVICE_URL?.ADMIN}/package/preferences/${packageId}/0/${featureId}?languageId=${langId}`);
  }

  getUserProjectInfo(params) {
    return this.http.get<UserInfo>(environment.API_MICROSERVICE_URL.PROJECT +'/project',{params});
  }

  getClientDetailsByClientId(clientId) {
    return this.http.get<any>(`${environment.API_MICROSERVICE_URL.PROJECT}/project/clientdetails/${clientId}`)
  }

  getUserCreateProjectFormData(languageId?) {
    return this.http.get<UserInfo>(`${environment.API_MICROSERVICE_URL.PROJECT}/project/projectDetailsPage?languageId=${languageId}`);
  }

  getProjectDetailsPage(packageId,languageId?) {
    return this.http.get<UserInfo>(`${environment.API_MICROSERVICE_URL.PROJECT}/project/projectDetailsPage?packageId=${packageId}&languageId=${languageId}`);
  }
  getUserPrefPackages() {
    return this.http.get<erpPkgs>(`${environment.API_MICROSERVICE_URL?.METHOD}/package/package-detail?statusId=1`).map(res => this.getPckgResponse(res));
  }

  getPckgResponse(res) {
    res?.data?.forEach((item) => {
      item.name = item.package_method_nm;
      item.package_id = item.package_method_id;
      item.project_mode = item.is_project_mode_set ? 'Y' : 'N';
    })
    return res;
   }

  getUserPackageFunction(packageId: any) {
    return this.http.get<any>(`${environment.API_MICROSERVICE_URL?.METHOD}/package/package-function?isFunction=true&packageMethodId=${packageId}`)
      .map(res => this.getReqResponse(res));
  }

  getReqResponse(res) {
    res?.data.forEach((item) => {
      item.id = item.packageFunctionId;
      item.service_name = item.packageFunctionName;
    })
    return res;
  }

  requestCreateProject(obj, files) {
    var formData = new FormData();
    obj[0].clientName = obj[0].clientName.replace(/\</g, "&lt;").replace(/\>/g, "&gt;");

    formData.append('data',JSON.stringify(obj));
    if(!files || obj.logoConsentFlag == 'N') {
      return this.http.post(environment.API_MICROSERVICE_URL.PROJECT + `/project`, formData);
    } else {
      formData.append('file',files);
      return (this.http.post(environment.API_MICROSERVICE_URL.PROJECT + `/project`, formData));
    }
  }

  setCreateFormDetail(data) {
    this.formDataCreateProj = data;
  }

  getCreateFormData() {
    return this.formDataCreateProj;
  }

}
