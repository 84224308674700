import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, retry, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SharedService } from './shared.service';
import { ActivitySuccessComponent } from '../components/activity-success/activity-success.component';
import { ActivitiesService } from './activities.service';

@Injectable({
  providedIn: 'root',
})
export class PollingService {
  private processStartURL = `${environment.API_BASE_URL}/process/start`;
  private stopPolling = new Subject();
  private allPollingData$: Observable<any[]>;
  public resultJiraexp: Subject<any>;
  public resultAlmexp: Subject<any>;
  public resultTeamsexp: Subject<any>;
  public finalizeSprintConfirmCompleted: BehaviorSubject<any>;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private shared: SharedService,
    private activityService: ActivitiesService
  ) {
    this.resultJiraexp = new Subject<any>();
    this.resultAlmexp = new Subject<any>();
    this.resultTeamsexp = new Subject<any>();

    this.finalizeSprintConfirmCompleted = new BehaviorSubject(null);
  }

  startPolling(instanceId, activityId?, fromScreen?) {
    let url = `${environment.API_MICROSERVICE_URL.PROJECT}/process/status/` + instanceId;
    this.allPollingData$ = timer(1, 10000).pipe(
      switchMap(() =>
        this.http.get<any>(url).catch((err) => this.handleError(err))
      ),
      catchError((err) => this.handleError(err)),
      retry(5),
      tap((data) => {
        if (data.status === 'Completed' || data.status === 'Failed') {
          if (activityId == '6026') {
            this.exportJiraModal(data.result);
          } else if (activityId == '6027') {
            this.exportAlmModal(data.result);
          } else if (fromScreen == 'projectSummary') {
            console.log('from :--projectSummary-------');
            this.exportTeamsModal(data.result);
          } else if (activityId == 6015) {
            this.finalizeSprintConfirmDone(data, fromScreen);
          } else {
            this.exitPolling(data);
          }
        }
      }),
      share(),
      takeUntil(this.stopPolling)
    );
  }

  getProcessStart(activityName): Observable<any> {
    return this.http.post<any>(this.processStartURL + '/' + activityName, {});
  }

  getPollingData(): Observable<any[]> {
    return this.allPollingData$.pipe(
      tap((data) => /* console.log('data sent to subscriber', new Date()) */ {})
    );
  }

  exitPolling(data) {
    this.stopPolling.next();
    this.shared.setUploadTaxanomyStatus(true);
    if (data.status === 'Completed') {
      this.activityService.setImportFilesData(data.result);
      this.openStatusModal('210px', '820px');
    } else {
      this.openStatusModal('280px', '820px');
    }
  }

  handleError(error: HttpErrorResponse) {
    console.log('An error occurred:', error);
    this.exitPolling(error);
    return throwError('Something bad happened; please try again later.');
  }

  openStatusModal(height, width) {
    const dialogRef = this.dialog.open(ActivitySuccessComponent, {
      minHeight: height,
      width: width,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  exportJiraModal(data) {
    this.stopPolling.next();
    this.resultJiraexp.next(data);
  }
  exportAlmModal(data) {
    this.stopPolling.next();
    this.resultAlmexp.next(data);
  }
  exportTeamsModal(data) {
    this.stopPolling.next();
    this.resultTeamsexp.next(data);
  }

  finalizeSprintConfirmDone(data, fromScreen) {
    this.stopPolling.next();
    data['fromScreen'] = fromScreen;
    this.finalizeSprintConfirmCompleted.next(data);
  }
}
