<div class="step2-container">
  <section class="header">
    <div class="header-title-action">
      <div class="stepheader">{{'Activities.UserStoryLibrary.SprintCapacity.Label.GenerateReleasePlan' | translate}} /
        {{l1_obj?.l1_tab}}</div>
      <div>
        <button class="download-release-plan-btn" [disabled]="disabledDownloadSprintCapacity" (click)="downloadReleasePlan()">
          <img class="arrow-up" src="assets/psg/download-btn.svg" alt="" />
          {{ 'Activities.UserStoryLibrary.SprintCapacity.Action.DownloadReleasePlan' | translate}}
          <span class="tooltiptext" *ngIf="disabledDownloadSprintCapacity">
            {{'Activities.UserStoryLibrary.SprintCapacity.NoL1Generated.DownloadReleasePlanTooltip' | translate}}
          </span>
          <span class="tooltiptext" *ngIf="!disabledDownloadSprintCapacity">
            {{'Activities.UserStoryLibrary.SprintCapacity.DownloadReleasePlanTooltip' | translate}}
          </span>
        </button>
        <button class="generate-all-release-plan-btn" [disabled]="!isAtleastOneL1Confirmed || l1_obj?.is_finalized || viewMode === ASCEND_MODES?.EXPLORE_MODE" (click)="generateAllReleasePlan()">
          {{ 'Activities.UserStoryLibrary.SprintCapacity.Action.GenerateAllReleasePlan' | translate}}
          <span class="tooltiptext" *ngIf="!isAtleastOneL1Confirmed">
            {{'GenerateReleasePlan.Popup.SprintCapacity.GenerateAllFlow1Tooltip' | translate}}
          </span> 
          <span class="tooltiptext" *ngIf="isAtleastOneL1Confirmed">
            {{'GenerateReleasePlan.Popup.SprintCapacity.ConfirmedL1Tooltip' | translate}}
          </span>
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>
    <div class="step-sub-header">{{'Activities.UserStoryLibrary.SprintCapacity.stepDetails' | translate}}</div>
  </section>

  <section class="chart-section">
    <div class="toggle-editbtn-section">
      <span class="tab-toggle" *ngIf="l1_obj?.sprintPlan?.length"> <!-- UPDATE ngif condition-->
        <mat-button-toggle-group #group="matButtonToggleGroup" value="VIEW_1">
          <mat-button-toggle class="view" value="VIEW_1" (click)="toggle(false)"
            [ngClass]="{'selected' : group.value == 'VIEW_1', 'not-selected' : group.value == 'VIEW_2'}">
            {{'Activities.UserStoryLibrary.SprintCapacity.chart.percent' | translate}}
          </mat-button-toggle>
          <mat-button-toggle class="view" value="VIEW_2" (click)="toggle(true)" [disabled]='false'
            [ngClass]="{'selected' : group.value == 'VIEW_2', 'not-selected' : group.value == 'VIEW_1'}">
            {{'Activities.UserStoryLibrary.SprintCapacity.storyPoints' | translate}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </span>

      <span class="button-row">
        <button class="edit-button" [disabled]="isAllL1Finalized || l1_obj?.is_finalized || l1_obj?.sprintPlan?.length === 1 || !l1_obj?.sprintPlan || viewMode === ASCEND_MODES?.EXPLORE_MODE" (click)="editCapacity()" #editButton>
          <img class="edit-img" src="assets/psg/vector.svg" alt="" />
          <span class="edit-label">{{'Activities.UserStoryLibrary.SprintCapacity.Label.EditExpectedVelocity' |
            translate}}</span>
          <span class="tooltiptext" *ngIf="!isAtleastOneL1Confirmed">
            {{'GenerateReleasePlan.Popup.SprintCapacity.GenerateAllFlow1Tooltip' | translate}}
          </span>   
        </button>
      </span>
    </div>
    <div class="graph-area"
      *ngIf="l1_obj?.stage_chart_object">
      <sprint-barchart #barChartComponent id="barChartComponent" [data]="l1_obj?.stage_chart_object"
        [unAssignedUSCount]="l1_obj?.countUnassigned || 0"></sprint-barchart>
    </div>
  </section>

  <section class="sprints-area" >

    <div class="custom-activity-section">
      <!-- level 01 - sprint level -->
      <div *ngIf="l1_obj?.isconfirm_sprint_us_allocation || l1_obj?.is_finalized">
        <div class="sprint-header">

          <ng-container>
            <div class="header-row">
              <div class="sprint-col">
                <span class="hash-symbol" >#</span>
                <span class="header-col-name">{{'Global.Common.Label.Sprint' | translate}}</span>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <section class="header-col-first-part">
                <span class="header-col-fte-count"
                *ngIf="!hideFunTechStoryPoints">{{'Activities.UserStoryLibrary.SprintCapacity.Label.FunctionalFTECount'
                | translate}}</span>
                <span class="header-col-fte-count"
                  *ngIf="!hideFunTechStoryPoints">{{'Activities.UserStoryLibrary.SprintCapacity.Label.TechnicalFTECount' |
                  translate}}</span>
              </section>
              <mat-divider [vertical]="true"></mat-divider>
              <section class="header-col-second-part">
                <span class="header-col-name">{{'Global.Common.Label.UserStories' | translate}}</span>
                <span class="header-col-name">{{'Global.Common.Label.StoryPoints' | translate}}</span>
                <span class="header-col-name"
                  *ngIf="!hideFunTechStoryPoints">{{'Activities.UserStoryLibrary.SprintCapacity.Label.FunctionalStoryPoints'
                  | translate}}</span>
                <span class="header-col-name"
                  *ngIf="!hideFunTechStoryPoints">{{'Activities.UserStoryLibrary.SprintCapacity.Label.TechnicalStoryPoints'|
                  translate}}</span>
              </section>
            </div>
          </ng-container>

        </div>
        <div class="sprint-content" *ngIf="l1_obj?.isconfirm_sprint_us_allocation">
        <mat-accordion multi="false" *ngFor="let sprintPlan of l1_obj?.sprintPlan;let i=index;">
          <mat-expansion-panel class="level-scope" hideToggle="true" #panelState1>
            <mat-expansion-panel-header class="l1-header">
              <mat-panel-title class="l1-label l-label">
                <button mat-icon-button>
                  <img *ngIf="panelState1.expanded" src="assets/psg/arrow-down.png" alt="" />
                  <img *ngIf="!panelState1.expanded" src="assets/psg/arrow-right.png" alt="" />
                </button>
                <span class="color-box"
                  [ngStyle]="{'background-color':colors[(sprintPlan.sprint_order_no-1)%10]}">{{sprintPlan?.sprint_order_no}}</span>
                <span class="pt-1 first-col-value">
                  {{ sprintPlan?.sprint_name}}
                </span>
                <ng-container>
                  <div class="header-cols">
                    <section class="header-col-first-part">
                      <span class="header-col-fte-count" *ngIf="!hideFunTechStoryPoints"> {{ sprintPlan?.functional_FTE_count
                      || '-'}}</span>
                      <span class="header-col-fte-count" *ngIf="!hideFunTechStoryPoints"> {{ sprintPlan?.technical_FTE_count
                        || '-'}}</span>
                    </section>
                    <section class="header-col-second-part">
                      <span class="header-col-name">
                        {{sprintPlan?.total_user_stories || '-'}}
                      </span>
                      <span class="header-col-name">
                        {{sprintPlan?.total_efforts || '-'}}</span>
                      <span class="header-col-name" *ngIf="!hideFunTechStoryPoints"> {{ sprintPlan?.total_f_story_point
                        || '-'}}</span>
                      <span class="header-col-name" *ngIf="!hideFunTechStoryPoints"> {{ sprintPlan?.total_t_story_point
                        || '-'}}</span>
                      </section>
                  </div>
                </ng-container>
                <ng-container *ngIf="l1_obj.isconfirm_sprint_us_allocation">
                  <div style="position: absolute; right:1rem">
                    <!-- <app-add-to-sprint-popup ></app-add-to-sprint-popup> -->
                    <button class="sprint-edit-popup" [disabled]="l1_obj?.is_finalized || sprintPlan?.isSprintCompleted || viewMode === ASCEND_MODES?.EXPLORE_MODE"
                      [ngClass]="{'focused':sprintPlan?.isPopupOpen}" #addtosprintBtn
                      (click)="$event.stopPropagation();openAddToSprintPopup(sprintPlan,i)">+</button>
                  </div>
                </ng-container>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <!-- level 02 - l3 group level -->
            <ng-template matExpansionPanelContent>
              <ng-container *ngFor="let l3_obj of sprintPlan?.l3_group">
                <mat-accordion multi="false">
                  <mat-expansion-panel [class.mat-expansion-panel]="false" hideToggle="true" #panelState2>
                    <mat-expansion-panel-header>
                      <mat-panel-title class="l-label l2-border"
                        [ngClass]="{'l2-label-expand ': panelState2.expanded, 'l2-label-collapse ': !panelState2.expanded}">
                        <button mat-icon-button>
                          <img *ngIf="panelState2.expanded" src="assets/psg/arrow-down.png" alt="" />
                          <img *ngIf="!panelState2.expanded" src="assets/psg/arrow-right.png" alt="" />
                        </button>

                        <span class="text-ellipsis">
                          {{l3_obj.l3_value}}
                          <span class="tooltiptext">{{l3_obj.l3_value}}</span>
                        </span>
                        <ng-container>
                          <div class="header-cols">
                            <span class="header-col-name">{{l3_obj.total_user_stories }}</span>
                            <span class="header-col-name">{{l3_obj.total_efforts }}</span>
                            <span class="header-col-name" *ngIf="!hideFunTechStoryPoints">{{ l3_obj.total_f_story_point
                              }}</span>
                            <span class="header-col-name" *ngIf="!hideFunTechStoryPoints">{{ l3_obj.total_t_story_point
                              }}</span>
                          </div>
                        </ng-container>

                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <!-- level 03 - Type level data -->
                    <ng-template matExpansionPanelContent>
                      <ng-container *ngFor="let type of l3_obj.type_level_data">
                        <mat-accordion multi="false">
                          <mat-expansion-panel [class.mat-expansion-panel]="false" hideToggle="true" #panelState3>
                            <mat-expansion-panel-header>
                              <mat-panel-title class="l-label l3-label l3-border"
                                [ngClass]="{'l3-label-expand ': panelState3.expanded, 'l3-label-collapse ': !panelState3.expanded}">
                                <button mat-icon-button>
                                  <img *ngIf="panelState3.expanded" src="assets/psg/arrow-down.png" alt="" />
                                  <img *ngIf="!panelState3.expanded" src="assets/psg/arrow-right.png" alt="" />
                                </button>
                                <span>
                                  {{ type.story_type_name}}
                                </span>

                                <ng-container>
                                  <span class="header-cols">
                                    <span class="header-col-name">{{type.total_user_stories}}</span>
                                    <span class="header-col-name">{{type.total_efforts}}</span>
                                    <span class="header-col-name"
                                      *ngIf="!hideFunTechStoryPoints">{{type.total_f_story_point}}</span>
                                    <span class="header-col-name"
                                      *ngIf="!hideFunTechStoryPoints">{{type.total_t_story_point}}</span>
                                  </span>
                                </ng-container>
                                <div class="rearrange-action-area"
                                  *ngIf="!l1_obj?.is_finalized ">
                                  <button class="rearrenge-button"
                                    (click)="moveToSprint(sprintPlan?.sprint_name, l3_obj.l3_value, type)"
                                    [disabled]="viewMode === ASCEND_MODES?.EXPLORE_MODE" matTooltip="{{'GenerateReleasePlan.Swap.Button.ToolTip' | translate}}" #tooltip="matTooltip" matTooltipPosition="above"
                                    matTooltipClass="sprint-swap-tooltip"
                                    #moveToSprintIcon>
                                    <mat-icon class="rearrenge-img">swap_vert</mat-icon>
                                  </button>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <!-- level 04 - l4 group -->
                            <div *ngIf="panelState2.expanded">
                              <!-- <ng-template matExpansionPanelContent> -->

                              <ng-container *ngFor="let l4 of type.l4_group">
                                <!-- <div><div *ngIf="l4.l4_jira_id"><strong>{{l4.l4_jira_id}}</strong></div> <span>{{l4.l4_value}}</span></div> -->
                                <mat-expansion-panel [class.mat-expansion-panel]="false" hideToggle="true">
                                  <mat-expansion-panel-header class="l4-expansion">
                                    <mat-panel-title (click)="$event.stopPropagation()" class="l4-label l4-border l-label">
                                      <div>
                                        <div *ngIf="l4.l4_jira_id"><strong>{{l4.l4_jira_id}}</strong></div>
                                        <span>{{l4.l4_value}}</span>
                                      </div>
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                </mat-expansion-panel>
                              </ng-container>
                              <!-- </ng-template> -->
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </ng-container>
                    </ng-template>
                  </mat-expansion-panel>
                </mat-accordion>
              </ng-container>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      </div>
      <!-- no data section -->
      <ng-container class="noData" *ngIf="!l1_obj?.isconfirm_sprint_us_allocation">
        <div class="noData">
          <span><img src="assets/trainstop/NoData.svg" class="no-data-img" alt="" /></span>
          <h4>{{ 'Activities.UserStoryLibrary.SprintCapacity.boldNoDataTitle' | translate }}</h4>
          <h6>{{ 'Activities.UserStoryLibrary.SprintCapacity.NoDataTitleStmt1' | translate }} </h6>
          <h6>{{ 'Activities.UserStoryLibrary.SprintCapacity.NoDataTitleStmt2' | translate }}</h6>
          <h6>{{ 'Activities.UserStoryLibrary.SprintCapacity.NoDataTitleStmt3' | translate }}</h6>
        </div>
      </ng-container>
    </div>

  </section>
  <section class="footer-button">
    <div class="footer">
      <div class="left-part"> <!---- Reset button -->
      </div>
      <div class="right-part">
        <button class="pre-btn btn-arrow-secondary" type="button" (click)="previousStep()">
          <img class="arrow-up" src="assets/psg/arrow-back.svg" alt="" />
          <span class="tooltiptext">
            {{'Global.Common.Label.PreviousStepTooltipText' | translate}}
          </span>
        </button>
        <button class="next-btn btn-arrow-secondary" type="button"
          (click)="nextStep()">
          <img class="arrow-up" src="assets/psg/arrow-up.svg" alt="" />
          <span class="tooltiptext">
            {{'Global.Common.Label.NextStepTooltipText' | translate}}
          </span>
        </button>
        <button *ngIf="!isAllL1Finalized" mat-raised-buttom class="btn-confirm" (click)="generateReleasePlan()"
          [disabled]="!l1_obj?.sprintPlan || l1_obj?.sprintPlan?.length === 0 || l1_obj?.is_finalized || viewMode === ASCEND_MODES?.EXPLORE_MODE">
          {{'Global.Common.Button.Generate' | translate}} {{l1_obj?.l1_tab}} <span class="tooltiptext" *ngIf="l1_obj?.sprintPlan?.length > 0">
            {{'Activities.UserStoryLibrary.SprintCapacity.confirmTooltipText' | translate}}
          </span>
          <span class="tooltiptext" *ngIf="!l1_obj?.sprintPlan">
            {{'GenerateReleasePlan.Popup.SprintCapacity.GenerateFlow1Tooltip' | translate}} {{ l1_obj?.l1_tab + ' step.'}} 
          </span>
        </button>
      </div>
    </div>
  </section>
</div>
<ng-template #snackBarTemplate>
  <img src="assets/white_tick.svg"> {{successMessage}}
</ng-template>
