import { Component, OnInit } from '@angular/core';
import { FilterOverlayRef } from '../../filter-overlay/filter-overlay-ref';
import { FilterData, FilterArray, FILTER_CUSTOM_CONSTANTS } from '../../../model/filter-content.model';
import { PassGlobalInfoService } from 'src/app/shared/services/pass-project-global-info.service';
import { ProjectGlobalInfoModel } from 'src/app/shared/model/project-global-info.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CryptUtilService } from "src/app/shared/services/crypt-util.service";
import { FilterCustomService } from "../../../services/filter-custom.service";
import { Router } from "@angular/router";
import { filter } from 'rxjs-compat/operator/filter';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';
import { IIDRService } from '@projects/iidr/src/app/iidr/iidr.service';

@Component({
  selector: "app-filter-content",
  templateUrl: "./filter-content.component.html",
  styleUrls: ["./filter-content.component.scss"],
})
export class FilterContentComponent implements OnInit {
  filterComponent: FilterData;
  functionFilteredData: FilterData;
  disableFilter: boolean = false;
  isAmpDelFilterApplyDisabled: boolean = false;
  selectAll: string = "Select all";
  FILTER_CONSTANT = FILTER_CUSTOM_CONSTANTS;
  projectGlobalInfo: ProjectGlobalInfoModel;
  disabled: boolean;
  isAscendAdmin: boolean;
  currentTab: string = "";
  isStatusFilterForUserStoryTab: boolean;
  isStatusFilterForTestCasesTab: boolean;
  sequenceExist: any;
  defaultPackageIdSelected: any;
  attributesChanged: boolean;
  previousResponse:any;
  PackageValueSession:any;
  packageValueUserPref:any;
  responsePackage:any;
  functionValueSession:any;
  functionValueUserPref:any;
  responseFunction:any;
  functionPrevValue:any;
  prjId:any;
  constructor(
    private cryptUtilService: CryptUtilService,
    private filterOverlayRef: FilterOverlayRef,
    private globalData: PassGlobalInfoService,
    private userService: AuthenticationService,
    public sharedService: SharedService,
    private router: Router,
    private _filterCustomService: FilterCustomService,
    private userPreferenceService : UserPreferenceService,
    private iidrservice:IIDRService
  ) {
    this.filterComponent = JSON.parse(JSON.stringify(filterOverlayRef.data));
    this.userPreferenceService.getProjectId().subscribe((id)=>{
      this.prjId=id;
    })
    if (this.filterComponent && this.filterComponent.title === "Functions" ) { 
      if(this.prjId !='0' && this.router.url =='/site-map'){
        this._filterCustomService.setFunctionsFilterSelectState(
          this.filterComponent,
          "SITEMAP_OG_FILTERDATA"
        );
      }

      if(this._filterCustomService.filterFuntionsByPackageId() ) {
        this.filterComponent = this._filterCustomService.filterFuntionsByPackageId();
        this.userPreferenceService.getPackageIdData().subscribe((id) => {
          if(id){
            if(this.projectGlobalInfo && this.projectGlobalInfo?.projectId == '0' && this.router.url == "/home"){
               this.filterComponent.l1Filter.filterValues[0].childValues.forEach((x) => {
              if(x.packageId == id)
              {
                x.selectedFlag ='Y';
                this.cryptUtilService.setItem(
                  "SELECTED_PACKAGE",
                  x,
                  "SESSION"
                );
                this._filterCustomService.setFunctionsFilterSelectState(
                  null,
                  "IIDR_OG_FILTERDATA"
                );
              }
              else{
                x.selectedFlag='N';
              }
              // if(x.packageId == ['1008']){
              //   x.selectedFlag ='Y';
              //   this.cryptUtilService.setItem(
              //     "SELECTED_PACKAGE",
              //     x,
              //     "SESSION"
              //   );
              //   this._filterCustomService.setFunctionsFilterSelectState(
              //     null,
              //     "IIDR_OG_FILTERDATA"
              //   );
              // }
              // else{
              //   x.selectedFlag='N';
              // }
            });
            }
            
          }
        });
      }
      
    }


    if (this.filterComponent &&
      this.filterComponent.title == "Attributes" &&
      !this._filterCustomService.attributesFilterChanged
    ) {
      this._filterCustomService.attributesFilterChanged = true;
      this.filterComponent.l1Filter.filterValues[0].childValues.map((x) => {
        x.selectedFlag = "Y";
      });
    }

  
    if ( this.filterComponent.title ==="Functions") {
      let functionCurrentData = this.filterComponent.l1Filter.filterValues[0].childValues.find((e)=>{if(e.selectedFlag =="Y"){return e.entityName}});
      this.userPreferenceService.getFunctionData().subscribe((response) => {
        if(response){
          if(this.router.url !='/site-map'){
              if(functionCurrentData && functionCurrentData?.entityName == response){
                this.defaultSelectedExplore(functionCurrentData);
                
              }
              else if(functionCurrentData && functionCurrentData?.entityName != response){
                if(this.previousResponse){
                  let resData =this.filterComponent.l1Filter.filterValues[0].childValues.find((e)=>{if(e.entityName == response){return e}});
                this.defaultSelectedExplore(resData);
                }
                else{
                let responsecurrentData =this.filterComponent.l1Filter.filterValues[0].childValues.find((e)=>{if(e.entityName == functionCurrentData?.entityName){return e}});
                this.defaultSelectedExplore(responsecurrentData);
                }
              }
              else{
                this.defaultSelectedExplore();
              }
            }
            if(this.router.url =='/site-map' && this.prjId =='0'){
              console.log(this.functionPrevValue)
              let functionCurrentData = this.filterComponent.l1Filter.filterValues[0].childValues.find((e)=>{if(e.selectedFlag =="Y"){return e.entityName}});
              this.userPreferenceService.getFunctionData().subscribe((response) => {
                if(response){
                  this.functionValueSession= this.cryptUtilService.getItem("Function-Value-Session","SESSION");
                  
                  this.functionValueUserPref = this.cryptUtilService.getItem("Function-Value-UserPref-Initial", "SESSION");
                 this.functionPrevValue =this.cryptUtilService.getItem("function-value-selected", "SESSION");
        
                    if(response && this.functionValueSession){
                      
                        this.defaultSelectedExplore(this.functionValueSession);
                      
                    }
                    else{
                      this.defaultSelectedExplore();
                    }

                    
                }
                  });
                  
             
            }
          }
          });
          
       
      
      
           
    }
    if(this.filterComponent.title === "Packages" && this.router.url =='/site-map'){
      this.PackageValueSession= this.cryptUtilService.getItem("Package-Value-Session","SESSION");
      this.packageValueUserPref = this.cryptUtilService.getItem("Package-Value-UserPref", "SESSION");
      this.responsePackage=this.cryptUtilService.getItem("Package-Value-Internal","SESSION");
        
      this.userPreferenceService.getPackageData().subscribe((responseData) => {
        if(responseData){
                  
          if(responseData && !this.PackageValueSession && !this.packageValueUserPref){
              this.defaultSelectedExplore();
            }
            else if(this.responsePackage ){
            if(this.responsePackage != responseData){
            this.defaultSelectedExplore();
            }
              if(this.responsePackage == responseData){
                this.userPreferenceService.getFunctionData().subscribe((func)=>{
                  this.functionValueUserPref = this.cryptUtilService.getItem("Function-Value-UserPref", "SESSION");
                 if(this.functionValueUserPref != func){
                   this.PackageValueSession ='';
                   this.defaultSelectedExplore();
                 }
                 if(this.functionValueUserPref != func){
                  this.defaultSelectedExplore(this.PackageValueSession);
                }
                })
                
              }
            
          }
          }
     
        
      });
    }
    this.sortData();
    this.currentTab = this.sharedService.currentTab;
  }

  sortData() {
    if (
      this.filterComponent.l2Filter &&
      this.filterComponent.l2Filter.filterValues
    ) {
      this.filterComponent.l2Filter.filterValues = this.filterComponent.l2Filter.filterValues.sort(
        (a, b) => Number(a.entityId) - Number(b.entityId)
      );
    }

    if (
      this.filterComponent.l3Filter &&
      this.filterComponent.l3Filter.filterValues
    ) {
      const ll = this.filterComponent.l3Filter.filterValues
        .map((x) => x.entityId)
        .sort();
      this.filterComponent.l3Filter.filterValues = ll.reduce((res, x) => {
        res.push(
          this.filterComponent.l3Filter.filterValues.find(
            (y) => y.entityId === x
          )
        );
        return res;
      }, []);

      this.filterComponent.l3Filter.filterValues = this.filterComponent.l3Filter.filterValues.sort(
        (a, b) =>
          String(a.entityId)[0] === String(b.entityId)[0] &&
          Number(a.entityId) - Number(b.entityId)
      );
    }
  }

  ngOnInit() {
    this.checkSequenceExist();
    this.isAscendAdmin = JSON.parse(
      this.userService.getUser().projectDetails.isAscendAdmin
    );
    this.globalData.share.subscribe((x) => (this.projectGlobalInfo = x));
    this.disabled =
      (this.projectGlobalInfo.viewMode != "EXPLORE" &&
        this.filterComponent.readOnly) ||
      (this.projectGlobalInfo.role == "PROJECT_MEMBER" && !this.isAscendAdmin);
    this.isStatusFilterForUserStoryTab =
      this.filterComponent.title == "Status"
        ? this.currentTab == "USER_STORIES"
          ? false
          : this.filterComponent.title == "Status"
        : true;
    this.isStatusFilterForTestCasesTab = !(this.filterComponent.title ==
    "Status"
      ? this.currentTab == "TEST_SCENARIOS"
        ? false
        : this.filterComponent.title == "Status"
      : true);
  }

  disableFilters(e) {
    if (
      (this.currentTab == "USER_STORY_SPRINT_CONFIGURE") &&
      e.title == "Type"
    ) {
      this.disableFilter = true;
      return true;
    } else {
      this.disableFilter = false;
      return false;
    }
  }

  resetFilter() {
    for (let obj of this.filterComponent.l1Filter.filterValues)
      for (let childEle of obj.childValues) {
        childEle.selectedFlag = "N";
        this.unCheckChildren(childEle, 2, "N");
      }
  }

  selectAllFilters(event, childArray, parentArray: any[], level: number) {
    let selected = event.source.checked ? "Y" : "N";
    if (childArray) {
      for (let obj of childArray) {
        if (!parentArray || this.filterAdded(obj, parentArray))
          //Check if filter is at first level or parent is selected
          obj.childValues = obj.childValues.map((t) => {
            t.selectedFlag = selected;
            this.unCheckChildren(t, level + 1, selected);
            return t;
          });
        obj.changed = "Y";
      }
    }
    if (level == 3) {
      this.updateL1L2L3AfterApply(
        this.filterComponent.l3Filter,
        this.filterComponent.l2Filter
      );
      this.updateL1L2L3AfterApply(
        this.filterComponent.l2Filter,
        this.filterComponent.l1Filter
      );
    } else if (level == 2) {
      this.updateL1L2L3AfterApply(
        this.filterComponent.l2Filter,
        this.filterComponent.l1Filter
      );
    }
  }

  allSelected(childArray, parentArray) {
    let isUnChecked = false;

    if (childArray) {
      for (let obj of childArray) {
        if (
          (!parentArray || this.filterAdded(obj, parentArray)) &&
          obj.childValues
        ) {
          isUnChecked =
            obj.childValues.findIndex(
              (t) => !t.selectedFlag || t.selectedFlag == "N"
            ) >= 0;
          if (isUnChecked) break;
        }
      }
    }

    if (this.router.url == "/site-map" && this.filterComponent.title == "Amplifiers / Deliverables") {
      this.isAmpDelFilterApplyDisabled=this.checkAmplifierDeliverableFilterSelected(this.filterComponent.l1Filter.filterValues);
    }

    return !isUnChecked;
  }
  setStatusFilters(name, value, type) {
    if (type == "Status" && this.currentTab == "USER_STORIES") {
      if (name == "Failed") {
        this.sharedService.IsFailedSelected = value;
      }
      if (name == "Imported") {
        this.sharedService.IsImportedSelected = value;
      }
    }
  }
  filterChanged(event, element, level, selectType) {
    let filterArray = this.getFilterValues(level);
    let selectedFlag;
    if (event.source.checked) {
      this.setStatusFilters(element.entityName, true, element.entityType);
      selectedFlag = "Y";
      //In case of single select deselect existing selected value
      if (selectType == this.FILTER_CONSTANT.SINGLE) {
        for (let obj of filterArray) {
          if (obj.childValues) {
            let index = obj.childValues.findIndex((t) => t.selectedFlag == "Y");
            if (index >= 0) {
              obj.childValues[index].selectedFlag = "N";
              this.unCheckChildren(obj.childValues[index], level + 1, "N");
            }
          }
        }
      }
    } else {
      selectedFlag = "N";
      this.setStatusFilters(element.entityName, false, element.entityType);
    }
    //Update current element and its children
    element.selectedFlag = selectedFlag;
    if (selectedFlag == "N" || selectType != this.FILTER_CONSTANT.SINGLE)
      this.unCheckChildren(element, level + 1, selectedFlag);

    filterArray = filterArray.map((t) => {
      t.changed = "Y";
      return t;
    });
    if (level == 3) {
      this.updateL1L2L3AfterApply(
        this.filterComponent.l3Filter,
        this.filterComponent.l2Filter
      );
      this.updateL1L2L3AfterApply(
        this.filterComponent.l2Filter,
        this.filterComponent.l1Filter
      );
    } else if (level == 2) {
      this.updateL1L2L3AfterApply(
        this.filterComponent.l2Filter,
        this.filterComponent.l1Filter
      );
    }
    if (this.router.url == "/site-map" && this.filterComponent.title == "Amplifiers / Deliverables") {
      this.isAmpDelFilterApplyDisabled=this.checkAmplifierDeliverableFilterSelected(filterArray);
    }
  }

  getFilterValues(level): FilterArray[] {
    switch (level) {
      case 1:
        return this.filterComponent.l1Filter.filterValues;
      case 2:
        return this.filterComponent.l2Filter.filterValues;
      case 3:
        return this.filterComponent.l3Filter.filterValues;
      case 4:
        return this.filterComponent.l4Filter.filterValues;
    }
  }

  unCheckChildren(element, level, flag) {
    let childObj;

    childObj = this.getFilterValues(level);

    if (childObj)
      for (let node of childObj) {
        if (
          element.entityId === node.entityId &&
          element.entityType.toLowerCase() === node.entityType.toLowerCase()
        ) {
          for (let childEle of node.childValues) {
            childEle.selectedFlag = flag;
            this.unCheckChildren(childEle, level + 1, flag);
          }
        }
      }
  }

  filterAdded(childElement, parentFilterArray) {
    //Find if parentId of child element is selected in parent element
    let isVisible = false;

    if (parentFilterArray)
      for (let x of parentFilterArray) {
        isVisible =
          x.childValues.findIndex(
            (t) =>
              t.entityId == childElement.entityId &&
              t.entityType.toLowerCase() ==
                childElement.entityType.toLowerCase() &&
              t.selectedFlag == "Y"
          ) != -1;
        if (isVisible) break;
      }
    return isVisible;
  }

  checkAmplifierDeliverableFilterSelected(filterArray: any): boolean {
    if (filterArray) {
      for (let obj of filterArray) {
        if (obj.childValues) {
          let isCheckedCount = obj.childValues.filter((t) => t.selectedFlag == "Y").length;
          if (isCheckedCount >= 1) 
            return false;
          else
            return true;
        }
      }
    }
  }

  childElementPresent(childArray, parentFilterArray) {
    //Check if next level elements exist for selected parent
    return !!(
      !!parentFilterArray &&
      !!childArray &&
      childArray.filter((t) => this.filterAdded(t, parentFilterArray)).length >
        0
    );
  }

  checkSequenceExist() {
    this.sharedService.checkSequenceNumber(this.currentTab).subscribe(
      (res) => {
        this.sequenceExist = res;
      },
      () => {
        this.sequenceExist = "";
      }
    );
  }
  calculateTaxonomySequence(
    level: string,
    level1: number,
    level2?: number,
    level3?: number
  ) {
    if (this.sequenceExist !== "") {
      let l2, l3;
      switch (level) {
        case "level1":
          if (this.sequenceExist.l1 == "Y" && level1) return level1;
          else return "";
        case "level2":
          if (this.sequenceExist.l2 == "Y" && level1 && level2) {
            l2 = String(level2).substring(String(level1).length);
            return [level1, l2].join(".");
          } else return "";
        case "level3":
          if (this.sequenceExist.l3 == "Y" && level1 && level2 && level3) {
            l2 = String(level2).substring(String(level1).length);
            l3 = String(level3).substring(String(level2).length);
            return [level1, l2, l3].join(".");
          } else return "";
      }
    } else {
      return "";
    }
  }

  updateL1L2L3AfterApply(x, y) {
    let entityArr = [];
    if (x && y && x.filterValues && y.filterValues) {
      x.filterValues.forEach((f, j) => {
        if (f && f.childValues) {
          f.childValues.forEach((h, l) => {
            if (h && h.selectedFlag == "Y") {
              y.filterValues.forEach((e, i) => {
                if(e && e.childValues) {
                  e.childValues.forEach((g, k) => {
                    let exist = entityArr.indexOf(g.entityId);
                    if (entityArr.length == 0 && f.entityId == g.entityId) {
                      entityArr.push(g.entityId);
                      y.filterValues[i].childValues[k].selectedFlag = "Y";
                    } else if (f.entityId == g.entityId) {
                      entityArr.push(g.entityId);
                      y.filterValues[i].childValues[k].selectedFlag = "Y";
                    } else if (exist == -1 && f.entityId == g.entityId) {
                      y.filterValues[i].childValues[k].selectedFlag = "N";
                    }
                  });
                
                }
              });
            } else {
              y.filterValues.forEach((e, i) => {
                if(e && e.childValues) {
                  e.childValues.forEach((g, k) => {
                    let exist = entityArr.indexOf(g.entityId);
                    if (
                      entityArr.length == 0 ||
                      (exist == -1 && f.entityId == g.entityId)
                    ) {
                      y.filterValues[i].childValues[k].selectedFlag = "N";
                    }
                  });
                }
              });
            }
          });
        }
      });
    }

    entityArr = [];
    console.log(x);
    console.log(y);
  }

  packageFunctionApply(functionVal?){
    if (this.filterComponent.title === "Packages") {
      this.filterComponent.l1Filter.filterValues[0].childValues.forEach(
        (element) => {
          if (
            element.selectedFlag === "Y"
            // this.defaultPackageIdSelected != element.entityId
          ) {
            this.sharedService.selectedPackage = element;
            this.cryptUtilService.setItem(
              "SELECTED_PACKAGE",
              element,
              "SESSION"
            );
              if (this.router.url == "/site-map") {
                this._filterCustomService.setFunctionsFilterSelectState(
                  null,
                  "SITEMAP_OG_FILTERDATA"
                );
              }
              if (this.router.url == "/home") {
                this._filterCustomService.setFunctionsFilterSelectState(
                  null,
                  "IIDR_OG_FILTERDATA"
                );
                this.sharedService.isSitemapPackageReset = true;
              } else {
                this.sharedService.isSitemapPackageReset = false;
              }
          }
          
          
        }
      );
    }

    if (this.filterComponent.title === "Functions") {
      if (this.router.url == "/site-map") {
        this._filterCustomService.attributesFilterChanged = false;
        this.userPreferenceService.getFunctionData().subscribe((response) => {
          if(response){
            this.filterComponent.l1Filter.filterValues[0].childValues.forEach(x => {
              if(response.toLowerCase() == x.entityName.toLowerCase()){
                x.selectedFlag = "Y";
              }
              else{
                x.selectedFlag = "N";
              }
              
            });
           
          }
          if(functionVal && response && response != functionVal?.entityName){
          this.filterComponent.l1Filter.filterValues[0].childValues.forEach(x => {
                 if(response && functionVal.entityName.toLowerCase() == x.entityName.toLowerCase()){
                     x.selectedFlag = "Y";
              }
              else{
                x.selectedFlag = "N";
              }
                 });
          }
        });
        this._filterCustomService.setFunctionsFilterSelectState(
          this.filterComponent,
          "SITEMAP_OG_FILTERDATA"
        );
      } 
      else if (this.router.url == "/home") {
        this.userPreferenceService.getFunctionData().subscribe((response) => {
          if(response){
            
            this.filterComponent.l1Filter.filterValues[0].childValues.forEach(x => {
              if(response.toLowerCase() == x.entityName.toLowerCase()){
                x.selectedFlag = "Y";
              }
            });
     
          }
          if(functionVal && response && response != functionVal?.entityName){
            this.filterComponent.l1Filter.filterValues[0].childValues.forEach(x => {
                   if(response && functionVal.entityName.toLowerCase() == x.entityName.toLowerCase()){
                       x.selectedFlag = "Y";
                }
                else{
                  x.selectedFlag = "N";
                }
                   });
            }
        });
        this._filterCustomService.setFunctionsFilterSelectState(
          this.filterComponent,
          "IIDR_OG_FILTERDATA"
        );
      }
    }

  }

  defaultSelectedExplore(functionVal?)
  {
    if (this.filterComponent.title === "Packages") {
      if(functionVal){
        if (this.router.url == "/site-map") {
         
      this.userPreferenceService.getPackageData().subscribe((responsePkg) => {
        if(responsePkg){
          this.filterComponent.l1Filter.filterValues[0].childValues.forEach(x => {
            if(responsePkg.toLowerCase() == x.entityName.toLowerCase()){
              x.selectedFlag = "Y";
            }
            else{
              x.selectedFlag ="N";
            }
            
          });
         
        }
        if(functionVal && responsePkg && responsePkg != functionVal?.entityName){
        this.filterComponent.l1Filter.filterValues[0].childValues.forEach(x => {
               if(responsePkg && functionVal.entityName == x.entityName){
                   x.selectedFlag = "Y";
            }
            else{
              x.selectedFlag ="N";
            }
               });
        }
        this._filterCustomService.setFunctionsFilterSelectState(
          null,
          "SITEMAP_OG_FILTERDATA"
        );
      
      });
    }
    }
    else{
      this.filterComponent.l1Filter.filterValues[0].childValues.forEach(
        (element) => {
          if (
            element.selectedFlag === "Y"
            // this.defaultPackageIdSelected != element.entityId
          ) {
            this.sharedService.selectedPackage = element;
            this.cryptUtilService.setItem(
              "SELECTED_PACKAGE",
              element,
              "SESSION"
            );
              if (this.router.url == "/site-map") {
                this._filterCustomService.setFunctionsFilterSelectState(
                  null,
                  "SITEMAP_OG_FILTERDATA"
                );
              }
              if (this.router.url == "/home") {
                this._filterCustomService.setFunctionsFilterSelectState(
                  null,
                  "IIDR_OG_FILTERDATA"
                );
                this.sharedService.isSitemapPackageReset = true;
              } else {
                this.sharedService.isSitemapPackageReset = false;
              }
          }
        }
      );
      }
    }
    if (this.filterComponent.title === "Functions") {
      if (this.router.url == "/site-map") {
        this._filterCustomService.attributesFilterChanged = false;
        this.userPreferenceService.getFunctionData().subscribe((response) => {
          if(response){
            this.filterComponent.l1Filter.filterValues[0].childValues.forEach(x => {
              if(response.toLowerCase() == x.entityName.toLowerCase()){
                x.selectedFlag = "Y";
              }
              else{
                x.selectedFlag ="N";
              }
              
            });
           
          }
          if(functionVal && response && response != functionVal?.entityName){
          this.filterComponent.l1Filter.filterValues[0].childValues.forEach(x => {
                 if(response && functionVal.entityName == x.entityName){
                     x.selectedFlag = "Y";
              }
              else{
                x.selectedFlag ="N";
              }
                 });
          }
        });
        this._filterCustomService.setFunctionsFilterSelectState(
          this.filterComponent,
          "SITEMAP_OG_FILTERDATA"
        );
      } 
      else if (this.router.url == "/home") {
        this.userPreferenceService.getFunctionData().subscribe((response) => {
          if(response){
            this.userPreferenceService.getPackageIdData().subscribe((id)=>{
              if(id){
            this.filterComponent.l1Filter.filterValues[0].childValues.forEach(x => {
            
              if(x.entityName.toLowerCase() == response.toLowerCase()){
                x.selectedFlag="Y";
              }
              else{
                x.selectedFlag="N";
              }
            });
          }
        });
          }
        });
        this._filterCustomService.setFunctionsFilterSelectState(
          this.filterComponent,
          "IIDR_OG_FILTERDATA"
        );
      }
    }
  }

  getFunctionCurrentVal(functionVal?){
    return functionVal;
  }

  apply(e?) {
    let functionVal=this.filterComponent.l1Filter.filterValues[0].childValues.find((e)=>{if(e.selectedFlag =="Y"){return e.entityName}});
            this.getFunctionCurrentVal(functionVal);
    if(this.filterComponent.title == "Packages"){
    this.cryptUtilService.setItem("Package-Value-Session", functionVal,"SESSION");
              this.packageFunctionApply(functionVal);
            }
            if(this.filterComponent.title == "Functions"){
              
              this.cryptUtilService.setItem("Function-Value-Session", functionVal,"SESSION");
              this.packageFunctionApply(functionVal);
            }
    
    if (this.filterComponent.title === "Attributes") {
      this._filterCustomService.attributesFilterChanged = true;
    }

    if (this.filterComponent.title === "Processes") {
      this.updateL1L2L3AfterApply(
        this.filterComponent.l3Filter,
        this.filterComponent.l2Filter
      );
      this.updateL1L2L3AfterApply(
        this.filterComponent.l2Filter,
        this.filterComponent.l1Filter
      );
    }

    this.filterOverlayRef.close(this.filterComponent);
  }


  cancel(e?) {
    this.filterOverlayRef.close();
  }
}