import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivitiesService } from '../../services/activities.service';

@Component({
  selector: 'app-activity-success',
  templateUrl: './activity-success.component.html',
  styleUrls: ['./activity-success.component.scss']
})
export class ActivitySuccessComponent implements OnInit {

  success: boolean = false;
  warning: boolean = false;
  fail: boolean = false;
  divider: boolean = false;
  responseItems: any = [{
    status: '',
    successMessage: '',
    successItemList: [],
    warningMessage: '',
    warningItemList: []
  }]
  constructor(private activityService: ActivitiesService, private dialog: MatDialog, public dialogRef: MatDialogRef<ActivitySuccessComponent>) {}

  closeModal(message) {
    this.dialogRef.close(message);
  }

  ngOnInit() {

    this.activityService.importFileRes.subscribe(res => {
      if(res.status == 'success' || res.result == 'success') {
        this.responseItems.status = "Success!"
        this.responseItems.successItemList = res.l1;
        if(res.message) {
          this.responseItems.successMessage = res.message;
        } else {
          this.responseItems.successMessage = "You have uploaded the following items from Excel.";
        }
      }
      else if(res.status == 'warning') {
        this.warning = true;
        this.responseItems.status = "Upload status"
        this.responseItems.warningMessage = "Any changes to Process map will not be allowed once user stories are exported to Jira."
        this.responseItems.successItemList = res.l1.filter(e=> {
          return e.status == 'success';
        });
        if(this.responseItems.successItemList.length > 0) {
          this.divider = true;
          this.responseItems.successMessage = "You have uploaded the following items from Excel.";
        }
        this.responseItems.warningItemList = res.l1.filter(e=> {
          return e.status == 'blocked';
        });

      }
      else if(res.status == 'failed') {
        this.failed();
        if(res.failuremessage) {
          this.responseItems.warningMessage = res.failuremessage;
        } else if(res.message) {
          this.responseItems.successMessage = res.message;
        }
      } else {
        this.failed();
        if(res.message)
          this.responseItems.successMessage = res.message;
        if(res && res.error && res.error.error && (typeof res.error.error === 'string'))
          this.responseItems.warningMessage = res.error.error;
      }
    });

  }

  failed() {
    this.fail = true;
    // According to new figna screens
    this.responseItems.status = "Error";
    this.responseItems.successMessage = "Something went wrong. Unable to process request at this time."
  }

}
