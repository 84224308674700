import { Component, Inject, OnInit } from '@angular/core';
import { inject } from '@angular/core/testing';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  text = "Activities.UserStoryLibrary.SprintGeneration.SuccessModal.ConsolidationSuccess"
  constructor(public dialogRef: MatDialogRef<SuccessModalComponent>, @Inject(MAT_DIALOG_DATA) public data: {text: string} ) {
    if(data && data.text)
      this.text = data.text;
  }

  ngOnInit(): void {
  }

  dismiss(){
    this.dialogRef.close();
  }

}
