<div class="custom-height">
    <div class="title">
        <span>{{ data?.title }}</span>
        <mat-icon [mat-dialog-close]="true" class="close-button">close</mat-icon>  
    </div>
    <div class="description">
        <span [innerHTML]="data?.description"></span>
    </div>
</div>
<mat-divider></mat-divider>
<div class="button-row">
    <button class="btn-back" (click)="close()">{{data?.cancelBtn}}</button>
    <button  class="btn-confirm" (click)="regenerate()">{{data?.regenerateBtn}}</button>
</div>