export const DEVELOP_LAYTOUT = {
  right: {
    title: 'Define roadmap',
    colorScheme: 'rgb(0,85,135,1)',
    activitiesColorScheme: 'rgb(0,85,135,1)',
    // textColorScheme: '#002fba'
    textColorScheme: 'rgb(0,85,135,1)'
  },
  left: {
    title: 'Insights',
    subTitle: 'Explore digital platform, ambitions and value it provides',
    image: {
      normal: 'Insights_wheel_small.svg',
      normalDimension: {
        left: '0%',
        height: '100',
        width: '100'
      },
      expandedDimension: {
        left: '3%',
        top: '45%',
        height: '100%',
        width: '100%'
      }
    },

    activities: [
      "Understand Client's Business Performance, Growth Strategy and M & A Outlook",
      "Evaluate Budget Considerations",
      "Prioritize Digital initiatives based on value delivered",
      "Conduct sequencing lab to seek alignment on execution priority",
      "Action plan to address capability needs (talent acquisitions)"
    ],
    deliverables: [
      "Digital Journey Roadmap",
      "Short-Term, Medium-Term, and Long-Term",
      "Roadmap Execution Risk and Initial Response Plan"
    ],
    stopDescription: "Our experience shows that developing roadmap for Finance Transformation requires careful contemplation of business value drivers, major dependencies, and constraints. Our proven delivery methods will help us produce a pressure-tested Digital Journey roadmap",
    amplifiers: [
      {
        "name": "Cloud Transition Lab (Digital Roadmap Tool)",
        "progress": "0"
      }
    ],
    digitalMaturity: [
      "Client gains confidence in roadmap and determines execution priority of their Digital Initiatives"
    ]
  }
}
