import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ElementRef, ViewChild } from "@angular/core";
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger
} from "@angular/material/legacy-autocomplete";
import { MatLegacyChipInputEvent as MatChipInputEvent } from "@angular/material/legacy-chips";
import { Observable } from "rxjs";
import { map, startWith, takeLast } from "rxjs/operators";
import { HttpParams } from '@angular/common/http';
import { ListService } from '@projects/workspace/src/app/my-projects/list/list.service';

@Component({
  selector: "app-filter-popup",
  templateUrl: "./filter-popup.component.html",
  styleUrls: ["./filter-popup.component.scss"],
  host: {
    '(document:click)': 'handleOutsideClick($event)'
  }
})
export class FilterPopupComponent implements OnInit, OnChanges {
  showFilter: boolean = false;
  packages: any;
  countries: any;
  openKPISection = false;
  openERP = false;
  openManagerSection = false;
  openClientSection = false;
  openCountrySection = false;
  managers: any;
  clients: any;
  managerLength = 0;
  clientLength = 0;
  showPrioritySect: boolean = true;
  allClientSelected = false;
  allManagerSelected = false;
  activeFlag: boolean = false;
  selectedManagerName: UntypedFormControl = new UntypedFormControl();

  selectedClientName: UntypedFormControl = new UntypedFormControl();

  // Packages = [{ Name: "Oracle ERP Cloud" }, { Name: "Oracle HCM Cloud" }];
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  clientCtrl = new UntypedFormControl();
  countryCtrl = new UntypedFormControl();
  managerCtrl = new UntypedFormControl();
  erppackage = new UntypedFormControl();
  country = new UntypedFormControl();

  filteredClients: Observable<string[]>;
  filteredManagers: Observable<string[]>;
  clientNames: string[] = [];
  selectedPackage: string[] = [];
  selectedCountry = [];
  managerNames: string[] = [];
  filtersSetup = false;
  initialClientNames = [];
  initialSelectedPackage: string[] = [];
  initialManagerNames: string[] = [];
  initialCountryNames: string[] = [];
  lastClientList = [];
  currentClientList = [];
  lastManagerList = [];
  currentManagerList = [];
  lastCountryList = [];
  currentCountryList = [];
  lastClientInput = '';
  lastManagerInput = '';
  lastCountryInput = '';
  originalProjectList = [];
  isUpdated = false;
  @Input("projectInfo") projectInfo;
  @Output() filterUpdate = new EventEmitter();
  @ViewChild("clientInput") clientInput: ElementRef<HTMLInputElement>;
  @ViewChild("countryInput") countryInput: ElementRef<HTMLInputElement>;
  @ViewChild("managerInput") managerInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  regionCountryList: any[];
  selectAll: any;
  instanceChecked:boolean = false;
  searchControl = new FormControl();
  countryCtrlNew = new FormControl();
  filteredCountries: Observable<any[]>;
  allCountrySelected = false;
  countryLength = 0;
  countryNames: string[] = [];
  filteredInstances: any[] = [];
  constructor(
    private elRef: ElementRef
  ) {
    console.log('@@@@@@@@@@@');
    this.filteredClients = this.clientCtrl.valueChanges.pipe(
      startWith(""),
      map((cl) => {
        if (cl !== 'all' && this.lastClientInput !== cl) {
          this.lastClientInput = cl;
          this.allClientSelected = false;
        }
        return cl ? this._filterClient(cl) : this.clients.slice()
      })
    );
    this.filteredManagers = this.managerCtrl.valueChanges.pipe(
      startWith(""),
      map((mn) => {
        if (mn !== 'all' && this.lastManagerInput !== mn) {
          this.lastManagerInput = mn;
          this.allManagerSelected = false;
        }
        return mn ? this._filter(mn) : this.managers.slice();
      })
    );
    this.filteredCountries = this.countryCtrlNew.valueChanges.pipe(
      startWith(''),
      map(value => this._filterCountries(value))
    );
    // this.packages = [
    //   {
    //     Name: "Oracle ERP Cloud",
    //     Checked: false,
    //   },
    //   {
    //     Name: "Oracle HCM Cloud",
    //     Checked: false,
    //   },
    // ];
  }

  ngOnInit() {
    if (this.projectInfo) {
      this.setupFilters();
    }
  }
  
  private _filterCountries(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.regionCountryList
      .map(region => ({
        ...region,
        leadCountry: region.leadCountry.filter(country =>
          country.Name.toLowerCase().includes(filterValue)
        ),
        expanded: filterValue ? true : region.expanded // Expand if filtering is applied
      }))
      .filter(region => region.leadCountry.length > 0); // Only show regions with matching countries
  }

  ngOnChanges(changes) {
    if(changes.projectInfo.currentValue && !this.filtersSetup) {
      this.filtersSetup = true;
      this.setupFilters();
      this.filteredClients.subscribe(val => {
        this.lastClientList = [...this.currentClientList];
        this.currentClientList = val;
      });

      this.filteredManagers.subscribe(val => {
        this.lastManagerList = [...this.currentManagerList];
        this.currentManagerList = val;
      });
    }
    this.setupActiveFlag();
  }

  setupFilters() {
    this.originalProjectList = [...this.projectInfo];
    const _ = require("lodash");
    let clientList = this.projectInfo?.map((cn) => cn?.clientName);
    let managerList = this.projectInfo?.map((mn) => mn?.managerName);
    let erpPackageList = this.projectInfo?.map((mn) => mn?.erpPackage);
    let leadCountryList = this.projectInfo?.map((lc) => lc?.leadCountry);
     this.regionCountryList = [];

    this.projectInfo?.forEach(item => {
      const instance = this.regionCountryList.find(r => r.instanceName === (item.instance_name === "EU" ? "Global" : item.instance_name));
      
      if (instance) {
          if (!instance.leadCountry.some(country => country.Name === item.leadCountry)) {
              instance.leadCountry.push({ Name: item.leadCountry, Checked: false });
          }
      } else {
        this.regionCountryList.push({
              instanceName: item.instance_name === "EU" ? "Global" : item.instance_name,
              instanceValue: item.instance_name,
              checked: false,
              expanded: false,
              leadCountry: [{ Name: item.leadCountry, Checked: false }]
          });
      }
  });
    let uniqclientList = _.union(clientList);
    this.clients = uniqclientList?.map(client => {
      return {
        Name: client,
        Checked: false
      }
    });
    this.clients = this.clients?.filter(client => client.Name);

    let uniqManagerList = _.union(managerList);
    this.managers = uniqManagerList?.map(manager => {
      return {
        Name: manager,
        Checked: false
      }
    });
    this.managers = this.managers?.filter(manager => manager.Name);

    let uniqErpPackageList = _.union(erpPackageList);
    this.packages = uniqErpPackageList?.map(erp => {
      return {
        Name: erp,
        checked: false
      }
    });
    this.packages = this.packages?.filter(p => p.Name);

    let uniqLeadCountryList = _.union(leadCountryList);
    this.countries = uniqLeadCountryList?.map(country => {
      return {
        Name: country,
        Checked: false
      }
    });
    this.countries = this.countries?.filter(p => p.Name);
  }

  toggleData(event) {
    this.isUpdated = true;
    if (event.target.checked) {
      this.selectedPackage?.push(event.target.id);
      this.erppackage.setValue(this.selectedPackage);
    } else {
      this.selectedPackage?.forEach((ele, index) => {
        if (ele == event.target.id) {
          this.selectedPackage?.splice(index, 1);
        }
      });
    }
  }

  toggleClientData(event, val) {
    this.isUpdated = true;
    const self = this;
    if (event.target.tagName !== 'INPUT') {
      event.preventDefault();
    }

    self.autoTrigger.openPanel();
    if (val === 'all') {
      this.allClientSelected = !this.allClientSelected;
      const lastList = [...this.lastClientList];
      this.clientCtrl.setValue(this.clientInput.nativeElement.value);
      this.clients?.forEach(item => {
        const filteredClient = lastList?.find(client => client.Name === item.Name);
        if (filteredClient) {
          item.Checked = this.allClientSelected;
          if (this.allClientSelected && !this.clientNames?.includes(item.Name)) {
            this.clientNames?.push(item.Name);
          }
          if (!this.allClientSelected) {
            if (this.clientNames?.includes(item.Name)) {
              const index = this.clientNames?.findIndex(client => client === item.Name);
              this.clientNames?.splice(index, 1);
            }
          }
        }
      });
      this.clientLength = this.clientNames.length;
      return;
    }

    let selectedClient = this.clients?.find(client => client.Name === val.Name);
    selectedClient.Checked = !val.Checked;
    if (selectedClient.Checked) {
      this.clientNames?.push(val.Name);
      this.clientCtrl.setValue(this.clientInput.nativeElement.value);
      this.clientLength = this.clientNames.length;
    } else {
      this.clientNames?.forEach((ele, index) => {
        if (ele == val.Name) {
          this.clientNames?.splice(index, 1);
        }
      });
      this.clientCtrl.setValue(this.clientInput.nativeElement.value);
      this.clientLength = this.clientNames.length;
    }
  }

  toggleManagerData(event, val) {
    this.isUpdated = true;
    const self = this;
    if (event.target.tagName !== 'INPUT') {
      event.preventDefault();
    }
    self.autoTrigger.openPanel();

    if (val === 'all') {
      this.allManagerSelected = !this.allManagerSelected;
      const lastList = [...this.lastManagerList];
      this.managerCtrl.setValue(this.managerInput.nativeElement.value);
      this.managers?.forEach(item => {
        const filteredManager = lastList?.find(manager => manager.Name === item.Name);
        if (filteredManager) {
          item.Checked = this.allManagerSelected;
          if (this.allManagerSelected && !this.managerNames?.includes(item.Name)) {
            this.managerNames?.push(item.Name);
          }
          if (!this.allManagerSelected) {
            if (this.managerNames?.includes(item.Name)) {
              const index = this.managerNames?.findIndex(manager => manager === item.Name);
              this.managerNames?.splice(index, 1);
            }
          }
        }
      });
      this.managerLength = this.managerNames.length;
      return;
    }

    let selectedManager = this.managers?.find(manager => manager.Name === val.Name);
    selectedManager.Checked = !val.Checked;
    if (selectedManager.Checked) {
      this.managerNames?.push(val.Name);
      this.managerCtrl.setValue(this.managerInput.nativeElement.value);
      this.managerLength = this.managerNames.length;
    } else {
      this.managerNames?.forEach((ele, index) => {
        if (ele == val.Name) {
          this.managerNames?.splice(index, 1);
        }
      });
      this.managerCtrl.setValue(this.managerInput.nativeElement.value);
      this.managerLength = this.managerNames.length;
    }
  }

  addClient(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our client
    if (value) {
      this.clientNames?.push(value);
    }
    this.clientLength = this.clientNames.length;
    // Clear the input value

    this.clientCtrl.setValue(null);
  }

  addCountry(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our client
    if (value) {
      this.countryNames?.push(value);
    }

    // Clear the input value

    this.countryCtrlNew.setValue(null);
  }
  removeClient(client: string): void {
    const index = this.clientNames?.indexOf(client);
    let selectedClient = this.clients?.find(c => c.Name === client);
    selectedClient.Checked = false;

    if (index >= 0) {
      this.clientNames?.splice(index, 1);
    }
    this.clientLength = this.clientNames.length;
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our client
    if (value) {
      this.managerNames?.push(value);
    }

    // Clear the input value

    this.managerCtrl.setValue(null);
  }

  remove(manager: string): void {
    const index = this.managerNames?.indexOf(manager);
    let selectedManager = this.managers?.find(c => c.Name === manager);
    selectedManager.Checked = false;

    if (index >= 0) {
      this.managerNames?.splice(index, 1);
    }
    this.managerLength = this.managerNames.length;
  }

  selectedClient(event: MatAutocompleteSelectedEvent): void {
    this.clientNames?.push(event.option.viewValue);
    this.clientInput.nativeElement.value = "";
    this.clientCtrl.setValue(null);
    this.clientLength = this.clientNames.length;
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.managerNames?.push(event.option.viewValue);
    this.managerInput.nativeElement.value = "";
    this.managerCtrl.setValue(null);
    this.managerLength = this.managerNames.length;
  }

  private _filterClient(value): string[] {
    const filterValue = value.Name ? value.Name.toLowerCase() : value.toLowerCase();

    return this.clients.filter(
      (client) => client.Name.toLowerCase().indexOf(filterValue) !== -1
    );
  }

  private _filter(value): string[] {
    const filterValue = value.Name ? value.Name.toLowerCase() : value.toLowerCase();

    return this.managers.filter(
      (manager) => manager.Name.toLowerCase().indexOf(filterValue) !== -1
    );
  }
  OpenExpansion(name: string) {
    if (name == "openERP") {
      this.openERP = true;
    }
    if (name == "openManagerSection") {
      this.openManagerSection = true;
    }
    if (name == "openClientSection") {
      this.openClientSection = true;
    }
    if (name == "openCountrySection") {
      this.openCountrySection = true;
    }
  }
  CloseExpansion(name: string) {
    if (name == "openERP") {
      this.openERP = false;
    }
    if (name == "openManagerSection") {
      this.openManagerSection = false;
    }
    if (name == "openClientSection") {
      this.openClientSection = false;
    }
    if (name == "openCountrySection") {
      this.openCountrySection = false;
    }
  }
  closeExpansionOnAction() {
    if(this.openERP) this.openERP = false;
    if(this.openClientSection) this.openClientSection = false;
    if(this.openManagerSection) this.openManagerSection = false;
    if(this.openCountrySection) this.openCountrySection = false;
  }
  openFilter() {
    this.showFilter = !this.showFilter;
    this.initialClientNames = [...this.clientNames];
    this.initialSelectedPackage = [...this.selectedPackage];
    this.initialManagerNames= [...this.managerNames];
    this.initialCountryNames = [...this.countryNames];
  }
  apply() {
    const clientIds = this.clientNames.map(client => {
      const selectedClient = this.originalProjectList?.find(c => c.clientName === client);
      return selectedClient.clientId;
    });
    const managerEmails = this.managerNames.map(manager => {
      const selectedManager = this.originalProjectList?.find(m => m.managerName === manager);
      return selectedManager.manager;
    });

    this.regionCountryList.forEach(instance => {
      const checkedCountries = instance.leadCountry
        .filter(country => country.Checked)
        .map(country => country.Name);

      if (checkedCountries.length > 0) {
        this.selectedCountry.push({
          instanceName: instance.instanceValue,
          countries: checkedCountries
        });
      }
    });
    this.showFilter = !this.showFilter;
    this.filterUpdate.emit({
      clients: clientIds,
      managers: managerEmails,
      packages: this.selectedPackage,
      countries: this.selectedCountry
    });

    this.setupActiveFlag();
    this.closeExpansionOnAction();
  }

  cancel() {
    this.showFilter = !this.showFilter;
    this.clientNames = [...this.initialClientNames];
    this.selectedPackage = [...this.initialSelectedPackage];
    this.managerNames = [...this.initialManagerNames];
    this.selectedCountry = [];
    this.countryNames = [...this.initialCountryNames];
    this.clientLength = this.clientNames.length;
    this.managerLength = this.managerNames.length;
    this.countryLength = this.countryNames.length;
    this.allCountrySelected = false;
    this.packages?.forEach(item => {
      if(!this.selectedPackage?.includes(item.Name)) {
        item.Checked = false;
      } else {
        item.Checked = true;
      }
    });

    this.clients?.forEach(item => {
      if(!this.clientNames?.includes(item.Name)) {
        item.Checked = false;
      } else {
        item.Checked = true;
      }
    });

    this.managers?.forEach(item => {
      if(!this.managerNames?.includes(item.Name)) {
        item.Checked = false;
      } else {
        item.Checked = true;
      }
    });

    this.regionCountryList.forEach(region => {
      region.checked = false;
      region.leadCountry.forEach(country => {
        country.Checked = false;
      });
    });
    this.handleInstanceChange();
    this.setupActiveFlag();
    this.closeExpansionOnAction();
  }

  clearManagersList() {
    this.managers?.forEach(manager => manager.Checked = false);
    this.managerNames = [];
    this.managerLength = 0;
    this.allManagerSelected = false;
    this.managerCtrl.setValue('');
  }

  clearClientsList() {
    this.clients?.forEach(client => client.Checked = false);
    this.clientNames = [];
    this.clientLength = 0;
    this.allClientSelected = false;
    this.clientCtrl.setValue('');
  }

  clearPackagesList() {
    this.packages?.forEach(p => p.Checked = false);
    this.selectedPackage = [];
  }

  resetAll() {
    this.packages?.forEach(item => item.Checked = false);
    this.clients?.forEach(item => item.Checked = false);
    this.managers?.forEach(item => item.Checked = false);
    this.countries?.forEach(item => item.Checked = false);
    this.regionCountryList.forEach(region => {
      region.checked = false
      region.leadCountry.forEach(country => {
        country.Checked = false;
      });
    });
    this.clientNames = [];
    this.selectedPackage = [];
    this.selectedCountry = [];
    this.countryNames = [];
    this.managerNames = [];
    this.clientLength = this.clientNames.length;
    this.managerLength = this.managerNames.length;
    this.countryLength = this.countryNames.length;
    this.allCountrySelected = false;
    this.handleInstanceChange();
  }

  handleOutsideClick(event) {
    if(this.showFilter &&
      (!this.elRef.nativeElement.contains(event.target) &&
      !event.target.closest('.mat-autocomplete-panel') &&
      !event.target.closest('.count-class'))) {
      this.cancel();
    }
  }


  // to highlight search key in search result
  highlightSearchResult(optionName, searchTerm) : string {
    let temp = ' ' + optionName;
    if(temp.indexOf(searchTerm)) {
        var re = new RegExp(searchTerm, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
        return temp.replace(re, "<b>$&</b>");
    }
    return optionName;
  }
  //Blue Dot when filter is applied
  setupActiveFlag():void {
    if (this.clientNames?.length>0 || this.managerNames?.length>0 || this.selectedPackage?.length>0 || this.selectedCountry?.length>0) {
      this.activeFlag=true
    }
    else if (!this.clientNames?.length && !this.managerNames?.length && !this.selectedPackage?.length && !this.selectedCountry?.length) {
      this.activeFlag=false;
    }
  }

  toggleInstance(event: any, instance: any) {
    const self = this;
    self.autoTrigger.openPanel();
    const checkbox = event.target as HTMLInputElement
    instance.checked = checkbox.checked;
    instance.leadCountry.forEach(country => {
      country.Checked = instance.checked;
      if (instance.checked && !this.countryNames.includes(country.Name)) {
        this.countryNames.push(country.Name);
      } else if (!instance.checked) {
        this.countryNames = this.countryNames.filter(name => name !== country.Name);
      }
    });
    this.updateCountryLength();
  }
  toggleCountryData(event: any, country: any, region: any) {
    const self = this;
    self.autoTrigger.openPanel();
    const checkbox = event.target as HTMLInputElement
    country.Checked = checkbox.checked;
    if (country.Checked) {
      this.countryNames.push(country.Name);
    } else {
      this.countryNames = this.countryNames.filter(name => name !== country.Name);
    }
    region.checked = region.leadCountry.every(c => c.Checked);
    this.updateCountryLength();
  }

  updateCountryLength() {
    this.countryLength = this.countryNames.length;
  }

  removeCountry(countryName: string) {
    this.countryNames = this.countryNames.filter(name => name !== countryName);
    this.regionCountryList.forEach(region => {
      region.leadCountry.forEach(country => {
        if (country.Name === countryName) {
          country.Checked = false;
        }
      });
      region.checked = region.leadCountry.every(c => c.Checked);
    });
    this.updateCountryLength();
  }

  clearCountriesList() {
    this.countryNames = [];
    this.selectedCountry = []
    this.regionCountryList.forEach(region => {
      region.checked = false;
      region.leadCountry.forEach(country => {
        country.Checked = false;
      });
    });
    this.updateCountryLength();
  }
  toggleAllSelection() {
    this.allCountrySelected = !this.allCountrySelected;
    this.countryNames = [];
    this.regionCountryList.forEach(region => {
      region.checked = this.allCountrySelected;
      region.leadCountry.forEach(country => {
        country.Checked = this.allCountrySelected;
        if (this.allCountrySelected) {
          this.countryNames.push(country.Name);
        }
      });
    });
    this.updateCountryLength();
    this.handleInstanceChange();
  }
  onCountryChange(instance: any) {
    instance.checked = instance.leadCountry.every(country => country.checked);
    this.checkSelectAllState();
  }


  toggleCountrySelection(country: any) {
    country.checked = !country.checked;
    this.checkSelectAllState();
  }

  checkSelectAllState() {
    this.selectAll = this.regionCountryList.every(instance =>
      instance.leadCountry.every(country => country.checked)
    );
  }

  toggleInstanceExpansion(instance: any, event: MouseEvent) {
    event.stopPropagation();
    instance.expanded = !instance.expanded;
  }
  handleInstanceChange(){
    this.filteredCountries = this.countryCtrlNew.valueChanges.pipe(
      startWith(''),
      map(value => this._filterCountries(value))
    );
  }
}
