<div class="quick-navigator">
    <button class="grid-icon" [ngClass] ="theme=== defaultTheme ? 'dark-theme' : 'light-theme'" mat-icon-button [matMenuTriggerFor]="menu" (click)="openQuickNavigator()" [style.top.px]="theme === 'light' ? (VIEW === VIEW_TYPE.GRID_VIEW ? -4 : -11) : null">
    </button>
    <mat-menu #menu="matMenu" yPosition="below" xPosition="before" [style.position]="'absolute'" [style.left.px]="0"
        [style.top.px]="0">
        <div *ngFor="let screen of screenOptions;">
            <button
                class="mat-menu-item" [ngClass]="{'current-screen': currentScreenName === screen.name}" mat-menu-item
                (click)="currentScreenName === screen.name ? $event.stopPropagation() : goto(screen?.name, screen?.path)">
                <span><img id="icon" src="/assets/navigator-default.svg"></span><span>{{screen.translatedName | translate}}</span>
            </button>
        </div>
    </mat-menu>

</div>