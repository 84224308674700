export const PURPOSE_LAYTOUT = {
  right: {
      title: 'Define operate organization',
      colorScheme: 'rgb(0,151,169,1)',
      textColorScheme: '#28b2c4',
      activitiesColorScheme: 'rgb(0,151,169,1)',
      tabs: [
        {
            tabName: "Define digital organization",
            tabURL: "/imagine/definedigitalorganization/define digital organization",
            tabStorage: "MISCJSONBKP",
            serviceURL: "/definedigitalorg/misc/",
            tabCode: "DEFINE_DIGITAL_ORGANIZATION" // Corresponds to name field in ascend.entities table
        },
      ],
      stopIcon: 'images/activity-icons/stops/Imagine/DefineAdvanceOrganization.svg',
  },
  left: {
      title: 'Imagine',
      subTitle: 'Decide your personalized digital strategy',
      image: {
          normal: 'Imagine_wheel_small.svg',
          normalDimension: {
              left: '0%',
              height: '100',
              width: '100'
          },
          expandedDimension: {
              left: '3%',
              top: '45%',
              height: '100%',
              width: '100%'
          }
      }
  }
}
