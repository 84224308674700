
<section class="finalize-sprint-grid">
  <section class="card-header">
      <div class="header-child">
        <span class="sprint-header-text">{{'Activities.UserStoryLibrary.FinalizeSprint.Label.FinalizeReleasePlan' | translate}} / {{l1_obj?.l1_tab}}</span>
        <div>
            <button mat-raised-buttom  class="btn-confirmall" *ngIf="!isAllL1Finalized" [disabled]="disableFinalizeAll || !isAtleastOneL1Generated || viewMode" (click)="onFinalizeAll()">

                <span>{{'GenerateReleasePlan.Popup.FinalizeSprint.ConfirmAndFinalizeAllBtn' | translate}} </span>
                <span class="tooltiptext" *ngIf="!isAtleastOneL1Generated">
                  {{'FinalizeReleasePlan.ConfirmAndFinalizeAll.L1NotGenerated.Button.ToolTip' | translate}}
                </span> 
                <span class="tooltiptext" *ngIf="isAtleastOneL1Generated">
                  {{'FinalizeReleasePlan.ConfirmAndFinalizeAll.L1Generated.Button.ToolTip' | translate}}
                </span> 
            </button>
        </div>
      </div>
      <div class="step-sub-header">The placeholder attribute specifies a short hint that describes the expected value of an input field.</div>
  </section>
  <section class="table-container">
      <div class="table" *ngIf="dataSource.length > 0">
        <table mat-table [dataSource]="dataSource" style="width: 100%;" class="tbl-sprint">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
          <ng-container matColumnDef="index">
              <th mat-header-cell *matHeaderCellDef>
                  <div class="hash">#</div>
              </th>
              <td mat-cell *matCellDef="let element">
                  <div class="sprint-number" [ngStyle]="{'background-color': (element?.sprintOrderNo ? (colors[(element?.sprintOrderNo-1)%10]) :(colors[(element?.sprint_order_no-1)%10])) }"> {{element?.sprintOrderNo ? element.sprintOrderNo: element?.sprint_order_no}}  </div>
              </td>
          </ng-container>

          <ng-container matColumnDef="sprint_name">
            <th mat-header-cell *matHeaderCellDef><span class="t-header">{{'Global.Common.Label.Sprint' | translate}}</span></th>
            <td mat-cell *matCellDef="let element;let i = index;" class="sprint-name"> {{element?.sprint ? element.sprint : element?.sprint_name}} </td>
          </ng-container>

          <!-- Sprint Name Column -->
          <ng-container matColumnDef="target_sprint_name">
            <th class="t-header" mat-header-cell *matHeaderCellDef> {{'Global.Common.Label.TargetSprintName' | translate}} </th>
            <td mat-cell *matCellDef="let element; let i = index;">
              <mat-form-field  *ngIf="!element.jira_sprint_name" class="our-form"
              [ngClass]="{'mat-form-field-invalid': element.errorText}"
              appearance="outline" floatLabel="never">
                <input class="custom-input" type="text" value="{{element.sprint_name }}" matInput (keyup)="onChangeInput($event, i, element)" disabled="{{element.isSprintsFinalizeForJira || isAllL1Finalized || l1_obj?.is_finalized || !l1_obj?.is_generated || viewMode}}"/>
                <span class="tooltiptext" *ngIf="!l1_obj?.is_generated">
                  {{'GenerateReleasePlan.Popup.FinalizeSprint.FinalizeInputTooltip' | translate}} {{ l1_obj?.l1_tab + ' step.'}} 
                </span>
              </mat-form-field>
              <mat-form-field  *ngIf="element.jira_sprint_name" class="our-form"
              [ngClass]="{
                'mat-form-field-invalid': element.errorText}"
              appearance="outline" floatLabel="never">
                <input class="custom-input" type="text" value="{{element.jira_sprint_name}}" matInput (keyup)="onChangeInput($event, i, element)" disabled="{{element.isSprintsFinalizeForJira || isAllL1Finalized || l1_obj?.is_finalized || !l1_obj?.is_generated || viewMode }}"/>
                <span class="tooltiptext" *ngIf="!l1_obj?.is_generated">
                  {{'GenerateReleasePlan.Popup.FinalizeSprint.FinalizeInputTooltip' | translate}} {{ l1_obj?.l1_tab + ' step.'}} 
                 </span>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Start Date Column -->
          <ng-container matColumnDef="start_date">
            <th class="t-header" mat-header-cell *matHeaderCellDef> {{'GenerateReleasePlan.Popup.FinalizeSprint.SprintStartDate' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="date-value"> {{ element?.startDate ? (element.startDate | date:'MM/dd/YYYY') : ( element?.start_date | date:'MM/dd/YYYY') }} </td>
          </ng-container>

          <!-- End date Column -->
          <ng-container matColumnDef="end_date">
            <th class="t-header" mat-header-cell *matHeaderCellDef> {{'GenerateReleasePlan.Popup.FinalizeSprint.SprintEndDate' | translate}} </th>
            <td mat-cell *matCellDef="let element" class="date-value"> {{ element?.endDate ? (element.endDate | date:'MM/dd/YYYY') : ( element?.end_date | date:'MM/dd/YYYY') }} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
  </section>
  <section class="card-footer">
    <div class="buttons" class="footer-button">
      <div class="right-fix">
        <button class="btn-arrow-secondary" type="button" (click)="onPreviousStep()">
          <img class="arrow-up" src="assets/psg/arrow-back.svg" alt="" />
        </button>
        <button *ngIf="(finalizeResponse && l1Obj.childIndex !== (finalizeResponse.length-1))" class="next-btn btn-arrow-secondary" type="button" (click)="onNextStep()">
          <img class="arrow-up" src="assets/psg/arrow-up.svg" alt="" />
          <span class="tooltiptext">
           {{'GenerateReleasePlan.Popup.FinalizeSprint.NextStepTooltip' | translate}}
          </span>
        </button>
        <button mat-raised-buttom aria-label="Button that displays"  *ngIf="!isAllL1Finalized" matToolTipPosition="above"
        class="btn-confirm" (click)="onConfirm()" [disabled]="(l1_obj?.is_finalized || !l1_obj?.is_generated || (dataSource.length > 0 && dataSource[0].isSprintsFinalizeForJira) || l1_obj?.isErrored || !l1_obj?.confirmFlag || disableFinalizeAll) || viewMode">
          {{'GenerateReleasePlan.Popup.FinalizeSprint.ConfirmAndFinalizeBtn' | translate}} {{l1_obj?.l1_tab}}
        <span class="tooltiptext" *ngIf="l1_obj?.is_generated">
            {{'GenerateReleasePlan.Popup.FinalizeSprint.ConfirmButtonTooltip' | translate}}
        </span>
        <span class="tooltiptext" *ngIf="!l1_obj?.is_generated">
          {{'GenerateReleasePlan.Popup.FinalizeSprint.FinalizeInputTooltip' | translate}} {{ l1_obj?.l1_tab + ' step.'}} 
        </span>
        </button>
      </div>

      <!-- <button mat-raised-buttom aria-label="Button that displays" matToolTipPosition="above"
      class="btn-primary-theme btn-confirm" (click)="generateSprint()" [disabled]="(dataSource.length > 0 && dataSource[0].isSprintsFinalizeForJira) || isErrored || projectGlobalInfo.projectId == '0'">
        Generate
      </button> -->
    </div>
  </section>
</section>


<div class="m-2" *ngIf="!dataSource || !l1_obj">
  {{'Global.Common.Label.NoDataAvailable' | translate}}
</div>
