<section class="banner-container">
    <div class="logo-align-logout" >
      <img  src="../../../assets/images/logo.svg" style="cursor: default;
      margin-top: 1rem;" width="140px"
        height="20px" />
        <span class="filter-pipe"></span>
      <div class="ascend-text-logout">
      Ascend
    </div>
      <sub class="sub-text">TM</sub>
    </div>
    
    <section class="logo-container">
      <div class="logo-box">
        <div class="animate-logo"> </div>
        <div class="logout-box">
            Logout
        </div>
        <div class="logout-text">
            You are now logged out from Ascend application
        </div>
        <div class="logout-btn">
            <button mat-button class="login-text" (click)="loginApp()">Log in</button>
        </div>
      </div>
      
    </section>
  </section>
  