<div class="page-container">
  <div class="sm-container">
    <div class="container-filters">
      <img
        class="site-map-header-image"
        src="assets/images/site-map/logo-site-map.svg"
        alt=""
      />
      <div class="site-map-header">Site Map</div>
      <app-filter-list
        (parentEmitter)="emitFilter($event)"
        filterBarHead="Filters"
        [filters]="filters"
        [filterLoaded]="filterLoaded"
        readOnly="false"
      ></app-filter-list>
    </div>
    <div class="container-legends medium-font">
      <span class="container-legends__item"> D = Deliverable </span>
      <span class="container-legends__item"> A = Amplifier</span>
      <!-- <span class="alphabet-circle-icon"> <img src="../../../../../assets/images/icons/site-map/D_blue.svg"> </span> 
                
                <span class="container-legends__item--icon">Filled = Current release </span>
                <span class="alphabet-circle-icon"> <img src="../../../../../assets/images/icons/site-map/D_gray.svg"> </span> 
                <span class="container-legends__item--icon">Grayed = Future Release </span> -->
      <div *ngIf="this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName == 'SAP ERP'">
        <span class="alphabet-circle-icon">
          <img style="height: 18px;" src="assets/images/icons/site-map/phase_zero.svg" />
        </span>
        <span class="container-legends__item--icon"> = Phase zero </span>
        <span class="alphabet-circle-icon">
          <img style="height: 18px;" src="assets/images/icons/site-map/waterfall.svg" />
        </span>
        <span class="container-legends__item--icon"> = Waterfall </span>
        <span class="alphabet-circle-icon">
          <img style="height: 18px;" src="assets/images/icons/site-map/agile.svg" />
        </span>
        <span class="container-legends__item--icon"> = Agile </span>
      </div>
    </div>
    <div class="container-breadcrumbs">
        <span *ngIf="breadPackage">{{breadPackage}}</span>
        <span *ngIf="breadFunction">&nbsp;>> {{breadFunction}}</span>
    </div>
  </div>
  <div class="main-container">

    <!-- prepare phase panel -->
    <div class="phase-panel prepare-border">
      <div class="circle circle-prepare">
        <img
          style="height: 56px; padding: 5px"
          src="assets/images/site-map/prepare.svg"
          alt=""
        />
      </div>
      <div class="phase-panel-header prepare-color">Prepare</div>
      <div *ngIf="prepare">
        <div *ngFor="let trainstop of prepare">
          <div >
            <div class="ul-box" style="margin-top: 2rem">
              <img
              *ngIf="trainstop?.sequence == 1"
                class="ul-image"
                style="height: 28px"
                src="assets/images/site-map/sense13.svg"
                alt=""
              />
              <img
              *ngIf="trainstop?.sequence == 2"
                  class="ul-image"
                  style="height:28px;"
                  src="assets/images/site-map/define-digital-ambition.svg"
                  alt=""
                />
                <img
                *ngIf="trainstop?.sequence == 3"
                    class="ul-image"
                    style="height:28px;"
                    src="assets/images/site-map/define-journey1.svg"
                    alt=""
                  />
                  <img
                  *ngIf="trainstop?.sequence == 4"
                  class="ul-image"
                  style="height:28px;"
                  src="assets/images/site-map/establish-value-case.svg"
                  alt=""
                />
              <span style="color: #005587" class="ul-text">{{trainstop?.trainstop_name}}</span>
            </div>
            <div *ngIf="trainstop?.deliverables && showDeliverables">
              <div class="li-box" *ngFor="let deliverable of trainstop?.deliverables">
                <!-- *ngFor="let x of array"-->
                <div class="text-indentation" *ngIf="deliverable.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" (click)="goToDeliverable(deliverable, 'prepare', trainstop?.trainstop_name)">
                  <img src="assets/images/site-map/prepare-dot.png" alt="" />
                  <span class="li-text">{{ deliverable?.name }} <span *ngIf="deliverable?.activity_name != null && deliverable?.showActivity">({{deliverable?.activity_name}})</span></span>
                </div>
                <div class="li-badge" >
                  <span *ngFor="let attribute of deliverable.attributes">
                  <span *ngIf="attribute.show_icon" class="li-badge-icon">
                  <img
                    style="height: 18px"
                    src="assets/images/{{attribute.method_icon_name}}"
                    alt=""
                  />
                </span>
                  </span>
                  <span *ngIf="deliverable.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" class="site-map-badge">
                    <img src="assets/images/site-map/deliverable_prepare.svg" />
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="trainstop?.amplifiers && showAmplifiers">
              <div
                class="li-box"
                *ngFor="let amplifier of trainstop?.amplifiers"
              >
                <!-- *ngFor="let x of array"-->
                <div class="text-indentation" *ngIf="amplifier.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP') || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" (click)="goToAmplifier(amplifier)">
                  <img src="assets/images/site-map/prepare-dot.png" alt="" />
                  <span class="li-text">{{ amplifier?.name }}</span>
                </div>
                <div class="li-badge">
                  <span *ngFor="let attribute of amplifier.attributes">
                    <span *ngIf="attribute.show_icon" class="li-badge-icon">
                    <!-- <span style="margin-right: 0.5rem"> -->
                    <img
                      style="height: 18px"
                      src="assets/images/{{attribute.method_icon_name}}"
                      alt=""
                    />
                  </span>
                    </span>
                  <span  *ngIf="amplifier.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" class="site-map-badge">
                    <img src="assets/images/site-map/amplifier_prepare.svg" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- imagine phase panel -->
    <div class="phase-panel imagine-border">
      <div class="circle circle-imagine">
        <img
          style="height: 56px; padding: 5px"
          src="assets/images/site-map/imagine.svg"
          alt=""
        />
      </div>
      <div class="phase-panel-header imagine-color">Imagine</div>
      <div *ngIf="imagine">
          <div *ngFor="let trainstop of imagine">
            <div >
              <div class="ul-box" style="margin-top: 2rem">
                <img
                *ngIf="trainstop?.sequence == 1"
                  class="ul-image"
                  style="height: 30px"
                  src="assets/images/site-map/launch1.png"
                  alt=""
                />
                <img
                *ngIf="trainstop?.sequence == 2"
                  class="ul-image"
                  style="height: 30px"
                  src="assets/images/site-map/define-operate.png"
                  alt=""
                />
                <img
                *ngIf="trainstop?.sequence == 3"
                    class="ul-image"
                    style="height: 30px"
                    src="assets/images/site-map/apply-design.png"
                    alt=""
                  />
                  <img
                  *ngIf="trainstop?.sequence == 4"
                    class="ul-image"
                    style="height: 30px"
                    src="assets/images/site-map/adapt-leading.png"
                    alt=""
                  />
                  <img
                  *ngIf="trainstop?.sequence == 5"
                    class="ul-image"
                    style="height: 30px"
                    src="assets/images/site-map/refine-user.png"
                    alt=""
                  />
                <span style="color: #0097a9" class="ul-text">{{trainstop?.trainstop_name}}</span>
              </div>
              <div *ngIf="trainstop?.deliverables && showDeliverables">
                <div
                  class="li-box"
                  *ngFor="let deliverable of trainstop?.deliverables"
                >
                  <!-- *ngFor="let x of array"-->
                  <div class="text-indentation" *ngIf="deliverable.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" (click)="goToDeliverable(deliverable, 'imagine', trainstop?.trainstop_name)">
                    <img src="assets/images/site-map/imagine-dot.png" alt="" />
                    <span class="li-text">{{ deliverable?.name }} <span *ngIf="deliverable?.activity_name != null && deliverable?.showActivity">({{deliverable?.activity_name}})</span></span>
                  </div>
                  <div class="li-badge">
                    <span *ngFor="let attribute of deliverable.attributes">
                      <span *ngIf="attribute.show_icon" class="li-badge-icon">
                        <img
                          style="height: 18px"
                          src="assets/images/{{attribute.method_icon_name}}"
                          alt=""
                        />
                        </span>
                      </span>
                    <span *ngIf="deliverable.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" class="site-map-badge">
                       <img src="assets/images/site-map/deliverable_imagine.svg" />
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="trainstop?.amplifiers && showAmplifiers">
                <div
                  class="li-box"
                  *ngFor="let amplifier of trainstop?.amplifiers"
                >
                  <!-- *ngFor="let x of array"-->
                  <div class="text-indentation" *ngIf="amplifier.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" (click)="goToAmplifier(amplifier)">
                    <img src="assets/images/site-map/imagine-dot.png" alt="" />
                    <span class="li-text">{{ amplifier?.name }}</span>
                  </div>
                  <div class="li-badge">
                    <span *ngFor="let attribute of amplifier.attributes">
                      <span *ngIf="attribute.show_icon" class="li-badge-icon">
                      <!-- <span style="margin-right: 0.5rem"> -->
                      <img
                        style="height: 18px"
                        src="assets/images/{{attribute.method_icon_name}}"
                        alt=""
                      />
                    </span>
                      </span>
                    <span *ngIf="amplifier.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" class="site-map-badge">
                      <img src="assets/images/site-map/amplifier_imagine.svg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <!-- deliver phase panel -->
    <div class="phase-panel deliver-border">
      <div class="circle circle-deliver" >
        <img
          style="height: 52px; padding: 5px"
          src="assets/images/site-map/deliver.svg"
          alt=""
        />
      </div>
      <div class="phase-panel-header deliver-color">Deliver</div>
      <div *ngIf="deliver">
          <div *ngFor="let trainstop of deliver">
            <div>
              <div class="ul-box" style="margin-top: 2rem">
                <img
                *ngIf="trainstop?.sequence == 1"
                  class="ul-image"
                  style="height: 30px"
                  src="assets/images/site-map/activate-operate.svg"
                  alt=""
                />
                <img
                *ngIf="trainstop?.sequence == 2"
                  class="ul-image"
                  style="height: 30px"
                  src="assets/images/site-map/sprint.svg"
                  alt=""
                />
                <img
                *ngIf="trainstop?.sequence == 3"
                    class="ul-image"
                    style="height: 30px"
                    src="assets/images/site-map/validate.svg"
                    alt=""
                  />
                  <img
                  *ngIf="trainstop?.sequence == 4"
                    class="ul-image"
                    style="height: 30px"
                    src="assets/images/site-map/validate.svg"
                    alt=""
                  />
                <span style="color: #a1af00" class="ul-text"
                  >{{trainstop?.trainstop_name}}</span
                >
              </div>
              <div *ngIf="trainstop?.deliverables && showDeliverables">
                <div class="li-box" *ngFor="let deliverable of trainstop?.deliverables;">
                  <!-- *ngFor="let x of array"-->
                  <div class="text-indentation" *ngIf="deliverable.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" (click)="goToDeliverable(deliverable, 'deliver', trainstop?.trainstop_name)">
                    <img src="assets/images/site-map/deliver-dot.png" alt="" />
                    <span class="li-text">{{ deliverable?.name }} <span *ngIf="deliverable?.activity_name != null && deliverable?.showActivity">({{deliverable?.activity_name}})</span></span>
                  </div>
                  <div class="li-badge">
                    <span *ngFor="let attribute of deliverable.attributes">
                      <span *ngIf="attribute.show_icon" class="li-badge-icon">
                        <img
                          style="height: 18px"
                          src="assets/images/{{attribute.method_icon_name}}"
                          alt=""
                        />
                        </span>
                      </span>
                    <span *ngIf="deliverable.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" class="site-map-badge"> 
                      <img src="assets/images/site-map/deliverable_deliver.svg" />
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="trainstop?.amplifiers && showAmplifiers">
                <div class="li-box" *ngFor="let amplifier of trainstop?.amplifiers">
                  <!-- *ngFor="let x of array"-->
                  <div class="text-indentation" *ngIf="amplifier.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" (click)="goToAmplifier(amplifier)">
                    <img src="assets/images/site-map/deliver-dot.png" alt="" />
                    <!-- routerLink="/activities/iidr/deliver/{{trainstop?.trainstop_name}}" -->
                    <span  class="li-text">{{ amplifier?.name }}</span>
                  </div>
                  <div class="li-badge">
                    <span *ngFor="let attribute of amplifier.attributes">
                      <span *ngIf="attribute.show_icon" class="li-badge-icon">
                      <!-- <span style="margin-right: 0.5rem"> -->
                      <img
                        style="height: 18px"
                        src="assets/images/{{attribute.method_icon_name}}"
                        alt=""
                      />
                    </span>
                      </span>
                    <span *ngIf="amplifier.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" class="site-map-badge">
                      <img src="assets/images/site-map/amplifier_deliver.svg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    
    <!-- run phase panel -->
    <div class="phase-panel run-border">
      <div class="circle circle-run">
        <img
          style="height: 56px; padding: 5px"
          src="assets/images/site-map/run.svg"
          alt=""
        />
      </div>
      <div class="phase-panel-header run-color">Run</div>
      <div *ngIf="run">
          <div *ngFor="let trainstop of run">
            <div >
              <div class="ul-box" style="margin-top: 2rem">
                <img
                *ngIf="trainstop?.sequence == 1"
                  class="ul-image"
                  style="height: 30px"
                  src="assets/images/site-map/initiate.svg"
                  alt=""
                />
                <img
                *ngIf="trainstop?.sequence == 2"
                  class="ul-image"
                  style="height: 30px"
                  src="assets/images/site-map/transition.svg"
                  alt=""
                />
                <img
                *ngIf="trainstop?.sequence == 3"
                  class="ul-image"
                  style="height: 30px"
                  src="assets/images/site-map/stabilize_and_optimize.svg"
                  alt=""
                />
                <img
                *ngIf="trainstop?.sequence == 4"
                  class="ul-image"
                  style="height: 30px"
                  src="assets/images/site-map/stabilize_and_optimize.svg"
                  alt=""
                />
                <span style="color: #86bc25" class="ul-text">{{trainstop?.trainstop_name}}</span>
              </div>
              <!-- <div class="ul-box"> <img class="ul-image" style="height: 30px;" src="assets/images/site-map/validate.svg" alt=""> <span style="color: #A1AF00;" class="ul-text">Validate</span></div> -->
              <div *ngIf="trainstop?.deliverables && showDeliverables ">
                <div
                  class="li-box"
                  *ngFor="let deliverable of trainstop?.deliverables"
                >
                  <!-- *ngFor="let x of array"-->
                  <div class="text-indentation" *ngIf="deliverable.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" (click)="goToDeliverable(deliverable, 'run', trainstop?.trainstop_name)">
                    <img src="assets/images/site-map/run-dot.svg" alt="" />
                    <span class="li-text">{{ deliverable?.name }} <span *ngIf="deliverable?.activity_name != null && deliverable?.showActivity">({{deliverable?.activity_name}})</span> </span>
                  </div>
                  <div class="li-badge">
                    <span *ngFor="let attribute of deliverable.attributes">
                      <span *ngIf="attribute.show_icon" class="li-badge-icon">
                        <img
                          style="height: 18px"
                          src="assets/images/{{attribute.method_icon_name}}"
                          alt=""
                        />
                        </span>
                      </span>
                    <span *ngIf="deliverable.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" class="site-map-badge">
                      <img src="assets/images/site-map/deliverable_run.svg" />
                    </span>
                  </div>
                </div>
              </div>
              <div *ngIf="trainstop?.amplifiers && showAmplifiers">
                <div
                  class="li-box"
                  *ngFor="let amplifier of trainstop?.amplifiers"
                >
                  <!-- *ngFor="let x of array"-->
                  <div class="text-indentation" *ngIf="amplifier.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" (click)="goToAmplifier(amplifier)">
                    <img src="assets/images/site-map/run-dot.svg" alt="" />
                    <span class="li-text">{{ amplifier?.name }}</span>
                  </div>
                  <div class="li-badge">
                    <span *ngFor="let attribute of amplifier.attributes">
                      <span *ngIf="attribute.show_icon" class="li-badge-icon">
                      <!-- <span style="margin-right: 0.5rem"> -->
                      <img
                        style="height: 18px"
                        src="assets/images/{{attribute.method_icon_name}}"
                        alt=""
                      />
                    </span>
                      </span>
                    <span *ngIf="amplifier.is_display || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP') || (this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName !== 'SAP ERP')" class="site-map-badge">
                      <img src="assets/images/site-map/amplifier_run.svg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
