import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { environment } from 'src/environments/environment';
import { BaseService } from 'shared-lib';
@Injectable({
  providedIn: 'root'
})
export class AdminManagementService {

  BASE_URL = '';
  private userPreferenceURL: string = `${environment.API_MICROSERVICE_URL?.USER}/user-preferences`
  private allUserAdminsURL: string = `${environment.API_MICROSERVICE_URL?.USER}/user-management/admins`
  private pinUnpinContactURL: string = `/user/pin-unpin-contact/update`;

  constructor(private http: HttpClient,private baseService: BaseService) {
    this.BASE_URL = environment.API_BASE_URL;
  }

  getUserPreferences(): Observable<any> {
    return this.http.get<any>(this.userPreferenceURL + '/packages');
  }

  getAllUserAdmins(langId, filters?): Observable<any> {
    return this.http.get<any>(`${this.allUserAdminsURL}?languageId=${langId}`, { params: filters });
  }

  getUser(userId,langId, fromScreen?) {
    let apiEndPoint = fromScreen ? 'user-management/userRoleDetails' : 'user-management';
    return this.http.get<any>(environment.API_MICROSERVICE_URL?.USER + `/${apiEndPoint}/` + userId + `?languageId=${langId}`);
  }

  getAllAdminEmails(): Observable<any> {
    return this.http.get<any>(environment.API_MICROSERVICE_URL?.USER + '/user-management/admin-emails');
  }

  activateDeactivateUser(requestPayload): Observable<any> {
    return this.http.put<any>(this.allUserAdminsURL, requestPayload );

  }

  saveUsers(users) {
    return this.http.post<any>(this.allUserAdminsURL, users);
  }

  getContactList(userId, region?){
    let api_base_url = environment.API_MICROSERVICE_URL?.USER;
    if(region)
      api_base_url = region.api_base_url + '/user/api/v1';
    return this.baseService.get(`/user/admin/contacts/${userId}`, api_base_url);
 }
  getElevatedUserDetails() {     
    return this.http.get<any>(environment.API_MICROSERVICE_URL?.USER + '/user-management/elevatedUserDetails');
  }

  pinUnpinContact(payload,region) {
    let api_base_url = environment.API_MICROSERVICE_URL?.USER;
    if(region)
      api_base_url = region.api_base_url + '/user/api/v1';
    return this.baseService.post(`${this.pinUnpinContactURL}`, payload , api_base_url);
  }

}
