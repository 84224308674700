<div class="box-{{cardData.themeKey}}">

  <div class="activity-flex">
    <div class="activity-flex-inline">
      <div class="activity-flex-inline-1">
        <span class="{{cardData.themeKey}}-dot"></span>
        <div class="activity-caption">{{cardData.header}}</div>
        <hr class="{{cardData.themeKey}}-underline" />
        <div class="idr">
          <div>{{cardData.subheader}}</div>
        </div>
        <div class="idr-desc">
          {{cardData.description}}
        </div>

        <hr class="{{cardData.themeKey}}-desc-underline" />
        <app-insights *ngIf="cardData.themeKey=='insights'"> </app-insights>
        <app-imagine *ngIf="cardData.themeKey=='imagine'"> </app-imagine>
        <app-deliver *ngIf="cardData.themeKey=='deliver'"> </app-deliver>
        <app-run *ngIf="cardData.themeKey=='run'"> </app-run>
      </div>
    </div>
  </div>
</div>
