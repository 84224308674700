<div class="dialog-container">
    <div class="title" mat-dialog-title>
        {{data?.title}}
        <img class="close-icon" (click)="cancelChanges()" src="assets/close-icon.png" />
    </div>
    <mat-divider></mat-divider>

    <div class="my-3">
        <mat-dialog-content class="content">
            <img  *ngIf="!data?.isBlankData" class="description-icon"  src="/assets/language-settings/Description.svg" alt="" />
            <img *ngIf="data?.isBlankData" class="description-icon"  src="/assets/language-settings/red-icon.svg" alt="" />
            <span class="description">
                {{data?.message}}
            </span>
        </mat-dialog-content>
    </div>

    <mat-divider></mat-divider>
    <div class="float-end">
        <mat-dialog-actions>
            <button *ngIf="data?.cancelButtonText" class="mat-raised-button"
                (click)="cancelChanges()">{{data?.cancelButtonText}}</button>
            <button *ngIf="data?.secondaryButtonText" class="mat-raised-button"
                (click)="discardChanges()">{{data?.secondaryButtonText}}</button>
            <button *ngIf="data?.primaryButtonText" class="mat-raised-button mat-primary ms-3" 
                (click)="saveChanges()">{{data?.primaryButtonText}}</button>
        </mat-dialog-actions>
    </div>
</div>