import { Injectable } from '@angular/core';
import { BaseService } from '@projects/shared-lib/src/public-api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesGlobalContextService {
  private globalActivityContext: BehaviorSubject<any>;
  private getSelectedFilter: BehaviorSubject<any>;
  private trainstopSelectedFilter: BehaviorSubject<any>;
  private activityTabcount: BehaviorSubject<any>;
  private buildRowCount: BehaviorSubject<any>;
  private totalRowCount: BehaviorSubject<any>;
  private unRefinedRowCount: BehaviorSubject<any>;
  private globalFilter: BehaviorSubject<any>;
  private activityTabcode: BehaviorSubject<any>;
  private downloadUserStories: BehaviorSubject<boolean>;
  private downloadProcesses: Subject<boolean>;
  private downloadTestScenarios: Subject<boolean>;
  private filterExist: Subject<boolean>;
  private isL1Selected: Subject<boolean>;
  private generateTestScenario: Subject<boolean>;
  private confirmFlag: Subject<boolean>;
  private dataSavedFlag: BehaviorSubject<any>
  private downloadExcelFlag: BehaviorSubject<any>
  private isSprintsGenerated: Subject<any>;
  private filterInfoTabData: BehaviorSubject<any>;
  private enableGenerateTestScenariosButton: BehaviorSubject<any>
  private triggerGenerateTestScenariosButton: BehaviorSubject<any>
  private instanceIDsGenerated: BehaviorSubject<any>
  public resultGenerateTestScenario: BehaviorSubject<any>;
  private metricTableConfirmData: BehaviorSubject<any>;
  private implementationCostConfirmData: BehaviorSubject<any>;
  private avgPercentageObervable: BehaviorSubject<any>;
  private cancelGenerationClickedObservable: BehaviorSubject<any>;
  private depthLimitReachedL1s: BehaviorSubject<any>;
  private downloadFile: BehaviorSubject<any>;
  private strategyData: BehaviorSubject<boolean>;
  private newReport: BehaviorSubject<any>;
  private refreshUserStoriesContainerFlag: BehaviorSubject<any>;
  private userMicroserviceUrl: string;
  private buildUploadUSInstanceId$: BehaviorSubject<any>;
  public context: any = {
    package: {},
    project: {},
    stop: {},
    selectedActivity: {},
    viewmode: 'EXPLORE'
  };

  public dfteData: any;
  languageId: number;

  constructor(
    private baseService: BaseService,
    private userPref: UserPreferenceService
  ) {
    this.globalActivityContext = new BehaviorSubject({});
    this.getSelectedFilter = new BehaviorSubject({});
    this.trainstopSelectedFilter = new BehaviorSubject({});
    this.activityTabcount = new BehaviorSubject({ count: 0 });
    this.buildRowCount = new BehaviorSubject({count: 0})
    this.totalRowCount = new BehaviorSubject({count: 0})
    this.unRefinedRowCount = new BehaviorSubject({count: 0})
    this.globalFilter = new BehaviorSubject({ filter: null });
    this.activityTabcode = new BehaviorSubject({ code: null });
    this.downloadUserStories = new BehaviorSubject(false);
    this.downloadTestScenarios = new Subject();
    this.filterExist = new BehaviorSubject(false);
    this.isL1Selected = new BehaviorSubject(false);
    this.generateTestScenario = new BehaviorSubject(false);
    this.downloadProcesses=new Subject();
    this.confirmFlag = new Subject();
    this.filterInfoTabData = new BehaviorSubject(null);
    this.enableGenerateTestScenariosButton = new BehaviorSubject(false);
    this.metricTableConfirmData = new BehaviorSubject(false);
    this.implementationCostConfirmData = new BehaviorSubject(false);
    this.dataSavedFlag = new BehaviorSubject(false);
    this.downloadExcelFlag = new BehaviorSubject(false);
    this.triggerGenerateTestScenariosButton = new BehaviorSubject(false);
    this.instanceIDsGenerated = new BehaviorSubject(false);
    this.resultGenerateTestScenario = new BehaviorSubject(null);
    this.avgPercentageObervable = new BehaviorSubject({showInProgressNote: false, avg : 0});
    this.cancelGenerationClickedObservable = new BehaviorSubject(false);
    this.depthLimitReachedL1s = new BehaviorSubject([]);
    this.downloadFile = new BehaviorSubject(false);
    this.strategyData = new BehaviorSubject(false);
    this.newReport = new BehaviorSubject({active: false,status: ''});
    this.refreshUserStoriesContainerFlag = new BehaviorSubject(false);
    this.userMicroserviceUrl = environment.API_MICROSERVICE_URL?.USER;
    this.buildUploadUSInstanceId$ = new BehaviorSubject(null);
    this.userPref.getLanguageId().subscribe((res) => {
      this.languageId = res;
    })
  }

  getGlobalActivityContext(): Observable<any> {
    return this.globalActivityContext.asObservable();
  }

  setGlobalActivityContext(data) {
    const obj = { ...this.context, ...data };
    this.context = JSON.parse(JSON.stringify(obj));
    this.globalActivityContext.next(obj);
  }

  setDfteData(data) {
    this.dfteData = data;
  }

  getDfteData() {
    return this.dfteData;
  }

  updateGlobalActivityContext(data) {
    const getObj = {
      project: {
        projectId: data.projectId
      },
      package: {
        packageId: data.packageId,
        functionId: data.functionId.toString(),
        subFunctionId: data.subFunctionId ? data.subFunctionId : null,
        parentPackageId: data.parentId
      }
    }

    const obj = { ...this.context, ...getObj };
    this.context = JSON.parse(JSON.stringify(obj));
    this.getSelectedFilter.next(data);
  }

  getGlobalSelectedFilter(): Observable<any> {
    return this.getSelectedFilter.asObservable();
  }

  getActivePackages(adminPackageId?) {
    if(adminPackageId)
    return this.baseService.get(`/package/package-methods/${adminPackageId}`, environment.API_MICROSERVICE_URL?.METHOD);
    else
    return this.baseService.get(`/package/package-detail?statusId=1`, environment.API_MICROSERVICE_URL?.METHOD).map(res => this.getPckgResponse(res));
  }

  getPckgResponse(res) {
    res?.data?.forEach((item) => {
      item.name = item.package_method_nm;
      item.package_id = item.package_method_id;
      item.project_mode = item.is_project_mode_set ? 'Y' : 'N';
    })
    return res;
   }

  getPackageFunction(packageId: number) {
    return this.baseService.get(`/user-preferences/functions/?packageId=${packageId}`, this.userMicroserviceUrl);
  }

  getTabCount(): Observable<any> {
    return this.activityTabcount.asObservable();
  }

  setTabCount(count: number) {
    this.activityTabcount.next({ count: count });
  }

  getBuildRowCount(): Observable<any> {
    return this.buildRowCount.asObservable();
  }

  setBuildRowCount(count: number) {
    this.buildRowCount.next({ count: count });
  }

  getTotalRowCount(): Observable<any> {
    return this.totalRowCount.asObservable();
  }

  setTotalRowCount(count: number) {
    this.totalRowCount.next({ count: count });
  }

  getUnRefinedRowCount(): Observable<any> {
    return this.unRefinedRowCount.asObservable();
  }

  setUnRefinedRowCount(count: number) {
    this.unRefinedRowCount.next({ count: count });
  }

  setConfrmFlag(flag) {
    this.confirmFlag.next(flag);
  }

  getSprintGenerateFlag() {
    return this.confirmFlag.asObservable();
  }

  setSprintGenerateFlag(flag) {
    this.confirmFlag.next(flag);
  }

  setActivityContextForTrainstop(data) {
    const getObj = {
      project: {
        projectId: data.projectId
      },
      package: {
        packageId: data.packageId,
        functionId: data.functionId.toString(),
        subFunctionId: data.subFunctionId ? data.subFunctionId : null
      }
    }

    const obj = { ...this.context, ...getObj };
    this.context = JSON.parse(JSON.stringify(obj));
    this.trainstopSelectedFilter.next(data);
  }
  getActivityContextForTrainstop(): Observable<any> {
    return this.trainstopSelectedFilter.asObservable();
  }

  getConfirmFlag() {
    return this.confirmFlag.asObservable();
  }

  getDataSavedFlag(){
    return this.dataSavedFlag.asObservable();
  }

  setDataSavedFlag(data){
    this.dataSavedFlag.next(data)
  }

  setDownloadExcelFlag(data){
    this.downloadExcelFlag.next(data)
  }

  getDownloadExcelFlag(){
    return this.downloadExcelFlag.asObservable();
  }

  setDownloadFile(data){
    this.downloadFile.next(data)
  }

  getDownloadFile(){
    return this.downloadFile.asObservable();
  }

  getActivityTabCode(): Observable<any> {
    return this.activityTabcode.asObservable();
  }

  setActivityTabCode(code: string) {
    this.activityTabcode.next({ code: code });
  }

  getDownloadProcesses(): Observable<any> {
    return this.downloadProcesses.asObservable();
  }

  setDownloadProcesses(flag: boolean) {
    this.downloadProcesses.next(flag);
  }

  getGlobalfilter(): Observable<any> {
    return this.globalFilter.asObservable();
  }

  setGlobalfilter(obj: any) {
    this.globalFilter.next({filter: obj});
  }

  triggerDownloadUserStories(): Observable<any> {
    return this.downloadUserStories.asObservable();
  }

  setDownloadUserStories(flag: boolean) {
    this.downloadUserStories.next(flag);
  }

  checkFilterExist(): Observable<any> {
    return this.filterExist.asObservable();
  }

  setFilterExist(flag: boolean) {
    this.filterExist.next(flag);
  }

  checkisL1Selected(): Observable<any> {
    return this.isL1Selected.asObservable();
  }

  setisL1Selected(flag: boolean) {
    this.isL1Selected.next(flag);
  }


  getFiltersData(packageId, projectId, tabCode, functionId?, taxonomyId?): Observable<any> {
    let taxonomyIdValue = projectId==0 ? taxonomyId : 0;
    if (projectId == 0) {
      return this.baseService.get(`/deliver/filterinfoTab/${tabCode}?packageId=${packageId}&functionId=${functionId}&industryPrintId=${taxonomyIdValue}&languageID=${this.languageId}`, environment.API_MICROSERVICE_URL?.PACKAGE_CONTENT);
    } else {
      return this.baseService.get(`/deliver/filterinfoTab/${projectId}/${tabCode}?packageId=${packageId}&functionId=${functionId}&industryPrintId=${taxonomyIdValue}`, environment.API_MICROSERVICE_URL?.PROJECT);
    }
  }

  getDownloadTestScenarios(): Observable<any> {
    return this.downloadTestScenarios.asObservable();
  }

  setDownloadTestScenarios(flag: boolean) {
    this.downloadTestScenarios.next(flag);
  }

  setFilterInfoTabData(data) {
    return this.filterInfoTabData.next(data);
  }

  getFilterInfoTabData(): Observable<any>  {
    return this.filterInfoTabData.asObservable();
  }

  setState_GenerateTestScenariosButton(data) {
    this.enableGenerateTestScenariosButton.next(data);
  }

  getState_GenerateTestScenariosButton(): Observable<any> {
    return this.enableGenerateTestScenariosButton.asObservable();
  }

  setState_triggerGenerateTestScenariosButton(data) {
    this.triggerGenerateTestScenariosButton.next(data);
  }

  getState_triggerGenerateTestScenariosButton(): Observable<any> {
    return this.triggerGenerateTestScenariosButton.asObservable();
  }

  setGenerateTestScenariosData(data) {
    this.strategyData.next(data);
  }

  getGenerateTestScenariosData(): Observable<any> {
    return this.strategyData.asObservable();
  }

  setState_instanceIDsGenerated(data) {
    this.instanceIDsGenerated.next(data);
  }

  getState_instanceIDsGenerated(): Observable<any> {
    return this.instanceIDsGenerated.asObservable();
  }

  setTestScenarioPollingData(data: any) {
    this.resultGenerateTestScenario.next(data);
  }

  getTestScenarioPollingData(): Observable<any> {
    return this.resultGenerateTestScenario.asObservable();
  }

  setMetricTableConfirmData(data) {
    return this.metricTableConfirmData.next(data);
  }

  getMetricTableConfirmData(): Observable<any>  {
    return this.metricTableConfirmData.asObservable();
  }

  setImplmentationCostConfirmData(data) {
    return this.implementationCostConfirmData.next(data);
  }

  getImplmentationCostConfirmData(): Observable<any>  {
    return this.implementationCostConfirmData.asObservable();
  }

  setAvgPercentageObervable(data) {
    this.avgPercentageObervable.next(data);
  }

  getAvgPercentageObervable(): Observable<any> {
    return this.avgPercentageObervable.asObservable();
  }

  setCancelGenerationClickedObservable(data) {
    this.cancelGenerationClickedObservable.next(data);
  }
  getCancelGenerationClickedObservable(): Observable<any> {
    return this.cancelGenerationClickedObservable.asObservable();
  }

  setDepthLimitReachedL1s(data) {
    this.depthLimitReachedL1s.next(data);
  }
  getDepthLimitReachedL1s(): Observable<any> {
    return this.depthLimitReachedL1s.asObservable();
  }
  getNewReport()
  {
    return this.newReport.asObservable()
  }
  setNewReport(data)
  {
    this.newReport.next(data)
  }
  setRefreshUserStoriesContainerFlag(data: boolean){
    this.refreshUserStoriesContainerFlag.next(data)
  }

  getRefreshUserStoriesContainerFlag(){
    return this.refreshUserStoriesContainerFlag.asObservable();
  }

  setBuildUploadUSInstanceId(id){
    this.buildUploadUSInstanceId$.next(id) 
  }

  getBuildUploadUSInstanceId(){
    return this.buildUploadUSInstanceId$.asObservable()
  }
}
