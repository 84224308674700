import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin, BehaviorMapValidator } from '@microsoft/applicationinsights-clickanalytics-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppLoggerService {
  appInsights: ApplicationInsights;

  constructor() {
    this.configureAppInsights();
  }

  configureAppInsights() {
    const clickPluginInstance = new ClickAnalyticsPlugin();
    // Click Analytics configuration
    const behaviorMap = {          
      NAVIGATION : 1  // Advancing to the next route within a webpage
    }    
    const clickPluginConfig = {
      autoCapture: true,
    dataTags: {
      customDataPrefix : 'data-custom-',
      useDefaultContentNameOrId : true,
      parentDataTag : 'parent-group',
      donotTrackDataTag : 'dnt',
      captureAllMetaDataContent : true,
    },
    behaviorValidator : BehaviorMapValidator(behaviorMap),
    defaultRightClickBhvr: 'rc'
    };
    try {
      this.appInsights = new ApplicationInsights({
        config: {
          extensions: [clickPluginInstance],
          extensionConfig: {
            [clickPluginInstance.identifier]: clickPluginConfig
          },
          connectionString: environment.APPLICATIONINSIGHTS_CONNECTION_STRING
          ,enableAutoRouteTracking: true // option to log all route changes
        }
      });

      this.appInsights.loadAppInsights();

      var telemetryInitializer = (envelope: any) => {
        //Added below line because envelope.tags can be undefined in some cases.
        envelope.tags = envelope.tags || [];
        envelope.tags["ai.cloud.role"] = "Ascend";
      }
      this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }
    catch (error) {
      console.error("Application insights was not configured. Ignore this error if running in debug mode.")
    }
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}