import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { saveAs } from 'file-saver';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ASCEND_MODES, SPRINT_CAPACITY, SPRINT_CAPACITY_LABELS, SPRINT_COLORS } from '@projects/projectsummary/src/app/core/utility/constants';
import { SharedService } from '@projects/shared-lib/src/lib/services/shared.service';
import { SharedLibService } from '@projects/shared-lib/src/lib/shared-lib.service';
import { SharedService as sharedServ } from 'src/app/shared/services/shared.service';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';
import { CommonDialogueBoxComponent } from 'src/app/shared/components/common-dialogue-box/common-dialogue-box.component';
import { BUS_MESSAGE_KEY } from 'src/app/shared/constants/message-bus';
import { PrioritizeUserstoriesService } from '../../../prioritize-userstories/prioritize-userstories.service';
import { Subscription } from 'rxjs';
import { DefineRoadmapService } from '../../define-roadmap.service';
import { AddToSprintPopupComponent } from './add-to-sprint-popup/add-to-sprint-popup.component';
import { EditPlannedCapacityPopupComponent } from './edit-planned-capacity-popup/edit-planned-capacity-popup.component';
import { EditCapacityWarningPopupComponent } from './edit-capacity-warning-popup/edit-capacity-warning-popup.component';

import { MoveToSprintPopupComponent } from './move-to-sprint-popup/move-to-sprint-popup.component';
import { SprintCapacityModel, SprintTypeL3Model, AssignedSprintsModel } from "./sprint-capacity.model";
import { DialogueBoxComponent } from '@projects/trainstops/src/app/shared/dialogue-box/dialogue-box.component';
import { DEFINEROADMAP_STEPS } from '@projects/trainstops/src/app/core/utility/constants';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { PassGlobalInfoService } from 'src/app/shared/services/pass-project-global-info.service';
import { CryptUtilService } from 'src/app/shared/services/crypt-util.service';
import { SprintBarchartComponent } from '@projects/shared-lib/src/lib/components/sprint-barchart/sprint-barchart.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-sprint-capacity',
  templateUrl: './sprint-capacity.component.html',
  styleUrls: ['./sprint-capacity.component.scss']
})
export class SprintCapacityComponent implements OnInit, OnDestroy {

  @ViewChild('moveToSprintIcon') moveToSprintIcon: ElementRef;
  @ViewChild('editButton') editButton: ElementRef;
  selectedSubstep: string;
  context:any;
  viewMode: any;
  projectId: any;
  isJiraProject: any;
  sprintPlanList: any[] = [];
  L1IdListToGenerate: number[] = [];
  GeneratedL1NamesList = '';
  globalContextSubscription: Subscription;
  totalEffortForL3s:any;
  totalUSCountForL3s:any;
  l3_details:any;
  perSprintCapacity: number;
  isResetEnabled:boolean=false;
  isSaveEnabled:boolean=false;
  @Output() resetEnabled = new EventEmitter();
  @Output() saveEnabled = new EventEmitter();
  colors = SPRINT_COLORS;
  apiData: any;
  l1TotalCapacity: number;
  l1_obj:any;
  all_l1_data: any;
  hasNumber: any = /\d/;
  // @ViewChild('addtosprintBtn', { static: false }) public addtosprintBtn: ElementRef
  @ViewChildren('addtosprintBtn') public addtosprintBtnList: QueryList<ElementRef>
  selectedL1Subscription: Subscription;

  InitDataSubscriber: Subscription;
  isAtleastOneL1Confirmed: boolean = false;
  //declaration of L1 navigator
  totalL1:number;
  selectedParentStepIndex:number;
  selectedL1Index:number;

  originalL1Obj: any;
  userData:any;
  userIdNew: any;
  l1OG: any;
  totalUSCount: any = 0 ;
  @ViewChild('snackBarTemplate') snackBarTemplate: TemplateRef<any>;

  // NotToDo : do not change the sequence of objects in below array
  moscowPriorityConstant = [
    {priority : 1 , value : "Must Have"},
    {priority : 2 , value : "Should Have"},
    {priority : 3 , value : "Could Have"},
    {priority : 4 , value : "Wont Have"},
    {priority : 5 , value : "Not Prioritized"},
  ];

  moscowPriorityConfirmed : any;
  defaultMoscowPriority: any = [
    {
        "priority_id": 1,
        "proirity_name": "Must Have",
        "is_enabled": "Y"
    },
    {
        "priority_id": 2,
        "proirity_name": "Should Have",
        "is_enabled": "Y"
    },
    {
        "priority_id": 3,
        "proirity_name": "Could Have",
        "is_enabled": "Y"
    },
    {
        "priority_id": 4,
        "proirity_name": "Wont Have",
        "is_enabled": "N"
    },
    {
        "priority_id": 5,
        "proirity_name": "Not Prioritized",
        "is_enabled": "N"
    }];
  countUnassigned: number;
  countAssigned: number;
  calFlag : boolean = true;
  hideFunTechStoryPoints: any;
  funcVelocity;
  techVelocity;
  tabSelected= {checked: false};
  @ViewChild('barChartComponent') sprintBarchartComponent:SprintBarchartComponent;
  disabledDownloadSprintCapacity: boolean = false;
  successMessage: string;
  sprintCapacitySuccess= SPRINT_CAPACITY;
  generateClicked: boolean;
  confirmCountObj;
  taxonomyId: number = 0;
  languageId: number = 1;
  generatedL1s;
  isAllL1Finalized: boolean = false;
  l1StatusData;
  l1StatusDataSubscription: Subscription;
  isAtleastOneL1ConfirmedSubscription: Subscription;
  ASCEND_MODES = ASCEND_MODES;
  editedSprints = [];
  constructor(    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddToSprintPopupComponent>,
    // @Inject(MAT_DIALOG_DATA) public options: { positionRelativeToElement: ElementRef },
    private sharedLibService:SharedService,
    private globalContext: ActivitiesGlobalContextService,
    private prioritizeUserstoriesService: PrioritizeUserstoriesService,
    private defineRoadmapService: DefineRoadmapService,
    private messagingBus: MessagingService,
    private passGlobalInfoService: PassGlobalInfoService,
    private cryptUtilService: CryptUtilService,
    private _snackBar: MatSnackBar,
    public sharedServ: sharedServ,
    private userPreference: UserPreferenceService,
    private translateService: TranslateService,
    ) { }


  ngOnInit(): void {
    this.globalContextSubscription = this.globalContext
    .getGlobalActivityContext()
    .subscribe((data) => {
      this.context = data;
      this.projectId = this.context.project.projectId;
      this.viewMode = this.context.viewmode;

    });

    this.userPreference.getTaxonomyIdData().subscribe((resp) => {
      if(resp) {
        this.taxonomyId = resp;
      }
      else if (this.userPreference.getTaxonomyFromSessionStorage()
      ) {
        this.taxonomyId  = this.userPreference.getTaxonomyFromSessionStorage();
      }
    });

    this.sharedServ.getCurrentLanguage().subscribe((data) => {
      if (data) {
        this.languageId = data;
      }
    })

    this.defineRoadmapService.getIsAllL1Finalized().subscribe((data)=>{
      this.isAllL1Finalized=data
    })

    this.defineRoadmapService.getDailyVelocity(this.projectId, this.context?.package?.packageId,this.taxonomyId).subscribe((data)=>{
      this.funcVelocity = data[0].func_velocity;
      this.techVelocity = data[0].tech_velocity;
      this.getFTECount();
  });

    this.isJiraProject = this.defineRoadmapService.isJiraProject;
    this.moscowPriorityConfirmed = this.defaultMoscowPriority;
    this.defineRoadmapService.getMoscowData(this.projectId).subscribe( (data:any) => {
      this.moscowPriorityConfirmed = data?.data?.isConfirm == 'true' ?
                                     data?.data?.priority :  this.defaultMoscowPriority;
     
      this.calFlag = true;
      //this call was used for default sprint allocation without clicking on Generate L1 button
      this.initialDataRender();
      this.passGlobalInfoService.share.subscribe((data) => {
        this.userData = data;
      })
    });

    this.userIdNew = this.cryptUtilService.getItem('userInfo', 'SESSION');
    this.defineRoadmapService.getconfirmedSprintCapacityL1Count().subscribe(count => this.confirmCountObj = count);

    this.l1StatusDataSubscription = this.defineRoadmapService.getL1StatuesData().subscribe(data => {
      this.l1StatusData = JSON.parse(JSON.stringify(data));
    });

    this.isAtleastOneL1ConfirmedSubscription = this.defineRoadmapService.getConfirmedCountData().subscribe(confirmCount => { 
      this.isAtleastOneL1Confirmed = (confirmCount?.confirmedSprintPlanCount > 0) ? true : false});
      
  }

  initialDataRender() {
    this.selectedL1Subscription=this.defineRoadmapService.getSelectedL1().subscribe((data)=>{
      this.l1_obj = null;
      if(data && this.defineRoadmapService.sprintStepper[data.parentIndex].title== DEFINEROADMAP_STEPS.SPRINT_CAPACITY)
      {
          //check for new functionality to hide on projects where release plan is generated
        
         this.originalL1Obj = this.defineRoadmapService.sprintStepper[data.parentIndex].children[data.childIndex];
         this.l1_obj = JSON.parse(JSON.stringify(this.defineRoadmapService.sprintStepper[data.parentIndex].children[data.childIndex]));
         this.originalL1Obj.l3_group[0]?.type_level_data.forEach(l4 => {
           if(l4.l4_group.length > 0) {
           this.hideFunTechStoryPoints = l4.l4_group.some(us => us.functional_story_points == null);
           }
         });

         let l1 = this.defineRoadmapService.sprintStepper[data.parentIndex].children[data.childIndex];
         this.l1OG = JSON.parse(JSON.stringify(l1));
         this.selectedSubstep = this.l1_obj.l1_tab;

         this.totalL1=this.defineRoadmapService.sprintStepper[data.parentIndex].children?.length;
         this.selectedL1Index=data.childIndex;
         this.selectedParentStepIndex=data.parentIndex;
         this.l1_obj['stage_chart_object']['isJiraProject'] = this.isJiraProject;

        //  if(this.l1_obj?.isconfirm_sprint_us_allocation) {
        //   this.calculateTotalEffortsTotalUserStoriesForApply(this.l1_obj);
        // }
        // this.calUnassignableUS(this.moscowPriorityConfirmed ,l1);
        // this.prepareSprintsBarChart();
        // this.getEfforts(this.l1_obj);
        // this.getSprintPlanList();
        // this.getFTECount();
        if(this.defineRoadmapService.sprintStepper[1].title== DEFINEROADMAP_STEPS.SPRINT_PLAN) {
          if(this.viewMode !== ASCEND_MODES?.EXPLORE_MODE) {
            this.generatedL1s = this.defineRoadmapService.sprintStepper[1].children.filter(e=>{
              return e?.l1_export_status?.is_generated == true;
            })
          } 
          if(this.viewMode === ASCEND_MODES?.EXPLORE_MODE) {
            this.generatedL1s = this.defineRoadmapService.sprintStepper[this.selectedParentStepIndex].children.filter(e => {
              return e?.isconfirm_sprint_us_allocation == true;
            })
          }
          if(this.generatedL1s.length == 0) this.disabledDownloadSprintCapacity = true;        
          
          const L1NamesList = [];
          this.L1IdListToGenerate =[];
          const sprintPlanStepL1Obj = this.defineRoadmapService.sprintStepper[1].children?.find(l1=> Number(l1?.l1_id) === this.l1_obj.l1_value_id);
         
          this.defineRoadmapService.sprintStepper[1].children?.forEach(l1 => {
            if((l1.sprint_plan?.length > 0 && l1.sprint_plan[0]?.sprint_plan_id) && !l1?.l1_export_status?.is_finalized){
              this.L1IdListToGenerate.push(l1?.l1_id);
              L1NamesList.push(l1?.l1_tab);
            }
          });
          this.GeneratedL1NamesList = L1NamesList.join(',');
          this.l1_obj['is_finalized'] = sprintPlanStepL1Obj?.is_finalized;
        }
        

        

      }
    });

    this.InitDataSubscriber = this.defineRoadmapService.getInitialDataL1().subscribe((l1) => {
      if(this.l1_obj) {
      this.totalUSCount = 0;
      this.calFlag = true;
      this.calUnassignableUS(this.moscowPriorityConfirmed ,this.l1_obj);
      }
    })
  }
  
  generateReleasePlan() {
    this.generateClicked = true;
    let alreadyConfirmed=false;
    if(this.l1_obj.isconfirm_sprint_us_allocation) {
      alreadyConfirmed = true;
    }
    let payload = {};
    payload['l1Id'] = [this.l1_obj.l1_value_id];
    payload['functionId'] = this.context.package.functionId;
    payload['activityId'] = this.context.selectedActivity.entityId;
    payload['isJiraProject'] = this.isJiraProject;
    payload['languageId'] = this.languageId;
    this.defineRoadmapService.generateL1(this.context.project.projectId,payload).subscribe(l1Data => {
      let showAllToasterMessage = false;
      if(this.defineRoadmapService.sprintStepper[1]?.children?.filter(l1 => l1.isStepCompleted ).length === this.defineRoadmapService.sprintStepper[1]?.children?.length-1) {
        showAllToasterMessage = true;
      }
      this.successMessage = this.l1_obj.l1_tab + ': ' + (showAllToasterMessage ?  (this.translateService.instant(this.sprintCapacitySuccess.SPRINT_CAPACITY_SUCCESS_ALL)) :  (this.translateService.instant(this.sprintCapacitySuccess.SPRINT_CAPACITY_SUCCESS)));
  
      this.l1_obj = l1Data[0]; 
     

      this.l1_obj.isconfirm_sprint_us_allocation = true;
      this.originalL1Obj.l3_group =  this.l1_obj.l3_group;
      this.originalL1Obj.sprintPlan = this.l1_obj?.sprintPlan;
      this.originalL1Obj.isconfirm_sprint_us_allocation = this.l1_obj.isconfirm_sprint_us_allocation;
      this._snackBar.open(this.successMessage, '', { duration: 5000 });
      if(!alreadyConfirmed) {
        this.defineRoadmapService.setconfirmedSprintCapacityL1Count({confirmedSprintCapacityL1Count: this.confirmCountObj + 1});
      }
      this.defineRoadmapService.setsprintCapacityL1Confirm(true);
      this.l1StatusData.forEach(l1 => {
        if(Number(l1?.l1_id) === this.l1_obj?.l1_value_id){
          l1['is_generated'] = true;
        }
      });
      this.defineRoadmapService.setL1StatuesData(this.l1StatusData);
      this.disabledDownloadSprintCapacity = false;
      this.generatedL1s.push(this.l1_obj);
    })


    // let l1_obj=this.assignUnallocatedSprintTotalEffortsAndUS(this.l1_obj);
    // this.decidePlannedCapacityPerSprint(l1_obj);
    // this.sprintAllocationByTotalStoryPointsAvailable(l1_obj);
    // this.prepareSprintsBarChart();
    // this.getEfforts(this.l1_obj);
    // this.getSprintPlanList();
    
  }

  generateAllReleasePlan(){
    let payload = {};
    payload['l1Id'] = this.L1IdListToGenerate;
    payload['functionId'] = this.context.package.functionId;
    payload['activityId'] = this.context.selectedActivity.entityId;
    payload['isJiraProject'] = this.isJiraProject;
    payload['languageId'] = this.languageId;
    this.defineRoadmapService.generateL1(this.context.project.projectId, payload).subscribe(l1List => {
      let showAllToasterMessage = false;
      if(this.L1IdListToGenerate.length === this.totalL1) {
        showAllToasterMessage = true;
      }
      this.successMessage = showAllToasterMessage ?  this.translateService.instant(this.sprintCapacitySuccess.SPRINT_CAPACITY_SUCCESS_ALL) : this.GeneratedL1NamesList + ': ' + this.translateService.instant(this.sprintCapacitySuccess.SPRINT_CAPACITY_SUCCESS);
      
      
      this.l1_obj = l1List?.find(l1 => l1?.l1_value_id === this.l1_obj?.l1_value_id); 
     
      this.defineRoadmapService.sprintStepper[this.selectedParentStepIndex].children = l1List;
      this.l1_obj.isconfirm_sprint_us_allocation = true;
      this.originalL1Obj.l3_group =  this.l1_obj.l3_group;
      this.originalL1Obj.sprintPlan = this.l1_obj?.sprintPlan;
      this.originalL1Obj.isconfirm_sprint_us_allocation = this.l1_obj.isconfirm_sprint_us_allocation;
      this._snackBar.open(this.successMessage, '', { duration: 5000 });
      this.defineRoadmapService.setconfirmedSprintCapacityL1Count({confirmedSprintCapacityL1Count: this.L1IdListToGenerate.length});
      this.defineRoadmapService.setsprintCapacityL1Confirm(true);
      this.l1StatusData.forEach(l1 => l1['is_generated'] = true);
      this.defineRoadmapService.setL1StatuesData(this.l1StatusData);
      this.disabledDownloadSprintCapacity = false;
      this.generatedL1s = l1List;
    });
  }

  downloadReleasePlan() {
    let l1SelectedNames = this.generatedL1s?.map(l1Name => l1Name.l1);
    let downloadUrl = environment.API_MICROSERVICE_URL.PROJECT + `/sprint-planning/sprint-generation/sprintPlan/download/${this.context.project.projectId}?l1=${l1SelectedNames}&packageId=${this.context?.package?.packageId}&industryPrintId=${this.taxonomyId}&languageId=${this.languageId}`
    this.defineRoadmapService.downloadFile(downloadUrl).subscribe((data: any) => {
      const fileName = data.headers.get('content-disposition')
        .split(';')[1].split('filename')[1].split('=')[1].trim().split('"').join('');
      const type = data.headers.get('content-type');
      const blob = new Blob([data.body], { type });
      saveAs(blob, fileName);
      this._snackBar.open(this.translateService.instant('GenerateReleasePlan.Popup.SprintCapacity.DownloadSuccess'), '', { duration: 5000 });
    },
    err => {
      console.log(err);
    });
  }

  

  private alertDialoguePopup(popupMessage, title?) {
    return this.dialog.open(DialogueBoxComponent, {
      data: {
        from: '',
        message: popupMessage,
        title: title ? title : 'Global.Common.Label.Success',
        buttonText: 'Global.Common.Button.Continue'
      },
      panelClass: 'project-confirm-dialog-wrapper',
      maxWidth: '50vw',
    });
  }

  reset() {
    let errorText = "Activities.UserStoryLibrary.SprintGeneration.Error";
    let successText = "Activities.UserStoryLibrary.SprintCapacity.CommonDialog.Reset.Success"
    this.defineRoadmapService.resetSprintCapacity(
      this.projectId,
      { "l1": this.l1_obj.l1 }
      ).subscribe((data)=>{
        if(data.status == "success"){
           this.l1_obj.l3_group.forEach(l3_Obj => {
            l3_Obj.type_level_data.forEach(type => {
              type.sprint_id = null;
              type.sprint_order_no = null;
             });
           });
         if (this.l1_obj?.sprintPlan?.length > 0) {
          this.l1_obj.sprintPlan.forEach(sprint => {
            sprint.planned_capacity = null;
            sprint.schedule_capacity = null;
          })
          this.originalL1Obj.sprintPlan = this.l1_obj.sprintPlan;
        }
           this.originalL1Obj.l3_group = this.l1_obj.l3_group;
           this.initialDataRender();
           this.alertDialoguePopup(successText)
        }
        else {
          this.alertDialoguePopup(errorText,"Global.Common.Label.Error")
        }
    },(err) => {
          this.alertDialoguePopup(errorText,"Global.Common.Label.Error")
    });
  }

  assignUnallocatedSprintTotalEffortsAndUS(l1_obj: any) {

    this.l1TotalCapacity = 0;
      // Sorting done
      l1_obj.l3_group = this.genericSortByProperty(l1_obj.l3_group, "priority");
      // add changes to sort type by priority given also, then go for moscow prioritization.
      l1_obj.l3_group.map(l3 => {
          l3 = this.genericSortByProperty(l3.type_level_data, "priority");
      });
      this.calUnassignableUS(this.moscowPriorityConfirmed, JSON.parse(JSON.stringify(this.l1OG)));
      l1_obj.l3_group = this.sortUSByMoscowPriority(this.moscowPriorityConfirmed, l1_obj.l3_group);
      // calculate total efforts - l1 level & l3 level
      l1_obj.l3_group.map((l3_obj, i) => {
        l3_obj.total_efforts = 0 ;
        l3_obj.total_user_stories =  0 ;

        l3_obj.total_f_story_point = 0;
        l3_obj.total_t_story_point = 0;
      //  l3_obj.total_story_point = 0;
        l3_obj.isUsedForDefaultAllocation = false;
        l3_obj.type_level_data.map((type,index) => {
          type.isUsedForDefaultAllocation = false;
          type.total_efforts = 0 ;
          type.total_user_stories =  0 ;
          type.remaining_size = 0;

          type.total_f_story_point = 0;
          type.total_t_story_point = 0;
        //  type.total_story_point = 0;

          type.total_user_stories = type.l4_group.length;
          type.l4_group.map(l4 => {
            l4.isUsedForDefaultAllocation = false;
            type.total_efforts += l4.story_point;
              type.total_f_story_point += l4?.functional_story_points;
              type.total_t_story_point += l4?.technical_story_points;
            // l4.story_point = l4.functional_story_points + l4.technical_story_points;
            // type.total_story_point += l4?.story_point;

            type.remaining_size += l4.story_point;
              });
              l3_obj.total_efforts += type.total_efforts;
              l3_obj.total_user_stories += type.total_user_stories;
              type.size= type.total_efforts;
                l3_obj.total_f_story_point += type.total_f_story_point;
                l3_obj.total_t_story_point += type.total_t_story_point;
              // l3_obj.total_story_point += type.total_story_point;

          });
          //if story_points isn't coming summation of f anf t from backend then changes here.
          l3_obj.remaining_size= l3_obj.total_efforts;
          this.l1TotalCapacity += l3_obj.total_efforts;
      });

      let l1_obj_copy = JSON.parse(JSON.stringify(l1_obj));

      return l1_obj_copy;
  }

  convertNumToAlphaPriority(priority:any) {
    return this.moscowPriorityConstant[priority-1]?.priority;
  }

  calUnassignableUS(moscowPriorityConfirmed:any, l1OG:any) {
    this.countUnassigned = 0;
    this.countAssigned = 0;
    this.totalUSCount = 0;
    l1OG.l3_group.forEach(l3 => {

      l3.type_level_data.forEach(type => {
          this.totalUSCount += type.l4_group.length;
        type.l4_group.forEach(l4 => {
          if(this.projectId == 0 && l4.moscow_priority == null){ l4.moscow_priority = this.moscowPriorityConstant[0].value }
          if(this.hasNumber.test(l4.moscow_priority)) { l4.moscow_priority = this.moscowPriorityConstant[0].value }
          if(moscowPriorityConfirmed && moscowPriorityConfirmed.find(p => p.is_enabled == "Y" && l4.moscow_priority.toLowerCase().replace(/[^a-zA-Z0-9]/g,"") == p.proirity_name.toLowerCase().replace(/[^a-zA-Z0-9]/g,""))) {
            this.countAssigned += 1;
          }

        })
      })

    })
    if(this.totalUSCount !== 0 && !this.originalL1Obj.countUnassigned) {
      this.countUnassigned = this.totalUSCount - this.countAssigned
      this.l1_obj.countUnassigned = this.countUnassigned;
      this.originalL1Obj.countUnassigned = this.countUnassigned;
    } else {
      this.countUnassigned = this.originalL1Obj.countUnassigned;
    }
    this.calFlag = false;
  }

  sortUSByMoscowPriority(moscowPriorityConfirmed: any, l3_group: any): any {
     l3_group.forEach(l3 => {
      l3.type_level_data.forEach(type => {
        let typeCopy = JSON.parse(JSON.stringify(type));
        typeCopy.l4_group = [];
        type.l4_group.forEach(l4 => {
          if(this.projectId == 0 && l4.moscow_priority == null){ l4.moscow_priority = this.moscowPriorityConstant[0].value }
          if(this.hasNumber.test(l4.moscow_priority)) { l4.moscow_priority = this.moscowPriorityConstant[0].value }
          if(moscowPriorityConfirmed && moscowPriorityConfirmed.find(p => p.is_enabled == "Y" && l4.moscow_priority.toLowerCase().replace(/[^a-zA-Z0-9]/g,"") == p.proirity_name.toLowerCase().replace(/[^a-zA-Z0-9]/g,""))) {
            typeCopy.l4_group.push(l4);
          }
        })

        typeCopy?.l4_group.forEach(function(us, index, object) {

        if(us.moscow_priority && (/\d/.test(us.moscow_priority))) {
          us.moscow_priority = this.convertNumToAlphaPriority(us.moscow_priority);
        }
        let moscowPriorityConstant = [
          {priority : 1 , value : "Must Have"},
          {priority : 2 , value : "Should Have"},
          {priority : 3 , value : "Could Have"},
          {priority : 4 , value : "Wont Have"},
          {priority : 5 , value : "Not Prioritized"},
        ];

        us.numPriority = moscowPriorityConstant.find((item,index) => item?.value.toLowerCase().replace(/[^a-zA-Z0-9]/g,"") == us.moscow_priority.toLowerCase().replace(/[^a-zA-Z0-9]/g,"")).priority;
        })
        type.l4_group = this.genericSortByProperty(typeCopy?.l4_group, 'numPriority');
      });
    });
    return l3_group;
  }


  decidePlannedCapacityPerSprint(l1_obj:any) {
    // calculate efforts/capacity per sprint can be allocated
    if(l1_obj?.sprintPlan?.length > 0){
    let total_no_of_sprints = l1_obj?.sprintPlan?.length;
    let capacity_per_sprint = Math.round((100 / total_no_of_sprints));

    let size_per_sprint = Math.floor(this.l1TotalCapacity / total_no_of_sprints);

    let filled_capacity = 0; let filled_size = 0;
    l1_obj?.sprintPlan?.forEach((sprint, index) => {
      sprint.planned_capacity = (index != l1_obj?.sprintPlan?.length-1) ? capacity_per_sprint : (100 - filled_capacity);
      filled_capacity += sprint.planned_capacity;

      sprint.planned_size = (index != l1_obj.sprintPlan?.length-1) ? size_per_sprint : (this.l1TotalCapacity - filled_size);
      filled_size += sprint.planned_size;
      // sprint.planned_size =(index != l1_obj.sprintPlan.length-1) ? Math.round(this.l1TotalCapacity * sprint.planned_capacity/ 100) : (this.l1TotalCapacity - filled_size);
      // filled_size += sprint.planned_size;

      //initializing
      sprint.total_efforts = 0;
      sprint.total_user_stories = 0;

      sprint.total_f_story_point = 0
      sprint.total_t_story_point = 0
      sprint.l3_group = [];
      sprint.remaining_size= sprint?.planned_size;
      sprint.schedule_size=0;
    });
  }
  }


  sprintAllocationByTotalStoryPointsAvailable(l1_obj){
    // allocate total planned capacity among all sprints

    if(l1_obj?.sprintPlan?.length > 0){
     l1_obj.sprintPlan?.forEach((sprint,sprint_index) => {
      sprint.total_efforts = 0;
      sprint.total_story_point = 0;

      //for new requirement
      sprint.total_f_story_point = 0;
      sprint.total_t_story_point = 0;

       l1_obj.l3_group.forEach((l3_obj, l3_obj_index) => {

        let l3_objCopy = JSON.parse(JSON.stringify(l3_obj));
        l3_objCopy.total_efforts = 0;
        l3_objCopy.total_user_stories = 0;
        l3_objCopy.type_level_data = [];

        l3_objCopy.total_f_story_point = 0;
        l3_objCopy.total_t_story_point = 0;

           let isLastSprint=(sprint_index==l1_obj.sprintPlan?.length-1)? true:false;

             if( sprint.remaining_size > 0 || isLastSprint) {
              this.moscowPriorityConfirmed.forEach(mp => {
                   l3_obj.type_level_data.forEach(type => {
                    let typeCopy = JSON.parse(JSON.stringify(type));
                    typeCopy.total_efforts = 0;
                    typeCopy.total_user_stories = 0;
                    typeCopy.l4_group = [];

                    typeCopy.total_f_story_point = 0;
                    typeCopy.total_t_story_point = 0;

                      //  type.sprint_id = sprint?.sprint_id;
                      //  type.sprint_order_no = sprint?.sprint_order_no;
                        // pick US as per sprint PC
                        type.l4_group.forEach(us => {

                          if(((sprint.remaining_size >= us.story_point && sprint.remaining_size > 0) || isLastSprint ) && !us.isUsedForDefaultAllocation) {
                            if(us.moscow_priority.toLowerCase().replace(/[^a-zA-Z0-9]/g,"") == mp.proirity_name.toLowerCase().replace(/[^a-zA-Z0-9]/g,"")) {
                              us.sprint_id = sprint.sprint_id;
                              us.sprint_order_no = sprint.sprint_order_no;
                              us.isUsedForDefaultAllocation = true;
                              sprint.remaining_size = sprint.remaining_size - us.story_point;
                              typeCopy.sprint_id = sprint?.sprint_id;
                              typeCopy.sprint_order_no = sprint?.sprint_order_no;
                              typeCopy.total_efforts = typeCopy.total_efforts + us.story_point;
                              typeCopy.total_user_stories += 1;
                              typeCopy.total_f_story_point += us.functional_story_points;
                              typeCopy.total_t_story_point += us.technical_story_points;
                              typeCopy.l4_group.push(us);
                            }
                          }
                        })


                    let found = l3_objCopy.type_level_data.findIndex(t => t.story_type_name == typeCopy.story_type_name);
                    if(found !== -1)
                     {
                      l3_objCopy.type_level_data[found].total_efforts += typeCopy.total_efforts;
                      l3_objCopy.type_level_data[found].total_user_stories += typeCopy.total_user_stories;
                      l3_objCopy.type_level_data[found].total_f_story_point += typeCopy.total_f_story_point;
                      l3_objCopy.type_level_data[found].total_t_story_point += typeCopy.total_t_story_point;

                      if(typeCopy.l4_group.length > 0) {
                        typeCopy.l4_group.forEach(l4 => l3_objCopy.type_level_data[found].l4_group.push(l4));
                      }
                     } else {
                      if(typeCopy.l4_group.length > 0) {
                        l3_objCopy.type_level_data.push(typeCopy);
                      }
                     }

                      l3_objCopy.total_efforts += typeCopy.total_efforts;
                      l3_objCopy.total_user_stories += typeCopy.total_user_stories;
                      l3_objCopy.total_f_story_point += typeCopy.total_f_story_point;
                      l3_objCopy.total_t_story_point += typeCopy.total_t_story_point;
                   });

              });
              if(l3_objCopy.type_level_data.length > 0) {
                sprint.l3_group.push(l3_objCopy);
              }

             }

         });
         sprint.l3_group.forEach(l3 => {
           sprint.total_efforts += l3.total_efforts;
           sprint.total_user_stories += l3.total_user_stories;

           sprint.total_f_story_point += l3.total_f_story_point;
            sprint.total_t_story_point += l3.total_t_story_point;
         })
         sprint.schedule_size = sprint.total_efforts;

       });
   }
        // update sprint to type_level_data
        l1_obj.l3_group=[];
       if(l1_obj?.sprintPlan?.length > 0){
        l1_obj.sprintPlan?.forEach(sprint => {
              sprint.l3_group.forEach(sprint_level_l3=>{
                      let foundFlag=false;
                      for(let i=0;i<l1_obj.l3_group.length;i++){
                        if(sprint_level_l3.l3_value_id==l1_obj.l3_group[i].l3_value_id)
                        {
                          foundFlag=true;
                          l1_obj.l3_group[i].type_level_data=l1_obj.l3_group[i].type_level_data.concat(sprint_level_l3.type_level_data)
                          break;
                        }
                      }

                      if(!foundFlag)
                      {
                        let temp_obj=JSON.parse(JSON.stringify(sprint_level_l3));
                        l1_obj.l3_group.push(temp_obj);
                      }

              });
        });
   }
       this.l1_obj = JSON.parse(JSON.stringify(l1_obj));
       this.saveAndConfirm();
  }

  ngAfterViewInit() {
    //this.prepareSprintsBarChart();
  }

  prepareSprintsBarChart() {

    let isJiraProject = this.isJiraProject;
    let colors = SPRINT_COLORS;
    this.all_l1_data = {};
    let total_story_point = 0;
    let temp_sprint_percentage = [
      {
        category: SPRINT_CAPACITY_LABELS.ScheduleCapacity,
      },
      {
        category: SPRINT_CAPACITY_LABELS.PlannedCapacity,
      }
    ];

    let temp_story_points = [
      {
        category: SPRINT_CAPACITY_LABELS.ScheduleCapacity,
      },
      {
        category: SPRINT_CAPACITY_LABELS.PlannedCapacity,
      }
    ];

    let temp_l1 = {
      l1: this.l1_obj.l1_tab,
      data: {
        isJiraProject: isJiraProject,
        sprint: [],
        sprint_percentage: temp_sprint_percentage,
        story_points: temp_story_points
      }
    };

    this.l1_obj.sprintPlan?.forEach(sprint => {
        total_story_point += sprint.total_efforts;
    });
    let remaining_story_point = total_story_point;

    let total_SC = 0;
    for (let i = 0; i < this.l1_obj?.sprintPlan?.length; i++) {
      this.l1_obj.sprintPlan[i].schedule_size = this.l1_obj.sprintPlan[i].total_efforts;
      if (i == this.l1_obj.sprintPlan.length - 1) {
        this.l1_obj.sprintPlan[i].schedule_capacity = +(100 - total_SC) //.toFixed(2);
      } else {
        this.l1_obj.sprintPlan[i].schedule_capacity = Math.round(+((this.l1_obj.sprintPlan[i].schedule_size / total_story_point) * 100).toFixed(2));
        total_SC += this.l1_obj.sprintPlan[i].schedule_capacity;
      }
    };

    this.l1_obj?.sprintPlan?.forEach((sprint, index) => {
      let sprint_obj = 'sprint' + sprint.sprint_order_no;
      let temp_sprint = {
        sprint_name: sprint.sprint_name,
        sprint_obj: sprint_obj,
        color: colors[sprint.sprint_order_no-1%10]
      };

      temp_l1.data.sprint.push(temp_sprint);

      temp_sprint_percentage[0][sprint_obj] = sprint.schedule_capacity;
      temp_sprint_percentage[1][sprint_obj] = sprint.planned_capacity;
      temp_story_points[0][sprint_obj] = sprint.schedule_size;
      let total_planned_story_point = Math.round(total_story_point * sprint.planned_capacity / 100);


      if (index!=this.l1_obj.sprintPlan?.length - 1 && total_planned_story_point < remaining_story_point) {
        remaining_story_point = remaining_story_point - total_planned_story_point;
        temp_story_points[1][sprint_obj] = total_planned_story_point;
      }
      else {
        temp_story_points[1][sprint_obj] = remaining_story_point;
      }

    });
    this.all_l1_data = temp_l1;

  }

  openAddToSprintPopup(sprintPlan:any, i:any) {

  const elementRef = this.addtosprintBtnList.find(
    (item,index) => index == i
  );

  elementRef.nativeElement.focus();
  let addToSprintData : any = {
    selectedSprint : sprintPlan,
    sprintsCanBeAddedList: [],
    l1_obj : this.l1_obj,
    context : this.context,
    isJiraProject : this.isJiraProject ? 1 : 0,
    languageId: this.languageId,
    projectId: this.projectId
  };

  addToSprintData.sprintsCanBeAddedList = this.prepareAddToSprintObject(i);
  this.dialogRef = this.sharedLibService.openDialog({
    positionRelativeToElement: elementRef,
    addToSprintData: addToSprintData,
    hasBackdrop: true
  });
  this.dialogRef.afterClosed().subscribe(
    (response) => {
      if(response)
      {
        sprintPlan.isPopupOpen = false;
        this.l1_obj = response;
        this.originalL1Obj.l3_group =  this.l1_obj.l3_group;
        this.originalL1Obj.sprintPlan = this.l1_obj?.sprintPlan;
        this.originalL1Obj.isconfirm_sprint_us_allocation = this.l1_obj.isconfirm_sprint_us_allocation;
      }
      else
      {
        sprintPlan.isPopupOpen = false;
      }
    }
  )
  sprintPlan.isPopupOpen = this.dialogRef ? true : false;

  }


  prepareAddToSprintObject(index:any): any[] {
    let sprintsCanBeAddedList = [];
    if(this.l1_obj?.sprintPlan?.length > 0){
    this.l1_obj?.sprintPlan?.filter( (sprint,i) => {
      if(i != index && !sprint?.isSprintCompleted) {
        sprintsCanBeAddedList.push(sprint);
      }
    });
  }
    return sprintsCanBeAddedList;
  }

  genericSortByProperty(property: any, key: any) : any {

    return property.sort(function(a, b)
    {
     let x = a[key]; let y = b[key];
     return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  getSprintPlanList(){
    if (this.l1_obj?.sprintPlan && (this.l1_obj?.sprintPlan?.length > 0)) {

        this.sprintPlanList =[];
        let pc_percentage = 0;
        let remainingPCHours = 0, remainingSCHours = 0;
        let remainingPCPercentage = 0, remainingSCPercentage = 0;
        let sprintPlanLength = this.l1_obj.sprintPlan?.length;
        let totalUSCountOrEfforts=Math.floor(this.perSprintCapacity*100/this.totalEffortForL3s);
        for (let i = 0; i < sprintPlanLength; i++) {
         let sprint = this.l1_obj.sprintPlan[i];
         let  sprintColorIndex=Math.floor(sprint.sprint_order_no % 10) ;
         let total_l1_efforts = 0;
         if( i!= (sprintPlanLength-1)){
          this.l1_obj.sprintPlan?.filter(sprint=> total_l1_efforts += sprint.planned_capacity);
           pc_percentage= (sprint.planned_capacity == null || sprint.planned_capacity <= 0) ? totalUSCountOrEfforts : Math.floor((sprint.planned_capacity/total_l1_efforts) *100);
          sprint.pc_percentage = pc_percentage;
         }
         else{
          this.l1_obj.sprintPlan?.filter((sprint, i)=> {
            if( i < sprintPlanLength -1){
              total_l1_efforts += sprint.pc_percentage
            }
            else{
             pc_percentage= (sprint.planned_capacity == null || sprint.planned_capacity <= 0) ? totalUSCountOrEfforts : Math.ceil(100 - total_l1_efforts);
            }
           });
         }

         let pc_hours= Math.floor(this.totalEffortForL3s*pc_percentage/100);
         let sprintPlanDetails = {
          'sprint_plan_id':sprint.sprint_plan_id,
          'sprint_id':sprint.sprint_id,
          'sprint_name':sprint.sprint_name,
          'sprint_order_no':sprint.sprint_order_no,
          'start_date':sprint.start_date,
          'end_date':sprint.end_date,
          'sprint_color_index':sprintColorIndex,
          'sprint_color':sprintColorIndex == 0 ? this.colors[10] : this.colors[sprintColorIndex],
          'planned_capacity_percentage':pc_percentage,
          'planned_capacity_hours':pc_hours,
          'scheduled_capacity_percentage': Math.floor(sprint.schedule_capacity*100/this.totalEffortForL3s),
          'scheduled_capacity_hours':sprint.schedule_capacity,
          'sprintwise_totalefforts':sprint.total_efforts
        };

          remainingPCPercentage += pc_percentage;
          remainingPCHours += pc_hours;
          remainingSCHours += sprint.schedule_capacity;
          remainingSCPercentage += sprintPlanDetails['scheduled_capacity_percentage']
          let totalUSCountEffortForL3s= this.totalEffortForL3s
            this.sprintPlanList.push(sprintPlanDetails);
        }
      }
  }

  getEfforts(data:any){
    this.totalEffortForL3s= 0;
    this.totalUSCountForL3s = 0;
    let sprintPlan = data?.sprintPlan;
    if(data?.sprintPlan?.length > 0){
    for(let i=0;i< sprintPlan?.length;i++) {
        this.totalEffortForL3s+=sprintPlan[i].total_efforts || 0;
        this.totalUSCountForL3s+=sprintPlan[i].total_user_stories || 0;
    }
  }
  }

  getFTECount(){
    let duration_of_sprint;
    if(this.l1_obj?.sprintPlan?.length >0){

      this.l1_obj?.sprintPlan?.forEach((sprint)=>{
        duration_of_sprint = sprint.duration_of_sprint ?? 2;
        let functional_FTE_count = sprint.total_f_story_point/(duration_of_sprint * 5 * this.funcVelocity);
        let technical_FTE_count = sprint.total_t_story_point/(duration_of_sprint * 5 * this.techVelocity);

        sprint.functional_FTE_count = Math.round(functional_FTE_count*2) / 2;
        sprint.technical_FTE_count = Math.round(technical_FTE_count*2) / 2;
      })
    }

  }

  editCapacity() {
    const dialogRef =  this.dialog.open(EditPlannedCapacityPopupComponent, {
        data: {
            title: 'Activities.UserStoryLibrary.EditPlannedCapacityPopup.Title',
            buttonText: 'Submit',
            projectid:  this.projectId,
            l1: this.l1_obj.l1,
            top : this.editButton.nativeElement.getBoundingClientRect().top,
            left : this.editButton.nativeElement.getBoundingClientRect().left,
            l1_obj : this.l1_obj
          },
        width:"400px",
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.editedSprints = result;
          this.showEditCapacityWarning();
        }
    });
}

  getL1ObjAfterEditVelocity() {
    let payload = {};
    payload['l1Id'] = [this.l1_obj.l1_value_id];
    payload['functionId'] = this.context.package.functionId;
    payload['activityId'] = this.context.selectedActivity.entityId;
    payload['isJiraProject'] = this.isJiraProject;
    payload['languageId'] = this.languageId;
    this.defineRoadmapService.generateL1(this.context.project.projectId,payload).subscribe(l1Data => {
      this.l1_obj = l1Data[0];
      this.originalL1Obj.l3_group =  this.l1_obj.l3_group;
      this.originalL1Obj.sprintPlan = this.l1_obj?.sprintPlan;
      this.originalL1Obj.isconfirm_sprint_us_allocation = this.l1_obj.isconfirm_sprint_us_allocation;
    });
  }

  regenerateWithEditCapacity(){
      const submitUrl = `${environment.API_MICROSERVICE_URL.PROJECT}/sprint-planning/release/edit/velocity`;
      const postData = {
        l1Name: this.l1_obj.l1,
        functionId: this.context.package.functionId,
        activityId: this.context.selectedActivity.entityId,
        isJiraProject: this.isJiraProject,
        languageId: this.languageId,
        editedSprints: this.editedSprints,
        projectId: this.projectId
      };

      this.sharedServ.postData(submitUrl, postData).subscribe((data) => {
        if (data && data.status === 200) {
              this.getL1ObjAfterEditVelocity();
              this.isResetEnabled=true;
              this.isSaveEnabled=true;
              this.resetEnabled.emit(this.isResetEnabled);
              this.saveEnabled.emit(this.isSaveEnabled);
        } else {
          this.messageDialoguePopup('Oops! Something went wrong. Please exit page and try task again.', 'Error', '', 'Continue');
        }
      }, (err) => {
          this.messageDialoguePopup('Oops! Something went wrong. Please exit page and try task again.', 'Error', '', 'Continue');
      });

  }

  showEditCapacityWarning(){
    let data = {
      title: "",
      description: this.translateService.instant('GenerateReleasePlan.Popup.SprintCapacity.EditCapacityWarning.Text'),
      cancelBtn: this.translateService.instant('Global.Common.Button.Cancel'),
      regenerateBtn: this.translateService.instant('GenerateReleasePlan.Popup.SprintCapacity.EditCapacityWarning.Regenerate')
    };
    const dialogRef = this.dialog.open(EditCapacityWarningPopupComponent, {
      width: "700px",
      height: "auto",
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result === 'confirm') {
        this.regenerateWithEditCapacity();
      }
    });
  }

  syncScheduleAndPlanCap() {
    let l1_obj=this.assignUnallocatedSprintTotalEffortsAndUS(this.l1_obj);
    let filled_size = 0;
    this.sprintPlanList.forEach(updatedSprint => {
      if(l1_obj?.sprintPlan?.length > 0){
      l1_obj.sprintPlan.forEach((defaultSprint,defaultSprintIndex) => {
        if(defaultSprint.sprint_id == updatedSprint.sprint_id)
        {
          defaultSprint.planned_capacity = +updatedSprint.planned_capacity_percentage;

          defaultSprint.planned_size =(defaultSprintIndex != this.l1_obj.sprintPlan.length-1) ? Math.round(this.l1TotalCapacity * defaultSprint.planned_capacity/ 100) : (this.l1TotalCapacity - filled_size);
          filled_size += defaultSprint.planned_size;

          defaultSprint.total_efforts = 0;
          defaultSprint.total_user_stories = 0;
          defaultSprint.l3_group = [];
          defaultSprint.remaining_size= defaultSprint.planned_size;
          defaultSprint.schedule_size=0;
        }
      })
    }
    });

    this.sprintAllocationByTotalStoryPointsAvailable(l1_obj);
    this.prepareSprintsBarChart();

  }

  private messageDialoguePopup(popupMessage,title?,data?,buttonText?) {
    return this.dialog.open(CommonDialogueBoxComponent, {
      data: {
        from: 'GENERATE_SPRINT',
        message: popupMessage ,
        title: title ? title: 'Success',
        buttonText: buttonText?buttonText:'Close',
        content:data
      },
      panelClass: 'custom-dialog-container',
      maxWidth: '30vw',
    });
  }

  moveToSprint(sprintName:any, l3_value:any,type:any){
    const sprintData = {
      top: this.moveToSprintIcon.nativeElement.getBoundingClientRect().top,
      left: this.moveToSprintIcon.nativeElement.getBoundingClientRect().left,
      sprintPlan: this.l1_obj.sprintPlan,
      selectedSprint: sprintName,
      l3_group: this.l1_obj.l3_group,
      l1: this.l1_obj.l1,
      sprintCapacities: JSON.parse(JSON.stringify(this.sprintPlanList)),
      type: type,
      l3_value: l3_value,
    };
    const dialogRef = this.dialog.open(MoveToSprintPopupComponent, {
      width: "280px",
      height: "316px",
      data: sprintData,
      panelClass: "move-to-sprint-popup",
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "cancel") {
      } else {
        let moveToSprintObj = result;
        let l3_group = this.l1_obj.l3_group;
        for (let i = 0; i < l3_group.length; i++) {
          if (l3_value == l3_group[i].l3_value) {
            let type_level_data = l3_group[i].type_level_data;

            for (let k = 0; k < type_level_data.length; k++) {
              if (moveToSprintObj.type.type_id == type_level_data[k].type_id) {
                this.l1_obj.l3_group[i].type_level_data[k].l4_group.forEach(
                  (l4) => {
                    l4.sprint_id = moveToSprintObj.toSprint.sprint_id;
                    l4.sprint_order_no =
                      moveToSprintObj.toSprint.sprint_order_no;
                  }
                );
              }
            }
          }
        }
        const payload = {
          l1Id: this.l1_obj.l1_value_id,
          functionId: this.context.package.functionId,
          activityId: this.context.selectedActivity.entityId,
          isJiraProject: this.isJiraProject,
          languageId: this.languageId,
          targetSprintName: result.toSprint.sprint_name,
          l3List: [
            {
              storyType: result.type.story_type_name,
              l3Name: l3_value,
              sourceSprintName: result.fromSprintNo
            },
          ],
        };
        const submitUrl = `${environment.API_MICROSERVICE_URL.PROJECT}/sprint-planning/sprint-capacity/sprints/calculation/save/addToSprint/${this.projectId}`;

        this.sharedServ.postData(submitUrl, payload).subscribe(
          (res) => {
            if (res) {
              //Generate API call if needed
              this.l1_obj = res;
            } 
          }
        );
        this.calculateTotalEffortsTotalUserStoriesForApply(this.l1_obj);
        this.prepareSprintsBarChart();
      }
    });
  }


  // L1 Navigation and save
  nextStep(){

    if(this.selectedL1Index<this.totalL1-1)
    {
      let obj={
        parentIndex:this.selectedParentStepIndex,
        childIndex:this.selectedL1Index+1,
      }
      this.defineRoadmapService.setSelectedL1(obj);
    }
    else{
      let obj={
        parentIndex:this.selectedParentStepIndex+1,
        childIndex:0
      }
      this.originalL1Obj.isconfirm_sprint_us_allocation=true;
      this.defineRoadmapService.setSwitchParent(obj);
    }
  }

  saveAndNext() {
    let sprintCapacitiesList : SprintCapacityModel[] = [];
    let sprintTypeL3ModelList : SprintTypeL3Model[] = [];
    let sprintPlan;
    let l3_group;

    let showAllToasterMessage = false;
    if(this.defineRoadmapService.sprintStepper[1]?.children?.filter(l1 => l1.isStepCompleted ).length === this.defineRoadmapService.sprintStepper[1]?.children?.length-1) {
      showAllToasterMessage = true;
    }
    this.successMessage = this.l1_obj.l1_tab + ': ' + (showAllToasterMessage ?  (this.translateService.instant(this.sprintCapacitySuccess.SPRINT_CAPACITY_SUCCESS_ALL)) :  (this.translateService.instant(this.sprintCapacitySuccess.SPRINT_CAPACITY_SUCCESS)));

    if(this.l1_obj?.sprintPlan?.length > 0){
    this.l1_obj.sprintPlan.forEach(sprint => {
      sprintPlan = JSON.parse(JSON.stringify(sprint));
      sprintCapacitiesList.push(
    {
      sprint_plan_id : sprintPlan.sprint_plan_id,
      sprint_id : sprintPlan.sprint_id,
      sprint_name : sprintPlan.sprint_name,
      sprint_order_no : sprintPlan.sprint_order_no,
      start_date : sprintPlan.start_date,
      end_date : sprintPlan.end_date,
      sprint_color_index : sprintPlan.sprint_color,
      sprint_color : sprintPlan.sprint_color,
      planned_capacity : sprintPlan.planned_capacity,
      schedule_capacity : sprintPlan.schedule_capacity,
      planned_capacity_percentage : 0,
      planned_capacity_hours : 0,  // figure what it is
      scheduled_capacity_percentage : 0,
      scheduled_capacity_hours : 0,	// figure what it is
      sprintwise_totalefforts : 0
    });
    sprintPlan.l3_group.forEach(l3 => {
      l3_group = JSON.parse(JSON.stringify(l3));
      l3_group.type_level_data.forEach(type => {
        type.l4_group.forEach(l4 => {
          if(l4.sprint_order_no && l4.sprint_id){
          sprintTypeL3ModelList.push(
            {
              type : type.story_type_name,
              type_id : type.type_id,
              l3 : l3.l3_value,
              sprint_order_no : l4.sprint_order_no,
              sprint_id : l4.sprint_id,
              project_workspace_id: l4.project_workspace_id
            });
          }
        })

    });

    });

    });
  }

    let assignedSprint : AssignedSprintsModel = {
      "l1" : this.l1_obj.l1,
      "sprintCapacities" : sprintCapacitiesList,
      "children" : sprintTypeL3ModelList
    };

    let requestPayloadSprintAllocation_Save = { "assignedSprints" : assignedSprint}

    // to-do : reuse this call for save without confirm
    // post call - Save
    this.defineRoadmapService.saveSprintCapacity_l1_specific(
      this.context.package.packageId,
      this.context.package.functionId,
      this.context.selectedActivity.entityId,
      this.context.project.projectId,
      requestPayloadSprintAllocation_Save).subscribe(res => {
        if(res.status == "success") {
          this.originalL1Obj.l3_group =  this.l1_obj.l3_group;
          this.originalL1Obj.sprintPlan = this.l1_obj?.sprintPlan;
          if(showAllToasterMessage) {
            this.disabledDownloadSprintCapacity = false;
          }
          this.defineRoadmapService.setconfirmedSprintCapacityL1Count({confirmedSprintCapacityL1Count: this.confirmCountObj + 1});
      
          this._snackBar.open(this.successMessage, '', { duration: 5000 });

          this.nextStep();
        }
      });
  }


  saveSprintAssignments() {

    let sprintCapacitiesList : SprintCapacityModel[] = [];
    let sprintTypeL3ModelList : SprintTypeL3Model[] = [];
    let sprintPlan;
    let l3_group;

    let showAllToasterMessage = false;
    if(this.defineRoadmapService.sprintStepper[1]?.children?.filter(l1 => l1.isStepCompleted ).length === this.defineRoadmapService.sprintStepper[1]?.children?.length-1) {
      showAllToasterMessage = true;
    }
    this.successMessage = this.l1_obj.l1_tab + ': ' + (showAllToasterMessage ?  (this.translateService.instant(this.sprintCapacitySuccess.SPRINT_CAPACITY_SUCCESS_ALL)) :  (this.translateService.instant(this.sprintCapacitySuccess.SPRINT_CAPACITY_SUCCESS)));

    if(this.l1_obj?.sprintPlan?.length > 0){
    this.l1_obj.sprintPlan.forEach(sprint => {
      sprintPlan = JSON.parse(JSON.stringify(sprint));
      sprintCapacitiesList.push(
    {
      sprint_plan_id : sprintPlan.sprint_plan_id,
      sprint_id : sprintPlan.sprint_id,
      sprint_name : sprintPlan.sprint_name,
      sprint_order_no : sprintPlan.sprint_order_no,
      start_date : sprintPlan.start_date,
      end_date : sprintPlan.end_date,
      sprint_color_index : sprintPlan.sprint_color,
      sprint_color : sprintPlan.sprint_color,
      planned_capacity : sprintPlan.planned_capacity,
      schedule_capacity : sprintPlan.schedule_capacity,
      planned_capacity_percentage : 0,
      planned_capacity_hours : 0,  // figure what it is
      scheduled_capacity_percentage : 0,
      scheduled_capacity_hours : 0,	// figure what it is
      sprintwise_totalefforts : 0
    });
    sprintPlan.l3_group.forEach(l3 => {
      l3_group = JSON.parse(JSON.stringify(l3));
      l3_group.type_level_data.forEach(type => {
          type.l4_group.forEach(l4 => {
            if(l4.sprint_order_no && l4.sprint_id){
            sprintTypeL3ModelList.push(
              {
                type : type.story_type_name,
                type_id : type.type_id,
                l3 : l3.l3_value,
                sprint_order_no : l4.sprint_order_no,
                sprint_id : l4.sprint_id,
                project_workspace_id: l4.project_workspace_id
              });
            }
          })

      });

    });

    });
    }

    let assignedSprint : AssignedSprintsModel = {
      "l1" : this.l1_obj.l1,
      "sprintCapacities" : sprintCapacitiesList,
      "children" : sprintTypeL3ModelList
    };

    let requestPayloadSprintAllocation_Save = { "assignedSprints" : assignedSprint}

    // post call - Save & Confirm
    this.defineRoadmapService.saveAndConfirmSprintCapacity_l1_specific(
      this.context.package.packageId,
      this.context.package.functionId,
      this.context.selectedActivity.entityId,
      this.context.project.projectId,
      requestPayloadSprintAllocation_Save).subscribe(res => {
        if(res.status == 'success') {
          this.l1_obj.isconfirm_sprint_us_allocation = true;
          this.originalL1Obj.l3_group =  this.l1_obj.l3_group;
          this.originalL1Obj.sprintPlan = this.l1_obj?.sprintPlan;
          this.disabledDownloadSprintCapacity = false;
          this.originalL1Obj.isconfirm_sprint_us_allocation = this.l1_obj.isconfirm_sprint_us_allocation;
          if(showAllToasterMessage) {
            this.disabledDownloadSprintCapacity = false;
          }
          this._snackBar.open(this.successMessage, '', { duration: 5000 });

          this.defineRoadmapService.setconfirmedSprintCapacityL1Count({confirmedSprintCapacityL1Count: this.confirmCountObj + 1});
          this.defineRoadmapService.setsprintCapacityL1Confirm(true);
          this.nextStep();
        }
      });
  }

  previousStep(){
    if(this.selectedL1Index!=0)
    {
      let obj={
        parentIndex:this.selectedParentStepIndex,
        childIndex:this.selectedL1Index-1,
      }
      this.defineRoadmapService.setSelectedL1(obj);
    }
    else{
      let obj={
        parentIndex:this.selectedParentStepIndex-1,
        childIndex:0,
        direction:'previous'
      }
      this.defineRoadmapService.setSwitchParent(obj);
    }

  }

calculateTotalEffortsTotalUserStoriesForApply(data) {

  let l3_group = this.sortUSByMoscowPriority(this.moscowPriorityConfirmed, data?.l3_group);
  data.l3_group = l3_group;
  let l1_obj = data;
  this.calUnassignableUS(this.moscowPriorityConfirmed, JSON.parse(JSON.stringify(this.l1OG)));
    if(l1_obj?.sprintPlan?.length > 0){
  l1_obj.sprintPlan.forEach(sprint => {
      sprint.total_efforts = 0;
      sprint.total_user_stories = 0;

      sprint.total_f_story_point = 0;
      sprint.total_t_story_point = 0;
    //  sprint.total_story_point = 0;
      sprint.l3_group = [];

      l1_obj?.l3_group.forEach(l3_obj=>{
          let isL3AvailableForSprint = false;
          let templ3_obj= JSON.parse(JSON.stringify(l3_obj));
          templ3_obj.total_efforts = 0;
          templ3_obj.total_user_stories = 0;

          templ3_obj.total_f_story_point = 0;
          templ3_obj.total_t_story_point = 0;
        //  templ3_obj.total_story_point = 0;
          templ3_obj.type_level_data = [];

          l3_obj?.type_level_data?.forEach(type=>{
            let type_temp = JSON.parse(JSON.stringify(type));
              type_temp.total_efforts = 0;
              type_temp.total_user_stories = 0;
              type_temp.total_f_story_point = 0;
              type_temp.total_t_story_point = 0;
              type_temp.l4_group = [];
              type.l4_group.forEach(l4 => {
                if(l4.sprint_id && l4.sprint_id == sprint.sprint_id)
                  {
                    isL3AvailableForSprint = true;
                    type_temp.l4_group.push(l4);
                    type_temp.total_user_stories = type_temp.total_user_stories + 1;
                    type_temp.total_efforts += l4?.story_point;
                    type_temp.total_f_story_point += l4?.functional_story_points;
                    type_temp.total_t_story_point += l4?.technical_story_points;
                  }
              });

              templ3_obj.total_efforts += type_temp.total_efforts;
              templ3_obj.total_user_stories += type_temp.total_user_stories;
                templ3_obj.total_f_story_point += type_temp.total_f_story_point;
                templ3_obj.total_t_story_point += type_temp.total_t_story_point;
                if(type_temp.l4_group.length > 0) {
                  templ3_obj.type_level_data.push(type_temp);
                }
          });
          if(isL3AvailableForSprint) {
            sprint.total_user_stories += templ3_obj.total_user_stories;
            sprint.total_efforts += templ3_obj.total_efforts;
              sprint.total_f_story_point += templ3_obj.total_f_story_point;
              sprint.total_t_story_point += templ3_obj.total_t_story_point;
            // sprint.total_f_story_point += templ3_obj.total_f_story_point;
            // sprint.total_t_story_point += templ3_obj.total_t_story_point;
            // sprint.total_story_point += templ3_obj.total_story_point;
            sprint.l3_group.push(templ3_obj);
          }
      });
  });
}
}

toggle(view) {
  this.tabSelected = view ? {checked: true}: {checked: false};
  this.sprintBarchartComponent.toggle(this.tabSelected);
}

  ngOnDestroy()
  {
    this.selectedL1Subscription?.unsubscribe();
    this.globalContextSubscription?.unsubscribe();
    this.l1StatusDataSubscription?.unsubscribe();
    this.isAtleastOneL1ConfirmedSubscription?.unsubscribe();
  }

  // this method is to confirm the sprint allocation. it was called on confirm button click.
  saveAndConfirm() {
    this.saveSprintAssignments();
  }


}
