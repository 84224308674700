<div class="notification-container">
    <img class="polygon-arrow" src="assets/polygon-up.png" alt="" />
    <div class="notification-header" (click)="$event.stopPropagation();">
        <span class="notification-text">
            {{"Navigation.Menu.Notification.Title" | translate}}
            <span *ngIf="unreadRecords" class="notification-count">{{unreadRecords}}</span>
        </span>
        <span *ngIf="notifications?.length" class="mark-as-read-text" [ngClass]="isUnreadNotificationPresent ? 'cursor-pointer' : 'text-muted'" (click)="markAllAsRead()">
            {{"Navigation.Menu.Notification.MarkAllAsRead" | translate}}
        </span>
        <button *ngIf="notifications?.length" class="deleteAllBtn" (click)="deleteAllNotifications()">
        <span class="tooltiptext">{{"Navigation.Menu.Notification.DeleteAll" | translate}}</span>
        </button>
    </div>
    <ng-container *ngIf="!!notifications?.length else noDataModel">
        <cdk-virtual-scroll-viewport class="notification" itemSize="80" minBufferPx="300" maxBufferPx="300" style="height: 35vh;" (scrolledIndexChange)="onScroll($event)">
            <ng-container *cdkVirtualFor="let notification of notifications" [style.height.px]="80">
                <app-notification [showNotifications]="true" [notification]="notification"></app-notification>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </ng-container>
    
    <ng-template #noDataModel>
        <div class="notification" (click)="$event.stopPropagation();">
            <app-notification [showNotifications]="false"></app-notification>
        </div>
    </ng-template>
</div>