import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/shared/services/util.service';

interface ActiveTab {
    defaultSelectedIndex: number,
    activityId: string,
    tabCode: string
}

@Injectable({
    providedIn: 'root'
})
export class ActivateTabDataService {

    activateContentsJson: any[];
    activeTab: ActiveTab;

    constructor(private http: HttpClient, private utilService: UtilService) { }

    getTabDataURL(URL): Observable<any> {
        return this.http.get<any>(`${environment.BASE_URL}${URL}${this.utilService.setfilterParamsURL()}`);
    }

    setSelectedFilter(e): Observable<any> {

        this.clearData();

        if (this.utilService.isGlobalFilter(e.data.type)) {
            this.utilService.setSelectedFilter(e)
        }

        return of(e.data);
    }

    clearData() {
        this.activateContentsJson = [];
    }

    setActiveTabData(deliverable: any): void {
        if(deliverable && deliverable.content_sequence && deliverable.activity_id && deliverable.code)
        this.activeTab = {
            defaultSelectedIndex: deliverable.content_sequence - 1,
            activityId: deliverable.activity_id,
            tabCode: deliverable.code
        }
        else
        this.activeTab = null;
    }
}
