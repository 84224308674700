import { MasterData } from './masterdata.model';

export class ClusterCapabilitySubDriver extends MasterData {
    ValueMapSubDriverId: number;
    ClusterCapabilityId: number;
     MappingType: DriverMappingType; 
}

export enum DriverMappingType {
        None = 0,
        Primary = 1,
        Secondary = 2
}

// export class MapSettingClusterAttribute {   
//     ClusterId: number;
//     Name: string;
//     Description: string;
// }