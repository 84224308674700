import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, retry, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import {  TEST_SCENARIOS, ESTABLISH_PROJECT_TOOLS, REFINE_USER_STORIES_CONFIRMALL } from '../core/polling-constants';
import { ActivitiesService } from '@projects/trainstops/src/app/feature/activities/activities.service';
import {   PROCESS_FLOWS_FEATURE, SPRINTPLAN_TAB_CODE, USER_STORIES_TAB_CODE, USER_STORY_SPRINT_CEREMONY } from '@projects/trainstops/src/app/core/utility/constants';

@Injectable({
  providedIn: 'root',
})
export class PollingService {
  private processStartURL = `${environment.API_BASE_URL}/process/start`;
  private stopPolling = new Subject();
  private allPollingData$: Observable<any[]>;
  public resultAlmExport: BehaviorSubject<any>;
  public resultUSSprint: BehaviorSubject<any>;
  public resultMSTeamsExport: BehaviorSubject<any>;
  public resultProcessFlow: Subject<any> = new Subject()
  public emitter: EventEmitter<any> = new EventEmitter()
  public USExportPollingResponse: BehaviorSubject<any>;
  public processFlows: BehaviorSubject<any>;
  public importNonJiraUserStories: BehaviorSubject<any>;


  constructor(private http: HttpClient, private activitiesService: ActivitiesService) {
    this.resultAlmExport = new BehaviorSubject(null);
    this.resultUSSprint = new BehaviorSubject(null);
    this.resultMSTeamsExport = new BehaviorSubject(null);
    this.USExportPollingResponse = new BehaviorSubject(null);
    this.processFlows = new BehaviorSubject(null);
    this.importNonJiraUserStories = new BehaviorSubject(null);

  }

  startPolling(instanceId, entityName?, tabCode?) {
    let url;
    if(entityName == "GENERATE") {
    } else {
    url = `${environment.API_MICROSERVICE_URL.PROJECT}/process/status/` + instanceId;
    this.allPollingData$ = timer(1, 10000).pipe(
      switchMap(() =>
        this.http.get<any>(url).catch((err) => this.handleError(err))
      ),
      catchError((err) => this.handleError(err)),
      retry(5),
      tap((data) => {
        if (data.status === 'Completed' || data.status === 'Failed') {
          if (entityName === TEST_SCENARIOS) {
            this.exportAlmProcessResult(data.result);
          }
          else if (tabCode?.includes(USER_STORIES_TAB_CODE) || tabCode?.includes(SPRINTPLAN_TAB_CODE)) {
            this.exportUSCompletedModal(data);
            this.importUSModal(data);
          }
          else if(entityName == ESTABLISH_PROJECT_TOOLS){
            this.exportMSTeamsProcessResult(data);
          }
          else if(entityName == REFINE_USER_STORIES_CONFIRMALL){
            this.exportUSConfirmAll(data)
          }
          else if (tabCode?.includes(PROCESS_FLOWS_FEATURE)) {
            this.exportProcessFlowModal(data);
          } else if(tabCode?.includes(USER_STORY_SPRINT_CEREMONY)){
            this.importNonJiraUsModal(data);
          }
          else {
            this.exitPolling(data);
          }
        }
      }),
      share(),
      takeUntil(this.stopPolling)
    );
    }
  }

  private saveAsBlob(data: any, valueId) {
    if(data && data.body && data.body.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    const blob = new Blob([data.body], { type: "json" });
    const a = document.createElement("a");
    let fileName = `testcases_${valueId}.xlsx`
    a.setAttribute("download", fileName);
    a.setAttribute("href", window.URL.createObjectURL(blob));
    a.click();
    this.stopPolling.next();
    }
}

  getProcessStart(activityName): Observable<any> {
    return this.http.post<any>(this.processStartURL + '/' + activityName, {});
  }

  getPollingData(): Observable<any[]> {
    return this.allPollingData$.pipe(
      tap((data) => /* console.log('data sent to subscriber', new Date()) */ {})
    );
  }

  exitPolling(data) {
    this.stopPolling.next();
  }

  handleError(error: HttpErrorResponse) {
    console.log('An error occurred:', error);
    this.exitPolling(error);
    return throwError('Something bad happened; please try again later.');
  }

  exportAlmProcessResult(data) {
    this.resultAlmExport.next(data);
    this.stopPolling.next();
  }

  exportUSConfirmAll(data) {
    this.resultUSSprint.next(data);
    this.stopPolling.next();
  }
  setresultUSSprint() {
    this.resultUSSprint.next(null);
  }
  exportMSTeamsProcessResult(data) {
    this.resultMSTeamsExport.next(data.result);
    this.stopPolling.next();
  }

  exportProcessFlowModal(data) {
    this.stopPolling.next();
    this.processFlows.next(data);
    this.emitter.emit(data)
    this.activitiesService.setImportFilesData(data);
  }

  importUSModal(data) {
    this.stopPolling.next();
    this.emitter.emit(data)
    this.activitiesService.setImportFilesData(data);
  }
  exportUSCompletedModal(data) {
    this.stopPolling.next();
    this.USExportPollingResponse.next(data);

    this.emitter.emit(data)
  }

  importNonJiraUsModal(data) {
    this.stopPolling.next();
    this.importNonJiraUserStories.next(data);
  }

  setImportNonJiraUserStories() {
    this.importNonJiraUserStories.next(null);
  }

}
