import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from "shared-lib";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentRepositoryService {
  private downloadData: BehaviorSubject<any>;
  public DocumentName: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public dropdown: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedPackageMethod: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public updateActivityData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private packageContentMS = environment.API_MICROSERVICE_URL.PACKAGE_CONTENT;
  constructor(private http: HttpClient, private baseService: BaseService) {
    this.downloadData = new BehaviorSubject(null);
    this.dropdown = new BehaviorSubject(null);
  }

  getDocumentList(projectId, params) {
    return this.http.get<any>(`${this.baseService.BASE_URL}/project/summary/document-details/${projectId}`, { params });
  }

  getDocList(projectId, body) {
    if(projectId==0)
      return this.http.post(`${this.packageContentMS}/document-repository/summary/document-details`, body)
    else
      return this.http.post(`${environment.API_MICROSERVICE_URL.PROJECT}/project/summary/document-details/${projectId}`, body)
  }

  getDocDetails(guidId) {
    return this.http.get(`${environment.API_MICROSERVICE_URL.CONTENT_MGMT}/File/Metadata?guidName=`+guidId);
  }

  getDownloadData(): Observable<any> {
    return this.downloadData.asObservable();
  }

  setDownloadData(data: any) {
    this.downloadData.next(data);
  }
  getDropdown(): Observable<any> {
    return this.dropdown.asObservable();
  }

  setDropdown(data: any) {
    this.dropdown.next(data);
  }

  getDocumentFilters(projectId,languageId =1) {
    if(projectId==0)
      return this.baseService.get(`/document-repository/summary/document-filters`, this.packageContentMS)
    else
      return this.baseService.get(`/project/summary/document-filters/${projectId}?languageId=${languageId}`,environment.API_MICROSERVICE_URL?.PROJECT)
  }

  getProjectDocumentsCount(projectId: any) {
    return this.http.get(`${environment.API_MICROSERVICE_URL.PROJECT}/project/summary/documents/${projectId}`);

  }

  getDocumentName(): Observable<any> {
    return this.DocumentName.asObservable();
  }

  setDocumentName(document: any) {
    this.DocumentName.next(document);
  }

  searchDocument(projectId: any, languageId: number, params?: any) {
    if(projectId==0)
      return this.http.get(`${this.packageContentMS}/document-repository/summary/document-search?languageId=${languageId}`, { params });
    else
      return this.http.get(`${environment.API_MICROSERVICE_URL.PROJECT}/project/summary/document-search/${projectId}?languageId=${languageId}`, { params });
  }

  getDocumentByGuid(fileData: any) {
    return this.http.post(`${environment.API_MICROSERVICE_URL.CONTENT_MGMT}/File/URIWithSAS`, fileData);
  }

  downloadDocument(fileData: any) {
    return this.http.post(`${environment.API_MICROSERVICE_URL.CONTENT_MGMT}/File/Download`, fileData, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  getSelectedPackageMethod() {
    return this.selectedPackageMethod.asObservable();
  }

  getUpdatedActivityData() {
    return this.updateActivityData.asObservable();
  }
}
