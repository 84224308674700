export class MasterData {
    constructor() {
        this.ModifiedDate = this.CreatedDate = new Date();
    }
    Id: number;
    Name: string;
    CreatedBy: number;
    CreatedDate: Date;
    ModifiedBy: number;
    ModifiedDate: Date;
    UserEmail: string;
    FirstName: string;
    LastName: string;
    ModifiedByName: string;
}